import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { Router } from "@angular/router";
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared.service';
import { GlobalService } from 'src/app/global.service';
import data from 'src/app/components/data/data';
import * as _moment from 'moment';
import { AcademicDiscipline, Qualificationa, Specialization } from '../educartion.types';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Qualification } from 'src/app/interface';
import { map, startWith } from 'rxjs/operators';
const moment = _moment;


@Component({
    selector: 'app-add-edit-edu',
    templateUrl: './add-edit-edu.component.html',
    styleUrls: ['./add-edit-edu.component.scss']
})
export class AddEditEducationComponent implements OnInit, OnDestroy {
    // submitted: boolean = false;
    submittedEdu: boolean = false;
    educationForm: FormGroup;
    public educationInfo = [];
    city = data.cities;
    response: any;
    eduId: number = null;
    saveEduBtn: boolean = true;
    updateEduBtn: boolean = false;
    // openform = false;
    boolCreate = true;
    allQualification: Observable<Qualification[]>;
    alldiscipline: Observable<AcademicDiscipline[]>;
    allspecialization: Observable<Specialization[]>;
    allEducationType: Observable<any>;
    public education: any;
    hideForm: boolean = true;

    allQualificationauto: Qualification[];
    filterQualification?: Observable<Qualification[]>;
    formControlQualificationine = new FormControl();
    QualificationId: number;

    alldisciplineauto: AcademicDiscipline[];
    filterdiscipline?: Observable<AcademicDiscipline[]>;
    formControldiscipline = new FormControl();
  
    allspecializationauto: Specialization[];
    filterspecialization?: Observable<Specialization[]>;
    formControlspecialization = new FormControl();
    disciplineId : number;
    specializationId : number;
  
    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private service: SharedService<any>,
        private Global: GlobalService,
        private router: Router,
        private dialogRef: MatDialogRef<AddEditEducationComponent>,
        @Inject(MAT_DIALOG_DATA) public editData: any
    ) { }

    ngOnInit(): void {

        this.educationForm = this.formBuilder.group({
            Id: ['', null],
            EmpId: ['', null],
            SchoolOrUniversity: ['', [Validators.required]],
            QualificationId: ['', [Validators.required]],
            Qualification: ['', [Validators.nullValidator]],
            Specialization: ['', [Validators.nullValidator]],
            GradeOrPercentage: ['', [Validators.required]],
            EducationType: ['', [Validators.nullValidator]],
            FromDateOfEducation: ['', [Validators.nullValidator]],
            ToDateOfEducation: ['', [Validators.nullValidator]],
            PassoutYear: ['', [Validators.required]],
            AcademicDisciplineId: ['', Validators.required],
            AcademicDescription: ['', Validators.nullValidator],
            SpecializationId: ['', Validators.required],
            SpecializationDescription: ['', Validators.nullValidator]
        });

        this.service.Get('Qualification/GetQualification?Id=0', null).subscribe(data => {
            if (data != null) {
              this.allQualificationauto = data;
              //this.formControlQualificationine.setValue(this.allQualification[2]);
              this.filterQualification = this.formControlQualificationine.valueChanges.pipe(
                startWith<string | Qualification>(''),
                map(value => typeof value === 'string' ? value : value.Qualification),
                map(Qualification => Qualification ? this._filterQualification(Qualification) : this.allQualificationauto.slice()),
              );
              //this.onchangeQualification(this.allQualification[2].Id)
            }
          });
          
        this.allQualification = this.service.Get('Qualification/GetQualification?Id=0', null);


        this.service.Get('GetListValue?Id=4', null).subscribe(educationtype => {
            this.allEducationType = educationtype;
        });
        if (this.editData) {
            this.editEdu(this.editData)
            this.updateEduBtn = true
            this.saveEduBtn = false
        }
        else {
            this.updateEduBtn = false
            this.saveEduBtn = true
        }
    }

    private _filterQualification(Qualification: string): Qualification[] {
        const filterValue = Qualification.toLowerCase();
        return this.allQualificationauto.filter(
          option => option.Qualification?.toLowerCase().indexOf(filterValue) === 0
        );
      }
    
      displayFnForQualification(value: any) {
        return value ? value.Qualification : undefined;
      }
    
      private _filterdiscipline(AcademicDescription: string): AcademicDiscipline[] {
        const filterValue = AcademicDescription.toLowerCase();
        return this.alldisciplineauto.filter(
          option => option.AcademicDescription?.toLowerCase().indexOf(filterValue) === 0
        );
      }
    
      displayFnFordiscipline(value: any) {
        return value ? value.
          AcademicDescription : undefined;
      }
    
      private _filterSpecialization(SpecializationDescription: string): Specialization[] {
        const filterValue = SpecializationDescription.toLowerCase();
        return this.allspecializationauto.filter(
          option => option.SpecializationDescription?.toLowerCase().indexOf(filterValue) === 0
        );
      }
    
      displayFnForSpecialization(value: any) {
        return value ? value.
          SpecializationDescription : undefined;
      }

    get fedu(): { [key: string]: AbstractControl } {
        return this.educationForm.controls;
    }

    //Education
    educationInfoForm() {
        this.submittedEdu = true;
        this.educationForm.controls["QualificationId"].setValue(this.QualificationId);
            this.educationForm.controls["AcademicDisciplineId"].setValue(this.disciplineId);
            this.educationForm.controls["SpecializationId"].setValue(this.specializationId);
        if (this.educationForm.invalid) {
            this.toastr.error("Please fill out the required fields (* fields)");
        }
        else {
            

            const updateeducation = this.educationForm.getRawValue();
            updateeducation.EmpId = this.Global.getCandidateId();
            this.service.Post('CandidateEducationInfo/InsertCandidateEducationInfo/', updateeducation).subscribe(
                (res) => {
                    if (res) {
                        this.toastr.success(res);
                        this.hideForm = false;
                        this.dialogRef.close('Save');
                        this.boolCreate = true;

                    } else {
                        this.toastr.error('Error While Insert Data');
                    }
                },
                (error) => {
                    this.toastr.error(error.error);
                });
            this.submittedEdu = false;
        }
    }
    close(){
        this.dialogRef.close(); 
    }
    educationUpdateForm() {
        this.submittedEdu = true;
        // let data = this.educationForm.value;

        if (this.educationForm.invalid) {
            this.toastr.error("Please fill out the required fields (* fields)");
        }
        else {
            const updateeducation = this.educationForm.getRawValue();
            this.service.Put('CandidateEducationInfo/UpdateCandidateEducationInfo/', this.educationForm.value).subscribe(
                (res) => {
                    if (res) {
                        this.toastr.success(res);

                        this.dialogRef.close('Update');
                        this.boolCreate = true;
                    } else {
                        this.toastr.error('Error While Update Data');
                    }
                },
                (error) => {
                    this.toastr.error(error.error);
                });

            this.submittedEdu = false;
        }

    }

    // getAllEducations() {
    //     this.service.Get('CandidateEducationInfo/GetCandidateEducationInfoByEmpId?EmpId=' + this.Global.getCandidateId(), null).subscribe(res => {
    //         this.educationInfo = res;
    //     },

    //         (error) => {
    //             this.toastr.error(error.error);
    //         });
    // }

    editEdu(enducationifo: any) {

        this.educationForm.controls["Id"].setValue(enducationifo.Id);
        this.educationForm.controls["SchoolOrUniversity"].setValue(enducationifo.SchoolOrUniversity);
        this.educationForm.controls["GradeOrPercentage"].setValue(enducationifo.GradeOrPercentage);
        this.educationForm.controls["QualificationId"].setValue(enducationifo.QualificationId);
        this.educationForm.controls["EducationType"].setValue(enducationifo.EducationType);

        const momentDate = new Date(enducationifo.FromDateOfEducation);
        const formateddate = moment(momentDate).format("yyyy-MM-DD");
        enducationifo.FromDateOfEducation = formateddate;
        this.educationForm.controls["FromDateOfEducation"].setValue(enducationifo.FromDateOfEducation);

        const momentDate1 = new Date(enducationifo.ToDateOfEducation);
        const formateddate1 = moment(momentDate1).format("yyyy-MM-DD");
        enducationifo.ToDateOfEducation = formateddate1;

        this.educationForm.controls["ToDateOfEducation"].setValue(enducationifo.ToDateOfEducation);
        this.educationForm.controls["AcademicDisciplineId"].setValue(enducationifo.AcademicDisciplineId);
        this.educationForm.controls["PassoutYear"].setValue(enducationifo.PassOutYear);


        this.educationForm.controls["SpecializationId"].setValue(enducationifo.SpecializationId);

        this.alldiscipline = this.service.Get('AcademicDiscipline/GetAcademicDisciplineByQualification?QualificationId=' + enducationifo.QualificationId, null);
        this.allspecialization = this.service.Get('Specialization/GetSpecializationDataByDiscipline?AcademicDisplineId=' + enducationifo.AcademicDisciplineId, null);
        this.service.Get('GetListValue?Id=4', null).subscribe(educationtype => {
            this.allEducationType = educationtype;
        });
        this.submittedEdu = false;
    }




    onchangeQualification(event) {
        this.allspecialization = null;
        if(this.saveEduBtn == true)
        {
            this.QualificationId = event;
            this.service.Get('AcademicDiscipline/GetAcademicDisciplineByQualification?QualificationId=' +  this.QualificationId, null).subscribe(data => {
              if (data != null) {
                this.alldisciplineauto = data;
                this.filterdiscipline = this.formControldiscipline.valueChanges.pipe(
                  startWith<string | AcademicDiscipline>(''),
                  map(value => typeof value === 'string' ? value : value.AcademicDescription),
                  map(AcademicDescription => AcademicDescription ? this._filterdiscipline(AcademicDescription) : this.alldisciplineauto.slice()),
                );
              }
            })

        }
        else
        {
            this.alldiscipline = this.service.Get('AcademicDiscipline/GetAcademicDisciplineByQualification?QualificationId=' + event, null);
        }
        
    }


    onchangeDiscipline(event) {
        if(this.saveEduBtn == true){
            this.disciplineId = event
            this.service.Get('Specialization/GetSpecializationDataByDiscipline?AcademicDisplineId=' +  this.disciplineId , null).subscribe(data => {
                if (data != null) {
                  this.allspecializationauto = data;
                  this.filterspecialization = this.formControlspecialization.valueChanges.pipe(
                    startWith<string | Specialization>(''),
                    map(value => typeof value === 'string' ? value : value.SpecializationDescription),
                    map(SpecializationDescription => SpecializationDescription ? this._filterSpecialization(SpecializationDescription) : this.allspecializationauto.slice()),
                  );
                }
              })
        }
        this.allspecialization = this.service.Get('Specialization/GetSpecializationDataByDiscipline?AcademicDisplineId=' + event, null);
    }

    onchangeSpecialization(event) {
        this.specializationId = event
        
    }

    // OnChangeSpecialization(event:any) {      

    //     //alert(event);
    // }


    ngOnDestroy(): void {

    }

}
