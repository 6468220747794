<div class="profile-content" id="form">
<form autocomplete="off" novalidate [formGroup]="locationForm"  class="profile-content">
    <!-- <i matTooltip="Close" style="font-size: 35px;margin-left: 402px;margin-top: -15px;color: red;"  
    class='bx bx-x Clear' (click)="close()"></i> -->
    <div style="width: 10px;
    height: 5px;
    position: relative;
    float: right;
    top: -16px;"  ><i matTooltip="Close" style="font-size: 25px;"  
        class='bx bx-x Clear' (click)="close()"></i></div>
    
    <div class="profile-content-inner">
        <div class="Name">Add Preffered Location</div>
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label>Location <label class="star">*</label></label>
                    <input type="text" class="form-control form-select" [formControl]="formCities"
                    [matAutocomplete]="autocity" placeholder="Select Location"
                    [ngClass]="{ 'is-invalid': submittedLocation && floc.JobLocation.errors }">
                <mat-autocomplete #autocity="matAutocomplete" [displayWith]="displayFnForCity" 
                    (optionSelected)="onchangeCity($event.option.value.Id)">
                    <mat-option *ngFor="let cities of filterCities | async" [value]="cities" >
                        {{cities.CityName}}
                    </mat-option>
                </mat-autocomplete>
                </div>
            </div>
        </div>
    </div>

    <!-- <button (click)="locationInfoForm()" class="btn dashboard-btn" *ngIf="saveLocBtn">
        Save Location
    </button>
    <button (click)="locationUpdateForm()" class="btn dashboard-btn" *ngIf="updateLocBtn">
        Update Your Location
    </button> -->

    <div class="button">
        <button (click)="close()" class=" btn cancle " >
            Cancel
        </button>

    <button (click)="locationInfoForm()" class="btn savebtn" *ngIf="saveLocBtn">
        Save
    </button>

    <button (click)="locationUpdateForm()" class="btn savebtn" *ngIf="updateLocBtn">
        Update
    </button>
    </div>
</form>
</div>