import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Router } from "@angular/router";
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared.service';
import { GlobalService } from 'src/app/global.service';
import { CollegeInformation } from 'src/app/interface';
import userToken from 'src/app/components/config/userToken';

@Component({
    selector: 'app-college_home',
    templateUrl: './college_home.component.html',
    styleUrls: ['./college_home.component.scss']
})

export class CollegeHomeComponent implements OnInit, OnDestroy{

    getcollegeData:CollegeInformation;
    userId: number = userToken.id;
    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private router: Router,
        private Service: SharedService<any>,
        private Global: GlobalService
    ) { }

    ngOnInit(): void {
        this.getUser();
        
    }


    getUser() {
        this.Service.Get('College/GetCollegeData?Id=' + this.Global.getuserId(), null).subscribe(
            (res) => {
                this.getcollegeData = res;
            },        
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }

    ngOnDestroy(): void {
        
    }
}