import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { PostAJobService } from './services/post-a-job.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { cities, Country, State } from '../dashboard/basic-info/basic-info.types';
import { AcademicDiscipline, Area, CompanyInformation, Currency, DocumentsList, JobRole, Qualificationa, Skills, Specialization, SpecialQualificationa ,ListValuesMap, UserType} from 'src/app/interface';
import { SharedService } from 'src/app/shared.service';
import { GlobalService } from 'src/app/global.service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

export class POstJobSpecialization {
    SpecializationId: number[];
    PostJobId: number;
}

export class POstJobList {
    JobCompanyName: string;
    Id: number;
    SpecializationDescription: string
    PostJobId: number;
}

@Component({
    selector: 'app-post-a-job',
    templateUrl: './post-a-job.component.html',
    styleUrls: ['./post-a-job.component.scss']
})
export class PostAJobComponent implements OnInit, OnDestroy {
@ViewChild('select') select: MatSelect;
    
    submittedJob: boolean = false;

    jobPostForm: FormGroup;
    public jobPostInfo = [];

    response: any;
    showMe: boolean = false;
    displayMe: boolean = false;
    hideMe: boolean = false;
    appearMe: boolean = false;


    allCities: Observable<cities[]>;
    allCountries: Observable<Country[]>
    allSalarytype?:Observable<any[]>;

    allJobRole: Observable<JobRole[]>;
    allareas: Observable<Area[]>;
    allQualification?: Observable<Qualificationa[]>;
    allspecialqualification?: Observable<SpecialQualificationa[]>;
    allDocuments?: Observable<DocumentsList[]>;
    allSkills?: Observable<Skills[]>;
    allCurrency?: Observable<Currency[]>;
    allGender?: Observable<any[]>;
    allJobType?: Observable<any[]>;
    allTypeofjob?: Observable<any[]>;
    allExperienceType?: Observable<any[]>;
    allWorkingDays?: Observable<any[]>;
    allDutyType?: Observable<any[]>;
    allStates: Observable<State[]>;
    allJobLocationType: Observable<any[]>;
    allworkinghpurs: Observable<any[]>;
    allShiftType?: Observable<any[]>;
    allNoticePeriod?: Observable<any[]>;
    alldiscipline: Observable<AcademicDiscipline[]>;
    allspecialization: Observable<POstJobList[]>;
    allCompanyName: Observable<CompanyInformation>;
    usertype: number;
    internshipvalue: boolean = true;
    stipend: boolean = false;
    salary: boolean = true;
    selectedStatus: number = 0;
    companydata: CompanyInformation;
    Company: number;
    CompanyAddress1: string = '';
    allResumebuild: CompanyInformation;
    selectCompanyID:number ;
    Country: number;
    joblocationvalue: number = 0;
    salarytype:boolean = true;
    allSelected = false;
    specializationsId: number[] = [];
    specializationlist: POstJobList[];
    sendspcId: POstJobSpecialization;

    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private service: SharedService<any>,
        private router: Router,
        private Global: GlobalService,
        private postAJobService: PostAJobService
    ) { }

    ngOnInit(): void {
        this.jobPostForm = this.formBuilder.group({
            CompanyId: ['', Validators.nullValidator],
            JobCompanyName: ['', Validators.required],
            JobRoleId: ['', Validators.required],
            JobLocation: ['', Validators.required],
            JobLocationCity: ['', Validators.nullValidator],
            JobType: ['', Validators.required],
            CurrencyId: ['', Validators.nullValidator],
            MinSalary: ['', Validators.nullValidator],
            MaxSalary: ['', Validators.nullValidator],
            JobOpening: ['', Validators.required],
            ReqQualification: ['', Validators.required],
            Qualification: ['', Validators.nullValidator],
            ExperienceType: ['', Validators.nullValidator],
            ExperienceTypeName: ['', Validators.nullValidator],
            JobShift: ['', Validators.nullValidator],
            JobShiftName: ['', Validators.nullValidator],
            MinYearExp: ['', Validators.nullValidator],
            SkillId: ['', Validators.nullValidator],
            Gender: ['', Validators.nullValidator],
            JobDescription: ['', Validators.required],
            Country: ['', [Validators.required]],
            State: ['', [Validators.required]],
            City: ['', [Validators.nullValidator]],
            WorkingDays: ['', Validators.nullValidator],
            CompanyAddress: ['', Validators.required],
            Typeofjob: ['', Validators.nullValidator],
            SubTypeofJob: ['', Validators.nullValidator],
            DutyType: ['', Validators.nullValidator],
            DutyTypeName: ['', Validators.nullValidator],
            NoticePeriod: ['', Validators.nullValidator],
            NoticePerioddays: ['', Validators.nullValidator],
            Timing: ['', Validators.nullValidator],
            JobTiming: ['', Validators.nullValidator],
            JobLocationType: ['', Validators.nullValidator],
            IsInternShip: ['', Validators.nullValidator],
            AcademicDisciplineId: ['', Validators.required],
            RecruiterId: ['', Validators.nullValidator],
            SpecializationId: ['', Validators.required],
            Salarytype: ['', Validators.nullValidator],
            TargetDate: ['', Validators.required],
            JobSpecification: ['', Validators.nullValidator],
            JobFor: ['', Validators.nullValidator],
            // IsDisClose: ['', Validators.required],
        })
        //this.allCities = this.service.Get('/City/GetCity?Id=0', null);
        this.jobPostForm.controls["JobLocationType"].setValue(this.selectedStatus);
        this.usertype = this.Global.GetUserType();
        this.allJobRole = this.service.Get('JobRole/GetJobRole?Id=0', null);
        this.allQualification = this.service.Get('Qualification/GetQualification?Id=0', null);
        this.allCurrency = this.service.Get('Currency/GetCurrency?Id=0', null);
        this.allSkills = this.service.Get('Skills/GetSkills?Id=0', null);
        this.allGender = this.service.Get('GetListValue?Id=' + ListValuesMap.Gender, null);
        this.allJobType = this.service.Get('GetListValue?Id='+ ListValuesMap.JobType, null);
        this.allTypeofjob = this.service.Get('GetListValue?Id=' + ListValuesMap.TypeOfJob, null);
        this.allworkinghpurs = this.service.Get('GetListValue?Id=' + ListValuesMap.JobWokingHours, null);
        this.allNoticePeriod = this.service.Get('GetListValue?Id=' + ListValuesMap.NoticePeriods, null);
        this.allDutyType = this.service.Get('GetListValue?Id=' + ListValuesMap.DutyType, null);
        this.allExperienceType = this.service.Get('GetListValue?Id=' + ListValuesMap.ExperienceType, null);
        this.allShiftType = this.service.Get('GetListValue?Id=' + ListValuesMap.ShiftType, null);
        this.allWorkingDays = this.service.Get('GetListValue?Id='+ ListValuesMap.WorkingDays, null);
        this.allCountries = this.service.Get('Country/GetCountry?Id=0', null);
        this.allSalarytype = this.service.Get('GetListValue?Id=' + ListValuesMap.Salarytype, null);

      //  this.allStates = this.service.Get('/State/GetStateData?Id=0', null);
        this.allJobLocationType = this.service.Get('GetListValue?Id='+ ListValuesMap.JobLocationType, null);
        //this.alldiscipline = this.service.Get('/AcademicDiscipline/GetAcademicDisciplineData?Id=0', null);
      //  this.allspecialization = this.service.Get('/Specialization/GetSpecialization?Id=0', null);

         if(this.usertype == UserType.Owner || this.usertype == UserType.Recruiter){
            this.allCompanyName = this.service.Get('Company/GetCompanyDataSelection', null);
         }       
      
        this.jobPostForm.controls["JobCompanyName"].setValue(this.Global.GetCompanyName());
        this.GetCompanyDataById();
    }

    get fjob(): { [key: string]: AbstractControl } {
        return this.jobPostForm.controls;
    }

   
    jobPostInfoForm() {
        this.submittedJob = true;

        if (this.jobPostForm.invalid) {
            this.toastr.error("This data is not valid");
        }
        else {
            this.allspecialization.subscribe((value: POstJobList[]) => {
                this.specializationlist = value;
                if (this.allSelected) {
                    for (var index in this.specializationlist) {
                        this.specializationsId.push(this.specializationlist[index].Id);
                    }
                    this.sendspcId.SpecializationId = this.specializationsId;
                    this.jobPostForm.controls["CompanyId"].setValue(this.selectCompanyID);
                    const Job = this.jobPostForm.getRawValue();
                    Job.IsInternShip = !this.internshipvalue;
                    Job.JobLocationType = this.joblocationvalue;
                   
                    Job.SpecializationId = this.sendspcId.SpecializationId = this.specializationsId
                    this.service.Post('Job/InsertJobPostData', Job).subscribe(
                        (res) => {
                            this.toastr.success(res);
                            this.jobPostForm.reset();
                            this.router.navigate(['companymanagejob']);
                        },
                        (error) => {
                            this.toastr.error(error.error);
                        });
                    this.sendspcId.SpecializationId = [];
                    this.submittedJob = false;
                }
                else {
                    
                
                    this.jobPostForm.controls["CompanyId"].setValue(this.selectCompanyID);
                    const Job = this.jobPostForm.getRawValue();
                    Job.IsInternShip = !this.internshipvalue;
                    Job.JobLocationType = this.joblocationvalue;
                   
                    Job.SpecializationId =this.specializationsId
                    this.service.Post('Job/InsertJobPostData', Job).subscribe(
                        (res) => {
                            this.toastr.success(res);
                            this.jobPostForm.reset();
                            this.router.navigate(['companymanagejob']);
                        },
                        (error) => {
                            this.toastr.error(error.error);
                        });
                    this.sendspcId.SpecializationId = [];
                    this.submittedJob = false;

                }
            });


        }
    }
    onchangeQualification(Qualification: any) {
        this.alldiscipline = this.service.Get('AcademicDiscipline/GetAcademicDisciplineByQualification?QualificationId='+ Qualification, null);
      }

    onchangeCountry(event) {
        this.allStates = this.service.Get('State/GetStateByCountry?countryId=' + event, null);
        this.Country = event;
        if (this.Country == 1) {
          this.jobPostForm.controls["CurrencyId"].setValue(68);
        }
        else {
          this.jobPostForm.controls["CurrencyId"].setValue(0);
        }

    }
    
    onchangeCity(event) {
        this.allCities = this.service.Get('City/GetCityDataByState?StateId=' + event, null);
    }

    onchangeDiscipline(event) {
        this.allspecialization = this.service.Get('Specialization/GetSpecializationDataByDiscipline?AcademicDisplineId=' + event, null);
    }
    onchangesalary(event){
        if(event == 0){
            this.salarytype = false;
            }
            else{
                this.salarytype = true;

            }
        }

    
    onchangecompany(event) {
        if (this.usertype == UserType.Owner || this.usertype == UserType.Recruiter) {
            this.allCompanyName.subscribe((data) => {
                const company = data.find((item) => item.CompanyName === event)
                this.jobPostForm.controls["CompanyAddress"].setValue(company.Address1 + ' ' + company.Address2);
                this.selectCompanyID = company.Id;
            })
        }
        else {
            this.CompanyAddress1 = event.value.Address1;
            this.jobPostForm.controls["CompanyAddress"].setValue(this.CompanyAddress1);
            this.selectCompanyID = this.Global.GetCompanyId();
        }
    }

    toggleTag() {
        this.showMe = true;
        this.displayMe = false;
        this.hideMe = false;
    }    

    intership() {
        this.internshipvalue = true;
        this.stipend = false;
        this.salary = true;
    }
    intership1() {
        this.internshipvalue = false;
        this.stipend = true;
        this.salary = false;
    }

    expType(event: any) {
        if (event == 0) {
            this.appearMe = false;
        }
        else {
            this.appearMe = true;
        }

    }

    GetCompanyDataById(){
        this.service.Get('Company/GetCompanyInfoByUserId?userId=' + this.Global.getuserId(), null).subscribe(data => {
            this.allResumebuild = data;
            this.jobPostForm.controls["CompanyAddress"].setValue(this.allResumebuild[0].Address1 + this.allResumebuild[0].Address2);
            this.selectCompanyID = this.allResumebuild[0].Id;
      
          })
        }

        wfh(){
            this.joblocationvalue = 1;
        }
        fieldjobs(){
            this.joblocationvalue = 2;
        }
    
        
    toggleAllSelection() {

        if (this.allSelected) {
            this.select.options.forEach((item: MatOption) => item.select());
        } else {
            this.select.options.forEach((item: MatOption) => item.deselect());
        }
    }

    getValues(event: {

        isUserInput: any;
        source: { value: any; selected: any };
    }) {
        if (event.isUserInput) {
            
            if (event.source.selected === true) {
                
                this.specializationsId.push(event.source.value);
            } else {
                var index = this.specializationsId.indexOf(event.source.value);
                if (index !== -1) {
                    this.specializationsId.splice(index, 1);
                }
            }
        }
    }

    ngOnDestroy(): void {

    }



}