<div class="profile-content" id="form">

    <form autocomplete="off"  [formGroup]="languageForm"  class="profile-content">
       
        <div style="width: 10px;
        height: 5px;
        position: relative;
        float: right;
        top: -16px;"  ><i matTooltip="Close" style="font-size: 25px;"  
            class='bx bx-x Clear' (click)="close()"></i></div>
        <div class="profile-content-inner">
            <div class="Name">Add Languages</div>
             <div class="row">
                <div class="col-lg-12 col-md-12">
            <div class="form-group">
                <label>Language <label class="star">*</label></label>
                <!-- <select class="form-control form-select" formControlName="LanguageId"
                    [ngClass]="{'is-invalid':submittedLanguage && flang.LanguageId.errors}">
                    <option value="" disabled selected>Please select Language</option>
                    <option *ngFor="let language of allLanguages"
                        [value]="isSkill == true ? language.Id :  language.Id ">
                        {{language.Language}}
                    </option>
                </select> -->

                <input type="text" class="form-control form-select" [formControl]="formControlLanguages"
                [matAutocomplete]="auto" placeholder="Select Language">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnForLanguage"
                (optionSelected)="onchangeLanguage($event.option.value.Id)">
                <mat-option *ngFor="let language of filterLanguages | async" [value]="language">
                    {{language.Language}}
                </mat-option>
            </mat-autocomplete>
            </div>
            </div>
            </div>
        </div>

        <!-- <button (click)="languageInfoForm()" class="btn dashboard-btn" *ngIf="saveLangBtn">
            Save Language
        </button>
        <button (click)="languageUpdateForm()" class="btn dashboard-btn" *ngIf="updateLangBtn">
            Update Your Language
        </button> -->

        <div class="button">
            <button (click)="close()" class=" btn cancle " >
                Cancel
            </button>

        <button (click)="languageInfoForm()" class="btn savebtn" *ngIf="saveLangBtn">
            Save
        </button>

        <button (click)="languageUpdateForm()" class="btn savebtn" *ngIf="updateLangBtn">
            Update
        </button>
        </div>
    </form>
</div>