import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Route, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/global.service';
import { SharedService } from 'src/app/shared.service';
import userToken from "../../config/userToken";
import { EmployersRegisterComponent } from '../../pages/employers-register/employers-register.component';
import { NavbarService } from '../services/navbar.service';


@Component({
    selector: 'app-navbar-style-three',
    templateUrl: './navbar-style-three.component.html',
    styleUrls: ['./navbar-style-three.component.scss']
})
export class NavbarStyleThreeComponent implements OnInit {
    token: any = userToken.token;
    isToken: boolean = false;
    userName: string;
    screenWidth: any;
    screenHeight: any;
    menu: boolean = false
    close: boolean = false;
    list: boolean = true;
    smallscreen: boolean = false;
    largescreen: boolean = false;

    constructor(private navbarService: NavbarService,
        private toastr: ToastrService,
        public myGlobals : GlobalService,
        public router: Router,
        private service:SharedService<any>,
        private dialog: MatDialog,

        ) { }

    ngOnInit(): void {
        this.getToken();
        this.userName = this.myGlobals.Getprofilename();
        this.getScreenSize();
    }
 
 getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 768 ||
       this.screenWidth == 1486 ||
       this.screenWidth == 1007 ||
       this.screenWidth == 1370 || this.screenHeight == 2784) {
      this.smallscreen = true;
      this.largescreen = false
    }
    else {
      this.smallscreen = false;
      this.largescreen = true;
    }

  }

  click() {
    this.menu = true;
    this.close = true;
    this.list = false;
  }

  closeclick1() {
    this.menu = false;
    this.close = false;
    this.list = true;
  }

    getToken() {
        if (this.token) this.isToken = true;
    }

   
    // onLogout(event: Event) {
    //     event.preventDefault();
    //     this.navbarService.logout().subscribe(
    //         (res) => {
    //             this.toastr.success(res.message);
    //         },
    //         (error) => {
    //             this.toastr.error(error.error.message);
    //         }
    //     )
    // }

    

    onLogout(event: Event){
        this.service.Post('User/SetUserLogOutAudit?userName=' +  this.myGlobals.Getprofilename(), null).subscribe({
            next: (res) => {
        this.myGlobals.isSubmitted = false;
        this.myGlobals.isCompany = false;
       // this.myGlobals.getLoginData = null;
        this.router.navigate(['/login']);

    }
})
}
AddCandidate() {
    const dialogRef = this.dialog.open(EmployersRegisterComponent, {
      width: '20%'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == "Save") {
       
      }
    });
  }

}
