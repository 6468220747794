<!-- <div class="bottom-item">
    <div class="create-button">
        <div>
            <h3> <img src="assets/png/pinklogo.png" alt="Icon">
                Experience</h3>
        </div>
        <div>
            <a class="cmn-btn" (click)="resetExp()">{{(boolCreate ? 'Create' : 'Hide Form')}} <i
                    class='bx bx-plus'></i></a>
        </div>
    </div>
</div> -->

<!-- <svg
class="rectangle-1119"
width="710"
height="360"
viewBox="0 0 710 360"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<g filter="url(#filter0_d_1015_1478)">
  <path
    d="M17.7778 0.444445H691.556C701.129 0.444445 708.889 8.20484 708.889 17.7778V341.333C708.889 350.906 701.129 358.667 691.556 358.667H17.7778C8.20482 358.667 0.444445 350.906 0.444445 341.333V17.7778C0.444445 8.20482 8.20484 0.444445 17.7778 0.444445Z"
    stroke="black"
    stroke-width="0.888889"
    shape-rendering="crispEdges"
  />
</g>
<defs>
  <filter
    id="filter0_d_1015_1478"
    x="-17.7778"
    y="-17.7778"
    width="944.889"
    height="394.667"
    filterUnits="userSpaceOnUse"
    color-interpolation-filters="sRGB"
  >
    <feFlood flood-opacity="0" result="BackgroundImageFix" />
    <feColorMatrix
      in="SourceAlpha"
      type="matrix"
      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      result="hardAlpha"
    />
    <feOffset />
    <feGaussianBlur stdDeviation="8.88889" />
    <feComposite in2="hardAlpha" operator="out" />
    <feColorMatrix
      type="matrix"
      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
    />
    <feBlend
      mode="normal"
      in2="BackgroundImageFix"
      result="effect1_dropShadow_1015_1478"
    />
    <feBlend
      mode="normal"
      in="SourceGraphic"
      in2="effect1_dropShadow_1015_1478"
      result="shape"
    />
  </filter>
</defs>
</svg> -->

<div>

    <div class="frame-1170">
        <div class="education">Personal Detail</div>

        <!-- <div class="add-education">ADD DETAIL</div> -->
    </div>


    <div *ngIf="this.hideForm">
        <div
            class="school-or-university-sikkim-manipal-university-specialization-computers-grade-or-percentage-7-08-education-type-name-correspondence-distance-passout-date-01-01-2013">


            <div class="work bottom-item" *ngIf="info" style="margin-bottom: 19px;margin-left: -19px;"
                class="box-centerside">
                 <i class="bx bx-edit bx-sm bx-tada-hover" style="float: right;" (click)="EditPI()"></i>
                <!-- <i class="bx bx-trash bx-sm bx-tada-hover" style="float: right;" (click)="delLang(lang)"></i>-->
                <table style="width: 708px;
                    height: 337px; margin-left: 32px;">
                    <tbody>
                        <tr>
                            <td scope="row">First Name :</td>
                            <td>{{info.FirstName}}</td>
                        </tr>
                        <tr>
                            <td scope="row">Last Name :</td>
                            <td>{{info.LastName}}</td>
                        </tr>

                        <tr>
                            <td scope="row">Mobile No :</td>
                            <td>{{info.MobileNo}}</td>
                        </tr>
                        <tr>
                            <td scope="row">Date of Birth :</td>
                            <td>{{info.DateOfBirth | date:'dd-MM-yyyy'}}</td>
                        </tr>
                        <tr>
                            <td scope="row">Gender :</td>
                            <td>{{info.GenderDesc}}</td>
                        </tr>
                        <tr>
                            <td scope="row">Marital Status : </td>
                            <td>{{info.MaritalStatusDesc}}</td>
                        </tr>
                        <tr>
                            <td scope="row">Nationality :</td>
                            <td>{{info.Nationality}}</td>
                        </tr>

                        <tr>
                            <td scope="row">Differently Abled : </td>
                            <td>{{info.DifferentlyAbled ? 'Yes' : 'No'}}</td>
                        </tr>
                        <tr>
                            <td scope="row">Address :</td>
                            <td>{{info.Address1}} {{info.Address2}}
                                ,{{info.StateName}},{{info.CityNameOfEmployeeInfo}}
                                {{info.PinCode}},{{info.CountryName}}</td>
                        </tr>
                        <!-- <tr>
                        <td scope="row"><i class='bx bxs-city'></i></td>
                        <td scope="row">Address2 :</td>
                        <td>{{info.Address2}}</td>
                    </tr> -->
                </table>
            </div>

        </div>
    </div>
</div>

<!-- Basic Info Form -->
