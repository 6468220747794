<div class="profile-content">
    <form [formGroup]="educationForm" id="form">
        <!-- <i matTooltip="Close" style="font-size: 35px;margin-left: 694px;margin-top: -15px;" (click)="close()"></i> -->
        <div style="width: 10px;
        height: 5px;
        position: relative;
        float: right;
        top: -16px;"  ><i matTooltip="Close" style="font-size: 25px;"
            class='bx bx-x Clear' (click)="close()"></i></div>
        <div class="profile-content-inner">
            <div class="Name">Add Education</div>
            <div class="row">

                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label>Education</label>
                        <select class="form-control form-select" formControlName="EducationType"
                            [ngClass]="{ 'is-invalid': submittedEdu && fedu.EducationType.errors }">
                            <option value="" disabled selected>Please select</option>
                            <option *ngFor="let EduType of allEducationType" [value]="EduType.ListIndex">
                                {{EduType.MessageText}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label>University <label class="star">*</label></label>
                        <input type="text" class="form-control" placeholder="Enter School Or University"
                            formControlName="SchoolOrUniversity"
                            [ngClass]="{ 'is-invalid': submittedEdu && fedu.SchoolOrUniversity.errors }">
                        <div class="invalid-feedback" *ngIf="submittedEdu && fedu.SchoolOrUniversity.errors">
                            <div *ngIf="fedu.SchoolOrUniversity.errors.required">
                                University is required
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="form-group" *ngIf="updateEduBtn">
                        <label>Qualification <label class="star">*</label></label>
                        <select class="form-control form-select" formControlName="QualificationId"
                            (change)="onchangeQualification($event.target.value)"
                            [ngClass]="{'is-invalid':submittedEdu && fedu.QualificationId.errors}">
                            <option value="" disabled selected>Choose any one</option>
                            <option *ngFor="let Quli of (allQualification | async)" [value]="Quli.Id">
                                {{Quli.Qualification}}
                            </option>
                        </select>
                        <div class="invalid-feedback" *ngIf="submittedEdu && fedu.QualificationId.errors">
                            <div *ngIf="fedu.QualificationId.errors.required">
                                Qualification is required
                            </div>
                        </div>
                    </div>

                    <div class="form-group" *ngIf="saveEduBtn">
                        <label>Qualification <label class="star">*</label></label>
                        <input type="text" class="form-control form-select" [formControl]="formControlQualificationine"
                            [matAutocomplete]="autoQuali"  [ngClass]="{'is-invalid':submittedEdu && fedu.QualificationId.errors}">
                        <mat-autocomplete #autoQuali="matAutocomplete" [displayWith]="displayFnForQualification"
                            (optionSelected)="onchangeQualification($event.option.value.Id)">
                            <mat-option *ngFor="let Quli of filterQualification | async" [value]="Quli">
                                {{Quli.Qualification}}
                            </mat-option>
                        </mat-autocomplete>
                        <div class="invalid-feedback" *ngIf="submittedEdu && fedu.QualificationId.errors">
                            <div *ngIf="fedu.QualificationId.errors.required">
                                Qualification is required
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="form-group" *ngIf="updateEduBtn">
                        <label>Academic Discipline <label class="star">*</label></label>
                        <select class="form-control form-select" formControlName="AcademicDisciplineId"
                            (change)="onchangeDiscipline($event.target.value)"
                            [ngClass]="{ 'is-invalid': submittedEdu && fedu.AcademicDisciplineId.errors }">
                            <option value="" disabled selected>Choose any one</option>
                            <option *ngFor="let discipline of (alldiscipline | async)" [value]="discipline.Id">
                                {{discipline.AcademicDescription}}
                            </option>
                        </select>
                        <div class="invalid-feedback" *ngIf="submittedEdu && fedu.AcademicDisciplineId.errors">
                            <div *ngIf="fedu.AcademicDisciplineId.errors.required">
                                Academic Discipline is required
                            </div>
                        </div>
                    </div>

                    <div class="form-group" *ngIf="saveEduBtn">
                        <label>Academic Discipline <label class="star">*</label></label>
                        <input type="text" class="form-control form-select" [formControl]="formControldiscipline"
                            [matAutocomplete]="autodec" [ngClass]="{ 'is-invalid': submittedEdu && fedu.AcademicDisciplineId.errors }">
                        <mat-autocomplete #autodec="matAutocomplete" [displayWith]="displayFnFordiscipline"
                            (optionSelected)="onchangeDiscipline($event.option.value.Id)">
                            <mat-option *ngFor="let discipline of filterdiscipline | async" [value]="discipline">
                                {{discipline.AcademicDescription}}
                            </mat-option>
                        </mat-autocomplete>
                        <div class="invalid-feedback" *ngIf="submittedEdu && fedu.AcademicDisciplineId.errors">
                            <div *ngIf="fedu.AcademicDisciplineId.errors.required">
                                Academic Discipline is required
                            </div>
                        </div>
                    </div>
                </div>


                <div class="col-lg-12 col-md-12">
                    <div class="form-group" *ngIf="updateEduBtn">
                        <label>Specialization <label class="star">*</label></label>
                        <select class="form-control form-select" formControlName="SpecializationId"
                            [ngClass]="{ 'is-invalid': submittedEdu && fedu.SpecializationId.errors }">
                            <option value="" disabled selected>Choose any one</option>
                            <option *ngFor="let specialization of (allspecialization | async)"
                                [value]="specialization.Id">
                                {{specialization.SpecializationDescription}}
                            </option>
                        </select>
                        <div class="invalid-feedback" *ngIf="submittedEdu && fedu.SpecializationId.errors">
                            <div *ngIf="fedu.SpecializationId.errors.required">
                                Specialization is required
                            </div>
                        </div>
                    </div>

                    <div class="form-group" *ngIf="saveEduBtn">
                        <label>Specialization <label class="star">*</label></label>
                        <input type="text" class="form-control form-select" 
                            [formControl]="formControlspecialization" [matAutocomplete]="autospec" 
                            [ngClass]="{ 'is-invalid': submittedEdu && fedu.SpecializationId.errors }">
                        <mat-autocomplete #autospec="matAutocomplete" [displayWith]="displayFnForSpecialization"
                            (optionSelected)="onchangeSpecialization($event.option.value.Id)">
                            <mat-option *ngFor="let specialization of filterspecialization | async"
                                [value]="specialization">
                                {{specialization.SpecializationDescription}}
                            </mat-option>
                        </mat-autocomplete>
                        <div class="invalid-feedback" *ngIf="submittedEdu && fedu.SpecializationId.errors">
                            <div *ngIf="fedu.SpecializationId.errors.required">
                                Specialization is required
                            </div>
                        </div>
                    </div>
                </div>


                <div class="col-lg-6 col-md-12">
                    <div class="form-group">
                        <label>GPA or Percentage or Grade <label class="star">*</label></label>
                        <input type="text" class="form-control" placeholder="e.g. 8.09"
                            formControlName="GradeOrPercentage"
                            [ngClass]="{ 'is-invalid': submittedEdu && fedu.GradeOrPercentage.errors }">
                        <div class="invalid-feedback" *ngIf="submittedEdu && fedu.GradeOrPercentage.errors">
                            <div *ngIf="fedu.GradeOrPercentage.errors.required">
                                GPA is required
                            </div>
                        </div>

                    </div>
                </div>
                
                <!-- <div class="col-lg-4 col-md-12">
                    <div class="form-group">
                        <label>Start date</label>
                        <input type="date" value="1995-10-01" class="form-control" placeholder="1995-01-01"
                            formControlName="FromDateOfEducation"
                            [ngClass]="{ 'is-invalid': submittedEdu && fedu.FromDateOfEducation.errors }">
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="form-group">
                        <label>End date</label>
                        <input type="date" value="1995-10-01" class="form-control" placeholder="1995-01-01"
                            formControlName="ToDateOfEducation"
                            [ngClass]="{ 'is-invalid': submittedEdu && fedu.ToDateOfEducation.errors }">
                    </div>
                </div> -->
                <div class="col-lg-6 col-md-12">
                    <div class="form-group">
                        <label>Passout Year <label class="star">*</label></label>
                        <input type="text" class="form-control" placeholder="eg.2021" formControlName="PassoutYear"
                            [ngClass]="{ 'is-invalid': submittedEdu && fedu.PassoutYear.errors }">

                    </div>
                </div>
            </div>
            <!-- <button (click)="educationInfoForm()" class="btn dashboard-btn" *ngIf="saveEduBtn">
                Save Your Information
            </button>
            <button (click)="educationUpdateForm()" class="btn dashboard-btn" *ngIf="updateEduBtn">
                Update Your Information
            </button> -->

            <div class="button">
                <button (click)="close()" class=" btn cancle " >
                    Cancel
                </button>

            <button (click)="educationInfoForm()" class="btn savebtn" *ngIf="saveEduBtn">
                Save
            </button>

            <button (click)="educationUpdateForm()" class="btn savebtn" *ngIf="updateEduBtn">
                Update
            </button>
            </div>
        </div>

    </form>
</div>