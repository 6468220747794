<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Candidates
                    </h2>
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                            <!-- <a routerLink="/">Home</a> -->
                        </li>
                        <!-- <li><span>/</span></li> -->
                        <li>Candidates
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Jobs -->
<div class="job-area-list ptb-50">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <ul class="job-list-item align-items-center">
                
                    <li>
                        <!-- <span class="sort">Sort By:</span> -->
                        <form>
                            <div class="form-group">
                                
                                <!-- <select>
                                    <option>Title</option>
                                    <option>Another option</option>
                                    <option>A option</option>
                                    <option>Potato</option>
                                </select> -->
                            </div>
                        </form>
                    </li>
                </ul>

                    <a media="print" class="cmn-btn" href="../../assets/Sample_Candidate_Data.xlsx"
                    style="margin-left: 1.5%; margin-right: 10px ; margin-bottom: 10px;"> Download Sample <i
                            class="bx bx-download bx-sm bx-tada-hover"></i></a>
                    <label>Upload File :</label>
                    <input type="file" #myInput (change)="onFileChange($event)" />

                    <input class="form-control" style="width: 300px; margin-left: 963px;margin-top: -49px;" (keyup)="applyFilter($event)" placeholder="Enter Text To Search" >
                   
                   
                <!-- </div> -->

                <!-- [routerLink]="['/add-country', id=0]" -->
                <!-- <div>
                    <a media="print" class="cmn-btn" (click)="AddCompany()">
                        Add
                        <i class="bx bx-plus bx-sm bx-tada-hover"></i>
                    </a>
                </div> -->

                <!-- <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" class="text-center">First Name</th>
                            <th scope="col" class="text-center">Last Name</th>
                            <th scope="col" class="text-center">Mobile No </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of Info">
                            <td class="text-center">{{item.FirstName}}</td>
                            <td class="text-center">{{item.LastName}}</td>
                            <td class="text-center">{{item.MobileNo}}</td>
                        </tr>
                    </tbody>
                </table> -->
                
                <div class="card mat-elevation-z6 m-3 w-auto">
                    <table mat-table [dataSource]="dataSource" matSort class="table-responsive">
                        <!-- Position Column -->
                        <ng-container matColumnDef="FirstName">
                            <th mat-header-cell *matHeaderCellDef class="M1"> First Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.FirstName}}
                            </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="LastName">
                            <th mat-header-cell *matHeaderCellDef class="M1"> Last Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.LastName}} </td>
                        </ng-container>

                        <!-- Symbol Column -->
                        <ng-container matColumnDef="MobileNo">
                            <th mat-header-cell *matHeaderCellDef class="M1"> Mobile Number </th>
                            <td mat-cell *matCellDef="let element"> {{element.MobileNo}} </td>
                        </ng-container>

                        <ng-container matColumnDef="Qualification">
                            <th mat-header-cell *matHeaderCellDef class="M1"> Qualification</th>
                            <td mat-cell *matCellDef="let element"> {{element.Qualification}} </td>
                        </ng-container>

                        <ng-container matColumnDef="AcademicDescription">
                            <th mat-header-cell *matHeaderCellDef class="M1"> Academic</th>
                            <td mat-cell *matCellDef="let element"> {{element.AcademicDescription}} </td>
                        </ng-container>

                        <ng-container matColumnDef="SpecializationDescription">
                            <th mat-header-cell *matHeaderCellDef class="M1"> Specialization</th>
                            <td mat-cell *matCellDef="let element"> {{element.SpecializationDescription}} </td>
                        </ng-container>

                        <ng-container matColumnDef="JobRole">
                            <th mat-header-cell *matHeaderCellDef class="M1"> Job Role</th>
                            <td mat-cell *matCellDef="let element"> {{element.JobRole}} </td>
                        </ng-container>

                        <ng-container matColumnDef="EmployeementType">
                            <th mat-header-cell *matHeaderCellDef class="M1"> Employement</th>
                            <td mat-cell *matCellDef="let element"> {{element.EmployeementType}} </td>
                        </ng-container>
                        
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>

                    <mat-paginator [pageSizeOptions]="[10, 20, 30, 50, 100]" style="font-size: 17px; color: #26448c;">
                    </mat-paginator>
                </div>

                <!-- <div *ngIf="info.length === 0">
                    <h3>No Recruiters Posted Yet!</h3>
                </div> -->

                <!-- <div class="pagination-area">
                    <ul>
                        <li><a routerLink="/country">Prev</a></li>
                        <li><a routerLink="/country">1</a></li>
                        <li><a routerLink="/country">2</a></li>
                        <li><a routerLink="/country">3</a></li>
                        <li><a routerLink="/country">Next</a></li>
                    </ul>
                </div> -->
            </div>

        </div>
    </div>
</div>

<!-- Modal -->
<!-- <div class="modal fade" *ngIf="showModalBox" id="exampleModal" tabindex="-1" aria- 
  labelledby="exampleModalLabel" >
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-danger" id="exampleModalLabel">Hey 
      Bootstrap 5!!</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" 
       aria-label="Close"></button>
      </div>
      <div class="modal-body text-danger">
       Therichpost.com
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-warning" data-bs- 
       dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div> -->



<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->