import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { event } from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import * as _moment from 'moment';
import { SharedService } from 'src/app/shared.service';
import { GlobalService } from 'src/app/global.service';
import { cities, Country, JobRole, State } from 'src/app/interface';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { map, startWith } from 'rxjs/operators';


@Component({
    selector: 'add-edit-basicinfo',
    templateUrl: './add-edit-basicinfo.component.html',
    styleUrls: ['./add-edit-basicinfo.component.scss']
})
export class AddEditBasicInfoComponent implements OnInit, OnDestroy {
    submitted: boolean = false;
    basicInfoForm: FormGroup;
    info: any = [];
    summary: string;
    response: any;
    openform = false;
    boolVar = true;
    fileType: string = '';
    base64textString: string[] = [];
    allMaritalStatus: Observable<any[]>;
    allGender: Observable<any[]>;
    allJobRole: JobRole[];
    filterJobRole?: Observable<JobRole[]>;
    formControlObj = new FormControl();
    JobRoleId: number;
  
    allCountries: Country[]
    filterCountries?: Observable<Country[]>;
    formCountries = new FormControl();
    CountriesId: number;
  
    allStates: State[];
    filterStates?: Observable<State[]>;
    formStates = new FormControl();
    StatesId: number;
  
    allCities: any[];
    filterCities?: Observable<any[]>;
    formCities = new FormControl();
    CityId: number;
    hideForm: boolean = true;
    hideme: boolean = true;
    EmpImages: string = '';
    role : any ;
    city : any ;
    state: any ;
    countery: any ;

    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private Service: SharedService<any>,
        private Global: GlobalService,
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<AddEditBasicInfoComponent>,
    ) { }

    ngOnInit(): void {
        this.basicInfoForm = this.formBuilder.group({
            Id: ['', null],
            FirstName: ['', [Validators.required]],
            LastName: ['', [Validators.required, Validators.maxLength(50)]],
            MobileNo: ['', [Validators.required]],
            ExpectedSalary: ['', [Validators.nullValidator]],
            JobRoleId: ['', [Validators.nullValidator]],
            JobRole: ['', [Validators.nullValidator]],
            PassoutYear: ['', [Validators.nullValidator]],
            Photo: ['', [Validators.nullValidator]],
            CityIdOfEmployeeInfo: ['', [Validators.required]],
            CityNameOfEmployeeInfo: ['', null],
            StateId: ['', [Validators.required]],
            CountryId: ['', [Validators.required]],
            CountryName: ['', null],
           EducationId: ['', null],
        });
        this.Service.Get('GetListValue?Id=3', null).subscribe(maritalstatus => {
            this.allMaritalStatus = maritalstatus;
        });
        this.Service.Get('GetListValue?Id=2', null).subscribe(GenderData => {
            this.allGender = GenderData;
        });
        this.Service.Get('Country/GetCountry?Id=0', null).subscribe(country => {
            this.allCountries = country;
        });

        this.Service.Get('JobRole/GetJobRole?Id=0', null).subscribe(data => {
            if (data != null) {
              this.allJobRole = data;
              this.formControlObj.setValue(this.allJobRole[0]);
              this.filterJobRole = this.formControlObj.valueChanges.pipe(
                startWith<string | JobRole>(''),
                map(value => typeof value === 'string' ? value : value.JobRole),
                map(JobRole => JobRole ? this._filterRole(JobRole) : this.allJobRole.slice()),
              );
            }
          });
      
          this.Service.Get('Country/GetCountry?Id=0', null).subscribe(data => {
            if (data != null) {
              this.allCountries = data;
              this.filterCountries = this.formCountries.valueChanges.pipe(
                startWith<string | Country>(''),
                map(value => typeof value === 'string' ? value : value.CountryName),
                map(CountryName => CountryName ? this._filterCountry(CountryName) : this.allCountries.slice()),
              );
            }
          });

          this.Service.Get('City/GetCity?Id=0', null).subscribe(data => {
            if (data != null) {
              this.allCities = data;
              this.filterCities = this.formCities.valueChanges.pipe(
                startWith<string | any>(''),
                map(value => typeof value === 'string' ? value : value.CityName),
                map(CityName => CityName ? this._filterCity(CityName) : this.allCities.slice()),
              );
            }
          })

          this.Service.Get('State/GetStateData?Id=0', null).subscribe(data => {
            if (data != null) {
              this.allStates = data;
              this.filterStates = this.formStates.valueChanges.pipe(
                startWith<string | State>(''),
                map(value => typeof value === 'string' ? value : value.StateName),
                map(StateName => StateName ? this._filterState(StateName) : this.allStates.slice()),
              );
            }
          })

        this.getUser();
    }

    get f(): { [key: string]: AbstractControl } {
        return this.basicInfoForm.controls;
    }

    private _filterState(StateName: string): State[] {
        const filterValue = StateName.toLowerCase();
        return this.allStates.filter(
          option => option.StateName?.toLowerCase().indexOf(filterValue) === 0
        );
      }
    
      displayFnForState(value: any) {
        return value ? value.StateName : undefined;
      }
    
      private _filterCity(CityName: string): any[] {
        const filterValue = CityName.toLowerCase();
        return this.allCities.filter(
          option => option.CityName?.toLowerCase().indexOf(filterValue) === 0
        );
      }
    
      displayFnForCity(value: any) {
        return value ? value.CityName : undefined;
      }

      private _filterRole(JobRole: string): JobRole[] {
        const filterValue = JobRole.toLowerCase();
        return this.allJobRole.filter(
          option => option.JobRole?.toLowerCase().indexOf(filterValue) === 0
        );
      }
    
      displayFnForRole(value: any) {
        return value ? value.JobRole : undefined;
      }
    
      private _filterCountry(CountryName: string): Country[] {
        const filterValue = CountryName.toLowerCase();
        return this.allCountries.filter(
          option => option.CountryName?.toLowerCase().indexOf(filterValue) === 0
        );
      }
      displayFnForCountry(value: any) {
        return value ? value.CountryName : undefined;
      }

    baiscInfoForm() {
        this.submitted = true;
        this.basicInfoForm.controls["JobRoleId"].setValue(this.JobRoleId);
        this.basicInfoForm.controls["CountryId"].setValue(this.CountriesId);
        this.basicInfoForm.controls["StateId"].setValue(this.StatesId);
        this.basicInfoForm.controls["CityIdOfEmployeeInfo"].setValue(this.CityId);

        if (this.basicInfoForm.invalid) {
            this.toastr.error("Please fill out the required fields (* fields)");
        }
        else {
            const updatepersonalinfo = this.basicInfoForm.getRawValue();
            
            updatepersonalinfo.Photo = this.EmpImages;
            this.Service.Put('CandidateInfo/UpdateBasicInfo', updatepersonalinfo).subscribe(
                (res) => {
                    this.toastr.success(res);
                    this.dialogRef.close('Update');  
                    this.boolVar = true;
                },
                (error) => {

                    this.toastr.error(error.error);
                });
            this.submitted = false;
        }
    }

    close(){
        this.dialogRef.close(); 
    }

   
    getUser() {
        this.Service.Get('CandidateInfo/GetCandidateInfoByUserId?userId=' + this.Global.getuserId(), null).subscribe(
            (res) => {
                this.info = res;
                this.basicInfoForm.controls["Id"].setValue(this.info.Id);
                this.Global.setCandidateId(this.info.Id);
                this.basicInfoForm.controls["FirstName"].setValue(this.info.FirstName);
                this.basicInfoForm.controls["LastName"].setValue(this.info.LastName);
                this.basicInfoForm.controls["MobileNo"].setValue(this.info.MobileNo);
                this.basicInfoForm.controls['PassoutYear'].setValue(this.info.PassOutYear);
                this.basicInfoForm.controls["ExpectedSalary"].setValue(this.info.ExpectedSalary);
                this.basicInfoForm.controls['Photo'].setValue(this.info.Photo);
                this.basicInfoForm.controls['EducationId'].setValue(this.info.EducationId);
                this.EmpImages = this.info.Photo;
                this.Service.Get('JobRole/GetJobRole?Id=' +this.info.JobRoleId, null).subscribe(data => {
                    if (data != null) {
                      this.role = data;
                      this.formControlObj.setValue(this.role[0]);
                      this.JobRoleId = this.role[0].Id;
                    }
                  });

                  this.Service.Get('Country/GetCountry?Id=' + this.info.CountryId, null).subscribe(data => {
                    if (data != null) {
                      this.countery = data;
                      this.formCountries.setValue(this.countery[0]);
                      this.CountriesId = this.countery[0].Id;
                    }
                  });

                  this.Service.Get('State/GetStateData?Id=' + this.info.StateId, null).subscribe(data => {
                    if (data != null) {
                      this.state = data;
                      this.formStates.setValue(this.state[0]);
                      this.StatesId = this.state[0].Id;
                    }
                  });
                  this.Service.Get('City/GetCity?Id=' + this.info.CityIdOfEmployeeInfo, null).subscribe(data => {
                    if (data != null) {
                      this.city = data;
                      this.formCities.setValue(this.city[0]);
                      this.CityId = this.city[0].Id;
                    }
                  });

                  // this.Service.Get('State/GetStateData?Id=0', null).subscribe(state => {
        //     this.allStates = state;
        // })
        // this.Service.Get('City/GetCity?Id=0', null).subscribe(city => {
        //     this.allCities = city;
        // })


            },
            (error) => {
                this.toastr.error(error.error);
            });
    }

     onchangeCountry(event) {
    this.CountriesId = event
    this.Service.Get('State/GetStateByCountry?countryId=' + this.CountriesId, null).subscribe(data => {
      if (data != null) {
        this.allStates = data;
        this.filterStates = this.formStates.valueChanges.pipe(
          startWith<string | State>(''),
          map(value => typeof value === 'string' ? value : value.StateName),
          map(StateName => StateName ? this._filterState(StateName) : this.allStates.slice()),
        );
      }
    })
}

onchangeState(event)
{
 this.StatesId = event;
 this.Service.Get('City/GetCityDataByState?StateId=' + this.StatesId, null).subscribe(data => {
   if (data != null) {
     this.allCities = data;
     this.filterCities = this.formCities.valueChanges.pipe(
       startWith<string | any>(''),
       map(value => typeof value === 'string' ? value : value.CityName),
       map(CityName => CityName ? this._filterCity(CityName) : this.allCities.slice()),
     );
   }
 })
}

onchangeCity(event) 
  {
    this.CityId = event;
  }
    Uploadcompanylogo(event: any) {
        if (event.target.files) {
            var reader = new FileReader();

            reader.readAsDataURL(event.target.files[0]);

            reader.onload = (event: any) => {
                this.EmpImages = event.target.result;
            };
        }
    }
    onchangejobrole(event) {
        this.JobRoleId = event
      }

    _handleReaderLoadedCompany(readerEvt) {
        var binaryString = readerEvt.target.result;
        this.base64textString.push('data:' + this.fileType + ';base64,' + btoa(readerEvt.target.result));
    }

    cycleImages(forward: boolean = true): void {
        // Get the image count and current image index
        const count = this.basicInfoForm.get('Images').value.length;
        const currentIndex = this.basicInfoForm.get('currentImageIndex').value;

        // Calculate the next and previous index
        const nextIndex = currentIndex + 1 === count ? 0 : currentIndex + 1;
        const prevIndex = currentIndex - 1 < 0 ? count - 1 : currentIndex - 1;

        // If cycling forward...
        if (forward) {
            this.basicInfoForm
                .get('currentImageIndex')
                .setValue(nextIndex);
        }
        // If cycling backwards...
        else {
            this.basicInfoForm
                .get('currentImageIndex')
                .setValue(prevIndex);
        }
    }


    keyPressAlphaNumeric(event: { keyCode: number; preventDefault: () => void; }) {
      var inp = String.fromCharCode(event.keyCode);
      if (/[0-9 ]/.test(inp)) {
        return true;
      } else {
        event.preventDefault();
        return false;
      }
    }

    keyPressAlpha(event: { keyCode: number; preventDefault: () => void; }) {
      var inp = String.fromCharCode(event.keyCode);
      if (/[a-zA-Z ]/.test(inp)) {
        return true;
      } else {
        event.preventDefault();
        return false;
      }
    }
    ngOnDestroy(): void {

    }
}
