<div class="profile-content">
    <form [formGroup]="FunctionInfoForm" id="noScreen">
        <div style="width: 10px;
        height: 5px;
        position: relative;
        float: right;
        top: -16px;"  ><i matTooltip="Close" style="font-size: 25px;"  
            class='bx bx-x Clear' (click)="close()"></i></div>
        
        <div class="profile-content-inner">
            <div class="Name">Function</div>
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="form-group">
                        <label>  Function1:</label>
                        <select class="form-control form-select  select" formControlName="Function1">
                            <option value="" disabled selected>Please select Function1</option>
                            <option *ngFor="let function of allfunction"
                                [value]="isfunction == true ? function.Id :  function.Id ">
                                {{function.FunctionName}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="form-group">
                        <label> Function2:</label>
                        <select class="form-control form-select  select" formControlName="Function2">
                            <option value="" disabled selected>Please select Function2</option>
                            <option *ngFor="let function2 of allfunction"
                                [value]="isfunction2 == true ? function2.Id :  function2.Id ">
                                {{function2.FunctionName}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <!-- <button (click)="personalInfoForm()" class="btn dashboard-btn">Update Your
            Information</button> -->

            <div class="button">
                <button (click)="close()" class=" btn cancle " >
                    Cancel
                </button>
    
            <button (click)="personalInfoForm()" class="btn savebtn">
                Save
            </button>
    
            
            </div>
    </form>
</div>