import { Component,OnDestroy,OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import { Observable } from 'rxjs';
import { AcademicDiscipline, Country, JobRole, Qualification, Specialization } from 'src/app/interface';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'app-employers-register',
    templateUrl: './employers-register.component.html',
    styleUrls: ['./employers-register.component.scss']
})

export class EmployersRegisterComponent implements OnInit, OnDestroy {
  submitted: boolean = false;
  candidateform: FormGroup;
  displayMe: boolean = false;
  allcode: any;

  allQualification?: Qualification[];
  filterQuli?: Observable<Qualification[]>;
  formControlQuli = new FormControl();
  QualificationId: number;

  alldiscipline: AcademicDiscipline[];
  filterdiscipline?: Observable<AcademicDiscipline[]>;
  formControldiscipline = new FormControl();
  disciplineId: number;

  allspecialization: Specialization[];
  filterspecialization?: Observable<Specialization[]>;
  formControlspecialization = new FormControl();
  specializationId: number;

  formControlforcode: FormControl;
  allJobRole: Observable<JobRole[]>;
  fileType: string = '';
  base64textString: string[] = [];
  allCountries: Observable<Country[]>;
  CountryCode: any;

    constructor(
        private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private router: Router,
        private service: SharedService<any>,
    ) {

    }

    ngOnInit(): void {
        this.formControlforcode = new FormControl(this.allcode);
        this.candidateform = this.formBuilder.group({
            FirstName: ['', Validators.required],
            LastName: ['', Validators.required],
            ProfileName: ['', null],
            Email: ['', [Validators.required, Validators.maxLength(150), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
            Password: ['', null],
            MobileNo: ['', [Validators.required, Validators.maxLength(10)]],
            Photo: ['', [Validators.nullValidator]],
            IsGET: ['', Validators.nullValidator],
            InternShipStartDate: ['', Validators.nullValidator],
            InternShipEndDate: ['', Validators.nullValidator],
            UserTypeId: [4],
            QualificationId: ['', Validators.required],
            AcademicDisciplineId: ['', Validators.required],
            SpecializationId: ['', Validators.required],
            JobRoleId: ['', Validators.nullValidator],
            PassOutYear: ['', Validators.nullValidator],
            CountryISDCode: ['', Validators.required],
        }
        );

        this.service.Get('Qualification/GetQualification?Id=0', null).subscribe(data => {
            if (data != null) {
              this.allQualification = data;
              this.filterQuli = this.formControlQuli.valueChanges.pipe(
                startWith<string | Qualification>(''),
                map(value => typeof value === 'string' ? value : value.Qualification),
                map(Qualification => Qualification ? this._filterQuali(Qualification) : this.allQualification.slice()),
              );
            }
          })
      
          this.service.Get('AcademicDiscipline/GetAcademicDisciplineByQualification?QualificationId=' + this.QualificationId, null).subscribe(data => {
            if (data != null) {
              this.alldiscipline = data;
              this.filterdiscipline = this.formControldiscipline.valueChanges.pipe(
                startWith<string | AcademicDiscipline>(''),
                map(value => typeof value === 'string' ? value : value.AcademicDescription),
                map(AcademicDescription => AcademicDescription ? this._filterdiscipline(AcademicDescription) : this.alldiscipline.slice()),
              );
            }
          })
      
          this.service.Get('Specialization/GetSpecializationDataByDiscipline?AcademicDisplineId=' + this.disciplineId, null).subscribe(data => {
            if (data != null) {
              this.allspecialization = data;
              this.filterspecialization = this.formControlspecialization.valueChanges.pipe(
                startWith<string | Specialization>(''),
                map(value => typeof value === 'string' ? value : value.SpecializationDescription),
                map(SpecializationDescription => SpecializationDescription ? this._filterSpecialization(SpecializationDescription) : this.allspecialization.slice()),
              );
            }
          })

        this.allJobRole = this.service.Get('JobRole/GetJobRole?Id=0', null);
        this.allCountries = this.service.Get('Country/GetCountryISDCode?Id=0',null);

        this.service.Get('Country/GetCountryISDCode?Id=0', null).subscribe({
            next: (data) => {
              this.allCountries = data;
              this.allcode = data;
              this.formControlforcode.setValue(this.allcode[0]);
              this.onchangeCountryCode(this.allcode[0].ISDCode);
            },
          });
    }

    get f(): { [key: string]: AbstractControl } {
        return this.candidateform.controls;
    }

    CandidateForm() {
        this.submitted = true;
        this.candidateform.controls["QualificationId"].setValue(this.QualificationId);
        this.candidateform.controls["AcademicDisciplineId"].setValue(this.disciplineId);
        this.candidateform.controls["SpecializationId"].setValue(this.specializationId);
        this.candidateform.controls["CountryISDCode"].setValue(this.CountryCode);
        if (this.candidateform.invalid) {
            this.toastr.error("Please fill out the required fields(* fields)");
        }
        else 
        {
            const emp = this.candidateform.getRawValue();
            emp.Photo = this.base64textString[0];
            var UserName = this.candidateform.value.Email;
            emp.ProfileName = this.candidateform.value.FirstName + ' ' + this.candidateform.value.LastName;

            this.service.Post('CandidateInfo/InsertCandidateRegistration', emp).subscribe(
                (res) => {
                    this.toastr.success(res);
                    this.candidateform.reset();
                    this.router.navigate(['/login']);
                },
                (error) => {
                    this.toastr.error(error.error);
                });
            this.submitted = false;
            // }
        }
    }

    close(){
      this.router.navigate(['/']);
    }
    onchangeSpecialization(specialization: any) {
        this.specializationId = specialization;
      }
    
      onchangeAcademicDiscipline(AcademicDescription: any) {
        this.disciplineId = AcademicDescription;
        this.service.Get('Specialization/GetSpecializationDataByDiscipline?AcademicDisplineId=' + this.disciplineId, null).subscribe(data => {
          if (data != null) {
            this.allspecialization = data;
            this.filterspecialization = this.formControlspecialization.valueChanges.pipe(
              startWith<string | Specialization>(''),
              map(value => typeof value === 'string' ? value : value.SpecializationDescription),
              map(SpecializationDescription => SpecializationDescription ? this._filterSpecialization(SpecializationDescription) : this.allspecialization.slice()),
            );
          }
        })}
    
      onchangeQualification(Qualification: any) {
        this.QualificationId = Qualification;
        this.service.Get('AcademicDiscipline/GetAcademicDisciplineByQualification?QualificationId=' + this.QualificationId, null).subscribe(data => {
          if (data != null) {
            this.alldiscipline = data;
            this.filterdiscipline = this.formControldiscipline.valueChanges.pipe(
              startWith<string | AcademicDiscipline>(''),
              map(value => typeof value === 'string' ? value : value.AcademicDescription),
              map(AcademicDescription => AcademicDescription ? this._filterdiscipline(AcademicDescription) : this.alldiscipline.slice()),
            );
          }
        })
      }
    
      onchangeCountryCode(CountryCode: any) {
          this.CountryCode =CountryCode;
    }

  private _filterQuali(Qualification: string): Qualification[] {
    const filterValue = Qualification.toLowerCase();
    return this.allQualification.filter(
      option => option.Qualification?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnForQuli(value: any) {
    return value ? value.Qualification : undefined;
  }

  private _filterdiscipline(AcademicDescription: string): AcademicDiscipline[] {
    const filterValue = AcademicDescription.toLowerCase();
    return this.alldiscipline.filter(
      option => option.AcademicDescription?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnFordiscipline(value: any) {
    return value ? value.
      AcademicDescription : undefined;
  }

  private _filterSpecialization(SpecializationDescription: string): Specialization[] {
    const filterValue = SpecializationDescription.toLowerCase();
    return this.allspecialization.filter(
      option => option.SpecializationDescription?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnForSpecialization(value: any) {
    return value ? value.
      SpecializationDescription : undefined;
  }

    toggleTag() {
        this.displayMe = true;
    }
    toggleTag1() {
        this.displayMe = false;
    }

    Uploadcompanylogo(event: any) {
        this.fileType = event.target.files[0].type
        var files = event.target.files;
        var file = files[0];
        if (files && file)
         {
            var reader = new FileReader();
            reader.onload = this._handleReaderLoadedCompany.bind(this);
            reader.readAsBinaryString(file);
        }
    }

    _handleReaderLoadedCompany(readerEvt) {
        var binaryString = readerEvt.target.result;
        this.base64textString.push('data:' + this.fileType + ';base64,' + btoa(readerEvt.target.result));
    }

    keyPressAlphaNumeric(event: { keyCode: number; preventDefault: () => void; }) {
      var inp = String.fromCharCode(event.keyCode);
      if (/[0-9 ]/.test(inp)) {
        return true;
      } else {
        event.preventDefault();
        return false;
      }
    }

    keyPressAlpha(event: { keyCode: number; preventDefault: () => void; }) {
      var inp = String.fromCharCode(event.keyCode);
      if (/[a-zA-Z ]/.test(inp)) {
        return true;
      } else {
        event.preventDefault();
        return false;
      }
    }

    ngOnDestroy(): void {

    }
}
