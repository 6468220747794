import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { event } from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import * as _moment from 'moment';
import { SharedService } from 'src/app/shared.service';
import { GlobalService } from 'src/app/global.service';
import { state } from '@angular/animations';
import { cities, Country, IndustryType, JobRole, State } from 'src/app/interface';
import { MatDialogRef } from '@angular/material/dialog';
const moment = _moment;

@Component({
    selector: 'app-add-edit-companyinfo',
    templateUrl: './add-edit-companyinfo.component.html',
    styleUrls: ['./add-edit-companyinfo.component.scss']
})
export class AddEditCompanyProfileComponent implements OnInit, OnDestroy {
    companyUpdateForm!: FormGroup;
    allCities: Observable<cities[]>;
    allStates: Observable<State[]>;
    allCountries: Observable<Country[]>;
    allIndustry: Observable<IndustryType[]>;
    public info: any;
    image: string = '';
    base64textString: string[] = [];
    fileType: string = '';
    summary: string;
    response: any;
    openform = false;
    boolVar = true;
    hideForm: boolean = true;
    hideme: boolean = true;
    submitted: boolean = false;


    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private router: Router,
        private Service: SharedService<any>,
        private Global: GlobalService,
        private dialogRef: MatDialogRef<AddEditCompanyProfileComponent>,
    ) { }


    ngOnInit(): void {
        this.companyUpdateForm = this.formBuilder.group({
            Id :['',null],
            CompanyEmail: ['', [Validators.required, Validators.maxLength(150), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
            CompanyProfileName: ['', null],
            CompanyName: ['', Validators.required],
            IndustryType: ['', null],
            IndustryTypeId :['', Validators.required],
            Address1: ['', Validators.required],
            Address2: ['', null],
            CityId: ['', [Validators.required]],
            CityName: ['', null],
            StateId: ['', [Validators.required]],
            StateName: ['', null],
            CountryId: ['', [Validators.required]],
            CountryName: ['', null],
            PinCode: ['', [Validators.required, Validators.maxLength(6)]],
            WebSite: ['', null],
            LinkedInPage: ['', null],
            Logo: ['', null],
            Description: ['', Validators.required],
            FirstName: ['', Validators.required],
            LastName: ['', Validators.required],
            MobileNo: ['', Validators.required],
        })

        this.getcompanydatabyId();
        this.Service.Get('Country/GetCountry?Id=0', null).subscribe(country => {
            this.allCountries = country;
        });


        this.Service.Get('State/GetStateData?Id=0', null).subscribe(state => {
            this.allStates = state;
        })
        this.Service.Get('City/GetCity?Id=0', null).subscribe(city => {
            this.allCities = city;
        })

        this.Service.Get('IndustryTypes/GetIndustryTypes?Id=0', null).subscribe(Industry => {
            this.allIndustry = Industry;
        })
    }

    get f(): { [key: string]: AbstractControl } {
        return this.companyUpdateForm.controls;
    }

    close(){
        this.dialogRef.close(); 
    }
    
    baiscInfoForm() {
        this.submitted = true;
        if (this.companyUpdateForm.invalid) {
            this.toastr.error('This data is not Valid');
        }
        else {
            const updatepersonalinfo = this.companyUpdateForm.getRawValue();
            updatepersonalinfo.Logo = this.base64textString[0];
            this.Service.Put('Company/UpdateCompanyData', updatepersonalinfo).subscribe(
                (res) => {
                    this.toastr.success(res);
                    this.getcompanydatabyId();
                    this.dialogRef.close('Update');  


                },
                (error) => {

                    this.toastr.error(error.error);
                });
            this.submitted = false;
        }
    }


    getcompanydatabyId() {

        this.Service.Get('Company/GetCompanyData?Id=' + this.Global.getuserId(), null).subscribe(
            (res) => {
                this.info = res;
                this.companyUpdateForm.controls["Id"].setValue(this.info[0].Id);
                this.companyUpdateForm.controls["CompanyName"].setValue(this.info[0].CompanyName);
                this.companyUpdateForm.controls["CompanyEmail"].setValue(this.info[0].CompanyEmail);
                this.companyUpdateForm.controls["IndustryTypeId"].setValue(this.info[0].IndustryTypeId);
                this.companyUpdateForm.controls["Address1"].setValue(this.info[0].Address1);
                this.companyUpdateForm.controls["Address2"].setValue(this.info[0].Address2);
                this.companyUpdateForm.controls["PinCode"].setValue(this.info[0].PinCode);
                this.companyUpdateForm.controls["WebSite"].setValue(this.info[0].WebSite);
                this.companyUpdateForm.controls["LinkedInPage"].setValue(this.info[0].LinkedInPage);
                this.companyUpdateForm.controls["CityId"].setValue(this.info[0].CityId);
                this.companyUpdateForm.controls["StateId"].setValue(this.info[0].StateId);
                this.companyUpdateForm.controls["CountryId"].setValue(this.info[0].CountryId);
                this.companyUpdateForm.controls["Description"].setValue(this.info[0].Description);
                this.companyUpdateForm.controls["FirstName"].setValue(this.info[0].FirstName);
                this.companyUpdateForm.controls["LastName"].setValue(this.info[0].LastName);
                this.companyUpdateForm.controls["MobileNo"].setValue(this.info[0].MobileNo);
                this.companyUpdateForm.controls["Logo"].setValue(this.info[0].Logo);
                this.Global.setCandidateId(this.info[0].Id);
          
                this.image = this.info[0].Logo;


            })

    }
    onchangeCountry(event) {
        this.Service.Get('State/GetStateByCountry?countryId=' + event, null).subscribe(state => {
            this.allStates = state;
        });
    }

    onchangeState(event) {
        this.Service.Get('City/GetCityDataByState?StateId=' + event, null).subscribe(city => {
            this.allCities = city;
        })
    }


    Uploadcompanylogo(event: any) {
        this.fileType = event.target.files[0].type
        var files = event.target.files;
        var file = files[0];
        if (files && file) {
            var reader = new FileReader();
            reader.onload = this._handleReaderLoadedCompany.bind(this);
            reader.readAsBinaryString(file);
        }
    }

    _handleReaderLoadedCompany(readerEvt) {
        var binaryString = readerEvt.target.result;
        this.base64textString = [];
        // this.base64textString = btoa(binaryString);
        // this.base64textString = 'data:' + this.fileType + ';base64,' + this.base64textString;
        this.base64textString.push('data:' + this.fileType + ';base64,' + btoa(readerEvt.target.result));
    }



    ngOnDestroy(): void {

    }
}
