import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/global.service';
import { SharedService } from 'src/app/shared.service';
import * as _moment from 'moment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
const moment = _moment;


@Component({
    selector: 'app-add-edit-certificate',
    templateUrl: './add-edit-certificate.component.html',
    styleUrls: ['./add-edit-certificate.component.scss']
})
export class AddEditCertificationComponent implements OnInit, OnDestroy {
    
    submitted: boolean = false;
    submittedCerty: boolean = false;
    savePrjBtn: boolean = true;
    updatePrjBtn: boolean = false;
    prjId: number;
    proejctForm: FormGroup;
    public CertificateInfo = [];
    response: any;
    // openform=false;
    boolVar=true;
    hideForm: boolean = false;
    hideTable: boolean = true;

    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private router: Router,
        private Service: SharedService<any>,
        private Global : GlobalService,
        private dialogRef: MatDialogRef<AddEditCertificationComponent>,
        @Inject(MAT_DIALOG_DATA) public editData: any
    ) { }

    ngOnInit(): void {
        this.proejctForm = this.formBuilder.group({
            Id: ['', null],
            EmpId :['',null],
            CertificationName: ['', [Validators.required]],
            Description: ['', [Validators.nullValidator]],
            DateOfIssue: ['', [Validators.required]],
            DateOfValidity: ['', [Validators.nullValidator]],
        })

        if (this.editData) {
            this.editCerty(this.editData)
            this.updatePrjBtn = true
            this.savePrjBtn = false
        }
        else {
            this.updatePrjBtn = false
            this.savePrjBtn = true
        }
    }

    get fCerty(): { [key: string]: AbstractControl } {
        return this.proejctForm.controls;
    }

    close(){
        this.dialogRef.close(); 
    }

    CertificatetInfoForm() {
        this.submitted = true;
        if (this.proejctForm.invalid) {
            this.toastr.error("Please fill out the required fields (* fields)");
        }
        else {
            const insertcerty = this.proejctForm.getRawValue();
            insertcerty.EmpId = this.Global.getCandidateId();
            this.Service.Post('CandidateCertificatios/InsertCandidateCertifications/', insertcerty).subscribe(
                (res) => {
                    if (res) {
                        this.hideForm = false;
                        this.dialogRef.close('Save');                 
                        this.boolVar = true;  
                    } else {
                        this.toastr.error('Error While Insert Data');
                    }
                });
            this.submitted = false;
        }
    }

    CertificatetUpdateForm() {
        this.submitted = true;

        if (this.proejctForm.invalid) {
            this.toastr.error("Please fill out the required fields (* fields)");
        }
        else {
            const updateCerty = this.proejctForm.getRawValue();
            this.Service.Put('CandidateCertificatios/UpdateCandidateCertifications/', updateCerty).subscribe(
                (res) => {
                    if (res) {
                        this.toastr.success(res);
                        this.dialogRef.close('Update');                     
                        this.boolVar = true;    
                    } 
                });
            this.submitted = false;
        }
    }


    editCerty(certyinfo: any) {
            this.proejctForm.controls["Id"].setValue(certyinfo.Id);
            this.proejctForm.controls["CertificationName"].setValue(certyinfo.CertificationName);
            this.proejctForm.controls["Description"].setValue(certyinfo.Description);

            if(certyinfo.DateOfIssue !=null){
                const momentDate1 = new Date(certyinfo.DateOfIssue);
                const formateddate1 = moment(momentDate1).format("yyyy-MM-DD");
                certyinfo.DateOfIssue = formateddate1;
            }
            this.proejctForm.controls["DateOfIssue"].setValue(certyinfo.DateOfIssue);

if(certyinfo.DateOfValidity != null)
{
    const momentDate = new Date(certyinfo.DateOfValidity);
    const formateddate = moment(momentDate).format("yyyy-MM-DD");
    certyinfo.DateOfValidity = formateddate;
}
           

            this.proejctForm.controls["DateOfValidity"].setValue(certyinfo.DateOfValidity);
    }

    clearPrj() {
        if(this.boolVar == true){
            this.hideForm = true;
            this.hideTable = false;
            this.savePrjBtn = true;
            this.updatePrjBtn = false;
        }
        else{
            this.hideForm = false;
            this.hideTable = true;
            this.savePrjBtn = false;
            this.updatePrjBtn = true;
        }
        this.boolVar = !this.boolVar;

        this.proejctForm.reset();
    }

    ngOnDestroy(): void {

    }

}
