import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/global.service';
import { SharedService } from 'src/app/shared.service';
import userToken from "../../config/userToken";
import { EmployersRegisterComponent } from '../../pages/employers-register/employers-register.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  token: any = userToken.token;
  isToken: boolean = false;
  screenWidth: any;
  screenHeight: any;
  menu: boolean = false
  close: boolean = false;
  list: boolean = true;
  smallscreen: boolean = false;
  largescreen: boolean = false;
  menuList?: any;
comapnyName: string;
CompanyEmail : string ; 
myporfile: boolean = true;
postjob: boolean = false;
FindCandidate: boolean = false;
managJob: boolean = false;

  constructor(public myGlobals: GlobalService,
    private toastr: ToastrService,
    public router: Router,
    private dialog: MatDialog,
    private service:SharedService<any>,
 
  ) { }

  ngOnInit(): void {
 this.comapnyName =  this.myGlobals.GetCompanyName();
 this.CompanyEmail = this.myGlobals.GetUserName();
    this.getScreenSize();
  }
  
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 768 ||
       this.screenWidth == 1486 ||
       this.screenWidth == 1007 ||
       this.screenWidth == 1370 || this.screenHeight == 2784) {
      this.smallscreen = true;
      this.largescreen = false
    }
    else {
      this.smallscreen = false;
      this.largescreen = true;
    }

  }
  Login() {
    this.router.navigate(['/login']);
  }

  click() {
    this.menu = true;
    this.close = true;
    this.list = false;
  }

  closeclick1() {
    this.menu = false;
    this.close = false;
    this.list = true;
  }

  myProfile(){
    this.router.navigate(['/companyhome/profile']);
    this.myporfile = true;
    this.postjob = false;
    this.FindCandidate = false;
    this.managJob = false;
  }

  Findcandidate(){
    this.router.navigate(['/candidates']);
    this.myporfile = false;
    this.postjob = false;
    this.FindCandidate = true;
    this.managJob = false;
  }

  ManageJob(){
    this.router.navigate(['/companymanagejob']);
    this.myporfile = false;
    this.postjob = false;
    this.FindCandidate = false;
    this.managJob = true;
  }

  jobpost(){
    this.router.navigate(['/post-a-job']);
    this.myporfile = false;
    this.postjob = true;
    this.FindCandidate = false;
    this.managJob = false;
  }

  AddCandidate() {
    const dialogRef = this.dialog.open(EmployersRegisterComponent, {
      width: '92%',
      position: { right: '1px', top: '96px' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == "Save") {

      }
    });
  }


  onLogout(event: Event){
    this.service.Post('User/SetUserLogOutAudit?userName=' +  this.myGlobals.Getprofilename(), null).subscribe({
        next: (res) => {
    this.myGlobals.isSubmitted = false;
    this.myGlobals.isCompany = false;
   // this.myGlobals.getLoginData = null;
    this.router.navigate(['/login']);

}
})
}
}
