
<div class="profile-content">

    <div style="width: 10px;
    height: 5px;
    position: relative;
    float: right;
    top: -16px;"  ><i matTooltip="Close" style="font-size: 25px;"  
        class='bx bx-x Clear' (click)="close()"></i></div>
    <form autocomplete="off" novalidate [formGroup]="skillsForm"  class="profile-content">
        <div class="profile-content-inner">
            <div class="Name"> Key Skills</div>

            <div class="row">
                <div class="col-lg-12 col-md-12">
                
                    <div class="form-group">
                        <label>Skill:</label> 
                        <!-- <select class="form-control form-select  select" formControlName="Skill_Id"
                            [ngClass]="{'is-invalid':submittedSkill && fskill.Skill_Id.errors}">
                            <option value="" disabled selected>Please select Skill</option>
                            <option *ngFor="let skill of skillsData"
                                [value]="isskill == true ? skill.Id :  skill.Id ">
                                {{skill.Skills}}
                            </option>
                        </select> -->
                        <input type="text" class="form-control form-select rectangle-1315 enter-your-area-of-expertise-specialization" [formControl]="formControlSkill"
                                    [matAutocomplete]="autoQuali" placeholder="Enter your area of Expertise/Specialization">
                                <mat-autocomplete #autoQuali="matAutocomplete" [displayWith]="displayFnForSkill"
                                    (optionSelected)="onchangeSkill($event.option.value.Id)">
                                    <mat-option *ngFor="let skill of filterSkill | async" [value]="skill">
                                        {{skill.Skills}}
                                    </mat-option>
                                </mat-autocomplete>
                    </div>
                </div>
              
            </div>
            <div class="button">
                <button (click)="close()" class=" btn cancle" >
                    Cancel
                </button>

            <button (click)="skillsInfoForm()" class="btn savebtn">
                Save
            </button>

            
       

        <!-- <div class="frame-1402" (click)="close()">
            <div class="cancle">Cancel</div>
          </div> -->
        </div>
</div>
        
    </form>
</div>