import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { GlobalService } from 'src/app/global.service';
import { AcademicDiscipline, cities, JobRole, Qualificationa, Specialization } from 'src/app/interface';
import { SharedService } from 'src/app/shared.service';


@Component({
    selector: 'app-candidatetest',
    templateUrl: './candidatetest.component.html',
    styleUrls: ['./candidatetest.component.scss']
})
export class CandidateTestComponent implements OnInit, OnDestroy {

    candidatsInfo = []
    candidatetestform: FormGroup;
    JobRole: number = 0;
    allTestType: Observable<any[]>;
    TestType: number = 0;
    Ans : string;

    constructor(private toastr: ToastrService,
   
        private router: Router,
        private myGlobal: GlobalService,
        private service: SharedService<any>,
        private formBuilder: FormBuilder,
    ) {
       

    }

    ngOnInit(): void {
        this.candidatetestform = this.formBuilder.group({
            CandidateId: ['', Validators.nullValidator],
            QuestionId: ['', Validators.nullValidator],
            Answer: ['', Validators.nullValidator],
            ObtainedMarks: ['', Validators.nullValidator],
            
        });
        
        // this.refreshListForFilter();

        this.allTestType = this.service.Get('CandidateTest/GetTestType?Id=0', null);
        //this.onchangeTestType(this.TestType)
        //this.refreshList();
       
    }
    
    refreshList() {
        this.service.Get('CandidateTest/GetQuestion?RoleId='+ this.myGlobal.getJobroleId() +'&TesttypeId='+ this.TestType, null).subscribe(data => {
            this.candidatsInfo = data; 
        },
            (error) => {
                this.toastr.error(error.error);
            });
    }

    onchangeTestType(TestType: any) {
        this.TestType = TestType;
       this.refreshList()
    }

    submitForm(row : any) {
        if (this.candidatetestform.invalid) {
            this.toastr.error("This data is not Valid");
        }
        else 
        {
            const test = this.candidatetestform.getRawValue();
            test.CandidateId = this.myGlobal.getCandidateId();
            test.QuestionId = row.Id;
            test.Answer = this.Ans;
            this.service.Post('CandidateTest/InsertTestDB', test).subscribe(
                (res) => {
                    this.toastr.success(res);
                },
                (error) => {
                    this.toastr.error(error.error);
                });
           
            // }
        }
    }
    AnswerA(){
        this.Ans = 'A'
    }
    AnswerB(){
        this.Ans = 'B'
    }
    AnswerC(){
        this.Ans = 'C'
    }
    AnswerD(){
        this.Ans = 'D'
    }

    
    ngOnDestroy(): void {

    }

}
