import { Component, OnDestroy, OnInit } from '@angular/core';
import { JobsService } from "./services/jobs.service";
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import { Observable } from 'rxjs';
import { AcademicDiscipline, Bookmark, cities, JobRole, Qualification, Qualificationa } from 'src/app/interface';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/global.service';
import { AuthService } from 'src/app/auth.service';
import { ViewportScroller } from '@angular/common';
import { map, startWith } from 'rxjs/operators';


@Component({
    selector: 'app-jobs',
    templateUrl: './jobs.component.html',
    styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit, OnDestroy {

    jobPostsInfo = [];
    changeColor = [false, false, false];

    // allCities: Observable<cities[]>
    obs: any;
    // dataFilter1: number[] = [];
    data: any;
    citycontrol = new FormControl();
    EducationControl = new FormControl();
    jobRoleControl = new FormControl();
    datasource: any;
    Education: number = 0;
    City: number = 0;
    JobRole: number = 0;
    alldiscipline: Observable<AcademicDiscipline[]>;
    page: number = 1;
    pagesize: number = 5;
    totalItems: number = 0;
    datapageInfo: any;
    addEditForm!: FormGroup;
    visible: boolean = false
    afterClickData: any = null;
    constructor(
        // private jobsService: JobsService,
        private Service: SharedService<any>,
        private toastr: ToastrService,
        private router: Router,
        public Global: GlobalService,
        private auth: AuthService,
        private formBuilder: FormBuilder,
        private scroller: ViewportScroller,
    ) {

    }

    allJobRole !: JobRole[];
    filterJobroles?: Observable<JobRole[]>;
    jobroleControl = new FormControl();

    allQualification!: Qualification[];
    filterQualifications?: Observable<Qualification[]>;
    QualificationsControl = new FormControl();

    allCities!: cities[];
    filtercities?: Observable<cities[]>;
    citiesControl = new FormControl();

    bookmark: Bookmark;
    totalpages: number;
    UserId: any
    UserType: any

    ngOnInit(): void {
        // this.dataFilter1 = [];
        // this.dataFilter1.push(this.Global.getuserId());
        // this.dataFilter1.push(this.Global.GetUserType());
        // this.dataFilter1.push(0);
        // this.dataFilter1.push(0);
        // this.dataFilter1.push(0);
        this.addEditForm = this.formBuilder.group({
            JobRoleId: ['', [Validators.nullValidator]],
            CityId: ['', [Validators.nullValidator]],
            QualificationaId: ['', [Validators.nullValidator]],
            PageSearch: [null, Validators.nullValidator],
        })


        this.UserId = this.Global.getuserId();
        this.UserType = this.Global.GetUserType();
        this.addEditForm.controls["PageSearch"].setValue(this.page );
        this.getAllJobs();

   
        

        this.Service.Get('JobRole/GetJobRole?Id=0', null).subscribe(data => {
            if (data != null) {
                this.allJobRole = data;
                this.filterJobroles = this.jobroleControl.valueChanges.pipe(
                    startWith<string | JobRole>(''),
                    map(value => typeof value === 'string' ? value : value.JobRole),
                    map(JobRole => JobRole ? this._filterJobRole(JobRole) : this.allJobRole.slice()),
                );
            }
        })

        this.Service.Get('Qualification/GetQualification?Id=0', null).subscribe(data => {
            if (data != null) {
                this.allQualification = data;
                this.filterQualifications = this.QualificationsControl.valueChanges.pipe(
                    startWith<string | Qualification>(''),
                    map(value => typeof value === 'string' ? value : value.
                        Qualification),
                    map(Qualification => Qualification ? this._filterQualificationa(Qualification) : this.allQualification.slice())
                );
            }
        })

        this.Service.Get('City/GetCity?Id=0', null).subscribe(data => {
            if (data != null) {
                this.allCities = data;
                this.filtercities = this.citiesControl.valueChanges.pipe(
                    startWith<string | cities>(''),
                    map(value => typeof value === 'string' ? value : value.
                        CityName),
                    map(CityName => CityName ? this._filterCity(CityName) : this.allCities.slice())
                );
            }
        })



    }

    private _filterJobRole(jobrole: string): JobRole[] {
        const filterValue = jobrole.toLowerCase();

        return this.allJobRole.filter(
            option => option.JobRole?.toLowerCase().indexOf(filterValue) === 0
        );
    }

    displayFnForJobRole(value: any) {
        return value ? value.JobRole : undefined;
    }

    private _filterQualificationa(Qualification: string): Qualification[] {
        const filterValue = Qualification.toLowerCase();

        return this.allQualification.filter(
            option => option.Qualification?.toLowerCase().indexOf(filterValue) === 0
        );
    }

    displayFnForQualification(value: any) {
        return value ? value.Qualification : undefined;
    }

    private _filterCity(City: string): cities[] {
        const filterValue = City.toLowerCase();

        return this.allCities.filter(
            option => option.CityName?.toLowerCase().indexOf(filterValue) === 0
        );
    }

    displayFnForCity(value: any) {
        return value ? value.CityName : undefined;
    }



    getAllJobs() {
     
        // this.dataFilter1.push(this.pagesize);
        this.Service.Post('Job/GetFindJobDataForCandidate?userId=' + this.UserId + '&usertype=' + this.UserType +
         '&jobRole=' + this.JobRole + '&education=' + this.Education + '&city=' + this.City + '&pageno=' + this.page +
          '&pagesize=' + this.pagesize,null).subscribe(
            (res) => {
                this.jobPostsInfo = res[0];
                this.obs = this.jobPostsInfo;
                this.datapageInfo = res[1];
                this.totalItems = this.datapageInfo[0].length;
                this.totalpages = Math.ceil(this.totalItems / 5);
                this.CardClick(this.obs[0])
            },
            (error) => {
                //if (error.status == 401) this.router.navigate(['/login']);
                this.toastr.error(error.error.message);
            });
    }

    GOTO() {
if (this.page >= 1) {
        this.getAllJobs();}
    }

    Previous() {


        if (this.page > 1) {
            this.page = this.page - 1;
            this.addEditForm.controls["PageSearch"].setValue(this.page);
            this.getAllJobs();

        } else {

        }

    }

    Next() {
        if (this.totalpages > this.page) {
            this.page = this.page + 1
            this.addEditForm.controls["PageSearch"].setValue(this.page);
            this.getAllJobs();
        }
    }

    GoToPage(event: Event) {
        var value = (event.target as HTMLInputElement).value;
        if (+value > this.totalpages
            || +value < 0) {
            this.toastr.success('Page no Should be between : 1 And ' + this.totalpages);

        }
        else {
            this.page = +value;
        }
    }


    CardClick(event) {
        this.scroller.scrollToAnchor("targetRed");
        if (event == undefined) 
        {
            this.visible = false;
        }
        else 
        {
            this.visible = true;
            this.afterClickData = event;
        }

    }


    pageChanged(value: any) {

        this.page = value;
        this.applyselection();
        //this.removeselection();
    }
    JobClick(jobpost: any) {
        this.auth.setJobPostId(jobpost.Id);
        this.router.navigate(['job-details']);
    }


    onchangeCity(city: any) {
        this.City = city;
        // this.refreshListFilter();
    }


    onchangeJobRole(jobrole: any) {
        this.JobRole = jobrole;
    }


    onchangeQualification(Qualification: any) {
        this.Education = Qualification;
    }

    applyselection() {
        this.getAllJobs();
    }

    removeselection() {
        this.JobRole = 0;
        this.Education = 0;
        this.City = 0;
        this.getAllJobs();


        this.filtercities = null;
        this.citiesControl = new FormControl();
        this.Service.Get('City/GetCity?Id=0', null).subscribe(data => {
            if (data != null) {
                this.allCities = data;
                this.filtercities = this.citiesControl.valueChanges.pipe(
                    startWith<string | cities>(''),
                    map(value => typeof value === 'string' ? value : value.
                        CityName),
                    map(CityName => CityName ? this._filterCity(CityName) : this.allCities.slice())
                );
            }
        })

        this.filterQualifications = null;
        this.QualificationsControl = new FormControl();

        this.Service.Get('Qualification/GetQualification?Id=0', null).subscribe(data => {
            if (data != null) {
                this.allQualification = data;
                this.filterQualifications = this.QualificationsControl.valueChanges.pipe(
                    startWith<string | Qualification>(''),
                    map(value => typeof value === 'string' ? value : value.
                        Qualification),
                    map(Qualification => Qualification ? this._filterQualificationa(Qualification) : this.allQualification.slice())
                )
            }
        })

        this.filterJobroles = null
        this.jobroleControl = new FormControl();
        this.Service.Get('JobRole/GetJobRole?Id=0', null).subscribe(data => {
            if (data != null) {
                this.allJobRole = data;
                this.filterJobroles = this.jobroleControl.valueChanges.pipe(
                    startWith<string | JobRole>(''),
                    map(value => typeof value === 'string' ? value : value.JobRole),
                    map(JobRole => JobRole ? this._filterJobRole(JobRole) : this.allJobRole.slice()),
                );
            }
        })
    }

    ApplyNowClick(jobpost: any) {
        this.Service.Post('AppliedJob/InsertAppliedJob?jobpostId=' + jobpost, null).subscribe(
            (res) => {
                this.toastr.success(res);
                this.router.navigate(['/candidateappliedjob']);
            })
    }


    BookMarked(Jobpost: any) {
        this.bookmark = new Bookmark;
        this.bookmark.PostedJobId = Jobpost.Id;
        this.bookmark.JobCompanyName = Jobpost.JobCompanyName;
        this.bookmark.UserId = this.Global.getuserId();
        this.Service.Post('Applyjob/Bookmark', this.bookmark).subscribe(data => {
            this.toastr.success(data);
            this.router.navigate(['/bookjobs']);

        })

    }
    
    ngOnDestroy(): void {


    }

}
