<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div>
    <!-- <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Applied Jobs
                    </h2>
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                            
                        </li>
                       
                        <li>Applied Job
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div> -->
</div>
<!-- End Page Title -->

<!-- Jobs -->
<div class="job-area-list ptb-50 startposition" style="background-color: #f4f4f4;">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <ul class="job-list-item align-items-center">
                    <li>
                        <form>
                            <div class="form-group">
                            </div>
                        </form>
                    </li>
                </ul>

                <div class=e1015_1615><span class="e1015_1616" *ngIf="AppliedJobCount">{{AppliedJobCount[0].Count}} Applied Jobs</span>
                    <!-- <div class=e1015_1617> <input class="form-control " style="width: 300px; margin-left: 963px;margin-top: -49px;" (keyup)="applyFilter($event)" placeholder="Enter Text To Search" ></div> -->
                  </div>
                  <div > <input class="form-control search " (keyup)="applyFilter($event)" placeholder="Enter Text To Search" ></div>
                  <div class="ptb-100">
                    <div class="card mat-elevation-z6 m-1 w-auto">
                        <table mat-table [dataSource]="dataSource" matSort class="table-responsive table1">
                          <ng-container matColumnDef="ProfileName">
                            <th mat-header-cell *matHeaderCellDef class="M1 align"> Candidate Name </th>
                            <td mat-cell *matCellDef="let element" class="align"> {{element.ProfileName}} </td>
                          </ng-container>
                    
                          <ng-container matColumnDef="JobNumber">
                            <th mat-header-cell *matHeaderCellDef class="M1 align"> Job Number </th>
                            <td mat-cell *matCellDef="let element" class="align"> {{element.JobNumber}} </td>
                          </ng-container>
                    
                          <ng-container matColumnDef="JobCompanyName">
                            <th mat-header-cell *matHeaderCellDef class="M1 align">Company Name </th>
                            <td mat-cell *matCellDef="let element" class="align"> {{element.JobCompanyName}} </td>
                          </ng-container>
                    
                          <ng-container matColumnDef="JobRole">
                            <th mat-header-cell *matHeaderCellDef class="M1 align">Job Role </th>
                            <td mat-cell *matCellDef="let element" class="align"> {{element.JobRole}} </td>
                          </ng-container>
                    
                          <ng-container matColumnDef="AppliedDate">
                            <th mat-header-cell *matHeaderCellDef class="M1 align"> Applied Date </th>
                            <td mat-cell *matCellDef="let element" class="align"> {{element.AppliedDate | date:'dd-MM-yyyy'}}
                            </td>
                          </ng-container>
                    
                          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    
                        <mat-paginator color="primary" [pageSize]="10" [pageSizeOptions]="[5, 10, 15,25]" aria-label="Select page"
                        class="page">
                      </mat-paginator>
                    
                      </div>
                    </div>
            </div>

        </div>
    </div>
</div>

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->



