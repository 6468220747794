
<div class="tab-pane fade show active" id="v-pills-password" role="tabpanel" aria-labelledby="v-pills-password-tab">
  <div class="profile-content">
    <form [formGroup]="ChangePasswordInfo" id="noScreen" [hidden]="!openform">
      <!-- <i matTooltip="Close" style="font-size: 35px;margin-left: 563px;margin-top: -15px;color: red;"  
    class='bx bx-x Clear' (click)="close()"></i> -->
      <div class="profile-content-inner">
        <div class="Name">Change Password</div>

        <div class="row">
          <div class="form-group">
            <label>Old Password</label>

            <input type="password" id="password" class="form-control" placeholder="Password" formControlName="oldpass"
              [ngClass]="{ 'is-invalid': submitpassword && fpass.oldpass.errors }">
              <i class="bi-eye-slash" (click)="show('password', 'togglePassword')" id="togglePassword" style="cursor: pointer;"></i>
            <div *ngIf="submitpassword && fpass.oldpass.errors" class="invalid-feedback">
              <div *ngIf="fpass.oldpass.errors.required">Password is required</div>
              <div *ngIf="fpass.oldpass.errors.minlength">
                Password must be at least 6 characters
              </div>
              <div *ngIf="fpass.oldpass.errors.maxlength">
                Password must not exceed 40 characters
              </div>
            </div>
          </div>


          <div class="form-group">
            <label>New Password</label>

            <input type="password" id="newpassword" class="form-control" placeholder="New Password"
              formControlName="newpass" [ngClass]="{ 'is-invalid': submitpassword && fpass.newpass.errors }">
              <i class="bi-eye-slash" (click)="show1('newpassword', 'togglePassword')" id="togglePassword" style="cursor: pointer;"></i>
            <div *ngIf="submitpassword && fpass.newpass.errors" class="invalid-feedback">
              <div *ngIf="fpass.newpass.errors.required">Password is required</div>
              <div *ngIf="fpass.newpass.errors.minlength">
                Password must be at least 6 characters
              </div>
              <div *ngIf="fpass.newpass.errors.maxlength">
                Password must not exceed 40 characters
              </div>
            </div>

          </div>
          <div class="form-group">
            <label>Confirm Password</label>
            <input type="password" id="confirmPassword" class="form-control" placeholder="Confirm Password"
              formControlName="CFpass" [ngClass]="{ 'is-invalid': submitpassword && fpass.CFpass.errors }"/>
              <i class="bi-eye-slash" (click)="show2('confirmPassword', 'togglePassword')" id="togglePassword" style="cursor: pointer;"></i>
            <div *ngIf="submitpassword && fpass.CFpass.errors" class="invalid-feedback">
              <div *ngIf="fpass.CFpass.errors.required">
                Confirm Password is required
              </div>
              <div *ngIf="fpass.CFpass.errors.confirmPasswordValidator">
                Confirm Password does not match
              </div>
            </div>
          </div>


        </div>
      </div>

      <!-- <button (click)="changepassSubmit()" class="btn dashboard-btn">Save Your
        Password</button> -->
        <div class="button">
          <button (click)="close()" class=" btn cancle " >
              Cancel
          </button>

      <button (click)="changepassSubmit()" class="btn savebtn" >
          Save
      </button>

      
      </div>

    </form>
  </div>
</div>

<script>
  const togglePassword = document.querySelector("#togglePassword");
  const password = document.querySelector("#password");

  togglePassword.addEventListener("click", function () {

    // toggle the type attribute
    const type = password.getAttribute("type") === "password" ? "text" : "password";
    password.setAttribute("type", type);
    // toggle the eye icon
    this.classList.toggle('fa-eye');
    this.classList.toggle('fa-eye-slash');
  });
</script>