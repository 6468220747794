import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  isSubmitted = false;
  isRegSunmitted = false;
  isCompany = false;
  isCandidate = false;
  isUser = false;
  isLoggedIn = false;
  isCollege = false;
  isOwner = false;
  jobtypenumber :number;
  

  //getLoginData: any;
  // getUserData: any;
  userid: any;
  getAppjobdata: any;

  EmplyeeData: string[] = [];
  Id: string;
  FirstName: string;
  LastName: string;
  ProfileName: string;
  CompanyName: string;
  UserName: string;
  ClgName: string;
  OwnerName:string;
  Email: string;
  MobileNo: string;
  EmployeePersonalData: string[] = [];
  EmpId: string;
  firstName: string;
  lastName: string;
  mobileno: string;
  gender: string;
  DOB: string;
  MaritalStatus: string;
  Nationality: string;
  DifferentlyAbled: string;
  Address1: string;
  Address2: string;
  CityId: string;
  StateId: string;
  CountryId: string;
  PinCode: string;
  ExpectedSalary: string;
  jobTypeId: any;
  jobType: any;
  city: any;
  area: any;
  candidateId: any;
  jobrole:any;
  UserType:number;
  companyId: number =0;
  collegeId: number =0;
  CandidateEmail : string;

  constructor(public router: Router) { }

  public getjobTypeId(): number {
    return this.jobTypeId;
  }

  public setjobTypeId(id: number) {
    this.jobTypeId = id;
  }

  public getuserId(): number {
    return this.userid;
  }

  public setuserId(id: number) {
    this.userid = id;
  }

  public getjobtype(): number {
    return this.jobtypenumber;
  }

  public setjobtype(id: number) {
    this.jobtypenumber = id;
  }


  public GetUserType(): number {
    return this.UserType;
  }

  public setUserType(id: number) {
    this.UserType = id;
  }

  public Getprofilename(): string {
    return this.ProfileName;
  }

  public setprofilename(str: string) {
    this.ProfileName = str;
  }

  public GetCandidateemail(): string {
    return this.CandidateEmail;
  }

  public setCandidateemail(str: string) {
    this.CandidateEmail = str;
  }

  public GetUserName(): string {
    return this.UserName;
  }

  public setUserName(str: string) {
    this.UserName = str;
  }

  public GetClgName(): string {
    return this.ClgName;
  }

  public setClgName(str: string) {
    this.ClgName = str;
  }

  public GetOwnerName(): string {
    return this.OwnerName;
  }


  public setOwnerName(str: string) {
    this.OwnerName = str;
  }
// ChageToSolve for CompanyProfile
  public GetCompanyName():string{
    return this.CompanyName;
  }
  public setCompanyName(str: string) {
    this.CompanyName = str;
  }

  public GetCompanyId() :number{
    return this.companyId;
  }
  public SetCompanyId(value: number) {
    this.companyId = value;
  }

  public GetCollegeId() :number{
    return this.collegeId;
  }
  public SetCollegeId(value: number) {
    this.collegeId = value;
  }

  // public setEmployeeData(data: any) {
  //   this.Id = data.UserId;
  //   this.ProfileName = data.ProfileName;
  //   this.Email = data.Email;
    
  // }

  // public getEmployeeData(): string[] {
  //   this.EmplyeeData = [];
  //   this.EmplyeeData.push(this.Id);
  //   this.EmplyeeData.push(this.FirstName);
  //   this.EmplyeeData.push(this.LastName);
  //   this.EmplyeeData.push(this.Email);
  //   this.EmplyeeData.push(this.MobileNo);
  //   return this.EmplyeeData;
  // }
 
  public getjobType(): number {
    return this.jobType;
  }

  public setjobType(id: number) {
    this.jobType = id;
  }

  public getCity(): number {
    return this.city;
  }

  public setCity(id: number) {
    this.city = id;
  }

  public getArea(): number {
    return this.area;
  }

  public setArea(id: number) {
    this.area = id;
  }

  public getCandidateId(): number {
    return this.candidateId;
  }

  public setCandidateId(id: number) {
    this.candidateId = id;
  }
  public  setJobroleId(id:number){
    this.jobrole = id;
  }
  public getJobroleId(): number{
    return this.jobrole;
  }
 
}
