<div class="navbar-area fixed-top">

<div class="mobile-nav mean-container" *ngIf="smallscreen">
  <div class="mean-bar">
      <a class="navbar-brand" href="#"><img src="assets/png/finallightthme.png" alt="Logo"></a>
      <a *ngIf="list" class="meanmenu-reveal" style="color:#fff;right:0;left: 23%;
      top: 30px;" (click)="click()">
          <span>
              <span>
                  <span></span>
              </span>
          </span>
      </a>
      <a *ngIf="close" (click)="closeclick1()" class="meanmenu-reveal meanmenu-reveal meanclose"
          style="right: 0px; left: 23%;
          top: 0px; text-align: center; text-indent: 0px; font-size: 18px;">X</a>
  </div>

  <nav class="mean-nav" *ngIf="menu">
      <ul class="navbar-nav" style>
         
        <li class="nav-item" *ngIf="myGlobals.isCompany && myGlobals.isSubmitted">
          <a routerLink="/companyhome/profile" class="nav-link" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}">My Profile</a>
        </li>

        <li class="nav-item" *ngIf="myGlobals.isCompany && myGlobals.isSubmitted">
          <a routerLink="/post-a-job" class="nav-link" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}">Job Post</a>
        </li>

        <li class="nav-item" *ngIf="myGlobals.isCompany && myGlobals.isSubmitted">
          <a routerLink="/candidates" class="nav-link" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}">Find Candidate</a>
        </li>

        <li class="nav-item" *ngIf="myGlobals.isCompany && myGlobals.isSubmitted">
          <a routerLink="/companymanagejob" class="nav-link" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}">Manage Jobs</a>
        </li>
      
      </ul>
      <div class="side-nav">

        <a class="login-left" routerLink="/login" *ngIf="!myGlobals.isSubmitted" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}" style="margin-top: 56px;">
            <i class="flaticon-enter"></i>
            Login
        </a>
        <a class="login-left" *ngIf="myGlobals.isSubmitted" (click)="onLogout($event)"><i
                class="flaticon-enter"></i> Logout</a>
    </div>
  </nav>
  
</div>
</div>



<div class="sidenav" *ngIf="largescreen">
  <div style="background-color: #fff;  height: 90px;">
    <a class="navbar-brand" href="#"><img src="assets/png/finallightthme.png" alt="Logo" style="margin-top: 25px;
      margin-left: 40px;
  " ></a>

<div class="group-1207">
  <div class="rectangle-1193"></div>

  <div class="frame-1227">
    <div class="frame-1213">
      <svg
        class="vector"
        width="28"
        height="27"
        viewBox="0 0 28 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.449219 26.7731H13.7826V0.106445H0.449219V26.7731ZM8.78255 3.43978H12.1159V6.77311H8.78255V3.43978ZM8.78255 10.1064H12.1159V13.4398H8.78255V10.1064ZM8.78255 16.7731H12.1159V20.1064H8.78255V16.7731ZM2.11589 3.43978H5.44922V6.77311H2.11589V3.43978ZM2.11589 10.1064H5.44922V13.4398H2.11589V10.1064ZM2.11589 16.7731H5.44922V20.1064H2.11589V16.7731ZM15.4492 8.43978H27.1159V10.1064H15.4492V8.43978ZM15.4492 26.7731H18.7826V20.1064H23.7826V26.7731H27.1159V11.7731H15.4492V26.7731Z"
          fill="#221668"
        />
      </svg>

      <div class="be-spoke-systems-private-limited">
       {{comapnyName}}
      </div>
    </div>

    <div class="line-32"></div>

    <div class="frame-1215">
      <div class="frame-1214">
        <svg
          class="vector2"
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.44911 4.88439C6.44911 3.70565 6.91736 2.57518 7.75086 1.74169C8.58435 0.908194 9.71482 0.439941 10.8936 0.439941C12.0723 0.439941 13.2028 0.908194 14.0363 1.74169C14.8697 2.57518 15.338 3.70565 15.338 4.88439C15.338 6.06313 14.8697 7.19359 14.0363 8.02708C13.2028 8.86058 12.0723 9.32883 10.8936 9.32883C9.71482 9.32883 8.58435 8.86058 7.75086 8.02708C6.91736 7.19359 6.44911 6.06313 6.44911 4.88439ZM6.44911 11.5511C4.97569 11.5511 3.56261 12.1364 2.52074 13.1782C1.47887 14.2201 0.893555 15.6332 0.893555 17.1066C0.893555 17.9907 1.24474 18.8385 1.86987 19.4636C2.49499 20.0888 3.34283 20.4399 4.22689 20.4399H17.5602C18.4443 20.4399 19.2921 20.0888 19.9172 19.4636C20.5424 18.8385 20.8936 17.9907 20.8936 17.1066C20.8936 15.6332 20.3082 14.2201 19.2664 13.1782C18.2245 12.1364 16.8114 11.5511 15.338 11.5511H6.44911Z"
            fill="#221668"
          />
        </svg>

        <div class="tejas-bespokesolutions-co-in" >
         {{CompanyEmail}}
        </div>
      </div>
      <!-- <svg
        class="vector-8"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.782227 9.43994L9.32024 17.9731L17.8583 9.43994"
          stroke="black"
          stroke-width="1.77778"
          stroke-linecap="round"
        />
      </svg> -->
      <div class="side-nav" style="margin-left: 115px;">
        <a class="login-left" routerLink="/login" *ngIf="!myGlobals.isSubmitted" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}">
          <i class="flaticon-enter"></i>
          Login
        </a>
        <a class="login-left" *ngIf="myGlobals.isSubmitted " (click)="onLogout($event)"><i
            class="flaticon-enter"></i> Logout</a>
      </div>
    </div>
    
  </div>

</div>

  </div>
  

  <ul>
  <!-- <li (click)="myProfile()" class="nav-item" *ngIf="myGlobals.isCompany && myGlobals.isSubmitted">
    <a routerLink="/companyhome/profile" class="nav-link" routerLinkActive="active"
      [routerLinkActiveOptions]="{exact: true}">My Profile</a>
  </li> -->
  <div class="frame-1228" (click)="myProfile()" *ngIf="myGlobals.isCompany && myGlobals.isSubmitted">
   
    <svg
      class="vector9"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.77821 5.01525C5.77821 3.83651 6.24646 2.70604 7.07996 1.87255C7.91345 1.03905 9.04392 0.570801 10.2227 0.570801C11.4014 0.570801 12.5319 1.03905 13.3654 1.87255C14.1988 2.70604 14.6671 3.83651 14.6671 5.01525C14.6671 6.19399 14.1988 7.32445 13.3654 8.15794C12.5319 8.99144 11.4014 9.45969 10.2227 9.45969C9.04392 9.45969 7.91345 8.99144 7.07996 8.15794C6.24646 7.32445 5.77821 6.19399 5.77821 5.01525ZM5.77821 11.6819C4.30479 11.6819 2.89171 12.2672 1.84984 13.3091C0.807972 14.351 0.222656 15.764 0.222656 17.2375C0.222656 18.1215 0.573846 18.9694 1.19897 19.5945C1.82409 20.2196 2.67193 20.5708 3.55599 20.5708H16.8893C17.7734 20.5708 18.6212 20.2196 19.2463 19.5945C19.8715 18.9694 20.2227 18.1215 20.2227 17.2375C20.2227 15.764 19.6373 14.351 18.5955 13.3091C17.5536 12.2672 16.1405 11.6819 14.6671 11.6819H5.77821Z"
        fill="white"
      />
    </svg>

    <div  class="my-profile">My Profile</div>
  </div>
  <!-- <li class="nav-item" *ngIf="myGlobals.isCompany && myGlobals.isSubmitted">
    <a routerLink="/post-a-job" class="nav-link" routerLinkActive="active"
      [routerLinkActiveOptions]="{exact: true}">Job Post</a>
  </li> -->

  <div class="frame-1229" (click)="jobpost()" *ngIf="myGlobals.isCompany && myGlobals.isSubmitted">
    <svg
      class="group-1198"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.11916 2.50899V4.08677L3.23694 4.2401C2.5903 4.29214 1.97979 4.5595 1.50302 4.99941C1.02624 5.43933 0.710731 6.0264 0.606938 6.66677C0.561382 6.95344 0.518049 7.2401 0.480271 7.52788C0.471278 7.59746 0.484617 7.6681 0.518367 7.72961C0.552116 7.79111 0.60453 7.84031 0.668049 7.8701L0.753605 7.9101C6.78583 10.7657 14.0092 10.7657 20.0403 7.9101L20.1258 7.8701C20.1891 7.84014 20.2413 7.79087 20.2749 7.72937C20.3084 7.66788 20.3216 7.59733 20.3125 7.52788C20.2748 7.24025 20.233 6.95318 20.1869 6.66677C20.0831 6.0264 19.7676 5.43933 19.2909 4.99941C18.8141 4.5595 18.2036 4.29214 17.5569 4.2401L15.6747 4.08788V2.5101C15.6748 2.04438 15.5078 1.59409 15.204 1.24112C14.9001 0.888157 14.4797 0.655962 14.0192 0.58677L12.6636 0.383437C11.1609 0.157995 9.63297 0.157995 8.13027 0.383437L6.77471 0.58677C6.31435 0.655934 5.89408 0.887968 5.59028 1.2407C5.28648 1.59344 5.11931 2.04346 5.11916 2.50899ZM12.4158 2.03121C11.0774 1.83047 9.71649 1.83047 8.37805 2.03121L7.02249 2.23455C6.95673 2.24439 6.89667 2.2775 6.85324 2.32786C6.80981 2.37822 6.78589 2.44249 6.78583 2.50899V3.9701C9.19126 3.83231 11.6026 3.83231 14.008 3.9701V2.50899C14.008 2.44249 13.9841 2.37822 13.9406 2.32786C13.8972 2.2775 13.8371 2.24439 13.7714 2.23455L12.4158 2.03121Z"
        fill="white"
      />
      <path
        d="M20.5273 9.85217C20.5251 9.81624 20.5142 9.78139 20.4956 9.75061C20.4769 9.71983 20.4511 9.69404 20.4203 9.67546C20.3894 9.65688 20.3546 9.64606 20.3186 9.64394C20.2827 9.64182 20.2468 9.64845 20.214 9.66328C14.024 12.4044 6.76845 12.4044 0.578453 9.66328C0.545657 9.64845 0.509751 9.64182 0.473822 9.64394C0.437894 9.64606 0.403019 9.65688 0.372196 9.67546C0.341372 9.69404 0.315523 9.71983 0.296872 9.75061C0.27822 9.78139 0.267323 9.81624 0.265119 9.85217C0.151566 11.9794 0.26592 14.1126 0.60623 16.2155C0.710023 16.8559 1.02553 17.4429 1.50231 17.8829C1.97909 18.3228 2.5896 18.5901 3.23623 18.6422L5.31623 18.8111C8.69734 19.0833 12.094 19.0833 15.4762 18.8111L17.5562 18.6433C18.203 18.5912 18.8137 18.3237 19.2905 17.8835C19.7673 17.4434 20.0827 16.8561 20.1862 16.2155C20.5262 14.1099 20.6418 11.9766 20.5273 9.85328V9.85217Z"
        fill="white"
      />
    </svg>

    <div class="job-post">Job Post</div>
  </div>

  <!-- <li class="nav-item" *ngIf="myGlobals.isCompany && myGlobals.isSubmitted">
    <div routerLink="/candidates" class="find-candidate frame-1233" routerLinkActive="active"
      [routerLinkActiveOptions]="{exact: true}">Find Candidate</div>
  </li> -->

  <div class="frame-1233" (click)="Findcandidate()" *ngIf="myGlobals.isCompany && myGlobals.isSubmitted">
    <div class="frame-1230">
      <svg
        class="vector6"
        width="25"
        height="17"
        viewBox="0 0 25 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.6667 7.32292C18.5111 7.32292 19.9889 5.83403 19.9889 3.98958C19.9889 2.14514 18.5111 0.65625 16.6667 0.65625C14.8222 0.65625 13.3333 2.14514 13.3333 3.98958C13.3333 5.83403 14.8222 7.32292 16.6667 7.32292ZM7.77778 7.32292C9.62222 7.32292 11.1 5.83403 11.1 3.98958C11.1 2.14514 9.62222 0.65625 7.77778 0.65625C5.93333 0.65625 4.44444 2.14514 4.44444 3.98958C4.44444 5.83403 5.93333 7.32292 7.77778 7.32292ZM7.77778 9.54514C5.18889 9.54514 0 10.8451 0 13.434V16.2118H15.5556V13.434C15.5556 10.8451 10.3667 9.54514 7.77778 9.54514ZM16.6667 9.54514C16.3444 9.54514 15.9778 9.56736 15.5889 9.60069C16.8778 10.534 17.7778 11.7896 17.7778 13.434V16.2118H24.4444V13.434C24.4444 10.8451 19.2556 9.54514 16.6667 9.54514Z"
          fill="white"
        />
      </svg>

      <div class="find-candidate">Find Candidate</div>
    </div>

   
  </div>

  <!-- <li class="nav-item" *ngIf="myGlobals.isCompany && myGlobals.isSubmitted"> -->
    <!-- <svg
    class="group-1197"
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.63333 10.8801C9.38889 10.869 9.14444 10.8579 8.88889 10.8579C6.2 10.8579 3.68889 11.6024 1.54444 12.8801C0.566667 13.4579 0 14.5468 0 15.6912V17.5246C0 18.1357 0.5 18.6357 1.11111 18.6357H10.2889C9.5041 17.5162 9.02999 16.2087 8.91484 14.8463C8.79968 13.484 9.0476 12.1155 9.63333 10.8801Z"
      fill="white"
    />
    <path
      d="M8.88976 9.7468C11.3444 9.7468 13.3342 7.75695 13.3342 5.30235C13.3342 2.84776 11.3444 0.85791 8.88976 0.85791C6.43516 0.85791 4.44531 2.84776 4.44531 5.30235C4.44531 7.75695 6.43516 9.7468 8.88976 9.7468Z"
      fill="white"
    />
    <path
      d="M20.8339 14.1914C20.8339 13.947 20.8005 13.7247 20.7672 13.4914L21.7005 12.6803C21.9005 12.5025 21.945 12.2136 21.8116 11.9803L21.1561 10.847C21.0933 10.7354 20.993 10.6496 20.873 10.6049C20.753 10.5601 20.6211 10.5593 20.5005 10.6025L19.3227 11.0025C18.9672 10.7025 18.5672 10.4692 18.1227 10.3025L17.8783 9.09142C17.8521 8.96622 17.7838 8.85379 17.6847 8.77291C17.5856 8.69202 17.4618 8.64757 17.3339 8.64697H16.0227C15.7561 8.64697 15.5339 8.83586 15.4783 9.09142L15.2339 10.3025C14.7894 10.4692 14.3894 10.7025 14.0339 11.0025L12.8561 10.6025C12.7354 10.5611 12.6041 10.5628 12.4845 10.6074C12.365 10.652 12.2646 10.7366 12.2005 10.847L11.545 11.9803C11.4116 12.2136 11.4561 12.5025 11.6561 12.6803L12.5894 13.4914C12.5561 13.7247 12.5227 13.947 12.5227 14.1914C12.5227 14.4359 12.5561 14.6581 12.5894 14.8914L11.6561 15.7025C11.4561 15.8803 11.4116 16.1692 11.545 16.4025L12.2005 17.5359C12.3339 17.7692 12.6116 17.8692 12.8561 17.7803L14.0339 17.3803C14.3894 17.6803 14.7894 17.9136 15.2339 18.0803L15.4783 19.2914C15.5339 19.547 15.7561 19.7359 16.0227 19.7359H17.3339C17.6005 19.7359 17.8227 19.547 17.8783 19.2914L18.1227 18.0803C18.5672 17.9136 18.9672 17.6803 19.3227 17.3803L20.5005 17.7803C20.7561 17.8692 21.0227 17.7581 21.1561 17.5359L21.8116 16.4025C21.945 16.1692 21.9005 15.8803 21.7005 15.7025L20.7672 14.8914C20.8005 14.6581 20.8339 14.4359 20.8339 14.1914ZM16.6672 16.4136C15.445 16.4136 14.445 15.4136 14.445 14.1914C14.445 12.9692 15.445 11.9692 16.6672 11.9692C17.8894 11.9692 18.8894 12.9692 18.8894 14.1914C18.8894 15.4136 17.8894 16.4136 16.6672 16.4136Z"
      fill="white"
    />
  </svg>
   <div routerLink="/companymanagejob" class="manage-jobs frame-1231" routerLinkActive="active"
      [routerLinkActiveOptions]="{exact: true}">Manage Jobs</div> -->

      <div class="frame-1231" (click)="ManageJob()" *ngIf="myGlobals.isCompany && myGlobals.isSubmitted">
        <svg
          class="group-1197"
          width="22"
          height="20"
          viewBox="0 0 22 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.63333 10.8801C9.38889 10.869 9.14444 10.8579 8.88889 10.8579C6.2 10.8579 3.68889 11.6024 1.54444 12.8801C0.566667 13.4579 0 14.5468 0 15.6912V17.5246C0 18.1357 0.5 18.6357 1.11111 18.6357H10.2889C9.5041 17.5162 9.02999 16.2087 8.91484 14.8463C8.79968 13.484 9.0476 12.1155 9.63333 10.8801Z"
            fill="white"
          />
          <path
            d="M8.88976 9.7468C11.3444 9.7468 13.3342 7.75695 13.3342 5.30235C13.3342 2.84776 11.3444 0.85791 8.88976 0.85791C6.43516 0.85791 4.44531 2.84776 4.44531 5.30235C4.44531 7.75695 6.43516 9.7468 8.88976 9.7468Z"
            fill="white"
          />
          <path
            d="M20.8339 14.1914C20.8339 13.947 20.8005 13.7247 20.7672 13.4914L21.7005 12.6803C21.9005 12.5025 21.945 12.2136 21.8116 11.9803L21.1561 10.847C21.0933 10.7354 20.993 10.6496 20.873 10.6049C20.753 10.5601 20.6211 10.5593 20.5005 10.6025L19.3227 11.0025C18.9672 10.7025 18.5672 10.4692 18.1227 10.3025L17.8783 9.09142C17.8521 8.96622 17.7838 8.85379 17.6847 8.77291C17.5856 8.69202 17.4618 8.64757 17.3339 8.64697H16.0227C15.7561 8.64697 15.5339 8.83586 15.4783 9.09142L15.2339 10.3025C14.7894 10.4692 14.3894 10.7025 14.0339 11.0025L12.8561 10.6025C12.7354 10.5611 12.6041 10.5628 12.4845 10.6074C12.365 10.652 12.2646 10.7366 12.2005 10.847L11.545 11.9803C11.4116 12.2136 11.4561 12.5025 11.6561 12.6803L12.5894 13.4914C12.5561 13.7247 12.5227 13.947 12.5227 14.1914C12.5227 14.4359 12.5561 14.6581 12.5894 14.8914L11.6561 15.7025C11.4561 15.8803 11.4116 16.1692 11.545 16.4025L12.2005 17.5359C12.3339 17.7692 12.6116 17.8692 12.8561 17.7803L14.0339 17.3803C14.3894 17.6803 14.7894 17.9136 15.2339 18.0803L15.4783 19.2914C15.5339 19.547 15.7561 19.7359 16.0227 19.7359H17.3339C17.6005 19.7359 17.8227 19.547 17.8783 19.2914L18.1227 18.0803C18.5672 17.9136 18.9672 17.6803 19.3227 17.3803L20.5005 17.7803C20.7561 17.8692 21.0227 17.7581 21.1561 17.5359L21.8116 16.4025C21.945 16.1692 21.9005 15.8803 21.7005 15.7025L20.7672 14.8914C20.8005 14.6581 20.8339 14.4359 20.8339 14.1914ZM16.6672 16.4136C15.445 16.4136 14.445 15.4136 14.445 14.1914C14.445 12.9692 15.445 11.9692 16.6672 11.9692C17.8894 11.9692 18.8894 12.9692 18.8894 14.1914C18.8894 15.4136 17.8894 16.4136 16.6672 16.4136Z"
            fill="white"
          />
        </svg>
  
        <div class="manage-jobs">Manage Jobs</div>
      </div>

</ul>
  
</div>


