<div class="user-form-area">
  <div class="container-fluid p-0">
    <div class="row m-0 align-items-center">
      <div class="col-lg-12 p-0 col-md-12">
        <div class="user-content">
          <div class="top">
            <h2>Forgot Password</h2>

            <form [formGroup]="addEditForm">
              <div class="form-group" *ngIf="User">
                <input type="email" class="form-control" placeholder="Email" formControlName="Email">
              </div>

              <div class="form-group" *ngIf="OTP">
                <input type="password" class="form-control" placeholder="Please Enter OTP" formControlName="OTP">
              </div>

              <div *ngIf="ChangePsw">
                <div class="form-group">
                  <label>New Password</label>
                  <input type="password" id="newpassword" class="form-control" placeholder="New Password"
                    formControlName="newpass" [ngClass]="{ 'is-invalid': submitpassword && fpass.newpass.errors }">
                  <i class="bi-eye-slash" (click)="show1('newpassword', 'togglePassword')" id="togglePassword"
                    style="cursor: pointer;"></i>
                  <div *ngIf="submitpassword && fpass.newpass.errors" class="invalid-feedback">
                    <div *ngIf="fpass.newpass.errors.required">Password is required</div>
                    <small *ngIf="newpass?.invalid && newpass?.touched" class="text-danger">* Password Should be
                      Minimum 8 Character one upper case one lowe case,one Number,one Special symbol</small>
                  </div>
                </div>

                <div class="form-group">
                  <label>Confirm Password</label>
                  <input type="password" id="confirmPassword" class="form-control" placeholder="Confirm Password"
                    formControlName="CFpass" [ngClass]="{ 'is-invalid': submitpassword && fpass.CFpass.errors }" />
                  <i class="bi-eye-slash" (click)="show2('confirmPassword', 'togglePassword')" id="togglePassword"
                    style="cursor: pointer;"></i>
                  <div *ngIf="submitpassword && fpass.CFpass.errors" class="invalid-feedback">
                    <div *ngIf="fpass.CFpass.errors.required">
                      Confirm Password is required
                    </div>
                    <div *ngIf="fpass.CFpass.errors.confirmPasswordValidator">
                      Confirm Password does not match
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <button (click)="Submit()" type="submit" class="btn" *ngIf="Userbtn"> Submit</button>
                <button (click)="SendOtp()" type="submit" class="btn" *ngIf="OTP"> Verify OTP</button>
                <button (click)="changepsw()" type="submit" class="btn" *ngIf="ChangePsw"> Save</button>
                <!-- <button  type="close" class="btn"  *ngIf="Userbtn">Close</button> -->
              </div>
            </form>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</div>