import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/global.service';
import { SharedService } from 'src/app/shared.service';
import { AddEditPrefferedLocationComponent } from '../Preffered Location/add-edit-location/add-edit-location.component';
import { ChangepasswordService } from './services/Change-password.service';
@Component({
    selector: 'app-changepassword',
    templateUrl: './Change-password.component.html',
    styleUrls: ['./Change-password.component.scss']
})
export class ChangepasswordComponent implements OnInit, OnDestroy {
    submitpassword: boolean = false;
    ChangePasswordInfo: FormGroup;
    public info: any;
    summary: string;
    response: any;
    openform = true;
    boolVar = true;
    fileType: string = '';
    base64textString: string[] = [];
    type: string = 'password';

    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private router: Router,
        private Service: SharedService<any>,
        private Global:GlobalService,
        private dialogRef: MatDialogRef<AddEditPrefferedLocationComponent>,
    ) { }

    ngOnInit(): void {
        this.ChangePasswordInfo = this.formBuilder.group({
            UserId:[Validators.nullValidator],
            oldpass: ['', [Validators.required]],
            newpass: ['', [Validators.required]],
            CFpass: ['', [Validators.required]],
        });
        this.getUser();
    }

    get fpass(): { [key: string]: AbstractControl } {
        return this.ChangePasswordInfo.controls;
    }

    // changepassSubmit() {
    //     this.submitpassword = true;
    //     if (this.ChangePasswordInfo.invalid) {
    //         this.toastr.error(this.response.message);
    //     }
    //     else {
    //         this.basicInfoService.basicInfoForm(this.ChangePasswordInfo.value).subscribe(
    //             (res) => {
    //                 this.toastr.success(res.message);
    //                 this.getUser();
    //             },
    //             (error) => {
    //                 //if (error.status == 401) this.router.navigate(['/login']);
    //                 this.toastr.error(error.error.message);
    //             });
    //         this.submitpassword = false;
    //     }
    // }

    changepassSubmit() {
        this.submitpassword = true;
        if (this.ChangePasswordInfo.invalid) {
            this.toastr.error('This data is not Valid');
        }
        else {
            const updatepersonalinfo = this.ChangePasswordInfo.getRawValue();
            updatepersonalinfo.userId = this.Global.getuserId();
            if (updatepersonalinfo.oldpass != updatepersonalinfo.newpass) {
                if (updatepersonalinfo.newpass == updatepersonalinfo.CFpass) {
                    this.Service.Post('EmployeeChangePass/ChangePassword', updatepersonalinfo).subscribe(
                         (res) => {
                            this.toastr.success(res);
                            this.ChangePasswordInfo.reset();
                            this.dialogRef.close();
                            this.router.navigate(['/login']);

                        },
                    )
                }
                else {
                    alert("New Password And Confirm Password Are Not Same");
                }

            }
            else {
                alert("Old Password And New Password are Same");
            }
        }
    }
    close()
    {
        this.dialogRef.close(); 
    }

    show(a, b) {
        var x = document.getElementById(a);
        var c = document.getElementById(b);
        if (x.getAttribute('type') == "password") {
            c.removeAttribute("class");
            c.setAttribute("class", "bi-eye");
            x.removeAttribute("type");
            x.setAttribute("type", "text");
        } else {
            x.removeAttribute("type");
            x.setAttribute('type', 'password');
            c.removeAttribute("class");
            c.setAttribute("class", "bi-eye-slash");
        }
    }

    show1(a, b) {
        var x = document.getElementById(a);
        var c = document.getElementById(b);
        if (x.getAttribute('type') == "password") {
            c.removeAttribute("class");
            c.setAttribute("class", "bi-eye");
            x.removeAttribute("type");
            x.setAttribute("type", "text");
        } else {
            x.removeAttribute("type");
            x.setAttribute('type', 'password');
            c.removeAttribute("class");
            c.setAttribute("class", "bi-eye-slash");
        }
    }

    show2(a, b) {
        var x = document.getElementById(a);
        var c = document.getElementById(b);
        if (x.getAttribute('type') == "password") {
            c.removeAttribute("class");
            c.setAttribute("class", "bi-eye");
            x.removeAttribute("type");
            x.setAttribute("type", "text");
        } else {
            x.removeAttribute("type");
            x.setAttribute('type', 'password');
            c.removeAttribute("class");
            c.setAttribute("class", "bi-eye-slash");
        }
    }

    getUser() {
        // this.basicInfoService.findUsers().subscribe(
        //     (res) => {
        //         this.info = res;

        //         // if (this.basicInfo.length > 0) {
        //         //     this.summary = this.basicInfo[0].summary;
        //         // } else {
        //         //     this.summary = "Hi i have not updated my information yet!"
        //         // }
        //     },
        //     (error) => {
        //         //if (error.status == 401) this.router.navigate(['/login']);
        //         this.toastr.error(error.error.message);
        //     });
    }

    isEmpty(obj: any) {
        console.log(obj);
        return Object.keys(obj).length != 0;
    }

    editInfo() {
        // this.openform = !this.openform;
        // this.boolVar = !this.boolVar;
        let i = 0;
        // this.basicInfoForm.controls.name.setValue(this.basicInfo[i].name);
        // this.basicInfoForm.controls.email.setValue(this.basicInfo[i].email);
        // this.basicInfoForm.controls.mobile_number.setValue(this.basicInfo[i].mobile_number);
        // this.basicInfoForm.controls.summary.setValue(this.basicInfo[i].summary);
        // this.basicInfoForm.controls.dob.setValue(this.basicInfo[i].dob);
        // this.basicInfoForm.controls.gender.setValue(this.basicInfo[i].gender);
        // this.basicInfoForm.controls.marital_status.setValue(this.basicInfo[i].marital_status);
        // this.basicInfoForm.controls.nationality.setValue(this.basicInfo[i].nationality);
        // this.basicInfoForm.controls.cnic.setValue(this.basicInfo[i].cnic);
        // this.basicInfoForm.controls.career_level.setValue(this.basicInfo[i].career_level);
        // this.basicInfoForm.controls.experience.setValue(this.basicInfo[i].experience);
        // this.basicInfoForm.controls.city.setValue(this.basicInfo[i].city);
        // this.basicInfoForm.controls.area.setValue(this.basicInfo[i].area);
        // this.basicInfoForm.controls.expected_salary.setValue(this.basicInfo[i].expected_salary);

        // this.getUser();
    }



    ngOnDestroy(): void {

    }
}
