<h1 mat-dialog-title class="h5">
  {{title}}
</h1>

<div mat-dialog-content>
  <p class="h6 m">{{message}}</p>
</div>

<div mat-dialog-actions style="margin-left: 20%;">
  <button mat-raised-button style="background-color:rgb(0, 38, 75)" (click)="onConfirm()" class="text-white">
    Yes
  </button>
  <button mat-button (click)="onDismiss()" style="background-color:rgb(0, 38, 75)" class="text-white">
    No
  </button>
</div>