import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import { Observable } from 'rxjs';
import { cities, JobRole, Qualificationa } from 'src/app/interface';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/global.service';
import { AuthService } from 'src/app/auth.service';

@Component({
    selector: 'app-companymanagejob',
    templateUrl: './companymanagejob.component.html',
    styleUrls: ['./companymanagejob.component.scss']
})

export class CompanyManageJobComponent implements OnInit, OnDestroy {

    jobPostsInfo = [];
    allJobRole: Observable<JobRole[]>;
    allQualification: Observable<Qualificationa[]>;
    allCities: Observable<cities[]>
    obs: any;
    dataFilter: number[] = [];
    data: any;
    cityControl = new FormControl();
    EducationControl = new FormControl();
    jobTypeControl = new FormControl();
    datasource: any;
    page: number = 1;
    pagesize: number = 10;
    totalItems: number = 0;
    datapageInfo: any;
    totalpages: number;
    Active: boolean = false;
    Close: boolean = false;
    Expired: boolean = false;
    jobpostcount: number;
    SearchLocation: string = '';
    order: string = 'ASC';
    SearchJobRole: string = '';
    manageForm: FormGroup;

    constructor(
        private Service: SharedService<any>,
        private toastr: ToastrService,
        private router: Router,
        public Global: GlobalService,
        private auth: AuthService,
        private formBuilder: FormBuilder,
    ) 
    {

    }

    ngOnInit(): void 
    {
        this.manageForm = this.formBuilder.group({
            PageSearch: [null, Validators.nullValidator],
        });
        this.manageForm.controls["PageSearch"].setValue(this.page);
        this.getAllActiveJobs();
        this.getpostjobcount()
    }

    getAllJobs() 
    {
        this.Active = false;
        this.Close = false;
        this.Expired = true;
        this.Service.Post('Job/GetJobsCardDataByForComapany?userid=' + this.Global.getuserId() + '&pageno=' + this.page + '&noOfRecords=' + this.pagesize +
            '&order=' + this.order + '&searchJobRole=' + this.SearchJobRole + '&searchLocation=' + this.SearchLocation + '&JobStatus=2', null).subscribe(
                (res) => {
                    this.jobPostsInfo = res[0];
                    this.obs = this.jobPostsInfo;
                    this.datapageInfo = res[1];
                    this.jobPostsInfo.length
                    if (this.datapageInfo[0].length) {
                        this.totalItems = this.datapageInfo[0].length;
                        this.totalpages = Math.ceil(this.totalItems / 10);
                    }
                },
                (error) => {
                    this.toastr.error(error.error.message);
                });
    }

    getAllCloseJobs() 
    {
        this.Active = false;
        this.Close = true;
        this.Expired = false;
        this.Service.Post('Job/GetJobsCardDataByForComapany?userid=' + this.Global.getuserId() + '&pageno=' + this.page + '&noOfRecords=' + this.pagesize +
            '&order=' + this.order + '&searchJobRole=' + this.SearchJobRole + '&searchLocation=' + this.SearchLocation + '&JobStatus=1', null).subscribe(
                (res) => {
                    this.jobPostsInfo = res[0];
                    this.obs = this.jobPostsInfo;
                    this.datapageInfo = res[1];
                    this.jobPostsInfo.length
                    if (this.datapageInfo[0].length) {
                        this.totalItems = this.datapageInfo[0].length;
                        this.totalpages = Math.ceil(this.totalItems / 10);
                    }

                },
                (error) => {
                    this.toastr.error(error.error.message);
                });
    }

    getAllActiveJobs() 
    {
        this.Active = true;
        this.Close = false;
        this.Expired = false;

        this.Service.Post('Job/GetJobsCardDataByForComapany?userid=' + this.Global.getuserId() + '&pageno=' + this.page + '&noOfRecords=' + this.pagesize +
            '&order=' + this.order + '&searchJobRole=' + this.SearchJobRole + '&searchLocation=' + this.SearchLocation + '&JobStatus=0', null).subscribe(
                (res) => {
                    this.jobPostsInfo = res[0];
                    this.obs = this.jobPostsInfo;
                    this.datapageInfo = res[1];
                    this.jobPostsInfo.length
                    if (this.datapageInfo[0].length) {
                        this.totalItems = this.datapageInfo[0].length;
                        this.totalpages = Math.ceil(this.totalItems / 10);
                    }
                },
                (error) => {
                    this.toastr.error(error.error.message);
                });
    }


    GOTO()
    {
        if (this.Active == true) {
            this.getAllActiveJobs();
        }
        if (this.Close == true) {
            this.getAllCloseJobs();
        }
        if (this.Expired == true) {
            this.getAllJobs();
        }

    }


    Previous() 
    {
        if (this.page > 1) {
            this.page = this.page - 1
            this.manageForm.controls["PageSearch"].setValue(this.page);

            this.pagesize;
            if (this.Active == true) {
                this.getAllActiveJobs();
            }
            if (this.Close == true) {
                this.getAllCloseJobs();
            }
            if (this.Expired == true) {
                this.getAllJobs();
            }
        } else {

        }

    }

    Next() 
    {
        if (this.totalpages > this.page) {
            this.page = this.page + 1
            this.manageForm.controls["PageSearch"].setValue(this.page);

            if (this.Active == true) {
                this.getAllActiveJobs();
            }
            if (this.Close == true) {
                this.getAllCloseJobs();
            }
            if (this.Expired == true) {
                this.getAllJobs();
            }
        }
    }

    GoToPage(event: Event) {
        var value = (event.target as HTMLInputElement).value;
        if (+value > this.totalpages
            || +value < 0) {
            this.toastr.success('Page no Should be between : 0 And ' + this.totalpages);
        }
        else 
        {
            this.page = +value;
            if (this.Active == true) {
                this.getAllActiveJobs();
            }
            if (this.Close == true) {
                this.getAllCloseJobs();
            }
            if (this.Expired == true) {
                this.getAllJobs();
            }
        }
    }

    getpostjobcount() {
        this.Service.Get('Job/GetCompanyWiseJobPostCount?userId=' + this.Global.getuserId(), null).subscribe(jobrole => {
            this.jobpostcount = jobrole;
        })
    }

    Searchjobtitles(event: Event) {
        this.SearchJobRole = (event.target as HTMLInputElement).value;
        if (this.Active == true) {
            this.getAllActiveJobs();
        }
        if (this.Close == true) {
            this.getAllCloseJobs();
        }
        if (this.Expired == true) {
            this.getAllJobs();
        }
    }

    Searchjoblocation(event: Event) {
        this.SearchLocation = (event.target as HTMLInputElement).value;
        if (this.Active == true) {
            this.getAllActiveJobs();
        }
        if (this.Close == true) {
            this.getAllCloseJobs();
        }
        if (this.Expired == true) {
            this.getAllJobs();
        }
    }

    onchangeOrder(event) {
        this.order = event;
        if (this.Active == true) {
            this.getAllActiveJobs();
        }
        if (this.Close == true) {
            this.getAllCloseJobs();
        }
        if (this.Expired == true) {
            this.getAllJobs();
        }
    }

    getAllJobRole() 
    {
        this.Service.Get('JobRole/GetJobRole?Id=0', null).subscribe(jobrole => {
            this.allJobRole = jobrole;
        })
    }

    getAllQualification() 
    {
        this.Service.Get('Qualification/GetQualification?Id=0', null).subscribe(Qualificationa => {
            this.allQualification = Qualificationa;
        })
    }

    getAllCity() 
    {
        this.Service.Get('City/GetCity?Id=0', null).subscribe(city => {
            this.allCities = city;
        })
    }

    JobClick(jobpost: any) 
    {
        this.auth.setJobPostId(jobpost.Id);
        this.router.navigate(['job-details']);
    }

    OnMatCardClickEvent(Jobpost: any) 
    {
        this.Global.setjobTypeId(Jobpost.Id);
        this.router.navigate(['edit-job-post']);
    }

    isReadMore = true
    isReadMore1 = true

    showText() 
    {
        this.isReadMore = !this.isReadMore
    }

    showspecficationText() 
    {
        this.isReadMore1 = !this.isReadMore1
    }

    PostJob() 
    {
        this.router.navigate(['/post-a-job']);
    }

    ngOnDestroy(): void {

    }

}
