import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/global.service';
import { SharedService } from 'src/app/shared.service';
import { BuildResume } from '../../dashboard/basic-info/basic-info.types';
import { AddJobRoleComponent } from './jobrol/addjobrole.component';

@Component({
    selector: 'app-landingpage',
    templateUrl: './landingpage.component.html',
    styleUrls: ['./landingpage.component.scss']
})
export class LandingPageComponent implements OnInit {

    userId: number;
    getresumeData: BuildResume;
    candidateImage: string = '';
    educationInfo: any;
    page: number = 1;
    pagesize: number = 3;
    totalItems: number = 0;
    datapageInfo: any; obs: any;
    jobPostsInfo = [];
    companyInfo = [];
    totalpages: number;
    totalcompanypages: number;
    companypage: number = 1;
    companypagesize: number = 3;
    companytotalItems: number = 0;
    companydatapageInfo: any;
    companyobs: any;
    nextdata: boolean = true;
    Previousdata: boolean = false;
    recommendeddata: boolean = true;
    addjobrole: boolean = false;
    displayjobRole: boolean = true;
    acdamic: boolean;
    count: boolean;
    nextcompanydata: boolean = true;
    Previouscompanydata: boolean = false;
    color = `#6e6e6e`;
    nextcolor = `#F4F4F4`;
    companycolor = `#6e6e6e`;
    companynextcolor = `#F4F4F4`;
    constructor(
        private toastr: ToastrService,
        private Service: SharedService<any>,
        private Global: GlobalService,
        private dialog: MatDialog,
        private router: Router,
        
    ) 
    {

    }

    ngOnInit() 
    {
        this.getUser();
        this.getAllEducations();
        this.getAllRecommandedJobs();
        this.getAlltopcompanys();
    }

    getUser() 
    {
        this.userId = this.Global.getuserId();
        this.Service.Get('CandidateInfo/GetCandidateInfoByUserId?userId=' + this.userId, null).subscribe(
            (res) => {
                this.getresumeData = res;
                this.candidateImage = this.getresumeData.Photo;
                if (this.getresumeData.JobRoleId == 0) {
                    this.displayjobRole = true;
                }
                else {
                    this.displayjobRole = false;
                }
            },(error) => {
                this.toastr.error(error.error);
            });
    }

    getAllEducations() {
        this.Service.Get('CandidateEducationInfo/GetCandidateEducationInfoByEmpId?EmpId=' + this.Global.getCandidateId(), null).subscribe(res => {
            this.educationInfo = res;
            if (this.educationInfo.length != 0)
             {
                this.acdamic = true;
            }
            else 
            {
                this.acdamic = false;
            }
        }, (error) => {
            this.toastr.error(error.error);
        });
    }

    getAllRecommandedJobs() {
        this.Service.Get('Job/GetRecommandedJobsForCandidate?userId=' + this.Global.getuserId() + '&page=' + this.page + '&pagesize=' + this.pagesize, null).subscribe(
            (res) => {
                this.jobPostsInfo = res[0];
                this.obs = this.jobPostsInfo;
                this.datapageInfo = res[1];
                this.totalItems = this.datapageInfo[0].length;
                if (this.totalItems > 0) 
                {
                    this.totalpages = Math.ceil(this.totalItems / 3);
                }
                else 
                {
                    this.totalpages = 0;
                }
                if (this.jobPostsInfo.length != 0) 
                {
                    this.recommendeddata = true;
                    this.addjobrole = false;
                }
                else 
                {
                    this.addjobrole = true;
                    this.recommendeddata = false;
                }
            },(error) => {
                this.toastr.error(error.error);
            });
    }

    getAlltopcompanys() 
    {
        this.Service.Get('Job/TopCompanyForCandidate?userId=' + this.Global.getuserId() + '&page=' + this.companypage + '&pagesize=' + this.pagesize, null).subscribe(
            (res) => {
                this.companyInfo = res[0];
                this.companyobs = this.companyInfo;
                this.companydatapageInfo = res[1];
                this.companytotalItems = this.companydatapageInfo[0].length;
                if (this.companytotalItems > 0) 
                {
                    this.totalcompanypages = Math.ceil(this.companytotalItems / 3);
                }
                else 
                {
                    this.totalcompanypages = 0;
                }
                if (this.companyobs.length != 0) 
                {
                    this.count = true;
                }
                else
                {
                    this.count = false;
                }
            },(error) => {
                this.toastr.error(error.error);
            });
    }

    Next() {
        if (this.totalpages > this.page) {
            this.page = this.page + 1
            this.nextdata = true;
            this.Previousdata = true;
            this.color = `#F4F4F4`;
            this.nextcolor = `#F4F4F4`;
            this.getAllRecommandedJobs();
        }
        else {
            this.nextdata = false;
            this.nextcolor = `#6e6e6e`;
            this.Previousdata = true;
           // this.toastr.success('Page Should be between : 1 And ' + this.totalpages);
        }
    }

    Previous() {
        if (this.page == 1) {
            this.nextdata = true;
            this.Previousdata = false;
            this.color = `#6e6e6e`;
            this.nextcolor = `#F4F4F4`;
        }
        else {
            this.nextdata = true;
            this.Previousdata = true;
            this.color = `#6e6e6e`;
            this.nextcolor = `#F4F4F4`;
        }
        if (this.page > 1) {
            this.page = this.page - 1
            this.getAllRecommandedJobs();
            this.nextdata = true;
            this.Previousdata = true;
            this.color = `#F4F4F4`;
            this.nextcolor = `#F4F4F4`;
        }
        else {
            //this.toastr.success('Page Should be between : 1 And ' + this.totalpages);
            this.Previousdata = false;
            this.color = `#6e6e6e`;
            // this.nextdata = false;
        }
    }

    Nextcompany() {
        if (this.totalcompanypages > this.companypage) {
            this.companypage = this.companypage + 1;
            this.nextcompanydata = true;
            this.Previouscompanydata = true;
            this.companycolor = `#F4F4F4`;
            this.companynextcolor = `#F4F4F4`;
            this.getAlltopcompanys();
        }
        else {
            this.nextcompanydata = false;
            this.Previouscompanydata = true;
            this.companynextcolor = `#6e6e6e`;
            this.companycolor = `#F4F4F4`;
            //this.toastr.success('Page Should be between : 1 And ' + this.totalpages);
        }
    }

    PreviousCompany() {
        if (this.companypage == 1) {
            this.nextcompanydata = true;
            this.Previouscompanydata = false;
            this.companynextcolor = `#F4F4F4`;
            this.companycolor = `#6e6e6e`;
        }
        else {
            this.nextcompanydata = true;
            this.Previouscompanydata = true;
            this.companycolor = `#F4F4F4`;
            this.companynextcolor = `#F4F4F4`;
        
        }
        if (this.companypage > 1) {
            this.companypage = this.companypage - 1
            this.getAlltopcompanys();
            this.nextcompanydata = true;
            this.Previouscompanydata = true;
            this.companycolor = `#F4F4F4`;
            this.companynextcolor = `#F4F4F4`;
        }
        else {
            this.Previouscompanydata = false;
            this.companycolor = `#6e6e6e`;
            //this.toastr.success('Page no Should be between : 1 And ' + this.totalpages);
        }
    }

    AddJobRole() {
        this.dialog.open(AddJobRoleComponent, {
            width: '30%',
            height: 'auto',
            autoFocus: false,
            restoreFocus: false
        }).afterClosed().subscribe(val => {
            if (val == "Save") {
                this.getAllRecommandedJobs();
                this.getAlltopcompanys();
                this.getUser();
            }
        });
    }

    ViewAll() {
        this.router.navigate(['/recommandedjobs']);
    }
}