<div class="profile-content">
    <form [formGroup]="PersonalInfoForm" id="noScreen">
       
        <div style="width: 10px;
        height: 5px;
        position: relative;
        float: right;
        top: -16px;"  ><i matTooltip="Close" style="font-size: 25px;"  
            class='bx bx-x Clear' (click)="close()"></i></div>
        <div class="profile-content-inner">
            <div class="Name">Personal Information</div>

            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="form-group">
                        <label>First Name <label class="star">*</label></label>
                        <input type="text" class="form-control" placeholder="Tom Henry" formControlName="FirstName"
                            [ngClass]="{ 'is-invalid': submitted && fPinfo.FirstName.errors }" (keypress)="keyPressAlpha($event)">
                        <div *ngIf="submitted && fPinfo.FirstName.errors" class="invalid-feedback">
                            <div *ngIf="fPinfo.FirstName.errors.required">First Name is required</div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="form-group">
                        <label>Last Name <label class="star">*</label></label>
                        <input type="text" class="form-control" placeholder="Tom Henry" formControlName="LastName"
                            [ngClass]="{ 'is-invalid': submitted && fPinfo.LastName.errors }" (keypress)="keyPressAlpha($event)">
                        <div *ngIf="submitted && fPinfo.LastName.errors" class="invalid-feedback">
                            <div *ngIf="fPinfo.LastName.errors.required">Last Name is required</div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="form-group">
                        <label>Mobile No. <label class="star">*</label></label>
                        <input type="text" class="form-control" placeholder="Enter Mobile Number"
                            formControlName="MobileNo"
                            [ngClass]="{ 'is-invalid': submitted && fPinfo.MobileNo.errors }" maxlength="15" (keypress)="keyPressAlphaNumeric($event)">
                        <div *ngIf="submitted && fPinfo.MobileNo.errors" class="invalid-feedback">
                            <div *ngIf="fPinfo.MobileNo.errors.required">Phone number is required</div>
                        </div>
                    </div>
                </div>



                <div class="col-lg-6 col-md-12">
                    <div class="form-group">
                        <label class="col-lg-12 col-md-12">Gender <label class="star">*</label></label>


                        <select class="form-control form-select" formControlName="Gender"
                            [ngClass]="{ 'is-invalid': submitted && fPinfo.Gender.errors }">
                            <option value="" disabled selected>Please select</option>
                            <option *ngFor="let Gender of allGender" [value]="Gender.ListIndex">
                                {{Gender.MessageText}}
                            </option>
                        </select>
                        <div class="invalid-feedback" *ngIf="submitted && fPinfo.Gender.errors">
                            <div *ngIf="fPinfo.Gender.errors.required">
                                Gender is required
                            </div>
                        </div>
                    </div>
                </div>


                <div class="col-lg-6 col-md-12">
                    <div class="form-group">
                        <label>Date Of Birth <label class="star">*</label></label>
                        <input type="date" value="1995-10-01" class="form-control" placeholder="1995-01-01"
                            formControlName="DateOfBirth"
                            [ngClass]="{ 'is-invalid': submitted && fPinfo.DateOfBirth.errors }">
                        <div *ngIf="submitted && fPinfo.DateOfBirth.errors" class="invalid-feedback">
                            <div *ngIf="fPinfo.DateOfBirth.errors.required">Date of birth is required</div>
                        </div>
                    </div>
                </div>


                <div class="col-lg-6 col-md-12">
                    <div class="form-group">
                        <label>Marital Status</label>
                        <select class="form-control form-select" formControlName="MaritalStatus"
                            [ngClass]="{ 'is-invalid': submitted && fPinfo.MaritalStatus.errors }">
                            <option value="" disabled selected>Please select</option>
                            <option *ngFor="let MaritalStatus of allMaritalStatus" [value]="MaritalStatus.ListIndex">
                                {{MaritalStatus.MessageText}}
                            </option>
                        </select>
                        <div *ngIf="submitted && fPinfo.MaritalStatus.errors" class="invalid-feedback">
                            <div *ngIf="fPinfo.MaritalStatus.errors.required">Marital Status is
                                required</div>
                        </div>
                    </div>

              
                </div>



                <div class="col-lg-6 col-md-12">
                    <div class="form-group">
                        <label>Nationality</label>
                        <input type="text" class="form-control" placeholder="Tom Henry" formControlName="Nationality"
                            [ngClass]="{ 'is-invalid': submitted && fPinfo.Nationality.errors }" (keypress)="keyPressAlpha($event)">
                        <div *ngIf="submitted && fPinfo.Nationality.errors" class="invalid-feedback">
                            <!-- <div *ngIf="fPinfo.Nationality.errors.required">Nationality is required</div> -->
                        </div>
                    </div>
                </div>


                <div class="col-lg-6 col-md-12">
                    <div class="form-group">
                        <label>Differently Abled</label>
                        <select class="form-control form-select" formControlName="DifferentlyAbled"
                            [ngClass]="{ 'is-invalid': submitted && fPinfo.DifferentlyAbled.errors }">
                            <option value="" disabled selected>Please select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                        <div *ngIf="submitted && fPinfo.DifferentlyAbled.errors" class="invalid-feedback">
                            <div *ngIf="fPinfo.DifferentlyAbled.errors.required">Differently Abled is
                                required</div>
                        </div>
                    </div>
                </div>




                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label>Address 1 <label class="star">*</label></label>
                        <textarea type="text" id="username" class="form-control" placeholder="Address1"
                            formControlName="Address1"
                            [ngClass]="{ 'is-invalid': submitted && fPinfo.Address1.errors }"></textarea>
                        <div *ngIf="submitted && fPinfo.Address1.errors" class="invalid-feedback">
                            <div *ngIf="fPinfo.Address1.errors.required">Address1 is required</div>
                        </div>
                    </div>
                </div>


                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label>Address2</label>
                        <textarea type="text" id="username" class="form-control" placeholder="Address2"
                            formControlName="Address2"
                            [ngClass]="{ 'is-invalid': submitted && fPinfo.Address2.errors }"></textarea>
                    </div>
                </div>

                <!-- <div class="col-lg-6 col-md-12">
                    <div class="form-group">
                        <label>Country:</label>
                        <select class="form-control form-select" formControlName="CountryId" disabled
                            [ngClass]="{'is-invalid':submitted && fPinfo.CountryId.errors}"
                            (change)="onchangeCountry($event.target.value)">
                            <option value="" disabled selected>Please select</option>
                            <option *ngFor="let countries of allCountries"
                                [value]="isCountry == true ? countries.Id :  countries.Id ">
                                {{countries.CountryName}}
                            </option>
                        </select>
                        <div class="invalid-feedback" *ngIf="submitted && fPinfo.CountryId.errors">
                            <div *ngIf="fPinfo.CountryId.errors.required">
                                Country is required
                            </div>
                        </div>
                    </div>
                </div>


                <div class="col-lg-6 col-md-12">
                    <div class="form-group">
                        <label>State</label>
                        <select class="form-control form-select" formControlName="StateId" disabled
                            [ngClass]="{'is-invalid':submitted && fPinfo.StateId.errors}"
                            (change)="onchangeState($event.target.value)">
                            <option value="" disabled selected>Please select</option>
                            <option *ngFor="let states of allStates" [value]="isState == true ? states.Id : states.Id">
                                {{states.StateName}}
                            </option>
                        </select>
                        <div class="invalid-feedback" *ngIf="submitted && fPinfo.StateId.errors">
                            <div *ngIf="fPinfo.StateId.errors.required">
                                State is required
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="form-group">
                        <label>City</label>
                        <select class="form-control form-select" formControlName="CityIdOfEmployeeInfo" disabled
                            [ngClass]="{'is-invalid': submitted && fPinfo.CityIdOfEmployeeInfo.errors}">
                            <option value="" disabled selected>Please select</option>
                            <option *ngFor="let cities of allCities" [value]="isCity == true ? cities.Id :cities.Id ">
                                {{cities.CityName}}
                            </option>
                        </select>
                        <div class="invalid-feedback" *ngIf="
                    submitted && fPinfo.CityIdOfEmployeeInfo.errors">
                            <div *ngIf="fPinfo.CityIdOfEmployeeInfo.errors.required">
                                City is required
                            </div>
                        </div>
                    </div>
                </div> -->

                <div class="col-lg-6 col-md-12">
                    <div class="form-group">
                        <label>Pincode <label class="star">*</label></label>
                        <input type="text" id="username" class="form-control" placeholder="PinCode"
                            formControlName="PinCode" [ngClass]="{ 'is-invalid': submitted && fPinfo.PinCode.errors }">
                        <div *ngIf="submitted && fPinfo.PinCode.errors" class="invalid-feedback">
                            <div *ngIf="fPinfo.PinCode.errors.required">PinCode is required</div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12" *ngIf="this.displayInternShipField">
                    <div class="form-group">
                        <label>Internship Start Date</label>
                        <input type="date" class="form-control" placeholder="01/01/1995"
                            formControlName="InternShipStartDate"
                            [ngClass]="{ 'is-invalid': submitted && fPinfo.InternShipStartDate.errors }">
                        <div *ngIf="submitted && fPinfo.InternShipStartDate.errors" class="invalid-feedback">
                            <div *ngIf="fPinfo.InternShipStartDate.errors.required">Date of birtd is required</div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12" *ngIf="this.displayInternShipField">
                    <div class="form-group">
                        <label>Internship End Date</label>
                        <input type="date" class="form-control" placeholder="01/01/1995"
                            formControlName="InternShipEndDate"
                            [ngClass]="{ 'is-invalid': submitted && fPinfo.InternShipEndDate.errors }">
                        <div *ngIf="submitted && fPinfo.InternShipEndDate.errors" class="invalid-feedback">
                            <div *ngIf="fPinfo.InternShipEndDate.errors.required">Date of birtd is required</div>
                        </div>
                    </div>
                </div>

                <!-- <div class="col-lg-4 col-md-12">
                    <div class="form-group">
                        <label>Profile Picture</label>
                        <input (change)="Uploadcompanylogo($event)" type="file" class="input">
                    </div>
                </div> -->
            </div>
        </div>

        <!-- <button (click)="personalInfoForm()" class="btn dashboard-btn">Update Your
            Information</button> -->

            <div class="button">
                <button (click)="close()" class=" btn cancle " >
                    Cancel
                </button>
    
            <button (click)="personalInfoForm()" class="btn savebtn">
                Save
            </button>
    
            
            </div>
    </form>
</div>