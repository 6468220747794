



import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { GlobalService } from 'src/app/global.service';
import { City, Country, JobRole, Qualification, Qualificationa } from 'src/app/interface';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {
  @ViewChild('myInput') myInputVariable: any;

  allJobRole: JobRole[];
  filterJobRole?: Observable<JobRole[]>;
  formControlObj = new FormControl();
  JobRoleId: number;


  allQualification: Qualification[];
  filterQualification?: Observable<Qualification[]>;
  formControlQualificationine = new FormControl();
  QualificationId: number;

  
  allCities: any[];
  filterCities?: Observable<any[]>;
  formCities = new FormControl();
  CityId: number;

  jobType: number = 0;
  city: number = 0;
  Education: number = 0;
  submitted: boolean = false;
  jobPostsInfo = [];
  obs: any;
  dataFilter1: string[] = [];
  data: any;
  citycontrol = new FormControl();
  EducationControl = new FormControl();
  jobRoleControl = new FormControl();
  info: any;

  constructor(
    private Service: SharedService<any>,
    public router: Router, 
    public Global: GlobalService,
    private service: SharedService<any>,
    private toastr: ToastrService,
    ) {
  }

  ngOnInit(): void {
    // this.Service.Get('JobRole/GetJobRole?Id=0', null).subscribe(jobrole => {
    //   this.allJobRole = jobrole;
    // });

    this.service.Get('JobRole/GetJobRole?Id=0', null).subscribe(data => {
      if (data != null) {
        this.allJobRole = data;
        this.filterJobRole = this.formControlObj.valueChanges.pipe(
          startWith<string | JobRole>(''),
          map(value => typeof value === 'string' ? value : value.JobRole),
          map(JobRole => JobRole ? this._filterRole(JobRole) : this.allJobRole.slice()),
        );
      }
    });
    
    this.service.Get('City/GetCity?Id=0', null).subscribe(data => {
      if (data != null) {
        this.allCities = data;
        this.filterCities = this.formCities.valueChanges.pipe(
          startWith<string | any>(''),
          map(value => typeof value === 'string' ? value : value.CityName),
          map(CityName => CityName ? this._filterCity(CityName) : this.allCities.slice()),
        );
      }
    })

    this.service.Get('Qualification/GetQualification?Id=0', null).subscribe(data => {
      if (data != null) {
        this.allQualification = data;
        this.filterQualification = this.formControlQualificationine.valueChanges.pipe(
          startWith<string | Qualification>(''),
          map(value => typeof value === 'string' ? value : value.Qualification),
          map(Qualification => Qualification ? this._filterQualification(Qualification) : this.allQualification.slice()),
        );
      }
    });

    // this.Service.Get('City/GetCity?Id=0', null).subscribe(city => {
    //   this.allcities = city;
    // });
    // this.Service.Get('Qualification/GetQualification?Id=0', null).subscribe(Qualificationa => {
    //   this.allQualification = Qualificationa;
    // })

    this.refreshList();
  }

  private _filterRole(JobRole: string): JobRole[] {
    const filterValue = JobRole.toLowerCase();
    return this.allJobRole.filter(
      option => option.JobRole?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnForRole(value: any) {
    return value ? value.JobRole : undefined;
  }

  private _filterCity(CityName: string): any[] {
    const filterValue = CityName.toLowerCase();
    return this.allCities.filter(
      option => option.CityName?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnForCity(value: any) {
    return value ? value.CityName : undefined;
  }

  private _filterQualification(Qualification: string): Qualification[] {
    const filterValue = Qualification.toLowerCase();
    return this.allQualification.filter(
      option => option.Qualification?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnForQualification(value: any) {
    return value ? value.Qualification : undefined;
  }


  imageObject = [{
    image: '"../../../../assets/images/_226432-1.png',
    thumbImage: '"../../../../assets/images/_226432-1.png',
    
}, {
  image: '"../../../../assets/images/abb-1-1.png',
  thumbImage: '"../../../../assets/images/abb-1-1.png',
}, {
  image: '"../../../../assets/images/blackveatch.png',
  thumbImage: '"../../../../assets/images/blackveatch.png',
    
},{
  image: '"../../../../assets/images/emerson-1-1.png',
  thumbImage: '"../../../../assets/images/emerson-1-1.png',
   
}, {
  image: '"../../../../assets/images/hmel-1.png',
  thumbImage: '"../../../../assets/images/hmel-1.png',
}, {
  image: '"../../../../assets/images/reliance-industries-limited-logo-1.png',
  thumbImage: '"../../../../assets/images/reliance-industries-limited-logo-1.png',
   
}, {
  image: '"../../../../assets/images/images.png',
  thumbImage: '"../../../../assets/images/images.png',
},
{
  image: '"../../../../assets/images/whats-app-image-2023-10-19-at-11-252.png',
  thumbImage: '"../../../../assets/images/whats-app-image-2023-10-19-at-11-252.png',
},
{
  image: '"../../../../assets/images/whats-app-image-2023-10-19-at-11-253.png',
  thumbImage: '"../../../../assets/images/whats-app-image-2023-10-19-at-11-253.png',
},
{
  image: '"../../../../assets/images/whats-app-image-2023-10-19-at-11-254.png',
  thumbImage: '"../../../../assets/images/whats-app-image-2023-10-19-at-11-254.png',
}, {
  image: '"../../../../assets/images/whats-app-image-2023-10-19-at-11-255.png',
  thumbImage: '"../../../../assets/images/whats-app-image-2023-10-19-at-11-255.png',
},
{
  image: '"../../../../assets/images/whats-app-image-2023-10-19-at-11-256.png',
  thumbImage: '"../../../../assets/images/whats-app-image-2023-10-19-at-11-256.png',
},
 {
  image: '"../../../../assets/images/whats-app-image-2023-10-19-at-11-257.png',
  thumbImage: '"../../../../assets/images/whats-app-image-2023-10-19-at-11-257.png',
}
];

  search() {
    const queryParams: any = {};
    const data: string[] = [];
    data.push('searchString')
    data.push(this.jobType.toString());
    data.push(this.city.toString());
    data.push(this.Education.toString());
    data.push('0');
    data.push('0');
    data.push('0');
    data.push('0');
    data.push('0');
    data.push('0');

    queryParams.myArray = data;
    const navigationExtras: NavigationExtras = {
      queryParams
    };
    this.router.navigate(['searchjobs'], navigationExtras);
  }

  onchangejobrole(event: any) {
    this.submitted = true;
    this.jobType = event;
  }
  onchangeEdu(edu: any) {
    this.Education = edu;
  }
  onchangeCity(event: any) {
    this.city = event;
    this.Global.setCity(event);
  }
  WorkFromHome(jobtype: number) {
    this.Global.setjobTypeId(jobtype);
    this.router.navigate(['workfromhomejobs']);
  }

  step1() {
    this.router.navigate(['login']);
  }
  regi() {
    this.router.navigate(['candidate/register']);
  }

  refreshList() {
    this.service.Get('Dashboard/GetDashboardData', null).subscribe(data => {
      this.info = data;
    })
  }


  
  uplodPfd(files: FileList) {
    var fileToUpload: File | null = null;
    fileToUpload = files.item(0);
    const formData: FormData = new FormData();
     formData.append('file', fileToUpload, fileToUpload.name);
    //formData.append('candidateId', this.Global.getCandidateId().toString());

    if (fileToUpload.size <= 4000000) {
        this.Service.resumeuploadwithoutlogin(formData).subscribe((res) => {
            this.toastr.success(res.toString());
           
            this.myInputVariable.nativeElement.value = "";
        },
            (error) => {
                this.toastr.error(error.error);
                this.myInputVariable.nativeElement.value = "";
            });
    }
    else {
        this.toastr.error("File size is large");
        this.myInputVariable.nativeElement.value = "";
    }
}
}
