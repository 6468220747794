<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Find Jobs</h2>
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Find Jobs</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Jobs -->
<div class="job-area-list ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <ul class="job-list-item align-items-center">
                    <li>
                        <a href="#">Results Found</a>
                        <!-- <a class="rss" routerLink="/"><i class='bx bx-rss'></i>RSS Feed</a> -->
                    </li>
                </ul>

                <!-- <div *ngIf="jobPostsInfo.length > 0"> -->
                <div class="employer-item" *ngFor="let jobpost of obs" (click)="JobClick(jobpost)">

                    <img [src]="jobpost.Logo" alt="Dashboard" style="height: 30%;margin-top: 57px;
                            margin-left: -12px;" *ngIf="jobpost.Logo">
                               <h3>{{jobpost.CompanyName}}</h3>

                    <h3>{{jobpost.JobRole}}</h3>
                    <ul>
                        <li><i class="flaticon-send"></i> {{jobpost.CompanyIndustry}}</li>
                        <li>{{jobpost.JobOpening}} Opening</li>
                    </ul>
                    <p>{{jobpost.JobDescription}}</p>

                    <div style="display:flex;flex-flow:initial;column-gap:23px;">

                        <span *ngIf="jobpost.NoticePerioddays" class="span-one five">
                            {{jobpost.NoticePerioddays}}
                        </span>
                        <span class="span-one five">
                            {{jobpost.JobLocationCity}}
                        </span>
                        <span class="span-one five">{{jobpost.MinYearExp}} Experinece</span>

                        <span class="span-one five">{{jobpost.Qualification}}</span>

                        <div *ngIf="jobpost.Salarytype == 1">
                            <span class="span-two">Salary Range:
                                {{jobpost.MinSalary}}Rs.-{{jobpost.MaxSalary}}Rs</span>
                        </div>

                        <div *ngIf="jobpost.Salarytype == 0">
                            <span class="span-two five">
                                {{jobpost.SalaryDesc}}</span>
                        </div>
                    </div>

                </div>
                <!-- <div *ngIf="jobPostsInfo.length <= 0">
                    <h3>No Job Posted Yet!</h3>
                </div> -->
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="job-list-right">
                    
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Jobs -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->