import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import userToken from 'src/app/components/config/userToken';
import { GlobalService } from 'src/app/global.service';
import { JobRole } from 'src/app/interface';
import { SharedService } from 'src/app/shared.service';
import { BuildResume } from '../../../dashboard/basic-info/basic-info.types';


@Component({
    selector: 'app-addjobrole',
    templateUrl: './addjobrole.component.html',
    styleUrls: ['./addjobrole.component.scss']
})
export class AddJobRoleComponent implements OnInit {

    allJobRole: JobRole[];
    filterJobRole?: Observable<JobRole[]>;
    formControlObj = new FormControl();
    JobRoleId: number;
    basicInfoForm: FormGroup;

    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private Service: SharedService<any>,
        private Global: GlobalService,
        private dialogRef: MatDialogRef<AddJobRoleComponent>,
    )
    {
    }

    ngOnInit() {
        this.basicInfoForm = this.formBuilder.group({
            Id: ['', null],
            JobRole: ['', [Validators.required]],
        });

        this.Service.Get('JobRole/GetJobRole?Id=0', null).subscribe(data => {
            if (data != null) {
                this.allJobRole = data;
                this.formControlObj.setValue(this.allJobRole[0]);
                this.filterJobRole = this.formControlObj.valueChanges.pipe(
                    startWith<string | JobRole>(''),
                    map(value => typeof value === 'string' ? value : value.JobRole),
                    map(JobRole => JobRole ? this._filterRole(JobRole) : this.allJobRole.slice()),
                );}
        });
    }

    onchangejobrole(event)
    {
        this.JobRoleId = event
    }

    private _filterRole(JobRole: string): JobRole[] {
        const filterValue = JobRole.toLowerCase();
        return this.allJobRole.filter(
            option => option.JobRole?.toLowerCase().indexOf(filterValue) === 0
        );
    }

    displayFnForRole(value: any) 
    {
        return value ? value.JobRole : undefined;
    }

    baiscInfoForm() {
        this.basicInfoForm.controls["JobRole"].setValue(this.JobRoleId);
        this.basicInfoForm.controls["Id"].setValue(this.Global.candidateId);
        if (this.basicInfoForm.invalid) 
        {
            this.toastr.error("Please fill out the required fields (* fields)");
        }
        else 
        {
            this.Service.Put('CandidateInfo/UpdateCandidateJobRole?jobroleid=' +this.JobRoleId + '&id=' + this.Global.candidateId, null).subscribe(
                (res) => {
                    this.toastr.success(res);
                    this.dialogRef.close('Save');
                },
                (error) => {
                    this.toastr.error(error.error);
        });
    }
}

    close() 
    {
        this.dialogRef.close();
    }

}