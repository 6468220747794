<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>My Resume</h2>
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image" />
                            <!-- <a routerLink="/">Home</a> -->
                        </li>
                        <!-- <li><span>/</span></li> -->
                        <li>My Resume</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Resume -->
<div class="job-filter-area pt-100">
    <div class="container">
        <form>
            <div class="row">
                <div class="col-sm-4 col-lg-3" *ngIf="Global.isOwner || Global.isUser">
                    <div class="form-group">
                        <select class="form-control form-select" (change)="onchangeCompany($event.target.value)">
                            <option value="" disabled selected>Choose any one Company</option>
                            <option *ngFor="let comp of (allCompanyName | async)" [value]="comp.Id">
                                {{comp.CompanyName}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-sm-4 col-lg-3" *ngIf="Global.isOwner || Global.isUser || Global.isCompany">
                    <div class="form-group">
                        <select class="form-control form-select" (change)="onchangeJobRole($event.target.value)">
                            <option value="" disabled selected>Choose any one JobRole</option>
                            <option *ngFor="let JobRole of (allJobRole | async)" [value]="JobRole.PostedJobId">
                                {{JobRole.JobRole}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-3" *ngIf="Global.isOwner || Global.isUser || Global.isCompany">
                    <button type="submit" class="cmn-btn" (click)="shortListCandidate()">ShortList Candidate <i
                            class='bx bx-plus'></i></button>
                </div>
            </div>
        </form>
    </div>
</div>

<mat-tab-group (selectedTabChange)="getshortlistedcandidates()">
    <mat-tab label="Summary">
        <div class="person-details-area resume-area ptb-100">
            <div class="container">
                <div class="row">
                    <div id="noScreen" class=" col-lg-4 col-md-12">
                        <div class="widget-area" *ngIf="getresumeData">
                            <div class="resume-profile">
                                <!-- <img [src]="getresumeData.Photo" alt="Dashboard" /> -->
                                <h2>{{getresumeData.FirstName}}</h2>
                                <span>{{getresumeData.JobRole}}</span>
                            </div>
                            <div class="information widget-item">
                                <h3>Overview</h3>
                                <ul>

                                    <li *ngIf="Global.isOwner || Global.isUser">
                                        <img src="assets/png/pinklogo.png" alt="Details" />
                                        <h4>Contact:</h4>
                                        <span>{{getresumeData.MobileNo}}</span>
                                    </li>
                                    <li>
                                        <img src="assets/png/pinklogo.png" alt="Details" />
                                        <h4>Gender:</h4>
                                        <span>{{ getresumeData.GenderDesc }}</span>
                                    </li>
                                    <li>
                                        <img src="assets/png/pinklogo.png" alt="Details" />
                                        <h4>Date Of Birth:</h4>
                                        <span>{{getresumeData.DateOfBirth | date:'dd-MM-yyyy'}}</span>
                                    </li>
                                    <li>
                                        <img src="assets/png/pinklogo.png" alt="Details" />
                                        <h4>Marital Status:</h4>
                                    </li>
                                    <li>
                                        <img src="assets/png/pinklogo.png" alt="Details" />
                                        <h4>Nationality:</h4>
                                        <span>{{ getresumeData.Nationality }}</span>
                                    </li>
                                    <li>
                                        <img src="assets/png/pinklogo.png" alt="Details" />
                                        <h4>Differently Abled:</h4>
                                        <span>{{getresumeData.DifferentlyAbled ? 'Yes' : 'No'}}</span>
                                    </li>
                                    <li>
                                        <img src="assets/png/pinklogo.png" alt="Details" />
                                        <h4>Location:</h4>
                                        <span>{{getresumeData.Address1}} ,{{getresumeData.Address2}}
                                            {{getresumeData.CityNameOfEmployeeInfo}},
                                            {{getresumeData.StateName}}, {{getresumeData.CountryName}} -
                                            {{getresumeData.PinCode}}</span>
                                    </li>
                                    <li>
                                        <img src="assets/png/pinklogo.png" alt="Details" />
                                        <h4>Country:</h4>
                                        <span>{{ getresumeData.CountryName }}</span>
                                    </li>
                                    <li>
                                        <img src="assets/png/pinklogo.png" alt="Details" />
                                        <h4>State:</h4>
                                        <span>{{ getresumeData.StateName }}</span>
                                    </li>
                                    <li>
                                        <img src="assets/png/pinklogo.png" alt="Details" />
                                        <h4>City:</h4>
                                        <span>{{ getresumeData.CityNameOfEmployeeInfo }}</span>
                                    </li>
                                    <li>
                                        <img src="assets/png/pinklogo.png" alt="Details" />
                                        <h4>Expected Salary:</h4>
                                        <span>{{ getresumeData.ExpectedSalary }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-8 col-md-12">
                        <div class="details-item">
                            <h3>Experience </h3>
                            <div class="work bottom-item" *ngFor="let getExperienceData of datasourceExp ">
                                <div class="row">
                                    <div class="col-11" *ngIf="getExperienceData">
                                        <ul>
                                            <li>
                                                {{ getExperienceData.JobRole }}
                                            </li>
                                        </ul>

                                        <li>
                                            <strong>Company Name:</strong>
                                            {{ getExperienceData.CompanyName }}
                                        </li>

                                        <li>
                                            <strong>Employeement Type:</strong>
                                            {{ getExperienceData.EmployeementTypeName }}
                                        </li>

                                        <li>
                                            <strong>WorkLocation:</strong>
                                            {{ getExperienceData.WorkLocation }}
                                        </li>

                                        <li>
                                            <strong>Current Job:</strong>
                                            {{getExperienceData.IsCurrentJob ? 'Yes' : 'No'}}
                                        </li>

                                        <li>
                                            <strong>Start Date :</strong>
                                            {{getExperienceData.FromDate | date:'dd-MM-yyyy' }}
                                        </li>

                                        <li>
                                            <strong>End Date :</strong>
                                            {{getExperienceData.ToDate | date:'dd-MM-yyyy' }}
                                        </li>

                                        <li>
                                            <strong>Shift Job :</strong>
                                            {{getExperienceData.IsShiftJob ? 'Yes' : 'No'}}
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="details-item">
                            <h3>Education </h3>
                            <div class="work bottom-item" *ngFor="let getEducationData of datasourceEdu ">
                                <div class="row">
                                    <div class="col-11" *ngIf="getEducationData">
                                        <ul>
                                            <li>
                                                {{ getEducationData.Qualification }}
                                            </li>
                                        </ul>

                                        <li>
                                            <strong>School Or University:</strong>
                                            {{ getEducationData.SchoolOrUniversity }}
                                        </li>

                                        <li>
                                            <strong>Specialization:</strong>
                                            {{ getEducationData.SpecializationDescription }}
                                        </li>

                                        <li>
                                            <strong>Grade Or Percentage:</strong>
                                            {{ getEducationData.GradeOrPercentage }}
                                        </li>

                                        <li>
                                            <strong>Education Type:</strong>
                                            {{ getEducationData.EducationTypeName }}
                                        </li>

                                        <li>
                                            <strong>Passout Date:</strong>
                                            {{getEducationData.FromDateOfEducation | date:'dd-MM-yyyy'}}
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="details-item">
                            <h3>Achievements</h3>
                            <div class="work bottom-item" *ngFor="let getAchievementsData of datasourceAchiv ">
                                <div class="row">
                                    <div class="col-11" *ngIf="getAchievementsData">
                                        <li>
                                            <strong>Description:</strong>
                                            {{ getAchievementsData.DescriptionOfAchievments}}
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="details-item">
                            <h3>Certification</h3>
                            <div class="work bottom-item" *ngFor="let getCertificateData of datasourceCerty">
                                <div class="row">
                                    <div class="col-11" *ngIf="getCertificateData">
                                        <ul>
                                            <li>
                                                {{ getCertificateData.CertificationName }}
                                            </li>
                                        </ul>

                                        <li>
                                            <strong>Description:</strong>
                                            {{ getCertificateData.Description }}
                                        </li>

                                        <li>
                                            <strong>Date Of Issue:</strong>
                                            {{getCertificateData.DateOfIssue | date:'dd-MM-yyyy'}}
                                        </li>

                                        <li>
                                            <strong> Date Of Validity:</strong>
                                            {{getCertificateData.DateOfValidity | date:'dd-MM-yyyy'}}
                                        </li>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="details-item">
                            <h3>Skills</h3>
                            <div class="work bottom-item" *ngFor="let getSkillData of datasourceSkill ">
                                <div class="row">
                                    <div class="col-11" *ngIf="getSkillData">
                                        <li>
                                            {{getSkillData.Skills}}
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="details-item">
                            <h3>Languages</h3>
                            <div class="work bottom-item" *ngFor="let getLanguagesData of datasourceLanguages  ">
                                <div class="row">
                                    <div class="col-11" *ngIf="getLanguagesData">
                                        <li>
                                            {{getLanguagesData.Language}}
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="details-item">
                            <h3>Preffered Location</h3>
                            <div class="work bottom-item" *ngFor="let getLocationsData of datasourceLocation ">
                                <div class="row">
                                    <div class="col-11" *ngIf="getLocationsData">
                                        <li>
                                            {{getLocationsData.CityName}}
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>

    <!-- <mat-tab label="Jobs" *ngIf="Global.isOwner  || Global.isUser ">
        <div class="card mat-elevation-z6 m-3 w-auto">
            <table mat-table [dataSource]="dataSource" matSort class="table-responsive">

                <ng-container matColumnDef="JobRole">
                    <th mat-header-cell *matHeaderCellDef class="M1"> Job Role</th>
                    <td mat-cell *matCellDef="let element" (click)="resume(element)"> {{element.JobRole}} </td>
                </ng-container>

                <ng-container matColumnDef="FirstName">
                    <th mat-header-cell *matHeaderCellDef class="M1"> Candidate Status </th>
                    <td mat-cell *matCellDef="let element" (click)="resume(element)"> {{element.CandidateStatusName}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="LastName">
                    <th mat-header-cell *matHeaderCellDef class="M1"> Company Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.JobCompanyName}} </td>
                </ng-container>

                <ng-container matColumnDef="MobileNo">
                    <th mat-header-cell *matHeaderCellDef class="M1"> Drop Reason </th>
                    <td mat-cell *matCellDef="let element"> </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <mat-paginator color="primary" [pageSize]="10" [pageSizeOptions]="[5, 10, 15, 25, 100]"
                aria-label="Select page" class="paginator">
            </mat-paginator>
        </div>
    </mat-tab> -->

    <mat-tab label="Jobs" *ngIf="Global.isCompany">
        <div>
            <table mat-table [dataSource]="shortlistdataSource" style="width: 99%;margin-left: 10px;">
                <ng-container matColumnDef="JobRole">
                    <th mat-header-cell *matHeaderCellDef class="M1"> Job Role</th>
                    <td mat-cell *matCellDef="let element" (click)="resume(element)"> {{element.JobRole}} </td>
                </ng-container>
                <ng-container matColumnDef="FirstName">
                    <th mat-header-cell *matHeaderCellDef class="M1"> Candidate Status </th>
                    <td mat-cell *matCellDef="let element" (click)="resume(element)"> {{element.CandidateStatusName}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="LastName">
                    <th mat-header-cell *matHeaderCellDef class="M1"> Company Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.JobCompanyName}} </td>
                </ng-container>
                <!-- <ng-container matColumnDef="MobileNo">
                    <th mat-header-cell *matHeaderCellDef class="M1"> Drop Reason </th>
                    <td mat-cell *matCellDef="let element"> </td>
                </ng-container> -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" style="font-size: 17px; color: #26448c;" showFirstLastButtons
                aria-label="Select page of periodic elements">
            </mat-paginator>
        </div>
    </mat-tab>
</mat-tab-group>