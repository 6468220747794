import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {  ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';

import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared.service';
import { GlobalService } from 'src/app/global.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-add-edit-function',
    templateUrl: './add-edit-function.component.html',
    styleUrls: ['./add-edit-function.component.scss']
})
export class AddEditCandidateFunctionComponent implements OnInit, OnDestroy {

    submitted: boolean = false;
    FunctionInfoForm: FormGroup;
    public info: any;
    summary: string;
    response: any;
    openform = false;
    boolVar = true;
    fileType: string = '';
    allfunction: Observable<any[]>;
    hideForm: boolean = false;
    hideTable: boolean = true;
    saveEduBtn: boolean = true;
    updateEduBtn: boolean = false;
    public function = [];
    submittedEdu: boolean = false;

    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private Service: SharedService<any>,
        private Global: GlobalService,
        private dialogRef: MatDialogRef<AddEditCandidateFunctionComponent>,
        @Inject(MAT_DIALOG_DATA) public editData: any
    ) { }
    
    ngOnInit(): void {
        this.FunctionInfoForm = this.formBuilder.group({
            Id: ['', null],
            Function1: ['', null],
            Function2: ['', null],
        });
        this.getUser();

        this.Service.Get('CandidateInfo/GetFunctionData?Id=0', null).subscribe(data => {
            this.allfunction = data;
        })

        if (this.editData) {
            this.editEdu(this.editData)
            this.updateEduBtn = true
            this.saveEduBtn = false
        }
        else {
            this.updateEduBtn = false
            this.saveEduBtn = true
        }

    }

    get fPinfo(): { [key: string]: AbstractControl } {
        return this.FunctionInfoForm.controls;
    }

    personalInfoForm() {
        this.submitted = true;
        if (this.FunctionInfoForm.invalid) {
            this.toastr.error("Please fill out the required fields (* fields)");
        }
        else {
            const updatepersonalinfo = this.FunctionInfoForm.getRawValue();
            this.Service.Put('CandidateInfo/UpdateFunction', updatepersonalinfo).subscribe(
                (res) => {
                    this.toastr.success(res);
                    this.dialogRef.close('Update');  
                },
                (error) => {
                   
                    this.toastr.error(error.error);
                });
            this.submitted = false;
        }
    }

    close(){
        this.dialogRef.close(); 
    }
    
    getUser() {
        this.Service.Get('CandidateInfo/GetCandidateInfo?id=' + this.Global.getCandidateId(), null).subscribe(
            (res) => {
                this.function = res;
            },
            (error) => {
                //if (error.status == 401) this.router.navigate(['/login']);
                this.toastr.error(error.error);
            });
    }

    editInfo() {
        this.openform = !this.openform;
        this.boolVar = !this.boolVar;
        if(this.boolVar == true){
            this.hideForm = false;
            this.hideTable = true; 
        }
        else{
            this.hideForm = true;
            this.hideTable = false;
        }
    }

    editEdu(event: any) {
       
        this.Service.Get('CandidateInfo/GetCandidateInfo?id=' + event.Id, null).subscribe(
            (res) => {
                this.function = res;
                this.FunctionInfoForm.controls["Id"].setValue(this.function[0].Id);
                this.FunctionInfoForm.controls["Function1"].setValue(this.function[0].Function1);
                this.FunctionInfoForm.controls["Function2"].setValue(this.function[0].Function2);
                this.getUser();
            },

            (error) => {
                //if (error.status == 401) this.router.navigate(['/login']);
                this.toastr.error(error.error);
            });
            this.Service.Get('CandidateInfo/GetFunctionData?Id=0', null).subscribe(data => {
                this.allfunction = data;
            })
            
        this.submittedEdu = false;
    }

    ngOnDestroy(): void {

    }
}

