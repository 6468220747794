import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import { MatTableDataSource } from '@angular/material/table';
import { AppliedJob } from 'src/app/interface';
import { MatPaginator } from '@angular/material/paginator';
import { GlobalService } from 'src/app/global.service';

@Component({
    selector: 'app-candidateappliedjob',
    templateUrl: './candidateappliedjob.component.html',
    styleUrls: ['./candidateappliedjob.component.scss']
})
export class CandidateAppliedJobComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['AppliedDate','JobNumber',  'ProfileName', 'JobRole', 'JobCompanyName',];
  dataSource: MatTableDataSource<AppliedJob>;
  AppliedJobCount : number;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

    constructor(
        private toastr: ToastrService,
        private router: Router,
        private service: SharedService<any>,
        private global:GlobalService
    ) { }

    ngOnInit(): void {
        this.getApplyjob()
    }

    getApplyjob() {
        this.service.Get('AppliedJob/GetCandidateAppliedJob?userid=' + this.global.getuserId(), null).subscribe(data => {
            this.dataSource = new MatTableDataSource(data);
            this.dataSource.paginator = this.paginator;
            this.getAppliedJobCount();
            },
            (error) => {
                //if (error.status == 401) this.router.navigate(['/login']);
                this.toastr.error(error.error);
            });
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
      }

      getAppliedJobCount() {
        this.service.Get('AppliedJob/GetCandidateAppliedJobCount?userid=' + this.global.getuserId(), null).subscribe(
            (res) => {
                this.AppliedJobCount = res;
            },        
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }
      
    ngOnDestroy(): void {

    }
   
}
