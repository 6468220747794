import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from "@angular/router";
import { SharedService } from 'src/app/shared.service';
import { GlobalService } from 'src/app/global.service';
import { BuildResume } from '../../dashboard/basic-info/basic-info.types';
import { MatTableDataSource } from '@angular/material/table';
import { EmployeeMaster, JobRole, UserType } from 'src/app/interface';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';


@Component({
    selector: 'app-viewresume',
    templateUrl: './viewresume.component.html',
    styleUrls: ['./viewresume.component.scss']
})
export class ViewResumeComponent implements OnInit, OnDestroy {

    candidateInfo = [];
    userId: number;
    name: string;
    designation: string;
    getresumeData: BuildResume;
    datasourceExp: any;
    displayedColumns: string[] = ['JobRole', 'FirstName', 'LastName'];
    allJobRole: Observable<JobRole[]>;
    allCompanyName: Observable<any>;
    companyId: number;
    JobRole: number;
    datasource: any;
    shortlistdataSource: any;
    EmpId: number = 0;
    Id: number;
    datasourceEdu: any;
    datasourceAchiv: any;
    datasourceCerty: any;
    datasourceSkill: any;
    datasourceLocation: any;
    datasourceLanguages: any;
    dataSource: MatTableDataSource<EmployeeMaster>;
    dataFilter: number[] = [];
    public placeholder: string = '';

    constructor(
        private toastr: ToastrService,
        private Service: SharedService<any>,
        public Global: GlobalService,
        private route: ActivatedRoute,
    ) { }
    @ViewChild(MatPaginator) paginator!: MatPaginator;

    ngOnInit(): void {

        // this.userId = this.Id
        this.route.paramMap.subscribe(
            (params) => {
                console.log("params.get('id')", params.get('id'));

                this.Id = Number(params.get('id'));
            }
        );
        this.userId = this.Id
        this.getUser();
        this.allCompanyName = this.Service.Get('Company/GetCompanyDataSelection', null)
        if (this.Global.UserType = UserType.Company) {
            this.allJobRole = this.Service.Get('ShortListCandidate/GetJobRoleWithPostedJobIdForShortlistedCandidate?companyId=' + this.Global.GetCompanyId(), null);
        }
    }

    getAllEducations(empId: number) {
        this.Service.Get('CandidateEducationInfo/GetCandidateEducationInfoByEmpId?EmpId=' + empId, null).subscribe(
            (res) => {
                this.datasourceEdu = res;
            },
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }

    //Experience
    getAllExperience(empId: number) {
        this.Service.Get('CandidateExperienceInfo/GetCandidateExperienceInfoByEmpId?EmpId=0' + empId, null).subscribe(
            (res) => {
                this.datasourceExp = res;
            },
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }

    //Basic Info
    getUser() {
        this.Service.Get('CandidateInfo/GetCandidateInfoByUserId?userId=' + this.Id, null).subscribe(
            (res) => {
                this.getresumeData = res;
                this.Global.setCandidateId(this.getresumeData.Id);
                this.getAllEducations(this.getresumeData.Id);
                this.getAllExperience(this.getresumeData.Id);
                this.getAllAchievements(this.getresumeData.Id);
                this.getAllCertification(this.getresumeData.Id);
                this.getAllSkills(this.getresumeData.Id);
                this.getAllCity(this.getresumeData.Id);
                this.getAllLanguages(this.getresumeData.Id);
                this.refreshListofCandidateStatus();
            },
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }

    getshortlistedcandidates() {
        if (this.Global.UserType = UserType.Company) {
            this.allJobRole = this.Service.Get('ShortListCandidate/GetJobRoleWithPostedJobIdForShortlistedCandidate?companyId=' + this.Global.GetCompanyId(), null);
        }
        this.Service.Get('CandidateInfo/GetCandidateInfoByCandidateStatusForCompany?EmpId=' + this.Global.getCandidateId() + '&UserId=' + this.Global.getuserId(), null).subscribe(data => {
            this.shortlistdataSource = new MatTableDataSource(data);
            this.shortlistdataSource.paginator = this.paginator;
        })
    }
    refreshListofCandidateStatus() {
        this.Service.Get('CandidateInfo/GetCandidateInfoByCandidateStatus?EmpId=' + this.Global.getCandidateId(), null).subscribe(data => {
            this.dataSource = data;
        })
    }

    //Achievements
    getAllAchievements(empId: number) {
        this.Service.Get('CandidateAchievements/GetCandidateAchievementsByEmpId?EmpId=0' + empId, null).subscribe(
            (res) => {
                this.datasourceAchiv = res;
            },
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }

    // Certification
    getAllCertification(empId: number) {
        this.Service.Get('CandidateCertificatios/GetCandidateCertificatiosByEmpId?EmpId=0' + empId, null).subscribe(
            (res) => {
                this.datasourceCerty = res;
            },
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }
    
    //Skills
    getAllSkills(empId: number) {
        this.Service.Get('CandidateSkills/GetCandidateSkills?EmpId=' + empId, null).subscribe(
            (res) => {
                this.datasourceSkill = res;
            },
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }

    //languages
    getAllLanguages(empId: number) {
        this.Service.Get('CandidateLanguages/GetCandidateLanguages?EmpId=' + empId, null).subscribe(
            (res) => {
                this.datasourceLanguages = res;
            },
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }

    getAllCity(empId: number) {
        this.Service.Get('CandidtaePreferredLocations/GetCandidatePreferredLocations?EmpId=' + empId, null).subscribe(
            (res) => {
                this.datasourceLocation = res;
            },
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    onchangeCompany(event) {
        this.companyId = event;
        this.allJobRole = this.Service.Get('ShortListCandidate/GetJobRoleWithPostedJobIdForShortlistedCandidate?companyId=' + this.companyId, null);
    }

    shortListCandidate() {
        this.dataFilter = [];
        this.dataFilter.push(this.JobRole);
        this.dataFilter.push(this.Global.getCandidateId());
        this.dataFilter.push(this.Global.getuserId());
        if (this.Global.UserType = UserType.Company) {
            this.dataFilter.push(this.Global.GetCompanyId());
        }
        else {
            this.dataFilter.push(this.companyId);
        }
        this.datasource = null;
        this.Service.Post('ShortListCandidate/ShortListCandidateForComany', this.dataFilter).subscribe(
            (res) => {
                this.toastr.success(res);
            },
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }

    onchangeJobRole(event) {
        this.JobRole = event
    }

    ngOnDestroy(): void {

    }
}
