import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Router } from "@angular/router";
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared.service';
import { GlobalService } from 'src/app/global.service';
import { CollegeInformation } from 'src/app/interface';
import userToken from 'src/app/components/config/userToken';
import { ThrowStmt } from '@angular/compiler';

@Component({
    selector: 'app-companyhome',
    templateUrl: './companyhome.component.html',
    styleUrls: ['./companyhome.component.scss']
})

export class CompanyHomeComponent implements OnInit, OnDestroy{

    getcompanyData:CollegeInformation;
    profile:boolean = true;
    shortlist:boolean = false;
    changepass:boolean = false;
   // userId: number = userToken.id;
    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private router: Router,
        private Service: SharedService<any>,
        private Global: GlobalService
    ) { }

    ngOnInit(): void {
        this.getUser();
        
    }


    getUser() {
        this.Service.Get('Company/GetCompanyData?Id=' + this.Global.getuserId(), null).subscribe(
            (res) => {
                this.getcompanyData = res;
            },        
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }

profileclick(){
    this.profile = true;
    this.shortlist = false;
    this.changepass = false

}

shortlistclick(){
this.shortlist = true;
this.profile = false;
this.changepass = false;
}

changepassword(){
    this.changepass = true ;
    this.shortlist = false ;
    this.profile = false;
}
    ngOnDestroy(): void {
        
    }
}