<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>College</h2>
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image" />
                            <!-- <a routerLink="/post-a-job">Home</a> -->
                        </li>
                        <!-- <li><span>/</span></li> -->
                        <li>College</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!--  -->
<div class="dashboard-area ptb-100">
    <div class="container">
        <div class="profile-content">
            <form [formGroup]="clgForm">
                <div class="profile-content-inner">
                    <h2>College</h2>
                    <div class="row">

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Name</label>
                                <input type="text" id="CollegeName" class="form-control" placeholder="College Name"
                                    formControlName="CollegeName"
                                    [ngClass]="{ 'is-invalid': submitted && f.userCollegeNamename.errors }">
                                <div *ngIf="submitted && f.CollegeName.errors" class="invalid-feedback">
                                    <div *ngIf="f.CollegeName.errors.required">College Name is required</div>
                                </div>
                        </div>
                    </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Email</label>
                                <input type="email" id="CollegeEmail" class="form-control" placeholder="xyz@gmail.com"
                                    formControlName="CollegeEmail" [ngClass]="{ 'is-invalid': submitted && f.CollegeEmail.errors }">
                                <div *ngIf="submitted && f.CollegeEmail.errors" class="invalid-feedback">
                                    <div *ngIf="f.CollegeEmail.errors.required">Email is required</div>
                                    <div *ngIf="f.CollegeEmail.errors.email">Email is invalid</div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Website</label>
                                <input type="text" id="WebSite" class="form-control" placeholder="e.g. YourWebsite.com"
                                    formControlName="WebSite"
                                    [ngClass]="{ 'is-invalid': submitted && f.WebSite.errors }">
                                <div *ngIf="submitted && f.WebSite.errors" class="invalid-feedback">
                                    <div *ngIf="f.WebSite.errors.required">College Website is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Description</label>
                                <textarea type="text" id="CollegeDescription" class="form-control" placeholder="College Description"
                                    formControlName="CollegeDescription"
                                    [ngClass]="{ 'is-invalid': submitted && f.CollegeDescription.errors }"></textarea>
                                <div *ngIf="submitted && f.CollegeDescription.errors" class="invalid-feedback">
                                    <div *ngIf="f.CollegeDescription.errors.required">College Description is required</div>
                                </div>
                            </div>
                </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Address 1</label>
                                <textarea type="text" id="Address1" class="form-control" placeholder="Address1"
                                    formControlName="Address1"
                                    [ngClass]="{ 'is-invalid': submitted && f.Address1.errors }"></textarea>
                                <div *ngIf="submitted && f.Address1.errors" class="invalid-feedback">
                                    <div *ngIf="f.Address1.errors.required">Address1 is required</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Address2</label>
                                <textarea type="text" id="Address2" class="form-control" placeholder="Address2"
                                    formControlName="Address2"
                                    ></textarea>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Country</label>
                                <select class="form-control form-select" formControlName="CountryId"
                                    [ngClass]="{ 'is-invalid': submitted && f.CountryId.errors }" (change)="onchangeCountry($event.target.value)">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let Countries of (allCountries | async)" [value]="Countries.Id">
                                        {{Countries.CountryName}}
                                      </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="submitted && f.CountryId.errors">
                                    <div *ngIf="f.CountryId.errors.required">
                                        Country  is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            
                        <div class="form-group">
                            <label>State</label>
                            <select class="form-control form-select" formControlName="StateId"
                                [ngClass]="{ 'is-invalid': submitted && f.StateId.errors }" (change)="onchangeCity($event.target.value)">
                                <option value="" disabled selected>Choose any one</option>
                                <option *ngFor="let states of (allStates | async)" [value]="states.Id">
                                    {{states.StateName}}
                                  </option>
                                
                            </select>
                            <div class="invalid-feedback" *ngIf="submitted && f.StateId.errors">
                                <div *ngIf="f.StateId.errors.required">
                                   State  is required
                                </div>
                            </div>
                        </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            
                        <div class="form-group">
                            <label>City</label>
                            <select class="form-control form-select" formControlName="CityId"
                                [ngClass]="{ 'is-invalid': submitted && f.CityId.errors }">
                                <option value="" disabled selected>Choose any one</option>
                                <option *ngFor="let cities of (allCities | async)" [value]="cities.Id">
                                    {{cities.CityName}}
                                  </option>
                                
                            </select>
                            <div class="invalid-feedback" *ngIf="submitted&& f.CityId.errors">
                                <div *ngIf="f.CityId.errors.required">
                                    City is required
                                </div>
                            </div>
                        </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Pincode</label>
                                <input type="text" id="PinCode" class="form-control" placeholder="PinCode"
                                    formControlName="PinCode"
                                    [ngClass]="{ 'is-invalid': submitted && f.PinCode.errors }">
                                <div *ngIf="submitted && f.PinCode.errors" class="invalid-feedback">
                                    <div *ngIf="f.PinCode.errors.required">PinCode is required</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Logo</label>
                                <input (change)="Uploadcompanylogo($event)" type="file" class="input">
                                <!-- <button class="btn" mat-raised-button color="primary" (click)="uploadFile()">Upload</button> -->
                            </div>
                            </div>

                        <button (click)="ClgForm()" class="btn dashboard-btn">
                            Save Your Information
                        </button>

                    </div>
                </div>


            </form>
        </div>
    </div>
</div>

<!-- End Post A Job -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->