
<!-- Basic Info Form -->
<div class="tab-pane fade show active" id="v-pills-basicInfo" role="tabpanel" aria-labelledby="v-pills-basicInfo-tab"
    >
    <div class="profile-content">

        <form [formGroup]="companyUpdateForm" id="noScreen">
            <i matTooltip="Close" style="font-size: 35px;margin-left: 842px;margin-top: -15px;color: red;"  
        class='bx bx-x Clear' (click)="close()"></i>
            <div class="profile-content-inner">
                <h2>Profile </h2>

                <div class="row">
                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>Company Name</label>
                            <input type="text" class="form-control" placeholder="Tom Henry"
                                formControlName="CompanyName"
                                [ngClass]="{ 'is-invalid': submitted && f.CompanyName.errors }">
                            <div *ngIf="submitted && f.CompanyName.errors" class="invalid-feedback">
                                <div *ngIf="f.CompanyName.errors.required">College Name is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>Email</label>
                            <input type="text" class="form-control" placeholder="Tom Henry"
                                formControlName="CompanyEmail"
                                [ngClass]="{ 'is-invalid': submitted && f.CompanyEmail.errors }">
                            <div *ngIf="submitted && f.CompanyEmail.errors" class="invalid-feedback">
                                <div *ngIf="f.CompanyEmail.errors.required">College Email is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>IndustryType</label>
                            <select class="form-control form-select" formControlName="IndustryTypeId"
                                [ngClass]="{'is-invalid': submitted && f.IndustryTypeId.errors}">
                                <option value="" disabled selected>Please select</option>
                                <option *ngFor="let Industry of allIndustry"
                                    [value]="isIndustry == true ? Industry.Id :Industry.Id ">
                                    {{Industry.IndustryType}}
                                </option>
                            </select>
                            <div class="invalid-feedback" *ngIf="
                    submitted && f.IndustryTypeId.errors">
                                <div *ngIf="f.IndustryTypeId.errors.required">
                                    Industry Type is required
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Address1</label>
                            <textarea type="text" class="form-control" placeholder="Tom Henry"
                                formControlName="Address1"
                                [ngClass]="{ 'is-invalid': submitted && f.Address1.errors }"></textarea>
                            <div *ngIf="submitted && f.Address1.errors" class="invalid-feedback">
                                <div *ngIf="f.Address1.errors.required">Address 1 is required</div>
                            </div>

                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Address2</label>
                            <textarea type="text" class="form-control" placeholder="Tom Henry"
                                formControlName="Address2"></textarea>
                        </div>
                    </div>

                   
                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>Country:</label>
                            <select class="form-control form-select" formControlName="CountryId"
                                [ngClass]="{'is-invalid':submitted && f.CountryId.errors}"
                                (change)="onchangeCountry($event.target.value)">
                                <option value="" disabled selected>Please select</option>
                                <option *ngFor="let countries of allCountries"
                                    [value]="isCountry == true ? countries.Id :  countries.Id ">
                                    {{countries.CountryName}}
                                </option>
                            </select>
                            <div class="invalid-feedback" *ngIf="submitted && f.CountryId.errors">
                                <div *ngIf="f.CountryId.errors.required">
                                    Country is required
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>State</label>
                            <select class="form-control form-select" formControlName="StateId"
                                [ngClass]="{'is-invalid':submitted && f.StateId.errors}"
                                (change)="onchangeState($event.target.value)">
                                <option value="" disabled selected>Please select</option>
                                <option *ngFor="let states of allStates"
                                    [value]="isState == true ? states.Id : states.Id">
                                    {{states.StateName}}
                                </option>
                            </select>
                            <div class="invalid-feedback" *ngIf="submitted && f.StateId.errors">
                                <div *ngIf="f.StateId.errors.required">
                                    State is required
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>City</label>
                            <select class="form-control form-select" formControlName="CityId"
                                [ngClass]="{'is-invalid': submitted && f.CityId.errors}">
                                <option value="" disabled selected>Please select</option>
                                <option *ngFor="let cities of allCities"
                                    [value]="isCity == true ? cities.Id :cities.Id ">
                                    {{cities.CityName}}
                                </option>
                            </select>
                            <div class="invalid-feedback" *ngIf="
                    submitted && f.CityId.errors">
                                <div *ngIf="f.CityId.errors.required">
                                    City is required
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>PinCode</label>
                            <input type="text" id="username" class="form-control" placeholder="PinCode"
                                formControlName="PinCode" [ngClass]="{ 'is-invalid': submitted && f.PinCode.errors }">
                            <div *ngIf="submitted && f.PinCode.errors" class="invalid-feedback">
                                <div *ngIf="f.PinCode.errors.required">PinCode is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>LinkedInPage</label>
                            <input type="text" id="username" class="form-control" placeholder="LinkedInPage"
                                formControlName="LinkedInPage"
                                [ngClass]="{ 'is-invalid': submitted && f.LinkedInPage.errors }">

                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>WebSite</label>
                            <input type="text" id="username" class="form-control" placeholder="WebSite"
                                formControlName="WebSite" [ngClass]="{ 'is-invalid': submitted && f.WebSite.errors }">

                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <label>Description</label>
                            <textarea type="text" class="form-control" placeholder="Description"
                                formControlName="Description"
                                [ngClass]="{ 'is-invalid': submitted && f.Description.errors }"></textarea>
                            <div *ngIf="submitted && f.Description.errors" class="invalid-feedback">
                                <div *ngIf="f.Description.errors.required">Description is required</div>
                            </div>
                        </div>
                    </div>


                    <div class="form-group" style="text-align:center ;"> Contact Person </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>First Name</label>
                            <input type="text" id="username" class="form-control" placeholder="FirstName"
                                formControlName="FirstName"
                                [ngClass]="{ 'is-invalid': submitted && f.FirstName.errors }">
                            <div *ngIf="submitted && f.FirstName.errors" class="invalid-feedback">
                                <div *ngIf="f.FirstName.errors.required">First Name is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>Last Name</label>
                            <input type="text" id="username" class="form-control" placeholder="LastName"
                                formControlName="LastName" [ngClass]="{ 'is-invalid': submitted && f.LastName.errors }">
                            <div *ngIf="submitted && f.LastName.errors" class="invalid-feedback">
                                <div *ngIf="f.LastName.errors.required">Last Name is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>Mobile Number</label>
                            <input type="text" id="username" class="form-control" placeholder="MobileNo"
                                formControlName="MobileNo" [ngClass]="{ 'is-invalid': submitted && f.MobileNo.errors }">
                            <div *ngIf="submitted && f.MobileNo.errors" class="invalid-feedback">
                                <div *ngIf="f.MobileNo.errors.required">Mobile No is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Logo</label>
                            <input (change)="Uploadcompanylogo($event)" type="file" class="input">
                            <!-- <button class="btn" mat-raised-button color="primary" (click)="uploadFile()">Upload</button> -->
                        </div>
                    </div>



                </div>
            </div>

            <button (click)="baiscInfoForm()" class="btn dashboard-btn">Update Your
                Information</button>
        </form>
    </div>
</div>