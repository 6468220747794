import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/global.service';
import { SharedService } from 'src/app/shared.service';

@Component({
    selector: 'app-add-edit-project',
    templateUrl: './add-edit-project.component.html',
    styleUrls: ['./add-edit-project.component.scss']
})
export class AddEditProjectComponent implements OnInit, OnDestroy {
    // submitted: boolean = false;
    submittedPrj: boolean = false;
    savePrjBtn: boolean = true;
    updatePrjBtn: boolean = false;
    prjId: number;
    proejctForm: FormGroup;
    public projectsInfo = [];
    response: any;
    // openform = false;
    boolVar = true;
    hideForm: boolean = false;
    hideTable: boolean = true;

    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private router: Router,
        private Service: SharedService<any>,
        private Global: GlobalService,
        private dialogRef: MatDialogRef<AddEditProjectComponent>,
        @Inject(MAT_DIALOG_DATA) public editData: any
    ) { }

    ngOnInit(): void {
        this.proejctForm = this.formBuilder.group({
            Id: ['', null],
            EmpId: ['', null],
            DescriptionOfAchievments: ['', [Validators.nullValidator]],

        })

        if (this.editData) {
            this.editPrj(this.editData)
            this.updatePrjBtn = true
            this.savePrjBtn = false
        }
        else {
            this.updatePrjBtn = false
            this.savePrjBtn = true

        }
    }

    get fprj(): { [key: string]: AbstractControl } {
        return this.proejctForm.controls;
    }

    projectInfoForm() {
        this.submittedPrj = true;
        if (this.proejctForm.invalid) {
            this.toastr.error('This data is not Valid');
        }
        else {
            const insertachivement = this.proejctForm.getRawValue();
            insertachivement.EmpId = this.Global.getCandidateId();
            this.Service.Post('CandidateAchievements/InsertCandidateAchievements/', insertachivement).subscribe(
                (res) => {
                    if (res) {
                        this.toastr.success(res);
                        this.dialogRef.close('Save');
                        this.boolVar = true;
                    } else {
                        this.toastr.error('Error While Insert Data');
                    }
                });
            this.submittedPrj = false;
        }
    }

    projectUpdateForm() {
        this.submittedPrj = true;

        if (this.proejctForm.invalid) {
            this.toastr.error('This data is not Valid');
        }
        else {
            const insertachivement = this.proejctForm.getRawValue();
            insertachivement.EmpId = this.Global.getCandidateId();
            this.Service.Put('CandidateAchievements/UpdateCandidateAchievements/', insertachivement).subscribe(
                (res) => {
                    this.toastr.success(res);
                    this.dialogRef.close('Update');
                    this.boolVar = true;
                },
                (error) => {
                    this.toastr.error(error.error);
                });
            this.submittedPrj = false;
        }
    }


    editPrj(achivementInfo: any) {
        // this.openform = !this.openform;
            this.hideForm = true;
            this.hideTable = false;
            this.savePrjBtn = false;
            this.updatePrjBtn = true;

                this.proejctForm.controls["Id"].setValue(achivementInfo.Id);
                this.proejctForm.controls["DescriptionOfAchievments"].setValue(achivementInfo.DescriptionOfAchievments);

            this.submittedPrj = false;
    }
    close(){
        this.dialogRef.close(); 
    }

    ngOnDestroy(): void {

    }

}
