<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-end">
                    <div class="col-lg-8 col-md-12">
                        <div class="title-item" *ngIf="datasource">
                            <h2 style="margin-right: 43%;">{{datasource[0].JobRole}}</h2>
                            <ul>
                                <li style="margin-right: 43%;"><i class='bx bx-pie-chart-alt-2'></i> {{datasource[0].CompanyIndustry}}
                                </li>
                                <li style="margin-right: 43%;"><i class='bx bx-time'></i> {{datasource[0].JobPostedDate | date:'dd-MM-yyyy'}}</li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="right">
                            <div>
                                <div *ngIf="usertype === 4">
                                    <a class="cmn-btn" (click)="ApplyNowClick(datasource[0].Id)">Apply Now <i
                                            class='bx bx-plus'></i></a>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Page Title -->



    <mat-tab-group>
        <mat-tab style="position: sticky;">

            <ng-template mat-tab-label>
                <!-- <mat-icon class="example-tab-icon">summarize</mat-icon> -->
                Summary
            </ng-template>

            <div class="job-details-area ptb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12" *ngIf="datasource">
                            <div class="details-item">

                                <div class="details-inner" *ngIf="datasource[0].JobNumber != ''">
                                    <h3>Job Reference</h3>
                                    <p>{{datasource[0].JobNumber}}</p>
                                </div>

                                <!-- <div class="details-inner" >
                                    <h3>Company Name</h3>
                                 
                                    <p>{{datasource[0].CompanyName}}</p>
                                </div> -->

                                <div class="details-inner" *ngIf="datasource[0].IsDisClose == false || datasource[0].JobForName == null">
                                    <h3>Company Name</h3>
                                    <p>Keshav Encon Private Limited</p>
                                </div>

                                <div class="details-inner" *ngIf="datasource[0].IsDisClose == true && datasource[0].JobForName != null ">
                                    <h3>Company Name</h3>
                                    <p>{{datasource[0].JobForName}}</p>
                                </div>
								

                                <div class="details-inner" *ngIf="datasource[0].JobDescription != null">
                                    <h3>Job Description</h3>
                                    <p>{{datasource[0].JobDescription}}</p>
                                </div>

                                <div class="details-inner" *ngIf="datasource[0].JobSpecification != null">
                                    <h3>Job Specification</h3>
                                    <p>{{datasource[0].JobSpecification}}</p>
                                </div>

                                <div class="details-inner" *ngIf="datasource[0].Qualification != null">
                                    <h3>Qualification</h3>
                                    <p>{{datasource[0].Qualification}}</p>
                                </div>

                                <div class="details-inner datasource[0].AcademicDescription != null">
                                    <h3>Academic Discipline </h3>
                                    <p>{{datasource[0].AcademicDescription}}</p>
                                </div>

                                <div class="details-inner" *ngIf="datasource[0].SpecializationDescription != null">
                                    <h3>Specialization</h3>
                                    <p>{{datasource[0].SpecializationDescription}}</p>
                                </div>

                                <div class="details-inner" *ngIf="datasource[0].PostJobDescription != null">
                                    <h3>Specialization</h3>
                                    <p>{{datasource[0].PostJobDescription}}</p>
                                </div>

                                <div class="details-inner" *ngIf="datasource[0].MinYearExp != null">
                                    <h3>Required Experience</h3>
                                    <p>{{datasource[0].MinYearExp}}</p>
                                </div>

                                <div class="details-inner" *ngIf="datasource[0].GenderDesc != null">
                                    <h3>Gender</h3>
                                    <p>{{datasource[0].GenderDesc}}</p>
                                </div>

                                <div class="details-inner" *ngIf="datasource[0].WorkingDay != null">
                                    <h3>Working Days</h3>
                                    <p>{{datasource[0].WorkingDay}}</p>
                                </div>

                                <div class="details-inner" *ngIf="datasource[0].JobTiming != null">
                                    <h3>Timing</h3>
                                    <p>{{datasource[0].JobTiming}}</p>
                                </div>

                                <div class="details-inner" *ngIf="datasource[0].DutyTypeName != null">
                                    <h3>Duty Type</h3>
                                    <p>{{datasource[0].DutyTypeName}}</p>
                                </div>

                                <div class="details-inner" *ngIf="datasource[0].NoticePeriodday != null">
                                    <h3>Joining Period</h3>
                                    <p>{{datasource[0].NoticePeriodday}}</p>
                                </div>

                                <div class="details-inner" *ngIf="datasource[0].CurrencyCode != 0">
                                    <h3>Currency</h3>
                                    <p>{{datasource[0].CurrencyCode }}</p>
                                </div>

                                <div class="details-inner">
                                    <h3>Salary Type</h3>
                                    <p>{{datasource[0].SalaryDesc}} </p>
                                </div>

                                <div class="details-inner" *ngIf="datasource[0].MinSalary != 0 || datasource[0].MaxSalary != 0 ">
                                    <h3>Minimum Salary - Mximum Salary <label>(annual)</label></h3>
                                    <p><i class='bx bx-rupee'></i>{{datasource[0].MinSalary }} - <i class='bx bx-rupee'></i>{{datasource[0].MaxSalary}}</p>
                                </div>

                                <div class="details-inner" *ngIf="datasource[0].JobOpening != null">
                                    <h3>Job Opening</h3>
                                    <p>{{datasource[0].JobOpening }}</p>
                                </div>

                                <div class="details-inner" *ngIf="datasource[0].JobShiftName != null">
                                    <h3>Job Shift</h3>
                                    <p>{{datasource[0].JobShiftName}}</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div> 

        </mat-tab>
        <mat-tab label="Summary" style="position: sticky;" *ngIf="Global.isOwner || Global.isUser">

            <ng-template mat-tab-label>
                <!-- <mat-icon class="example-tab-icon">groups</mat-icon> -->
                Candidate
            </ng-template>


            <div class="board-wrapper" style="width: 3680px;">
                <div class="board-columns">
                    <div class="board-column">
                        <div class="row">
                            <div class="col" *ngFor="let status of allCandidateStatus">
                                <div>
                                    <div class="column-title text-center" style="width: 330px;">
                                        {{status.MessageText}}
                                    </div>

                                    <div *ngFor="let BuildResume of datasource1" (click)="ClickCard(BuildResume)">
                                        <div  *ngIf="status.ListIndex === BuildResume.CandidateStatus">
                                        <div class="candidate-item two" *ngIf="BuildResume">
                                            <div class="left">
                                                <h3><a>{{BuildResume.FirstName}} {{BuildResume.LastName}}</a></h3>
                                                <div><span>{{BuildResume.CurrentRoleWithCompanyName}}</span>
                                                </div>
                                                <ul class="experience">
                                                    <li>Education: <span>{{BuildResume.HighestEducation}}</span>
                                                    <li>Experience: <span>{{BuildResume.Experience}} Years</span></li>
                                                    <li>Gender:<span>{{BuildResume.GenderDesc}}</span>
                                                    <li>City: <span>{{BuildResume.CityName}}</span></li>
                                                </ul>
                                                <div class="cmn-link">
                                                    <a (click)="viewResume(BuildResume)"><i
                                                            class="flaticon-right-arrow one"></i> View
                                                        Resume
                                                        <i class="flaticon-right-arrow two"></i></a>
                                                </div>
                                            </div>
                                            <ng-container *ngIf="(BuildResume.Photo); else noImage">
                                                <img class="circle-54" [src]="BuildResume.Photo">
                                            </ng-container>

                                            <ng-template #noImage>
                                                <ng-container *ngIf="BuildResume.Gender == 0">
                                                    <img class="circle-54" src="assets/candidateimg/Male_Colors.jpg">
                                                </ng-container>

                                                <ng-container *ngIf="BuildResume.Gender == 1">
                                                    <img class="circle-54" src="assets/candidateimg/Female_Colors.jpg">

                                                </ng-container>
                                                <ng-container
                                                    *ngIf="BuildResume.Gender != 1 && BuildResume.Gender != 0">
                                                    <img class="circle-54" src="assets/candidateimg/Male_Colors.jpg">

                                                </ng-container>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </mat-tab>
    </mat-tab-group>
    <div class="itPRVl">
        <div class="fdPPpV">
        </div>
    </div>
</div>
