<div class="container1">
    <div class="sidenav">
      <app-menu ></app-menu>
    </div>

<!-- Post A Job -->
<div class="dashboard-area ptb-100">
    <div class="container">
        <div class="profile-content">
            <form [formGroup]="jobPostForm">
                <div class="profile-content-inner">
                    <h2>Post a Job</h2>
                    <div class="row">

                        <div class="col-lg-4 col-md-12" hidden=true>
                            <div class="form-group">
                                <input class="form-control" formControlName="CompanyId" placeholder="" />
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12" *ngIf="usertype == 1 || usertype == 5">
                            <div class="form-group">
                                <label>Company</label>
                                <select class="form-control form-select" formControlName="JobCompanyName"
                                (change)="onchangecompany($event.target.value)"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.JobCompanyName.errors }">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let comp of (allCompanyName | async)" [value]="comp.CompanyName">
                                        {{comp.CompanyName}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.JobCompanyName.errors">
                                    <div *ngIf="fjob.JobCompanyName.errors.required">
                                        Company Name is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12" *ngIf="usertype == 2">
                            <div class="form-group">
                                <label>Company</label>
                                <input class="form-control" formControlName="JobCompanyName" placeholder="" [ngClass]="{
                                    'is-invalid':
                                        submittedJob &&
                                        fjob.JobCompanyName1.errors
                                }" />
                                <div class="invalid-feedback" *ngIf="
                                    submittedJob &&
                                    fjob.JobCompanyName1.errors
                                ">
                                    <div *ngIf="
                                        fjob.JobCompanyName1.errors
                                            .required
                                    ">
                                        Company Name is required
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Job Role</label>
                                <select class="form-control form-select" formControlName="JobRoleId"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.JobRoleId.errors }">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let JobRole of (allJobRole | async)" [value]="JobRole.Id">
                                        {{JobRole.JobRole}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.JobRoleId.errors">
                                    <div *ngIf="fjob.JobRoleId.errors.required">
                                        Job Role is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                         
                                <div class="form-group">
                                    <label>Company Address</label>
                                    <textarea type="number" class="form-control" formControlName="CompanyAddress"
                                        placeholder="" [ngClass]="{
                                            'is-invalid':
                                                submittedJob &&
                                                fjob.CompanyAddress.errors
                                        }"></textarea>
                                    <div class="invalid-feedback" *ngIf="
                                            submittedJob &&
                                            fjob.CompanyAddress.errors
                                        ">
                                        <div *ngIf="
                                                fjob.CompanyAddress.errors
                                                    .required
                                            ">
                                            Company Address is required
                                        </div>
                                    </div>
                                </div>
                           
    
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>Job Description</label>
                                <textarea class="form-control" formControlName="JobDescription"
                                    placeholder="Enter Description" style="height: 90px;" [ngClass]="{ 'is-invalid': submittedJob && fjob.JobDescription.errors }" ></textarea>
                                    <div class="invalid-feedback" *ngIf="submittedJob && fjob.JobDescription.errors">
                                        <div *ngIf="fjob.JobDescription.errors.required">
                                            Description is required
                                        </div>
                                    </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>Job Specification</label>
                                <textarea class="form-control textarea" formControlName="JobSpecification"
                                    placeholder="Enter Specification" style="height: 90px;"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.JobSpecification.errors }"
                                    ></textarea>
                            <div class="invalid-feedback" *ngIf="submittedJob && fjob.JobSpecification.errors">
                                <div *ngIf="fjob.JobSpecification.errors.required">
                                    Specification is required
                                </div>
                            </div>
                        </div>
                    </div>
  

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                            <form>
                                <label class="form-check-label" for="inlineRadio1">Single Job Location</label>
                                <input class="form-check-input" type="radio" formControlName="JobLocationType" value="0"
                                    name="inlineRadioOptions" id="inlineRadio1" [(ngModel)]="selectedStatus" checked
                                    style="margin-left: 2%; margin-right:2%"
                                     >

                                <label class="form-check-label" for="inlineRadio2">Work From Home</label>
                                <input class="form-check-input" type="radio" formControlName="JobLocationType"
                                    name="inlineRadioOptions" id="inlineRadio2" value="1"
                                    style="margin-left: 2%; margin-right:2%" (click)="wfh()">

                                <label class="form-check-label" for="inlineRadio3">Field Jobs</label>
                                <input class="form-check-input" type="radio" formControlName="JobLocationType"
                                    name="inlineRadioOptions" id="inlineRadio3" value="2"
                                    style="margin-left: 2%; margin-right:2%" (click)="fieldjobs()">

                            </form>

                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Country</label>
                                <select class="form-control form-select" formControlName="Country"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.Country.errors }"
                                    (change)="onchangeCountry($event.target.value)">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let Countries of (allCountries | async)" [value]="Countries.Id">
                                        {{Countries.CountryName}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.Country.errors">
                                    <div *ngIf="fjob.Country.errors.required">
                                        Country is required
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-4 col-md-12" >
                            <div class="form-group">
                                <label>State</label>
                                <select class="form-control form-select" formControlName="State"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.State.errors }"
                                    (change)="onchangeCity($event.target.value)">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let states of (allStates | async)" [value]="states.Id">
                                        {{states.StateName}}
                                    </option>

                                </select>
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.State.errors">
                                    <div *ngIf="fjob.State.errors.required">
                                        State is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12" >
                            <div class="form-group">
                                <label>City</label>
                                <select class="form-control form-select" formControlName="JobLocation"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.JobLocation.errors }">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let cities of (allCities | async)" [value]="cities.Id">
                                        {{cities.CityName}}
                                    </option>

                                </select>
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.JobLocation.errors">
                                    <div *ngIf="fjob.JobLocation.errors.required">
                                        City is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>No.Of Opening</label>
                                <input type="number" class="form-control" formControlName="JobOpening"
                                    placeholder="" [ngClass]="{
                                        'is-invalid':
                                            submittedJob &&
                                            fjob.JobOpening.errors
                                    }" />
                                <div class="invalid-feedback" *ngIf="
                                        submittedJob &&
                                        fjob.JobOpening.errors
                                    ">
                                    <div *ngIf="
                                            fjob.JobOpening.errors
                                                .required
                                        ">
                                        No Of Opening is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Joining Period</label>
                                <select class="form-control form-select" formControlName="NoticePeriod"
                                [ngClass]="{ 'is-invalid': submittedJob && fjob.NoticePeriod.errors }">
                                <option value="" disabled selected>Choose any one</option>
                                <option   *ngFor="let NoticePeriod of (allNoticePeriod | async)" [value]="NoticePeriod.ListIndex">
                                    {{NoticePeriod.MessageText}}
                                </option>
                            </select>
                            <div class="invalid-feedback" *ngIf="submittedJob && fjob.NoticePeriod.errors">
                                <div *ngIf="fjob.NoticePeriod.errors.required">
                                    Duty Type required
                                </div>
                            </div>
                        </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Type Of Job</label>
                                <select class="form-control form-select" formControlName="JobType"
                                [ngClass]="{ 'is-invalid': submittedJob && fjob.JobType.errors }">
                                <option value="" disabled selected>Choose any one</option>
                                <option *ngFor="let JobType of (allJobType | async)" [value]="JobType.ListIndex">
                                    {{JobType.MessageText}}
                                </option>

                            </select>
                            <div class="invalid-feedback" *ngIf="submittedJob && fjob.JobType.errors">
                                <div *ngIf="fjob.JobType.errors.required">
                                    Type Of Job is required
                                </div>
                            </div>
                        </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Sub Type Of Job</label>
                                <select class="form-control form-select" formControlName="Typeofjob"
                                [ngClass]="{ 'is-invalid': submittedJob && fjob.Typeofjob.errors }">
                                <option value="" disabled selected>Choose any one</option>
                                <option *ngFor="let Typeofjob of (allTypeofjob | async)" [value]="Typeofjob.ListIndex">
                                    {{Typeofjob.MessageText}}
                                </option>

                            </select>
                            <div class="invalid-feedback" *ngIf="submittedJob && fjob.Typeofjob.errors">
                                <div *ngIf="fjob.Typeofjob.errors.required">
                                    Sub Type Of Job is required
                                </div>
                            </div>
                        </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Shift Type</label>
                                <select class="form-control form-select" formControlName="JobShift"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.JobShift.errors }">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let ShiftType of (allShiftType | async)" [value]="ShiftType.ListIndex">
                                        {{ShiftType.MessageText}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="
                                    submittedJob &&
                                    fjob.JobShift.errors
                                ">
                                    <div *ngIf="
                                        fjob.JobShift.errors
                                            .required
                                    ">
                                        Shift Type is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Working Days</label>
                                <select class="form-control form-select" formControlName="WorkingDays">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let WorkingDays of (allWorkingDays | async)"
                                        [value]="WorkingDays.ListIndex">
                                        {{WorkingDays.MessageText}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Working Hours</label>
                                <select class="form-control form-select" formControlName="Timing"
                                [ngClass]="{ 'is-invalid': submittedJob && fjob.Timing.errors }" >
                                <option value="" disabled selected>Choose any one</option>
                                <option *ngFor="let Timing of (allworkinghpurs | async)" [value]="Timing.ListIndex">
                                    {{Timing.MessageText}}
                                </option>

                            </select>
                            <div class="invalid-feedback" *ngIf="submittedJob && fjob.Timing.errors">
                                <div *ngIf="fjob.Timing.errors.required">
                                    Working Hours is required
                                </div>
                            </div>
                        </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Duty Type</label>
                                <select class="form-control form-select" formControlName="DutyType"
                                [ngClass]="{ 'is-invalid': submittedJob && fjob.DutyType.errors }">
                                <option value="" disabled selected>Choose any one</option>
                                <option  *ngFor="let DutyType of (allDutyType | async)" [value]="DutyType.ListIndex">
                                    {{DutyType.MessageText}}
                                </option>
                            </select>
                            <div class="invalid-feedback" *ngIf="submittedJob && fjob.DutyType.errors">
                                <div *ngIf="fjob.DutyType.errors.required">
                                    Duty Type required
                                </div>
                            </div>
                        </div>
                        </div>

                        <div class="col-lg-12 col-md-12"  >
                            <div class="form-group">
                                <label>Available For InternShip(Graduate Engineer Trainee)</label>
                                <input class="form-check-input" type="radio" formControlName="IsInternShip"
                                    name="inlineRadioOptions4" id="inlineRadio4" [value]="true" style="margin-left:2%"
                                    (click)="intership1()">
                                <label class="form-check-label" for="inlineRadio4">Yes</label>
                                <input class="form-check-input" type="radio" formControlName="IsInternShip"
                                    name="inlineRadioOptions4" id="inlineRadio5" [value]="false"
                                    style="margin-left: 2%; margin-right:2%" (click)="intership()">
                                <label class="form-check-label" for="inlineRadio5">No</label>

                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12" *ngIf="this.internshipvalue">
                            <div class="form-group">
                                <label>Experience Type</label>
                                <select class="form-control form-select" formControlName="ExperienceType"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.ExperienceType.errors }"
                                    (change)="expType($event.target.value)">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let ExperienceType of (allExperienceType | async)" [value]="ExperienceType.ListIndex">
                                        {{ExperienceType.MessageText}}
                                    </option>

                                </select>
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.ExperienceType.errors">
                                    <div *ngIf="fjob.ExperienceType.errors.required">
                                        Experience Type required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Salary Type</label>
                                <select class="form-control form-select" formControlName="Salarytype"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.Salarytype.errors }"
                                    (change)="onchangesalary($event.target.value)">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let Salarytype of (allSalarytype | async)"
                                        [value]="Salarytype.ListIndex">
                                        {{Salarytype.MessageText}}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.Salarytype.errors">
                                    <div *ngIf="fjob.Salarytype.errors.required">
                                        Salary Type is required
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-4 col-md-12" *ngIf="this.appearMe">
                            <div class="form-group">
                                <label>Min. year</label>
                                <input type="number" class="form-control" formControlName="MinYearExp"
                                    placeholder="Enter Min Year" >
                            </div>
                        </div>


                        <div class="col-lg-4 col-md-12" *ngIf="this.salarytype">
                            <div class="form-group">
                                <label>Currency</label>
                                <select class="form-control form-select" formControlName="CurrencyId"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.CurrencyId.errors }" >
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let Currency of (allCurrency | async)" [value]="Currency.Id">
                                        {{Currency.CurrencyCode}}
                                    </option>

                                </select>
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.CurrencyId.errors">
                                    <div *ngIf="fjob.CurrencyId.errors.required">
                                        Currency is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12" *ngIf="this.salarytype" >
                            <div class="form-group">
                                <label *ngIf="this.stipend">Min.Stipend/Month</label>
                                <label *ngIf="this.salary">Min.Salary/Month</label>
                                <input type="number" class="form-control" formControlName="MinSalary"
                                    placeholder="" [ngClass]="{
                                        'is-invalid':
                                            submittedJob &&
                                            fjob.MinSalary.errors
                                    }" />
                                <div class="invalid-feedback" *ngIf="
                                        submittedJob &&
                                        fjob.MinSalary.errors
                                    ">
                                    <div *ngIf="
                                            fjob.MinSalary.errors
                                                .required
                                        ">
                                        Min.Stipend is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12" *ngIf="this.salarytype">
                            <div class="form-group">
                                <label *ngIf="this.stipend">Max.Stipend/Month</label>
                                <label *ngIf="this.salary">Max.Salary/Month</label>
                                <input type="number" class="form-control" formControlName="MaxSalary"
                                    placeholder="" [ngClass]="{
                                        'is-invalid':
                                            submittedJob &&
                                            fjob.MaxSalary.errors
                                    }" />
                                <div class="invalid-feedback" *ngIf="
                                        submittedJob &&
                                        fjob.MaxSalary.errors
                                    ">
                                    <div *ngIf="
                                            fjob.MaxSalary.errors
                                                .required
                                        ">
                                        Max.Stipend is required
                                    </div>
                                </div>
                            </div>
                        </div>



                        <!-- <div class="col-lg-4 col-md-12" *ngIf="this.salary">
                            <div class="form-group">

                                <input type="number" class="form-control" formControlName="MinSalary"
                                    placeholder="" [ngClass]="{
                                        'is-invalid':
                                            submittedJob &&
                                            fjob.MinSalary.errors
                                    }" />
                                <div class="invalid-feedback" *ngIf="
                                        submittedJob &&
                                        fjob.MinSalary.errors
                                    ">
                                    <div *ngIf="
                                            fjob.MinSalary.errors
                                                .required
                                        ">
                                        Min.Salary is required
                                    </div>
                                </div>
                            </div>
                        </div> -->

                        <!-- <div class="col-lg-4 col-md-12" *ngIf="this.salary">
                            <div class="form-group">
                                <label>Max.Salary/Month</label>
                                <input type="number" class="form-control" formControlName="MaxSalary"
                                    placeholder="" [ngClass]="{
                                        'is-invalid':
                                            submittedJob &&
                                            fjob.MaxSalary.errors
                                    }" />
                                <div class="invalid-feedback" *ngIf="
                                        submittedJob &&
                                        fjob.MaxSalary.errors
                                    ">
                                    <div *ngIf="
                                            fjob.MaxSalary.errors
                                                .required
                                        ">
                                        Max.Salary is required
                                    </div>
                                </div>
                            </div>
                        </div> -->



                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Minimum Qualification</label>
                                <select class="form-control form-select" formControlName="ReqQualification" (change)="onchangeQualification($event.target.value)"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.ReqQualification.errors }" >
                                    <option value="" disabled selected>Choose any one</option>
                                    <option *ngFor="let Quli of (allQualification | async)" [value]="Quli.Id">
                                        {{Quli.Qualification}}
                                    </option>

                                </select>
                                <div class="invalid-feedback" *ngIf="submittedJob && fjob.ReqQualification.errors">
                                    <div *ngIf="fjob.ReqQualification.errors.required">
                                        Qualification is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Academic Discipline</label>
                                <select class="form-control form-select" formControlName="AcademicDisciplineId"
                                (change)="onchangeDiscipline($event.target.value)"
                                [ngClass]="{ 'is-invalid': submittedJob && fjob.Timing.errors }" >
                                <option value="" disabled selected>Choose any one</option>
                                <option *ngFor="let discipline of (alldiscipline | async)" [value]="discipline.Id">
                                    {{discipline.AcademicDescription}}
                                </option>

                            </select>
                            <div class="invalid-feedback" *ngIf="submittedJob && fjob.AcademicDisciplineId.errors">
                                <div *ngIf="fjob.AcademicDisciplineId.errors.required">
                                    Academic Discipline is required
                                </div>
                            </div>
                        </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Specialization</label>
                                <div style="margin-top: -22px;">
                                <mat-form-field appearance="none">
                                    <mat-select #select multiple class="form-control1 form-select1" formControlName="SpecializationId">
                                        <div class="select-all">
                                            <mat-checkbox [(ngModel)]="allSelected"
                                                [ngModelOptions]="{standalone: true}" (change)="toggleAllSelection()">
                                                Select All</mat-checkbox>
                                        </div>
                                        <mat-option (onSelectionChange)="getValues($event)"
                                            *ngFor="let specialization of (allspecialization | async)"
                                            [value]="specialization.Id">
                                            {{specialization.SpecializationDescription}} </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Gender</label>
                                <select class="form-control form-select" formControlName="Gender"
                                [ngClass]="{ 'is-invalid': submittedJob && fjob.Gender.errors }">
                                <option value="" disabled selected>Choose any one</option>
                                <option  *ngFor="let Gender of (allGender | async)" [value]="Gender.ListIndex">
                                    {{Gender.MessageText}}
                                </option>
                            </select>
                            <div class="invalid-feedback" *ngIf="submittedJob && fjob.Gender.errors">
                                <div *ngIf="fjob.Gender.errors.required">
                                    Gender required
                                </div>
                            </div>
                        </div>
                        </div>

                        <div class="col-lg-4 col-md-12">
                            <div class="form-group">
                                <label>Target Date</label>
                                <input type="date" class="form-control" placeholder="01/01/1995"
                                    formControlName="TargetDate"
                                    [ngClass]="{ 'is-invalid': submittedJob && fjob.TargetDate.errors }">
                                <div *ngIf="submittedJob && fjob.TargetDate.errors" class="invalid-feedback">
                                    <div *ngIf="fjob.TargetDate.errors.required">TargetDate is required</div>
                                </div>
                            </div>
                          </div>

                        <!-- *ngIf="saveJobBtn" -->
                        <button (click)="jobPostInfoForm()" class="btn dashboard-btn">
                            Save Your Information
                        </button>

                    </div>
                </div>


            </form>
        </div>
    </div>
</div>
</div>
<!-- End Post A Job -->

<div class="footer"><!-- Footer -->
    <app-footer-style-two></app-footer-style-two>
  </div>
  
  