import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import userToken from "../../config/userToken";
import { user, Users, usertoken } from './user.types';
import { SharedService } from 'src/app/shared.service';
import { GlobalService } from 'src/app/global.service';
import { UserType } from 'src/app/interface';
import { EmployersRegisterComponent } from '../employers-register/employers-register.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

    userId: number = userToken.id;

    submitted: boolean = false;
    loginForm: FormGroup;
    userModel: user;
    userToken: usertoken;
    loginData: any;

    constructor(private formBuilder: FormBuilder,
        private service: SharedService<any>,
        private Global:GlobalService,
        private toastr: ToastrService,
        private router: Router,
        private dialog: MatDialog,

    ) { }

    ngOnInit(): void {
        this.loginForm = this.formBuilder.group({
            Password: ['', [Validators.required, Validators.minLength(5)]],
            Email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]]
        })
    }

    get f(): { [key: string]: AbstractControl } {
        return this.loginForm.controls;
    }

    login() {
        this.submitted = true;
        if (this.loginForm.invalid) {
          return;
        }
        else {
          const users = new Users(this.loginForm.value.Email, this.loginForm.value.Password)
          this.service.Post('Login', users).subscribe({
            next: (response: any) => {
              this.loginData = response;
              // if (this.loginData.UserType == UserType.Owner) {
              //   this.Global.isSubmitted = true;
              //   this.Global.isOwner = true;
              //   this.Global.isCompany = false;
              //   this.Global.isCollege = false;
              //   this.Global.isCandidate = false;
              //   this.Global.isUser = false;
              //   this.Global.setOwnerName( this.loginData.ProfileName);
              //   this.Global.setUserName('');
              //   this.Global.setClgName('');
              //   this.Global.setCompanyName('');
              //   this.Global.setuserId( this.loginData.UserId);
              //   this.Global.setprofilename( this.loginData.ProfileName);
              //   this.Global.setUserType(this.loginData.UserType);
              // //  this.Global.getLoginData = this.Global.Getprofilename() ;                
              // this.router.navigate(['ownerhome/profile']);
              //   this.toastr.success("Welcome " + this.loginData.ProfileName);
    
              // }
              if (this.loginData.UserType == UserType.Candidate) 
              {
                this.Global.isSubmitted = true;
                this.Global.isOwner = false;
                this.Global.isCompany = false;
                this.Global.isCollege = false;
                this.Global.isCandidate = true;
                this.Global.isUser = false;
                this.Global.setUserName( this.loginData.ProfileName);
                this.Global.setOwnerName('');
                this.Global.setClgName('');
                this.Global.setCompanyName('');
                this.Global.setuserId( this.loginData.UserId);
                this.Global.setprofilename( this.loginData.ProfileName);
                this.Global.setUserType(this.loginData.UserType);
                this.Global.Getprofilename() ;
                this.service.Get('CandidateInfo/GetCandidateInfoByUserId?userId=' + this.loginData.UserId, null).subscribe(
                  (res) => {         
                      this.Global.setCandidateId(res.Id);    
                      this.Global.setCandidateemail(res.Email);  
                      this.Global.setJobroleId(res.JobRoleId);                   
                      this.router.navigate(['landing']);
                  },
                  (error) => {
                      this.toastr.error(error.error);
                  });              
                
                this.toastr.success("Welcome " + this.loginData.ProfileName);
              }
             else if (this.loginData.UserType == UserType.College) 
             {
                this.Global.isSubmitted = true;
                this.Global.isOwner = false;
                this.Global.isCompany = false;
                this.Global.isCollege = true;
                this.Global.isCandidate = false;
                this.Global.isUser = false;
                this.Global.setClgName( this.loginData.ProfileName);
                this.Global.setUserName('');
                this.Global.setOwnerName('');
                this.Global.setCompanyName('');
                this.Global.setuserId( this.loginData.UserId);
                this.Global.setprofilename( this.loginData.ProfileName);
                 this.Global.setUserType(this.loginData.UserType);
                this.Global.Getprofilename() ;
                this.service.Get('College/GetCollegeData?Id=' + this.loginData.UserId, null).subscribe(
                  (res) => {                      
                      this.Global.SetCollegeId(res[0].Id);                      
                      this.router.navigate(['candidates']);
                  },
                  (error) => {
                      this.toastr.error(error.error);
                  });          
                this.toastr.success("Welcome " + this.loginData.ProfileName);
              }
           else if (this.loginData.UserType == UserType.Company) 
           {
                this.Global.isSubmitted = true;
                this.Global.isOwner = false;
                this.Global.isCompany = true;
                this.Global.isCollege = false;
                this.Global.isCandidate = false;
                this.Global.isUser = false;
                this.Global.setCompanyName( this.loginData.ProfileName);
                this.Global.setUserName(this.loginData.Email);
                this.Global.setOwnerName('');
                this.Global.setClgName('');
                this.Global.setuserId( this.loginData.UserId);
                this.Global.setprofilename( this.loginData.ProfileName);
                this.Global.setUserType(this.loginData.UserType);
              
                this.service.Get('Company/GetCompanyInfoByUserId?userId=' + this.loginData.UserId, null).subscribe(
                  (res) => {                      
                      this.Global.SetCompanyId(res[0].Id);   
                      this.router.navigate(['companyhome/profile']);
                      this.toastr.success("Welcome " + this.loginData.ProfileName);                     
                  },
                  (error) => {
                      this.toastr.error(error.error);
                  });   
              }
              else{
                this.toastr.error("Invalid LogIn");
              }
            },error: (error) => {
              this.toastr.error(error.error);
            }})
        }
      }

    getAuthData() {
        let userToken = JSON.parse(localStorage.getItem('userToken'));

        if (!userToken) 
        {
            this.router.navigate(['/login']);
            this.toastr.error("Please sign in");
        } 
        else 
        {
            return userToken.token;
        }
    }

    AddCandidate() {
      const dialogRef = this.dialog.open(EmployersRegisterComponent, {
        width: '20%'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result == "Save") 
        {

        }
      });
    }

    ngOnDestroy(): void {

    }

}
