import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import { GlobalService } from 'src/app/global.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-viewuplodedresume',
    templateUrl: './viewuplodedresume.component.html',
    styleUrls: ['./viewuplodedresume.component.scss']    
})

export class ViewUploadedResumeComponent implements OnInit, OnDestroy {

  viewer = 'google';
  src: any; 
  safeUrl : SafeResourceUrl ;
  filepath:string ='';
    constructor(
        private toastr: ToastrService,
        private router: Router,
        private service: SharedService<any>,
        private Global:GlobalService,
        private sanitized: DomSanitizer,
        private dialogRef: MatDialogRef<ViewUploadedResumeComponent>,

    ) { }
    
    ngOnInit(): void {
    //   this.src = 'http://keshavwebapi.jobonfingertip.com/assets/2_Lighbill-Mahendra.pdf';
    //   this.safeUrl = this.sanitized.bypassSecurityTrustResourceUrl(this.src);
      //this.src = 'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf';
      this.getResumeFilePath();
    }

    getResumeFilePath()
    {
        this.service.Get('CandidateInfo/GetResumefile?candidateId=' + this.Global.getCandidateId(), null).subscribe(
            (res) => {
                this.src  = res;
            //  this.filepath = res;
                this.safeUrl = this.sanitized.bypassSecurityTrustResourceUrl(this.src + '#toolbar=0&navpanes=0');
                // this.getresumeData = res;
                // this.candidateImage = this.getresumeData.Photo;
                // this.getAllExperience(this.getresumeData.Id);
            },        
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }

    close()
    {
        this.dialogRef.close(); 
    }
    ngOnDestroy(): void {

    }


}


