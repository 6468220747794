<!-- Login -->
<div class="user-form-area">
    <div class="container-fluid p-0">
        <div class="row m-0 align-items-center">
            <!-- <div class="col-lg-6 p-0 col-md-12">
                <div class="user-img">
                    <img src="assets/img/resgister-bg.jpg" alt="User">
                </div>
            </div> -->

            <div class="col-lg-12 p-0 col-md-12">
                <div class="user-content">
                    <div class="top">
                        <h2>Login</h2>

                        <form [formGroup]="loginForm">
                            <div class="form-group">
                                <input type="email" class="form-control" placeholder="Email" formControlName="Email"
                                    [ngClass]="{ 'is-invalid': submitted && f.Email.errors }">
                                <div *ngIf="submitted && f.Email.errors" class="invalid-feedback">
                                    <div *ngIf="f.Email.errors.required">Email is required</div>
                                    <div *ngIf="f.Email.errors.email">Email is invalid</div>
                                </div>
                            </div>

                            <div class="form-group">
                                <input type="password" class="form-control" placeholder="Password"
                                    formControlName="Password"
                                    [ngClass]="{ 'is-invalid': submitted && f.Password.errors }"
                                   
                                    >
                                    
                                <div *ngIf="submitted && f.Password.errors" class="invalid-feedback">
                                    <div *ngIf="f.Password.errors.required">Password is required</div>
                                    <div *ngIf="f.Password.errors.minlength">
                                        Password must be at least 6 characters
                                    </div>
                                    <div *ngIf="f.Password.errors.maxlength">
                                        Password must not exceed 40 characters
                                    </div>
                                </div>
                            </div>

                            <button (click)="login()" type="submit" class="btn">Login Here</button>
                        </form>
                        <div style="margin-top:1% ;">
                            <a style="font-weight: 600; color: blue; margin-left: 75%;" routerLink="/forgotpassword">ForgotPassword</a>

                        </div>

                        <div class="create-account">
                            <span class="account-direction">Create an account? <a
                                    style="font-weight: 600; color: #fe4a55; margin-right: 2%;"
                                    routerLink="/candidate/register">Candidate</a>
                                <a style="font-weight: 600; color: #fe4a55; margin-right: 2%;"
                                    routerLink="/company-register">Company</a>
                                <a style="font-weight: 600; color: #fe4a55; margin-right: 2%;" routerLink="/register">College</a></span>
                                <!-- <div class="text-center">
                                    <a (click)="forgotpass()" class="forgotPwd">Forgot Password</a>
                                </div> -->
                        </div>

                    </div>

                    <div class="end">
                        <ul>
                            <li><a href="https://www.facebook.com/login/" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-google-plus'></i></a></li>
                            <li><a href="https://www.linkedin.com/login" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#"><i class="bx bx-home-alt"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Login -->