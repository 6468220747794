import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import * as _moment from 'moment';
import { SharedService } from 'src/app/shared.service';
import { GlobalService } from 'src/app/global.service';
import { cities, Country, JobRole, State } from 'src/app/interface';
import { MatDialog } from '@angular/material/dialog';
import { AddEditPersonalInfoComponent } from './add-edit-personalinfo/add-edit-personalinfo.component';
const moment = _moment;

@Component({
    selector: 'app-Personal-info',
    templateUrl: './Personal-info.component.html',
    styleUrls: ['./Personal-info.component.scss']
})
export class PersonalInfoComponent implements OnInit, OnDestroy { 

    submitted: boolean = false;
    PersonalInfoForm: FormGroup;
    public info: any;
    summary: string;
    response: any;
    openform = false;
    boolVar = true;
    fileType: string = '';
    base64textString: string[] = [];
    allMaritalStatus: Observable<any[]>;
    allGender: Observable<any[]>;
    allCountries: Observable<Country[]>;
    allStates: Observable<State[]>;
    allCities: Observable<cities[]>
    allJobRole: Observable<JobRole[]>;
    hideForm: boolean = true;
    hideme: boolean = true;
    displayInternShipField: boolean = false;


    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private Service: SharedService<any>,
        private Global: GlobalService,
        private dialog: MatDialog,
    ) { }


    // editInfo() {
    //     this.openform = !this.openform;
    //     this.boolVar = !this.boolVar;
    //     if(this.boolVar == true){
    //         this.hideForm = true;
    //         this.hideme = false;
    //     }
    //     else{
    //         this.hideForm = false;
    //         this.hideme = true;
    //     }

    //     this.Service.Get('GetListValue?Id=3', null).subscribe(maritalstatus => {
    //         this.allMaritalStatus = maritalstatus;
    //     });
    //     this.Service.Get('GetListValue?Id=2', null).subscribe(GenderData => {
    //         this.allGender = GenderData;
    //     });
    //      this.Service.Get('Country/GetCountry?Id=0', null).subscribe(country =>{
    //         this.allCountries = country;
    //     });


    //     this.Service.Get('State/GetStateData?Id=0', null).subscribe(state => {
    //         this.allStates = state;
    //     })
    //     this.Service.Get('City/GetCity?Id=0', null).subscribe(city => {
    //         this.allCities = city;
    //     })
    //     this.Service.Get('JobRole/GetJobRole?Id=0', null).subscribe(jobrole => {
    //         this.allJobRole = jobrole;
    //     })

    //     this.getUser();
    // }
    
    ngOnInit(): void {
        this.PersonalInfoForm = this.formBuilder.group({
            Id: ['', null],
            FirstName: ['', [Validators.required]],
            LastName: ['', [Validators.required, Validators.maxLength(50)]],
            MobileNo: ['', [Validators.required]],
            Gender: ['', [Validators.required]],
            DateOfBirth: ['', [Validators.required]],
            MaritalStatus: ['', [Validators.required]],
            Nationality: ['', [Validators.nullValidator]],
            DifferentlyAbled: ['', [Validators.required]],
            Address1: ['', [Validators.required]],
            Address2: ['', [Validators.nullValidator]],
            CityIdOfEmployeeInfo: ['', [Validators.required]],
            CityNameOfEmployeeInfo: ['', null],
            StateId: ['', [Validators.required]],
            StateName: ['',null ],
            CountryId: ['', [Validators.required]],
            CountryName: ['', null],
            PinCode: ['', [Validators.required]],
            Photo:['',[Validators.nullValidator]]

        });
        
        this.Service.Get('GetListValue?Id=3', null).subscribe(maritalstatus => {
            this.allMaritalStatus = maritalstatus;
        });
        this.Service.Get('GetListValue?Id=2', null).subscribe(GenderData => {
            this.allGender = GenderData;
        });
         this.Service.Get('Country/GetCountry?Id=0', null).subscribe(country =>{
            this.allCountries = country;
        });


        this.Service.Get('State/GetStateData?Id=0', null).subscribe(state => {
            this.allStates = state;
        })
        this.Service.Get('City/GetCity?Id=0', null).subscribe(city => {
            this.allCities = city;
        })
        this.Service.Get('JobRole/GetJobRole?Id=0', null).subscribe(jobrole => {
            this.allJobRole = jobrole;
        })
        this.getUser();
    }

    get fPinfo(): { [key: string]: AbstractControl } {
        return this.PersonalInfoForm.controls;
    }

    personalInfoForm() {
        this.submitted = true;
        if (this.PersonalInfoForm.invalid) {
            this.toastr.error('This data is not Valid');
        }
        else {
            const updatepersonalinfo = this.PersonalInfoForm.getRawValue();
            updatepersonalinfo.Photo = this.base64textString[0];
            this.Service.Put('CandidateInfo/UpdatePersonalInfo', updatepersonalinfo).subscribe(
                (res) => {
                    this.toastr.success(res);
                    this.getUser();
                    this.hideForm = true;
                    this.hideme = false;
                    this.openform = false;                        
                    this.boolVar = true;    
                },
                (error) => {
                    this.toastr.error(error.error);
                });
            this.submitted = false;
        }
    }



    getUser() {
        this.Service.Get('CandidateInfo/GetCandidateInfoByUserId?userId=' + this.Global.getuserId(), null).subscribe(
            (res) => {
                this.info = res;
                this.PersonalInfoForm.controls["Id"].setValue(this.info.Id);
                this.PersonalInfoForm.controls["FirstName"].setValue(this.info.FirstName);
                this.PersonalInfoForm.controls["LastName"].setValue(this.info.LastName);
                this.PersonalInfoForm.controls["MobileNo"].setValue(this.info.MobileNo);
                const momentDate = new Date(this.info.DateOfBirth);
                const formateddate = moment(momentDate).format("yyyy-MM-DD");
                this.info.DateOfBirth = formateddate;
                this.PersonalInfoForm.controls["DateOfBirth"].setValue(this.info.DateOfBirth);
                this.PersonalInfoForm.controls["Gender"].setValue(this.info.Gender);
                this.PersonalInfoForm.controls["MaritalStatus"].setValue(this.info.MaritalStatus);
                this.PersonalInfoForm.controls["Nationality"].setValue(this.info.Nationality);
                this.PersonalInfoForm.controls["DifferentlyAbled"].setValue(this.info.DifferentlyAbled);
                this.PersonalInfoForm.controls["Address1"].setValue(this.info.Address1);
                this.PersonalInfoForm.controls["Address2"].setValue(this.info.Address2);
                this.PersonalInfoForm.controls['CountryId'].setValue(this.info.CountryId);
                this.PersonalInfoForm.controls['StateId'].setValue(this.info.StateId);
                this.PersonalInfoForm.controls['CityIdOfEmployeeInfo'].setValue(this.info.CityIdOfEmployeeInfo);
                this.PersonalInfoForm.controls["PinCode"].setValue(this.info.PinCode);
                this.PersonalInfoForm.controls["Photo"].setValue(this.info.Photo);
                this.displayInternShipField = res.IsGET;
                this.Global.setCandidateId(this.info.Id);
                //this.global.setEmpId(this.info.Id);
                // 
            },
            (error) => {
                //if (error.status == 401) this.router.navigate(['/login']);
                this.toastr.error(error.error);
            });
    }

    onchangeCountry(event) {
        this.Service.Get('State/GetStateByCountry?countryId=' + event, null).subscribe(state => {
            this.allStates = state;
        });
    }

    onchangeState(event) {
        this.Service.Get('City/GetCityDataByState?StateId=' + event, null).subscribe(city => {
            this.allCities = city;
        })
    }

    Uploadcompanylogo(event: any) {
        this.fileType = event.target.files[0].type
        var files = event.target.files;
        var file = files[0];
        if (files && file) {
            var reader = new FileReader();
            reader.onload = this._handleReaderLoadedCompany.bind(this);
            reader.readAsBinaryString(file);
        }
    }

    _handleReaderLoadedCompany(readerEvt) {
        var binaryString = readerEvt.target.result;
        // this.base64textString = btoa(binaryString);
        // this.base64textString = 'data:' + this.fileType + ';base64,' + this.base64textString;
        this.base64textString.push('data:' + this.fileType + ';base64,' + btoa(readerEvt.target.result));
    }

    EditPI() {
        const dialogRef = this.dialog.open(AddEditPersonalInfoComponent, {
            width: 'auto',
            height: '85%',
            position: { top: '111px' },
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result == "Save") {
                
                this.getUser();
               
            }
        });
    }

    

    ngOnDestroy(): void {

    }
}