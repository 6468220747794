export enum UserType {
    Owner = 1,
    Company = 2,
    College = 3,
    Candidate = 4,
    Recruiter = 5,
  }

  export enum ListValuesMap{
    UserType =1,
    Gender=2,
    MaritalStatus=3,
	EducationType=4,
	EmployeementType=5,
	JobType=6,
	SubJobType=7,
	ExperienceType=8,
	WorkingDays=9,
    JobLocationType=10,
	ShiftType=11,
	CandidateStatus=12,
	DutyType=13,
	TypeOfJob=14,
	JobWokingHours=15,
	NoticePeriods=16,
    Jobstatus=17,
    Salarytype = 18
  }

export class CompanyInformation {
    [x: string]: any;
    Id: number;
    UserId: number;
    CompanyEmail: string;
    CompanyProfileName: string;
    CompanyPassword: string;
    UserTypeId: number;
    CompanyName: string;
    RecruiterName?: string;
    RecruiterEmail?: string;
    RecruiterContactNo?: string;
    IndustryTypeId?: number;
    IndustryType?: string;
    Address1?: string;
    Address2?: string;
    CityId: number;
    CityName: string;
    StateId?: number;
    StateName?: string;
    CountryId?: number;
    CountryName?: string;
    PinCode?: number;
    WebSite: string;
    LinkedInPage?: string;
    Logo?: string;
    Description?: string;
    FirstName?: string;
    LastName?: string;
    MobileNo?:string;
    CountryISDCode:string;
}

export class EmployeeMaster {
    Id: number;
    UserId: number;
    Email?: string;
    ProfileName?: string;
    Password: string;
    UserTypeId: number;
    FirstName: string;
    LastName: string;
    Gender: number;
    GenderDesc: string;
    DateOfBirth: Date;
    MaritalStatus: number;
    MaritalStatusDesc: string;
    Nationality: string;
    DifferentlyAbled: boolean;
    Address1: string;
    Address2: string;
    CityId: number;
    CityName: string;
    StateId: number;
    StateName: string;
    CountryId: number;
    CountryName: string;
    PinCode: string;
    ExpectedSalary: string;
    OpenForWork: boolean;
    Photo: string;
    MobileNo?: string;
    IsGET: boolean;
    InternShipStartDate: Date;
    InternShipEndDate: Date;
    Qualification:string;
    AcademicDescription:string;
    SpecializationDescription:string
    JobRoleId:number;
    JobRole:string
    NoOfExperience:number;
    Function1:number;
    Section1:number;
    PassOutYear:number;
    CountryISDCode:string;
}

export interface EmployeeMaster {
    Id: number;
    UserId: number;
    Email?: string;
    ProfileName?: string;
    Password: string;
    UserTypeId: number;
    FirstName: string;
    LastName: string;
    Gender: number;
    GenderDesc: string;
    DateOfBirth: Date;
    MaritalStatus: number;
    MaritalStatusDesc: string;
    Nationality: string;
    DifferentlyAbled: boolean;
    Address1: string;
    Address2: string;
    CityId: number;
    CityName: string;
    StateId: number;
    StateName: string;
    CountryId: number;
    CountryName: string;
    PinCode: string;
    ExpectedSalary: string;
    OpenForWork: boolean;
    Photo: string;
    MobileNo?: string;
    IsGET: boolean;
    InternShipStartDate: Date;
    InternShipEndDate: Date;
    Qualification:string;
    AcademicDescription:string;
    SpecializationDescription:string
    JobRoleId:number;
    JobRole:string
    NoOfExperience:number;
    PassOutYear:number;
    CountryISDCode:string;
}

export interface RecruiterInfomation {
    Id: number;
    FirstName: string;
    LastName: string;
    Email?: string;
    MobileNo?: string;
    UserType: number;
    Password: string;
    UserTypeName: string;

}

export class RecruiterInfomation {
    Id: number;
    FirstName: string;
    LastName: string;
    Email?: string;
    MobileNo?: string;
    UserType: number;
    Password: string;
    UserTypeName: string;

}

export class CollegeInformation {
    Id: number;
    UserId: number;
    CollegeName: string;
    CollegeDescription: string;
    Address1?: string;
    Address2?: string;
    CityId: number;
    CityName: string;
    StateId?: number;
    StateName?: string;
    CountryId?: number;
    CountryName?: string;
    PinCode?: number;
    WebSite: string;
    Logo?: string;
    CompanyPassword: string;
    UserTypeId: number;
    CollegeEmail:string;
    ProfileName:string;
    Password:string;
}

export class JobPost {
    Id: number;
    CompanyId: number;
    CompanyName: string;
    JobCompanyName: string;
    JobPostedDate: Date;
    JobRoleId: number;
    JobRole: string;
    JobLocation: number;
    JobLocationCity: string;
    Country: number;
    CountryName: string;
    State: number;
    StateName: string;
    JobLocationType: number;
    JobLocationTypeName: string;
    WorkingDays: number;
    WorkingDay: string;
    Timing: number;
    DisplayName:string;
    CompanyAddress: string;
    JobType: number;
    JobTypeName: string;
    JobDescription: string;
    MinSalary: string;
    MaxSalary: string;
    CurrencyId: number;
    CurrencyCode: string;
    JobOpening: number;
    ExperienceType: number;
    ExperienceTypeName: string;
    MinYearExp: number;
    ReqQualification: number;
    Qualification: string;
    Gender: number;
    GenderDesc: string;
    SubJobType: number;
    SubJobTypeName: string;
    RecruiterName: string;
    RecruiterEmail: string;
    RecruiterContactNo: string;
    JobShift: number;
    JobShiftName: string;
    IsInternShip: boolean;
    Typeofjob:number;
    SubTypeofJob:string;
    DutyType:number;
    DutyTypeName:string;
    NoticePeriod:number;
    NoticePerioddays:string;
    AcademicDisciplineId:number;
    AcademicDescription:string;
    SpecializationId:number;
    SpecializationDescription:string;
    CompanyIndustry:string;
    RecruiterId:number;
    Salarytype:number;
}

export interface Jobpost {
    Id: number;
    CompanyId: number;
    CompanyName: string;
    JobCompanyName: string;
    JobPostedDate: Date;
    JobRoleId: number;
    JobRole: string;
    JobLocation: number;
    JobLocationCity: string;
    Country: number;
    CountryName: string;
    State: number;
    StateName: string;
    JobLocationType: number;
    JobLocationTypeName: string;
    WorkingDays: number;
    WorkingDay: string;
    Timing: number;
    DisplayName:string;
    CompanyAddress: string;
    JobType: number;
    JobTypeName: string;
    JobDescription: string;
    MinSalary: string;
    MaxSalary: string;
    CurrencyId: number;
    CurrencyCode: string;
    JobOpening: number;
    ExperienceType: number;
    ExperienceTypeName: string;
    MinYearExp: number;
    ReqQualification: number;
    Qualification: string;
    Gender: number;
    GenderDesc: string;
    SubJobType: number;
    SubJobTypeName: string;
    RecruiterName: string;
    RecruiterEmail: string;
    RecruiterContactNo: string;
    JobShift: number;
    JobShiftName: string;
    IsInternShip: boolean;
    Typeofjob:number;
    SubTypeofJob:string;
    DutyType:number;
    DutyTypeName:string;
    NoticePeriod:number;
    NoticePerioddays:string;
    AcademicDisciplineId:number;
    AcademicDescription:string;
    SpecializationId:number;
    SpecializationDescription:string;
    CompanyIndustry:string;
    RecruiterId:number;
    Salarytype:number;
}

export class cities {
    Id: number;
    CityName: string;
    StateId: number;
    StateName: string;
}

export class State {
    Id: number;
    StateCode: string;
    StateName: string;
    CountryId: number;
    CountryName: string;
}

export class Country {
    Id: number;
    CountryName: string;
    CountryCode: string;
}

export interface cities {
    Id: number;
    CityName: string;
    StateId: number;
}

export class Qualificationa {
    Id: number;
    Description: string; 
}

export class Qualification {
Id:number
IsActive:boolean
Qualification:string 
}

export class AcademicDiscipline {
    Id: number;
    QualificationId :number;
    Qualification:string;
    AcademicDescription: string;
}

export class Specialization {
    Id: number;
    AcademicDisplineId:number;
    AcademicDiscipline:string;
    SpecializationDescription: string;
    IsActive:boolean;
}

export class Area {
    Id: number;
    AreaName: string;
}

export class DocumentsList {
    Document_ID: number;
    Document_Name: string;
}


export class SpecialQualificationa {
    specialQual_ID?: number
    specialQual_Name?: string;
}

export interface BuildResume {
    Id: number;
    UserId: number;
    FirstName: string;
    LastName: string;
    MobileNo: string;
    Gender: number;
    DateOfBirth: Date;
    MaritalStatus: number;
    MaritalStatusDesc: string;
    Nationality: string;
    DifferentlyAbled: boolean;
    Address1: string;
    Address2: string;
    CityIdOfEmployeeInfo: number;
    CityNameOfEmployeeInfo: string;
    StateId: number;
    StateName: string;
    CountryId: number;
    JobRoleId: number;
    JobRole: string;
    CompanyName: string;
    CountryName: string;
    PinCode: string;
    GenderDesc: string
    ExpectedSalary: string;
    OpenForWork: boolean;
    Photo: string;
    Email: string;
    CurrentRoleWithCompanyName: string;
    EmpGender: string;
    InternShipStartDate: Date;
    InternShipEndDate: Date;
    CollegeId:number;
    CollegeName: string;
    NoOfExperience:number;
}

export class BuildResume {
    Id: number;
    UserId: number;
    FirstName: string;
    LastName: string;
    MobileNo: string;
    Gender: number;
    EmpGender: string;
    DateOfBirth: Date;
    MaritalStatus: number;
    Nationality: string;
    DifferentlyAbled: boolean;
    Address1: string;
    Address2: string;
    CityIdOfEmployeeInfo: number;
    CityNameOfEmployeeInfo: string;
    StateId: number;
    StateName: string;
    CountryId: number;
    JobRoleId: number;
    JobRole: string;
    CompanyName: string;
    CountryName: string;
    PinCode: string;
    ExpectedSalary: string;
    OpenForWork: boolean;
    Photo: string;
    Email: string;
    EmpId: number;
    SchoolOrUniversity: string;
    QualificationId: number;
    Qualification: string;
    Specialization: string;
    GradeOrPercentage: string;
    EducationType: boolean;
    FromDateOfEducation: Date;
    ToDateOfEducation: Date;
    CurrentRoleWithCompanyName: string;
    CollegeId:number;
    CollegeName: string;
    NoOfExperience:number;
}

export interface EmployeeEducationInfo {
    Id: number;
    EmpId: number;
    SchoolOrUniversity: string;
    QualificationId: number;
    Qualification: string;
    Specialization: string;
    GradeOrPercentage: string;
    EducationTypeName: string
    EducationType: boolean;
    FromDateOfEducation: Date;
    ToDateOfEducation: Date;
    AcademicDisciplineId:number;
    AcademicDescription:string;
    SpecializationId:number;
    SpecializationDescription:string;
    PassOutYear:DoubleRange;


}

export class EmployeeEducationInfo {
    Id: number;
    EmpId: number;
    SchoolOrUniversity: string;
    QualificationId: number;
    Qualification: string;
    Specialization: string;
    GradeOrPercentage: string;
    EducationTypeName: string
    EducationType: boolean;
    FromDateOfEducation: Date;
    ToDateOfEducation: Date;
    AcademicDisciplineId:number;
    AcademicDescription:string;
    SpecializationId:number;
    SpecializationDescription:string;
    PassOutYear:DoubleRange;

}

export class EmployeeWorkExperienceInfo {
    Id: number;
    EmpId: number;
    CompanyName: string;
    JobRoleId: number;
    JobRole: string;
    Description: string;
    EmployeementType: number;
    EmployeementTypeName: string;
    WorkLocation: string;
    IsCurrentJob: boolean;
    IsShiftJob: boolean;
    FromDate: Date;
    ToDate: Date;
}

export interface EmployeeWorkExperienceInfo {
    Id: number;
    EmpId: number;
    CompanyName: string;
    JobRoleId: number;
    JobRole: string;
    Description: string;
    EmployeementType: number;
    EmployeementTypeName: string;
    WorkLocation: string;
    IsCurrentJob: boolean;
    IsShiftJob: boolean;
    FromDate: Date;
    ToDate: Date;
}

export class EmployeeAchievements {
    Id: number;
    EmpId: number;
    DescriptionOfAchievments: string;
}

export interface EmployeeAchievements {
    Id: number;
    EmpId: number;
    DescriptionOfAchievments: string;
}

export class EmployeeCertifications {
    Id: number;
    EmpId: number;
    CertificationName: string;
    Description: string;
    DateOfIssue: Date;
    DateOfValidity: Date;
}

export interface EmployeeCertifications {
    Id: number;
    EmpId: number;
    CertificationName: string;
    Description: string;
    DateOfIssue: Date;
    DateOfValidity: Date;
}

export interface JobType {
    Id: number;
    JobType: string;
}

export interface EmployeeDetail {
    Id: number;
    FirstName: string;
    LastName: string;
    Email: string;
    MobileNo: string;
}


export class Users {
    Id: Number;
    Email: String;
    Password: String;
    ProfileName: String;
    UserType: String;

    constructor(Email: string, Password: string) {
        this.Email = Email;
        this.Password = Password;
    }

    getEmail(): String {
        return this.Email;
    }

    getPassword(): String {
        return this.Password;
    }

    getId(): Number {
        return this.Id;
    }

    setId(id: Number) {
        this.Id = id;
    }
}

export interface JobRole {
    Id: number;
    JobRole: string;
    Description: string;
    IsActive: boolean;
}

export class City {
    Id: number;
    StateId: number;
    StateName: string;
    CityName: string;
    IsActive: boolean;
}

export interface Area {
    Id: number;
    CityId: number;
    AreaName: string;
    CityName: string;
}

export interface Qualification {
    Id: number;
    Qualification: string;
    IsActive: boolean;
}

export interface Skills {
    Id: number;
    Skills: string;
    IsActive: boolean;
}
export interface Languages {
    Id: number;
    Language: string;
    IsActive: boolean;
}

export interface EmployeeSkills {
    Id: number;
    EmpId: number;
    Skill_Id: number;
    Skills: string;
}

export class EmployeeSkills {
    Id: number;
    EmpId: number;
    Skill_Id: number;
    Skills: string;
}

export interface EmployeePreferredLocations {
    Id: number;
    EmpId: number;
    CityId: number;
    CityName: string;
}

export class EmployeePreferredLocations {
    Id: number;
    EmpId: number;
    CityId: number;
    CityName: string;
}

export interface EmployeeLanguages {
    Id: number;
    EmpId: number;
    LanguageId: number;
    Language: string;
    LRead: number;
    LWrite: number;
    LSpeak: number;
}

export class EmployeeLanguages {
    Id: number;
    EmpId: number;
    LanguageId: number;
    Language: string;
    LRead: number;
    LWrite: number;
    LSpeak: number;
}

export interface IndustryType {
    Id: number;
    IndustryType: string;
    Description: string;
    IsActive: boolean;
}

export interface Currency {
    Id: number;
    CurrencyCode: string;
    CurrencyName: string;
    CurrencySymbol: string;
    IsActive: boolean;
}

export interface ListValue {
    ListIndex: number;
    MessageText: string;
}

export class Bookmark {
    Id: number;
    UserId: number;
    PostedJobId: number;
    JobCompanyName: string;
}

export class ShortlistedEmployee {
    Id: number;
    UserId: number;
    PostedJobId: number;
    CandidateId: number;
    CandidateStatus: number;


}


export class EmployeeChangePass {
    OldPass: string;
    NewPass: string;
    ConfrimPass: string;
    UserId: number
}
export interface EmployeeChangePass {
    OldPass: string;
    NewPass: string;
    ConfrimPass: string;
    UserId: number
}
export class CompanyChangePass {
    OldPass: string;
    NewPass: string;
    ConfrimPass: string;
    UserId: number

}
export interface CompanyChangePass {
    OldPass: string;
    NewPass: string;
    ConfrimPass: string;
    UserId: number
}

export interface Download {
    content: Blob | null;
    progress: number;
    state: "PENDING" | "IN_PROGRESS" | "DONE";
}

export class AppliedJob {
    Id: number;
    UserId: number;
    ProfileName: string;
    PostedJobId?: number;
    JobCompanyName?: string;
    JobPostedDate: Date;
    JobRole: string;
    JobOpening: number;
    CompanyAddress:string;
    JobNumber:number;
    AppliedDate:Date;
}