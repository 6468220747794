import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { DashboardService } from './services/dashboard.service';
import { Router } from "@angular/router";
import userToken from "../../config/userToken";
import { BuildResume } from './basic-info/basic-info.types';
import { SharedService } from 'src/app/shared.service';
import { GlobalService } from 'src/app/global.service';
import { MatDialog } from '@angular/material/dialog';
import { AddEditBasicInfoComponent } from './basic-info/add-edit-basicinfo/add-edit-basicinfo.component';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { ViewUploadedResumeComponent } from './viewuplodedresume/viewuplodedresume.component';
import { AddEditSkillsLanguagesComponent } from './skills-languages/add-edit-Skill/add-edit-skill.component';
import { AddEditEducationComponent } from './education/add-edit-edu/add-edit-edu.component';
import { AddEditExperienceComponent } from './experience/add-edit-experience/add-edit-experience';
import { AddEditCertificationComponent } from './Certification/add-edit-certification/add-edit-certificate.component';
import { AddEditLanguagesComponent } from './Languages/add-edit-languages/add-edit-languages.component';
import { AddEditPrefferedLocationComponent } from './Preffered Location/add-edit-location/add-edit-location.component';
import { AddEditCandidateFunctionComponent } from './CandidateFunction/add-edit-function/add-edit-function.component';
import { AddEditCandidateSectionComponent } from './CandidateSection/add-edit-section/add-edit-section.component';
import { AddEditPersonalInfoComponent } from './Personal-info/add-edit-personalinfo/add-edit-personalinfo.component';
import { ChangepasswordComponent } from './Change-password/Change-password.component';
import { ViewportScroller } from '@angular/common';
import { AddEditProjectComponent } from './project/add-edit-project/add-edit-project.component';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
    @ViewChild('myInput') myInputVariable: any;
    resumefilename: any;

    candidateInfo = [];
    userId: number = userToken.id;
    name: string;
    designation: string;
    getresumeData: BuildResume;
    datasourceExp: any;
    candidateImage: string = '';
    public skillInfo = [];
    public educationInfo = [];
    public experienceInfo = [];
    public projectsInfo = [];
    public CertificateInfo = [];
    public languageInfo = [];
    public locationInfo = [];
    public function = [];
    public section = [];
    public info: any;
    message: string = '';
    editskill: boolean = false;
    editLanguage: boolean = false;
    editLocation: boolean = false;
    public placeholder: string = '';
    EmpImages: string = '';
    basicInfoForm: FormGroup;

    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private Service: SharedService<any>,
        private Global: GlobalService,
        private dialog: MatDialog,
        private scroller: ViewportScroller,
    ) {

    }

    ngOnInit(): void {
        this.getUser();
        this.getresumefileName();
        this.getAllSkills();
        this.getAllEducations();
        this.getAllExperience();
        this.getAllAchivements();
        this.getAllCertificate();
        this.getAllLanguages();
        this.getAllCity();
        this.getFuction();
        this.getSection();
        this.getPersonalInfo();

        this.basicInfoForm = this.formBuilder.group({
            Id: ['', null],
            FirstName: ['', [Validators.required]],
            LastName: ['', [Validators.required, Validators.maxLength(50)]],
            MobileNo: ['', [Validators.required]],
            ExpectedSalary: ['', [Validators.nullValidator]],
            JobRoleId: ['', [Validators.nullValidator]],
            JobRole: ['', [Validators.nullValidator]],
            PassoutYear: ['', [Validators.nullValidator]],
            Photo: ['', [Validators.nullValidator]],
            CityIdOfEmployeeInfo: ['', [Validators.required]],
            CityNameOfEmployeeInfo: ['', null],
            StateId: ['', [Validators.required]],
            CountryId: ['', [Validators.required]],
            CountryName: ['', null],

        });
    }

    getUser() {
        this.userId = this.Global.getuserId();
        this.Service.Get('CandidateInfo/GetCandidateInfoByUserId?userId=' + this.userId, null).subscribe(
            (res) => {
                this.getresumeData = res;
                this.candidateImage = this.getresumeData.Photo;
                this.basicInfoForm.controls["Id"].setValue(this.getresumeData.Id);
                this.basicInfoForm.controls["FirstName"].setValue(this.getresumeData.FirstName);
                this.basicInfoForm.controls["LastName"].setValue(this.getresumeData.LastName);
                this.basicInfoForm.controls["MobileNo"].setValue(this.getresumeData.MobileNo);
                this.basicInfoForm.controls['JobRoleId'].setValue(this.getresumeData.JobRoleId);
                this.basicInfoForm.controls['PassoutYear'].setValue(this.getresumeData.PassOutYear);
                this.basicInfoForm.controls["ExpectedSalary"].setValue(this.getresumeData.ExpectedSalary);
                this.basicInfoForm.controls['CountryId'].setValue(this.getresumeData.CountryId);
                this.basicInfoForm.controls['StateId'].setValue(this.getresumeData.StateId);
                this.basicInfoForm.controls['CityIdOfEmployeeInfo'].setValue(this.getresumeData.CityIdOfEmployeeInfo);
                this.basicInfoForm.controls['Photo'].setValue(this.getresumeData.Photo);
                this.EmpImages = this.getresumeData.Photo
            },
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }

    EditBasicinfo(row: any) {
        this.dialog.open(AddEditBasicInfoComponent, {
            width: '52%',
            height: '61%%',
            position: { top: '120px' },
            data: row
        }).afterClosed().subscribe(val => {
            if (val == "Update") {
                this.getUser();
            }
        });
    }

    MyClick() {
        console.log("Clicked");
    }

    ViewResume() {
        const dialogRef = this.dialog.open(ViewUploadedResumeComponent, {
            width: '55%',
            height: '84%',
            position: { top: '117px' }
        });
    }

    uplodPfd(files: FileList) {
        var fileToUpload: File | null = null;
        fileToUpload = files.item(0);
        const formData: FormData = new FormData();
        // formData.append('pdf', fileToUpload, fileToUpload.name);
        formData.append('file', fileToUpload, fileToUpload.name);
        formData.append('candidateId', this.Global.getCandidateId().toString());

        if (fileToUpload.size <= 3000000) {
            this.Service.resumeupload(formData).subscribe((res) => {
                this.toastr.success(res.toString());
                this.getresumefileName();
                this.myInputVariable.nativeElement.value = "";
            },
                (error) => {
                    this.toastr.error(error.error);
                    this.myInputVariable.nativeElement.value = "";
                });
        }
        else {
            this.toastr.error("File size is large");
            this.myInputVariable.nativeElement.value = "";
        }
    }

    getresumefileName() {

        this.Service.Get('CandidateInfo/GetCandidateInfoByUserId?userId=' + this.Global.getuserId(), null).subscribe(
            (res) => {
                this.resumefilename = res;
            },
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }

    getAllSkills() {
        this.Service.Get('CandidateSkills/GetCandidateSkills?EmpId=' + this.Global.getCandidateId(), null).subscribe(res => {
            this.skillInfo = res;
        },
            (error) => {
                this.toastr.error(error.error);
            });
    }

    getAllEducations() {
        this.Service.Get('CandidateEducationInfo/GetCandidateEducationInfoByEmpId?EmpId=' + this.Global.getCandidateId(), null).subscribe(res => {
            this.educationInfo = res;
        },
            (error) => {
                this.toastr.error(error.error);
            });
    }

    getAllExperience() {
        this.Service.Get('CandidateExperienceInfo/GetCandidateExperienceInfoByEmpId?EmpId=' + this.Global.getCandidateId(), null).subscribe(
            (res) => {
                this.experienceInfo = res;
            },
            (error) => {
                this.toastr.error(error.error);
            });
    }

    getAllAchivements() {
        this.Service.Get('CandidateAchievements/GetCandidateAchievementsByEmpId?EmpId=' + this.Global.getCandidateId(), null).subscribe(
            (res) => {
                this.projectsInfo = res;
            },
            (error) => {
                this.toastr.error(error.error);
            });
    }

    getAllCertificate() {
        this.Service.Get('CandidateCertificatios/GetCandidateCertificatiosByEmpId?EmpId=' + this.Global.getCandidateId(), null).subscribe(res => {
            this.CertificateInfo = res;
        },
            (error) => {
                this.toastr.error(error.error);
            });
    }

    getAllLanguages() {
        this.Service.Get('CandidateLanguages/GetCandidateLanguages?EmpId=' + this.Global.getCandidateId(), null).subscribe(res => {
            this.languageInfo = res;
        },
            (error) => {
                this.toastr.error(error.error);
            });
    }

    getAllCity() {
        this.Service.Get('CandidtaePreferredLocations/GetCandidatePreferredLocations?EmpId=' + this.Global.getCandidateId(), null).subscribe(res => {
            this.locationInfo = res;
        },
            (error) => {
                this.toastr.error(error.error);
            });
    }

    getFuction() {
        this.Service.Get('CandidateInfo/GetCandidateInfo?id=' + this.Global.getCandidateId(), null).subscribe(
            (res) => {
                this.function = res;
            },
            (error) => {
                this.toastr.error(error.error);
            });
    }

    getSection() {
        this.Service.Get('CandidateInfo/GetCandidateInfo?id=' + this.Global.getCandidateId(), null).subscribe(
            (res) => {
                this.section = res;
            },
            (error) => {
                this.toastr.error(error.error);
            });
    }

    getPersonalInfo() {
        this.Service.Get('CandidateInfo/GetCandidateInfoByUserId?userId=' + this.Global.getuserId(), null).subscribe(
            (res) => {
                this.info = res;
                this.Global.setCandidateId(this.info.Id);
            },
            (error) => {
                this.toastr.error(error.error);
            });
    }

    AddSkill() {
        const dialogRef = this.dialog.open(AddEditSkillsLanguagesComponent, {
            width: '588px',
            height: 'auto',
            position: { top: '255px' },
            autoFocus: false,
            restoreFocus: false
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result == "Save") {
                this.getAllSkills();
            }
        });
    }

    AddEdu() {

        const dialogRef = this.dialog.open(AddEditEducationComponent, {
            width: '588px',
            height: '81%',
            position: { top: '92px' }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result == "Save") {
                this.getAllEducations();
                this.getUser();
            }
        });
    }

    AddExp() {
        const dialogRef = this.dialog.open(AddEditExperienceComponent, {
            width: '40%',
            height: '80%',
            position: { top: '94px' }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result == "Save") {
                this.getAllExperience();
            }
        });
    }

    AddAchiv() {
        const dialogRef = this.dialog.open(AddEditProjectComponent, {
            width: '588px',
            height: 'auto',
            position: { top: '135px' }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result == "Save") {
                this.getAllAchivements();
            }
        });
    }


    AddCertificate() {

        const dialogRef = this.dialog.open(AddEditCertificationComponent, {
            width: 'auto',
            height: 'auto',
            position: { top: '135px' }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result == "Save") {
                this.getAllCertificate();
            }
        });
    }

    AddLanguage() {
        const dialogRef = this.dialog.open(AddEditLanguagesComponent, {
            width: '588px',
            height: 'auto',
            position: { top: '135px' },
            autoFocus: false,
            restoreFocus: false
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result == "Save") {
                this.getAllLanguages();
            }
        });
    }

    AddLocation() {
        const dialogRef = this.dialog.open(AddEditPrefferedLocationComponent, {
            width: '588px',
            height: 'auto',
            position: { top: '135px' },
            autoFocus: false,
            restoreFocus: false
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result == "Save") {
                this.getAllCity();
            }
        });
    }

    EditFunction(row: any) {
        this.dialog.open(AddEditCandidateFunctionComponent, {
            width: 'auto',
            height: 'auto',
            position: { top: '135px' },
            data: row
        }).afterClosed().subscribe(val => {
            if (val == "Update") {
                this.getFuction();
            }
        });
    }

    EditSection(row: any) {
        this.dialog.open(AddEditCandidateSectionComponent, {
            width: 'auto',
            height: 'auto',
            position: { top: '135px' },
            data: row
        }).afterClosed().subscribe(val => {
            if (val == "Update") {
                this.getSection();
            }
        });
    }

    EditPI() {
        const dialogRef = this.dialog.open(AddEditPersonalInfoComponent, {
            width: '844px',
            height: '89%',
            position: { top: '84px' },
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result == "Save") {

                this.getPersonalInfo();
                this.getUser();

            }
        });
    }

    ChangePass() {
        const dialogRef = this.dialog.open(ChangepasswordComponent, {
            width: '41%',
            height: 'auto',
            position: { top: '142px' },
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result == "Save") {

                this.getUser();

            }
        });
    }


    resume() {
        // this.scroller.scrollToAnchor("resume");
        document.getElementById('resume').scrollIntoView(false);
    }
    Skill() {
        // this.scroller.scrollToAnchor("skill");
        document.getElementById('skill').scrollIntoView(false);
    }
    Education() {
        //this.scroller.scrollToAnchor("education");
        document.getElementById('education').scrollIntoView(false);
    }
    Achivements() {
        //this.scroller.scrollToAnchor("achivements");
        document.getElementById('achivements').scrollIntoView(false);
    }
    Experience() {
        //this.scroller.scrollToAnchor("experience");
        document.getElementById('experience').scrollIntoView(false);
    }
    Certificate() {
        //this.scroller.scrollToAnchor("certificate");
        document.getElementById('certificate').scrollIntoView(false);
    }
    Language() {
        //this.scroller.scrollToAnchor("language");
        document.getElementById('language').scrollIntoView(false);
    }
    PrefferedLocation() {
        //this.scroller.scrollToAnchor("prefferedLocation");
        document.getElementById('prefferedLocation').scrollIntoView(false);
    }
    Function() {
        //this.scroller.scrollToAnchor("function");
        document.getElementById('function').scrollIntoView(false);
    }
    IndustryType() {
        //this.scroller.scrollToAnchor("industryType");
        document.getElementById('industryType').scrollIntoView(false);
    }
    PersonalDetail() {
        //this.scroller.scrollToAnchor("personalDetail");
        document.getElementById('personalDetail').scrollIntoView(false);
    }

    delSkill(event: any) {
        // this.openform = !this.openform;
        this.message = `Are you sure you want to Delete ?`;



        const dialogData = new ConfirmDialogModel("Confirm Action", this.message);

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            maxWidth: "400px",
            data: dialogData
        });

        dialogRef.afterClosed().subscribe(dialogResult => {
            if (dialogResult == true) {
                this.Service.Get('CandidateSkills/DeleteCandidateSkill?Id=' + event.EmpSkillId, null).subscribe(
                    (res) => {
                        if (res) {
                            this.toastr.success(res);
                            this.getAllSkills();

                        } else {
                            this.toastr.error('Your Data Deleted Successfully');
                        }
                    });
            }
        });


    }

    delEdu(event: any) {

        this.message = `Are you sure you want to Delete ?`;



        const dialogData = new ConfirmDialogModel("Confirm Action", this.message);

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            maxWidth: "400px",
            data: dialogData
        });

        dialogRef.afterClosed().subscribe(dialogResult => {
            if (dialogResult == true) {
                this.Service.Get('CandidateEducationInfo/DeleteCandidateEducationInfo?Id=' + event.Id, null).subscribe(
                    (res) => {
                        if (res) {
                            this.toastr.success(res);
                            this.getAllEducations();

                        } else {
                            this.toastr.error('Your Data Deleted Successfully');
                        }
                    });
            }
        });


    }

    EditEdu(row: any) {
        this.dialog.open(AddEditEducationComponent, {
            width: '588px',
            height: '81%',
            position: { top: '92px' },
            data: row
        }).afterClosed().subscribe(val => {
            if (val == "Update") {
                this.getAllEducations();
                this.getUser();
            }
        });
    }

    delExp(event: any) {
        // this.openform = !this.openform;
        this.message = `Are you sure you want to Delete ?`;



        const dialogData = new ConfirmDialogModel("Confirm Action", this.message);

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            maxWidth: "400px",
            data: dialogData
        });

        dialogRef.afterClosed().subscribe(dialogResult => {
            if (dialogResult == true) {
                this.Service.Get('CandidateExperienceInfo/DeleteCandidateExperienceInfo?Id=' + event.Id, null).subscribe(
                    (res) => {
                        if (res) {
                            this.toastr.success(res);
                            this.getAllExperience();

                        } else {
                            this.toastr.error('Your Data Deleted Successfully');
                        }
                    });
            }
        });




    }


    EditExp(row: any) {
        this.dialog.open(AddEditExperienceComponent, {
            width: '40%',
            height: '80%',
            position: { top: '94px' },
            data: row
        }).afterClosed().subscribe(val => {
            if (val == "Update") {
                this.getAllExperience();
            }
        });
    }


    delPrj(event: any) {

        this.message = `Are you sure you want to Delete ?`;



        const dialogData = new ConfirmDialogModel("Confirm Action", this.message);

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            maxWidth: "400px",
            data: dialogData
        });

        dialogRef.afterClosed().subscribe(dialogResult => {
            if (dialogResult == true) {
                this.Service.Get('CandidateAchievements/DeleteCandidateAchievements?Id=' + event.Id, null).subscribe(
                    (res) => {
                        this.toastr.success(res);
                        this.getAllAchivements();

                    },
                    (error) => {
                        this.toastr.error('Your Data Deleted Successfully');
                    });
            }
        });

    }

    EditAchiv(row: any) {
        this.dialog.open(AddEditProjectComponent, {
            width: '588px',
            height: 'auto',
            position: { top: '135px' },
            data: row
        }).afterClosed().subscribe(val => {
            if (val == "Update") {
                this.getAllAchivements();
            }
        });
    }

    delCerty(event: any) {

        this.message = `Are you sure you want to Delete ?`;



        const dialogData = new ConfirmDialogModel("Confirm Action", this.message);

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            maxWidth: "400px",
            data: dialogData
        });

        dialogRef.afterClosed().subscribe(dialogResult => {
            if (dialogResult == true) {
                this.Service.Get('CandidateCertificatios/DeleteCandidateCertifications?Id=' + event.Id, null).subscribe(
                    (res) => {
                        if (res) {
                            this.toastr.success(res);
                            this.getAllCertificate();

                        } else {
                            this.toastr.error('Your Data Deleted Successfully');
                        }
                    });
            }
        });


    }



    EditCertificate(row: any) {
        this.dialog.open(AddEditCertificationComponent, {
            width: 'auto',
            height: 'auto',
            position: { top: '135px' },
            data: row
        }).afterClosed().subscribe(val => {
            if (val == "Update") {
                this.getAllCertificate();
            }
        });
    }

    delLoc(event: any) {

        this.message = `Are you sure you want to Delete ?`;



        const dialogData = new ConfirmDialogModel("Confirm Action", this.message);

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            maxWidth: "400px",
            data: dialogData
        });

        dialogRef.afterClosed().subscribe(dialogResult => {
            if (dialogResult == true) {
                this.Service.Get('CandidtaePreferredLocations/DeleteCandidateCity?Id=' + event.EmpCityId, null).subscribe(
                    (res) => {
                        if (res) {
                            this.toastr.success(res);
                            this.getAllCity()

                        } else {
                            this.toastr.error('Your Data Deleted Successfully');
                        }
                    });
            }
        });


    }

    EditSkill() {
        this.editskill = true;
    }
    CancelSkill() {
        this.editskill = false;
    }
    EditLang() {
        this.editLanguage = true;
    }
    CancelLang() {
        this.editLanguage = false;
    }

    EditLocation() {
        this.editLocation = true;
    }

    CancelLocation() {
        this.editLocation = false;
    }

    Uploadcompanylogo(event: any) {
        if (event.target.files) {
            var reader = new FileReader();

            reader.readAsDataURL(event.target.files[0]);

            reader.onload = (event: any) => {
                this.EmpImages = event.target.result;
                this.UpdatePhoto();
            };
        }



    }

    UpdatePhoto() {
        const updatepersonalinfo = this.basicInfoForm.getRawValue();

        updatepersonalinfo.Photo = this.EmpImages;
        this.Service.Put('CandidateInfo/UpdateBasicInfo', updatepersonalinfo).subscribe(
            (res) => {
                this.toastr.success(res);
                this.getUser();
            },
            (error) => {

                this.toastr.error(error.error);
            });

        this.myInputVariable.nativeElement.value = "";
    }


    ngOnDestroy(): void {

    }
}
