import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import config from 'src/app/components/config/config';
import userToken from 'src/app/components/config/userToken';
// import config from '../../../../config/config';
// import userToken from "../../../../config/userToken";

@Injectable({
    providedIn: 'root'
})
export class UserService {
    host: string = config.host;
    token: any = userToken.token;
    userId: string = userToken.id;

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Authorization, X-API-KEY, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Allow-Request-Method',
            'Authorization': 'Bearer ' + this.token,
            'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
            'Allow': 'GET, POST, OPTIONS, PUT, DELETE'
        })
    };

    constructor(private http: HttpClient) { }

    UserForm(data: any) {
        
        return this.http.post<any>(`${this.host}/api/Recruiter/InsertRecruiterData`, data, this.httpOptions);

    }

    SendEmail(UserName: string) {
        
        return this.http.post<any>(`${this.host}/api/ResetPassword?loginName=`, + UserName, this.httpOptions);

    }
      
}
