<!-- Navbar -->

<div class="container1" id="targetRed">
    <div class="sidenav">
        <app-menu></app-menu>
    </div>
    <app-menu></app-menu>
    <!-- End Navbar -->
    <div class="jobonfingertip-candidates content">

        <div class="frame-1243">
            <div class="find-candidates">find Candidates</div>

            <div class="line-21"></div>

            <div class="group-1233">
                <div class="group-1144" (click)="AllCandidate()">
                    <div class="frame-1254" [ngClass]="{'bg': allcandidate}">
                        <div class="all-candidates" [ngClass]="{'bg': allcandidate}">All Candidates</div>
                    </div>
                </div>

                <button class="frame-1145" [ngClass]="{'bg': shortlistcandidate}" (click)="getshortlistedcandidates()">
                    <div class="shortlist-candidates" [ngClass]="{'bg': shortlistcandidate}">shortlist Candidates</div>
                </button>
            </div>

            <div>
                <div class="frame-1300">
                    <svg class="vector10" width="31" height="30" viewBox="0 0 31 30" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M21.5492 18.8679H20.1872L19.7045 18.4048C21.4524 16.3877 22.413 13.8125 22.4111 11.1492C22.4111 8.94412 21.7539 6.78853 20.5226 4.95505C19.2914 3.12157 17.5413 1.69255 15.4937 0.848689C13.4462 0.00483079 11.1931 -0.215961 9.01947 0.214234C6.8458 0.64443 4.84916 1.70629 3.28204 3.26554C1.71491 4.82478 0.647686 6.81139 0.215317 8.97412C-0.217052 11.1369 0.00485519 13.3786 0.852977 15.4159C1.7011 17.4531 3.13734 19.1944 4.98009 20.4195C6.82283 21.6446 8.98931 22.2985 11.2056 22.2985C13.9811 22.2985 16.5325 21.2865 18.4978 19.6055L18.9633 20.0858V21.4408L27.5829 30L30.1516 27.4443L21.5492 18.8679ZM11.2056 18.8679C6.91297 18.8679 3.44787 15.4202 3.44787 11.1492C3.44787 6.87822 6.91297 3.43054 11.2056 3.43054C15.4982 3.43054 18.9633 6.87822 18.9633 11.1492C18.9633 15.4202 15.4982 18.8679 11.2056 18.8679Z"
                            fill="#8F8F8F" />
                    </svg>

                    <div class="frame-1096" (click)="PostJob()">
                        <div class="post-a-job">Post a job</div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="allcandidate">
            <div class="frame-1234" *ngIf="candidatecount">
                <div class="frame-1121">
                    <div class="active-1454">active ({{candidatecount[0].activecandidate}})</div>
                </div>

                <div class="frame-1122">
                    <div class="inactive-1067">Inactive ({{candidatecount[0].inactivecandidate}})</div>
                </div>
            </div>
            <div class="line-20"></div>
            <div class="frame-1056">
                <div class="all-filters">all filters</div>
                <div class="line-16"></div>
                <div class="frame-21">
                    <form>
                        <div class="row">
                            <div class="col-sm-4 col-lg-3">
                                <div class="form-group">
                                    <div class="frame-1236">
                                        <input type="text" class="form-control form-select software-developer"
                                            [formControl]="formControlObj" [matAutocomplete]="auto"
                                            placeholder="Choose any one Job Role">
                                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnForRole"
                                            (optionSelected)="onchangeJobRole($event.option.value.Id)">
                                            <mat-option *ngFor="let jobrole of filterJobRole | async" [value]="jobrole">
                                                {{jobrole.JobRole}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-4 col-lg-3">
                                <div class="form-group">
                                    <div class="frame-1237">
                                        <input type="text" class="form-control form-select city"
                                            [formControl]="formControlcity" [matAutocomplete]="autocity"
                                            placeholder="Choose any one City">
                                        <mat-autocomplete #autocity="matAutocomplete" [displayWith]="displayFnForCity"
                                            (optionSelected)="onchangeCity($event.option.value.Id)">
                                            <mat-option *ngFor="let cities of filtercity | async" [value]="cities">
                                                {{cities.CityName}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-4 col-lg-3">
                                <div class="frame-1238">
                                    <input type="text" class="form-control form-select education"
                                        [formControl]="formControlQuli" [matAutocomplete]="autoQuali"
                                        placeholder="Choose any one Education">
                                    <mat-autocomplete #autoQuali="matAutocomplete" [displayWith]="displayFnForQuli"
                                        (optionSelected)="onchangeQualification($event.option.value.Id)">
                                        <mat-option *ngFor="let Quli of filterQuli | async" [value]="Quli">
                                            {{Quli.Qualification}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>

                            <div class="col-sm-4 col-lg-3">
                                <div class="frame-1239">
                                    <input type="text" class="form-control form-select academic-discipline"
                                        [formControl]="formControldiscipline" [matAutocomplete]="autodec"
                                        placeholder="Choose any one Academic Discipline">
                                    <mat-autocomplete #autodec="matAutocomplete" [displayWith]="displayFnFordiscipline"
                                        (optionSelected)="onchangeAcademicDiscipline($event.option.value.Id)">
                                        <mat-option *ngFor="let discipline of filterdiscipline | async"
                                            [value]="discipline">
                                            {{discipline.AcademicDescription}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>

                            <div class="col-sm-4 col-lg-3">
                                <div class="frame-1240">
                                    <input type="text" class="form-control form-select specialization"
                                        [formControl]="formControlspecialization" [matAutocomplete]="autospec"
                                        placeholder="Choose any one Specialization">
                                    <mat-autocomplete #autospec="matAutocomplete"
                                        [displayWith]="displayFnForSpecialization"
                                        (optionSelected)="onchangeSpecialization($event.option.value.Id)">
                                        <mat-option *ngFor="let specialization of filterspecialization | async"
                                            [value]="specialization">
                                            {{specialization.SpecializationDescription}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>

                            <div class="col-sm-4 col-lg-3">
                                <div class="frame-1241">
                                    <input type="text" class="form-control form-select fuction-1"
                                        [formControl]="formControlfunction" [matAutocomplete]="autofunction"
                                        placeholder="Choose any one Fuction">
                                    <mat-autocomplete #autofunction="matAutocomplete"
                                        [displayWith]="displayFnForFunction"
                                        (optionSelected)="onchangeFunction($event.option.value.Id)">
                                        <mat-option *ngFor="let function of filterfunction | async" [value]="function">
                                            {{function.FunctionName}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>

                            <div class="col-sm-4 col-lg-3">
                                <div class="frame-1242">
                                    <input type="text" formControlName="SectionId"
                                        class="form-control form-select section-1" [formControl]="formControlsection"
                                        [matAutocomplete]="autosection" placeholder="Choose any one IndustryType">
                                    <mat-autocomplete #autosection="matAutocomplete" [displayWith]="displayFnForSection"
                                        (optionSelected)="onchangeSection($event.option.value.Id)">
                                        <mat-option *ngFor="let section of filtersection | async" [value]="section">
                                            {{section.IndustryType}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>

                            <div class="col-sm-2 col-lg-3">
                                <div class="row">
                                    <button type="submit" class="filterbtn" (click)="search()">Filter<i
                                            class='bx bx-search'></i></button>
                                    <button type="submit" class="filterbtn2" (click)="removefilter()">Show All<i
                                            class='bx bx-search'></i></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="group-1147" *ngIf="candidatecount">
                <div class="frame-1255">
                    <div class="group-1237">
                        <div class="_98-candidates">{{candidatecount[0].activecandidate}} candidates</div>
                        <!-- <div class="_40-of-98-candidates-shortlist">
              40 of 98 candidates shortlist
            </div> -->
                    </div>
                </div>
            </div>

            <div class="rectangle-1177" *ngIf="candidatsInfo"></div>

            <div class="row" style="margin-top: 323px;margin-left: -176px;">
                <div class="col-lg-2 col-md-12">
                    <form [formGroup]="candidateForm">
                        <input class="form-control gototxt" (keyup)="GoToPage($event)" placeholder="Goto Page"
                            formControlName="PageSearch">
                    </form>
                </div>

                <div class="col-lg-2 col-md-12">
                    <button type="submit" (click)="GOTO()" class="gotobtn">Goto Page<i
                            class='bx bx-search'></i></button>
                </div>
                <div class="col-lg-2 col-md-12">
                    <button type="submit" (click)="Previous()" class="Previous">Previous<i
                            class='bx bx-skip-previous-circle' style="margin-left: 10px;"></i></button>
                </div>
                <div class="col-lg-2 col-md-12">
                    <button type="submit" (click)="Next()" class="Next">Next<i class='bx bx-skip-next-circle'
                            style="margin-left: 10px;"></i></button>
                </div>
                <div class="col-lg-2 col-md-12">
                    <label class="totalRecords">Total Records : {{totalItems}}</label>
                </div>
                <div class="col-lg-2 col-md-12">
                    <label class="totalpage">Total Pages : {{totalpages}}</label>
                </div>
            </div>
            <div class="job-filter-area pt-100">
                <div class="employer-area two pb-100">
                    <div class="row " *ngFor="let BuildResume of candidatsInfo">
                        <div (click)="CardClick(BuildResume)">
                            <div class="employer-item two">
                                <div class="frame-12381">
                                    <div class="frame-1132">
                                        <div class="aditya-krushna">{{BuildResume.FirstName}}</div>

                                        <div class="software-developer2">{{BuildResume.CurrentRole}}</div>
                                    </div>

                                    <div class="frame-12382">
                                        <img class="salary-2" src="../../../../assets/png/salary-2.png" />

                                        <div *ngIf="BuildResume.CityName" class="vadodara-gujarat">
                                            {{BuildResume.CityName}},
                                            {{BuildResume.StateName}}</div>
                                    </div>
                                </div>
                                <div class="line-234 "></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="row">
                    <div class="col-lg-12"></div>
                </div>
            </div>

            <div *ngIf="visible">
                <div class="frame-12434" *ngIf="afterClickData">
                    <div class="frame-12432">
                        <div class="aditya-krushna2">{{afterClickData.FirstName}}</div>
                        <div class="group-1149">
                            <div class="group-1242">
                                <div class="vadodara-gujarat2" *ngIf="afterClickData.CityName">
                                    {{afterClickData.CityName}},
                                    {{afterClickData.StateName}}</div>
                                <img class="salary-8" src="../../../../assets/png/salary-2.png" />
                            </div>
                        </div>

                        <div class="applied-to-software-developer" *ngIf="appliedjob">
                            Applied to {{appliedjob.JobRole}}
                            <div class="line-28"></div>
                            <!-- <p class="personaType">{{jobrole.JobRole.join(', ')}}</p> -->
                        </div>

                    </div>
                    <div class="line-31" *ngIf="appliedjob == undefined"></div>
                    <div class="candidates-qualifications">Candidates qualifications</div>

                    <div
                        class="can-you-describe-your-educational-background-and-the-highest-level-of-education-you-have-completed">
                        Can you describe your educational background and the highest level of
                        education you have completed?
                    </div>

                    <div class="group-1161">
                        <div class="frame-1257" *ngIf="appliedjob">
                            <div class="frame-12433">
                                <div class="graduation">graduation</div>

                                <div class="your-requirement-graduation">
                                    Your requirement: {{ afterClickData.HighestEducation }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="line-29" *ngIf="appliedjob"></div>

                    <div class="line-30" *ngIf="appliedjob == undefined"></div>

                    <div class="candidates-Exp">Candidates Experience</div>
                    <div class="group-1162">
                        <div class="frame-1258" *ngIf="appliedjob">
                            <div class="frame-12435">
                                <div class="Exp">Company Name</div>
                                <div class="your-requirement-Exp">
                                    {{afterClickData.CurrentRoleWithCompanyName}}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="line-31" *ngIf="appliedjob"></div>

                    <div class="line-32" *ngIf="appliedjob == undefined"></div>

                    <div class="candidates-Exp">Candidates Preffered Location</div>
                    <div class="group-1162">
                        <div class="frame-1261" *ngIf="appliedjob">
                            <div class="frame-12435">
                                <div class="Exp">Location</div>
                                <div class="your-requirement-Exp">
                                    {{afterClickData.PreferredLocation}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="rectangle-1186"> </div>
                <div></div>

                <div class="lableName"> <label>ShortList Candidate </label></div>
                <div class="sorhtlistcandidate">

                    <input type="text" class="form-control form-select Cmbforpostjobrole"
                        [formControl]="formControlObjForShortlist" [matAutocomplete]="autocmp"
                        placeholder="Select job number for shortlist candidate">
                    <mat-autocomplete #autocmp="matAutocomplete" [displayWith]="displayFnForRoleForShortlist"
                        (optionSelected)="onchangeJobRoleCompany($event.option.value.PostedJobId)">
                        <mat-option *ngFor="let jobrolecmp of filterJobRoleForShortlist | async" [value]="jobrolecmp">
                            {{jobrolecmp.JobNumber}}
                        </mat-option>
                    </mat-autocomplete>
                </div>

                <div class="interest">
                    <div class="interested">interest?</div>

                    <svg class="rectangle-1165" width="67" height="35" viewBox="0 0 67 35" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.389648 0.818848H66.7826V34.8875H0.389648V0.818848Z" fill="#525252" stroke="#969696"
                            stroke-width="0.681372" />
                    </svg>

                    <div>
                        <div class="rectangle-1163"></div>

                        <div class="rectangle-1164"></div>

                        <div class="yes" (click)="confirmDialog(afterClickData)">yes</div>

                        <div class="no">no</div>

                        <div class="what">?</div>
                    </div>

                </div>

                <div class="rectangle-1188"></div>

                <div class="status">status :-</div>

                <div class="frame-1151" *ngIf="CandidateStatus">
                    <div class="shortlist">{{CandidateStatus[0].CandidateStatusName}}</div>
                </div>

                <div class="apply">
                    <div class="applied-date">
                        applied Date</div>

                    <div class="_29-jun-2022" *ngIf="appliedjob">{{appliedjob.AppliedDate | date : 'dd-MM-yyyy'}}</div>
                </div>
            </div>
        </div>

        <div>
            <div class="card mat-elevation-z6 m-3 w-auto" *ngIf="shortlistcandidate"
                style="margin-top: 121px !important;margin-bottom: 125px !important;">
                <table mat-table [dataSource]="shortlistdataSource" style="width: 99%;margin-left: 10px;">

                    <ng-container matColumnDef="CandidateName">
                        <th mat-header-cell *matHeaderCellDef class="M1"> Candidate Name</th>
                        <td mat-cell *matCellDef="let element" (click)="resume(element)"> {{element.FirstName}}</td>
                    </ng-container>

                    <ng-container matColumnDef="JobRole">
                        <th mat-header-cell *matHeaderCellDef class="M1"> Job Role</th>
                        <td mat-cell *matCellDef="let element" (click)="resume(element)"> {{element.JobRole}} </td>
                    </ng-container>
                    <ng-container matColumnDef="FirstName">
                        <th mat-header-cell *matHeaderCellDef class="M1"> Candidate Status </th>
                        <td mat-cell *matCellDef="let element" (click)="resume(element)">
                            {{element.CandidateStatusName}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="LastName">
                        <th mat-header-cell *matHeaderCellDef class="M1"> Company Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.JobCompanyName}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 15 ,20,25,100]"
                    style="font-size: 17px; color: #26448c;" showFirstLastButtons
                    aria-label="Select page of periodic elements">
                </mat-paginator>
            </div>
        </div>

    </div>
</div>
<div class="footer">
    <!-- Footer -->
    <app-footer-style-two></app-footer-style-two>
</div>