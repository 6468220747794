<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>My Resume</h2>
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                            <!-- <a routerLink="/">Home</a> -->
                        </li>
                        <!-- <li><span>/</span></li> -->
                        <li>Resume</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Dashboard -->
<div class="dashboard-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-12">
                <div class="profile-item">
                    <div *ngIf="getresumeData.Photo">
                        <img [src]="getresumeData.Photo" alt="Dashboard">
                    </div>
                        <div *ngIf="getresumeData.Photo === ''">
                            <img src="../../assets/candidateimg/Male_Colors.jpg" alt="Logo">
                        </div>
                    <!-- <img [src]="getresumeData.Photo" alt="Dashboard"> -->
                    <h2>{{getresumeData.FirstName}} {{getresumeData.LastName}}</h2>
                    <span>{{getresumeData.JobRole}}</span>
                </div>
            </div>
            <div class="col-lg-3 col-md-12">
                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <!-- <a class="nav-link" id="v-pills-basicInfo-tab" data-bs-toggle="pill" role="tab"
                        aria-controls="v-pills-basicInfo" aria-selected="true" [routerLink]="['/candidate/dashboard', userId]"
                        routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                        <i class='bx bx-user'></i>
                        Basic Info</a> -->

                        <a class="nav-link" id="v-pills-basicInfo-tab" data-bs-toggle="pill" role="tab"
                        aria-controls="v-pills-basicInfo" aria-selected="true"
                        [routerLink]="['/editcandidate', userId]" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact:true}">
                        <i class='bx bx-user'></i>
                        Basic Information
                    </a>

                    <!-- <a class="nav-link" id="v-pills-personalinfo-tab" data-bs-toggle="pill" routerLink="personalinfo"
                    role="tab" aria-controls="v-pills-personalinfo" aria-selected="false" routerLinkActive="active">
                    <div class="profile-list">
                        <i class="bx bxs-layer"></i>
                        Personal Information
                    </div>
                </a> -->
                    <a class="nav-link" id="v-pills-education-tab" data-bs-toggle="pill" routerLink="education"
                        role="tab" aria-controls="v-pills-education" aria-selected="false" routerLinkActive="active">
                        <div class="profile-list">
                            <i class="bx bxs-graduation"></i>
                            Education
                        </div>
                    </a>
                    <a class="nav-link" id="v-pills-experience-tab" data-bs-toggle="pill" routerLink="experience"
                        role="tab" aria-controls="v-pills-experience" aria-selected="false" routerLinkActive="active">
                        <div class="profile-list">
                            <i class="bx bxs-briefcase"></i>
                            Experience
                        </div>
                    </a>
                    <a class="nav-link" id="v-pills-projects-tab" data-bs-toggle="pill" routerLink="projects" role="tab"
                        aria-controls="v-pills-projects" aria-selected="false" routerLinkActive="active">
                        <div class="profile-list">
                            <i class='bx bxs-brightness'></i>
                            Achivements
                        </div>
                    </a>
                    <a class="nav-link" id="v-pills-certification-tab" data-bs-toggle="pill" routerLink="certification"
                        role="tab" aria-controls="v-pills-certification" aria-selected="false"
                        routerLinkActive="active">
                        <div class="profile-list">
                            <i class='bx bxs-certification'></i>
                            Certification
                        </div>
                    </a>
                    <a class="nav-link" id="v-pills-certification-tab" data-bs-toggle="pill" routerLink="uploadresume"
                    role="tab" aria-controls="v-pills-certification" aria-selected="false"
                    routerLinkActive="active">
                    <div class="profile-list">
                        <i class="bx bxs-grid"></i>
                        Upload Resume
                    </div>
                </a>

                    <a class="nav-link" id="v-pills-certification-tab" data-bs-toggle="pill" routerLink="function"
                    role="tab" aria-controls="v-pills-certification" aria-selected="false"
                    routerLinkActive="active">
                    <div class="profile-list">
                        <i class='bx bxs-star'></i>
                        Function
                    </div>
                </a>
                <a class="nav-link" id="v-pills-certification-tab" data-bs-toggle="pill" routerLink="section"
                role="tab" aria-controls="v-pills-certification" aria-selected="false"
                routerLinkActive="active">
                <div class="profile-list">
                    <i class="bx bxs-grid"></i>
                    Section
                </div>
            </a>
           
                    <!-- <a class="nav-link" id="v-pills-skills-tab" data-bs-toggle="pill" routerLink="skillsLanguages"
                        role="tab" aria-controls="v-pills-skills" aria-selected="false" routerLinkActive="active">
                        <div class="profile-list">
                            <i class="bx bxs-brain"></i>
                            Skills
                        </div>
                    </a>
                    <a class="nav-link" id="v-pills-languages-tab" data-bs-toggle="pill" routerLink="languages"
                        role="tab" aria-controls="v-pills-languages" aria-selected="false" routerLinkActive="active">
                        <div class="profile-list">
                            <i class="bx bxs-brain"></i>
                            Languages
                        </div>
                    </a>
                    <a class="nav-link" id="v-pills-location-tab" data-bs-toggle="pill" routerLink="preffereflocation"
                        role="tab" aria-controls="v-pills-location" aria-selected="false" routerLinkActive="active">
                        <div class="profile-list">
                            <i class="bx bxs-brain"></i>
                            Preffered Location
                        </div>
                    </a> -->

                    <!-- <a class="nav-link" id="v-pills-location-tab" data-bs-toggle="pill" routerLink="/recommandedjobs" role="tab"
                    aria-controls="v-pills-location" aria-selected="false" routerLinkActive="active">
                    <div class="profile-list">
                        <i class="bx bxs-brain"></i>
                        Recommended Jobs
                        </div>
                </a>
                    <a class="nav-link" id="v-pills-location-tab" data-bs-toggle="pill" routerLink="/bookjobs" role="tab"
                        aria-controls="v-pills-location" aria-selected="false" routerLinkActive="active">
                        <div class="profile-list">
                            <i class="bx bxs-brain"></i>
                            BookedMark Jobs
                        </div>
                    </a> -->
                    <!-- <a class="nav-link" id="v-pills-password-tab" data-bs-toggle="pill" routerLink="changepassword"
                        role="tab" aria-controls="v-pills-password" aria-selected="false" routerLinkActive="active">
                        <div class="profile-list">
                            <i class="bx bxs-brain"></i>
                            Change Password
                        </div>
                    </a> -->
                    <!-- <a class="nav-link" id="v-pills-SC-tab" data-bs-toggle="pill" routerLink="/employers" role="tab"
                        aria-controls="v-pills-SC" aria-selected="false" routerLinkActive="active">
                        <div class="profile-list">
                            <i class="bx bxs-brain"></i>
                            ShortListed Candidate
                        </div>
                    </a> -->
                    <!-- <a routerLink="/single-resume" class="nav-link">
                        <div class="profile-list"><i class='bx bx-note'></i> My Resume</div>
                    </a> -->
                    <!-- <a class="nav-link logout">
                        <div class="profile-list" (click)="onLogout($event)"><i class='bx bx-log-out'></i> Logout</div>
                    </a> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="tab-content" id="v-pills-tabContent">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Dashboard -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->