import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Route, Router } from "@angular/router";
import userToken from "../../config/userToken";
import { debugOutputAstAsTypeScript } from '@angular/compiler';
import { SharedService } from 'src/app/shared.service';
import { GlobalService } from 'src/app/global.service';
import { UserType } from 'src/app/interface';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '../../guards/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})

export class ForgotPasswordComponent implements OnInit, OnDestroy {

  addEditForm!: FormGroup;
  OTP!: boolean;
  User!: boolean;
  Userbtn !: boolean;
  ChangePsw: boolean = false;
  submitpassword: boolean = false;

  constructor(private service: SharedService<any>,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
  ) {

  }

  ngOnInit(): void {
    this.addEditForm = this.formBuilder.group({
      Id: ['', null],
      Email: ['', null],
      OTP: ['', null],
      newpass: ['', [Validators.maxLength(20), Validators.nullValidator,Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
      CFpass: ['', [Validators.maxLength(20), Validators.nullValidator,Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
    });
    this.OTP = false;
    this.User = true;
    this.Userbtn = true;
  }
  get fpass(): { [key: string]: AbstractControl } {
    return this.addEditForm.controls;
  }
  Submit() {
    if (this.addEditForm.invalid) {
      this.toastr.error("Enter all required data");
    }
    else {
      const forgotpassword = this.addEditForm.getRawValue();
      var UserName = forgotpassword.Email;
      this.service.Post('SendOTPForForgetPassword?loginName=' + UserName, null).subscribe({
        next: (res) => {
          this.toastr.success(res);
          this.Userbtn = false;
          this.OTP = true;
        },
      })
    }
  }


  SendOtp() {
    if (this.addEditForm.invalid) {
      this.toastr.error("Enter all required data");
    }
    else {
      const forgotpassword = this.addEditForm.getRawValue();
      var UserName = forgotpassword.Email;
      var OTP = forgotpassword.OTP;
      this.service.Post('VerifyOTPCode?loginName=' + UserName + '&OTPCode=' + OTP, null).subscribe({
        next: (res) => {
          this.toastr.success(res);
          if (res == 'OTP Code Verify Succesfully') {
            this.ChangePsw = true;
            this.OTP = false;
          }},
      })
    }
  }

  changepsw() {
    this.submitpassword = true
    const forgotpassword = this.addEditForm.getRawValue();
    var UserName = forgotpassword.Email;
    if (this.addEditForm.value.newpass == this.addEditForm.value.CFpass) {
      this.service.Put('EmployeeChangePass/UserChagePassword?username=' + UserName + '&password=' + this.addEditForm.value.newpass, null).subscribe(
        (res) => {
          this.toastr.success(res);
          this.router.navigate(['/login']);
        },
      )
    }
    else {
      alert("New Password And Confirm Password Are Not Same");
    }

  }

  show(a, b) {
    var x = document.getElementById(a);
    var c = document.getElementById(b);
    if (x.getAttribute('type') == "password") {
      c.removeAttribute("class");
      c.setAttribute("class", "bi-eye");
      x.removeAttribute("type");
      x.setAttribute("type", "text");
    } else {
      x.removeAttribute("type");
      x.setAttribute('type', 'password');
      c.removeAttribute("class");
      c.setAttribute("class", "bi-eye-slash");
    }
  }

  show1(a, b) {
    var x = document.getElementById(a);
    var c = document.getElementById(b);
    if (x.getAttribute('type') == "password") {
      c.removeAttribute("class");
      c.setAttribute("class", "bi-eye");
      x.removeAttribute("type");
      x.setAttribute("type", "text");
    } else {
      x.removeAttribute("type");
      x.setAttribute('type', 'password');
      c.removeAttribute("class");
      c.setAttribute("class", "bi-eye-slash");
    }
  }

  show2(a, b) {
    var x = document.getElementById(a);
    var c = document.getElementById(b);
    if (x.getAttribute('type') == "password") {
      c.removeAttribute("class");
      c.setAttribute("class", "bi-eye");
      x.removeAttribute("type");
      x.setAttribute("type", "text");
    } else {
      x.removeAttribute("type");
      x.setAttribute('type', 'password');
      c.removeAttribute("class");
      c.setAttribute("class", "bi-eye-slash");
    }
  }

  get newpass() {
    return this.addEditForm.get('newpass');
  }
  ngOnDestroy(): void {

  }
}
