<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <!-- <link rel="stylesheet" href="./style.css"> -->

  <title>Document</title>
</head>

<body>
  <div class="register-desktop">
    <img class="screencapture-jobonfingertip-2023-06-26-14-40-12-1"
      src="assets\images\screencapture-jobonfingertip-2023-06-26-14-40-12-1.png" />

    <div class="rectangle-1268"></div>

    <div class="mask-group">
      <div class="rectangle-1267"></div>

      <img class="mesmerizing-scenery-green-mountains-with-cloudy-sky-surface-1"
        src="assets\images\mesmerizing-scenery-green-mountains-with-cloudy-sky-surface-1.png" />
    </div>

    <div class="rectangle-12672"></div>

    <div class="rectangle-1278"></div>
    
    <div class="rectangle-1279"><i matTooltip="Close" style="font-size: 35px;margin-left: 434px;color: red;"  
      class='bx bx-x Clear' (click)="close()"></i></div>

    
    
    <div class="it-only-takes-a-couple-of-minutes-to-get-started">
     
      It only takes a couple of minutes to get started!
    </div>

    <div class="create-an-account">Create an Account</div>
    <form [formGroup]="candidateform">

      <div class="full-name">First Name *</div>
      <div class="form-group">

        <input type="text" id="FirstName" class="form-control rectangle-1269 enter-your-full-name "
          placeholder="enter your first name" formControlName="FirstName" (keypress)="keyPressAlpha($event)"
          [ngClass]="{ 'is-invalid': submitted && f.FirstName.errors }" maxlength="50">
        <div *ngIf="submitted && f.FirstName.errors" class="invalid-feedback">
          <div *ngIf="f.FirstName.errors.required">First Name is required</div>
        </div>
      </div>

      <div class="last-name">Last Name *</div>
      <div class="form-group">
        <input type="text" id="LastName" class="form-control rectangle-lastname enter-your-last-name"
          placeholder="enter your last name" formControlName="LastName" (keypress)="keyPressAlpha($event)"
          [ngClass]="{ 'is-invalid': submitted && f.LastName.errors }" maxlength="50">
        <div *ngIf="submitted && f.LastName.errors" class="invalid-feedback">
          <div *ngIf="f.LastName.errors.required">Last Name is required</div>
        </div>
      </div>


      <div class="email-i-d">Email *</div>
      <div class="form-group">
        <input type="email" id="Email" class="form-control rectangle-1270 enter-your-email-i-d"
          placeholder="xyz@gmail.com" formControlName="Email" [ngClass]="{ 'is-invalid': submitted && f.Email.errors }">
        <div *ngIf="submitted && f.Email.errors" class="invalid-feedback">
          <div *ngIf="submitted && f.Email.errors">Email is required</div>
        </div>
      </div>

      <div class="mobile-number">Mobile Number *</div>
      <div class="form-group">
        <select class="form-control form-select rectangle-12722 _91" [formControl]="formControlforcode"
          [ngClass]="{ 'is-invalid': submitted && f.CountryISDCode.errors }"
          (selectionChange)="onchangeCountryCode($event.value)">
          <option value="" disabled selected>Choose any one</option>
          <option *ngFor="let countries of allcode" [value]="countries">
            {{countries.ISDCode}}
          </option>
        </select>
        <div *ngIf="submitted && f.CountryISDCode.errors" class="invalid-feedback">
          <div *ngIf="f.CountryISDCode.errors.required">Phone number is required</div>
        </div>
      </div>

      <div class="form-group">
        <input maxlength="10" type="tel" class="form-control rectangle-12732 enter-your-mobile-number"
          placeholder="XXXXX-XXXXX " formControlName="MobileNo" (keypress)="keyPressAlphaNumeric($event)"
          [ngClass]="{ 'is-invalid': submitted && f.MobileNo.errors }">
        <div *ngIf="submitted && f.MobileNo.errors" class="invalid-feedback">
          <div *ngIf="f.MobileNo.errors.required">Phone number is required</div>
        </div>
      </div>
      
      <div class="Quali">Qualification *</div>
      <div class="form-group">
        <input type="text" class="form-control form-select rectangle-Quali" matInput [formControl]="formControlQuli"
          [matAutocomplete]="autoQuali" [ngClass]="{ 'is-invalid': submitted && f.QualificationId.errors }">
        <mat-autocomplete #autoQuali="matAutocomplete" [displayWith]="displayFnForQuli"
          (optionSelected)="onchangeQualification($event.option.value.Id)">
          <mat-option *ngFor="let Quli of filterQuli | async" [value]="Quli">
            {{Quli.Qualification}}
          </mat-option>
        </mat-autocomplete>
        <div class="invalid-feedback" *ngIf="submitted && f.QualificationId.errors">
          <div *ngIf="f.QualificationId.errors.required">
            Qualification is required
          </div>
        </div>
      </div>

      <div class="AD">Academic Discipline *</div>
      <div class="form-group">
        <input type="text" class="form-control form-select rectangle-AD" matInput [formControl]="formControldiscipline"
          [matAutocomplete]="autodec" [ngClass]="{ 'is-invalid': submitted && f.AcademicDisciplineId.errors }">
        <mat-autocomplete #autodec="matAutocomplete" [displayWith]="displayFnFordiscipline"
          (optionSelected)="onchangeAcademicDiscipline($event.option.value.Id)">
          <mat-option class="autooption" *ngFor="let discipline of filterdiscipline | async" [value]="discipline">
            {{discipline.AcademicDescription}}
          </mat-option>
        </mat-autocomplete>
        <div class="invalid-feedback" *ngIf="submitted && f.AcademicDisciplineId.errors">
          <div *ngIf="f.AcademicDisciplineId.errors.required">
            Academic Discipline is required
          </div>
        </div>
      </div>

      <div class="SP">Specialization *</div>
      <div class="form-group">
        <input type="text" class="form-control form-select rectangle-SP" matInput
          [formControl]="formControlspecialization" [matAutocomplete]="autospec"
          [ngClass]="{ 'is-invalid': submitted && f.SpecializationId.errors }">
        <mat-autocomplete #autospec="matAutocomplete" [displayWith]="displayFnForSpecialization"
          (optionSelected)="onchangeSpecialization($event.option.value.Id)">
          <mat-option *ngFor="let specialization of filterspecialization | async" [value]="specialization">
            {{specialization.SpecializationDescription}}
          </mat-option>
        </mat-autocomplete>
        <div class="invalid-feedback" *ngIf="submitted && f.SpecializationId.errors">
          <div *ngIf="f.SpecializationId.errors.required">
            Specialization Discipline is required
          </div>
        </div>
      </div>

      <div class="PP">Profile Picture</div>
      <div class=" rectangle-1274">
        <input (change)="Uploadcompanylogo($event)" type="file" class="input file">
        <svg class="system-uicons-upload-alt" width="27" height="27" viewBox="0 0 27 27" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M18.5713 9.59262L13.528 4.52148L8.4289 9.59262M13.528 4.54811V19.7349M4.62549 15.2977V21.0027C4.62549 21.6752 4.89263 22.3201 5.36815 22.7956C5.84367 23.2711 6.48861 23.5382 7.16109 23.5382H19.8391C20.5116 23.5382 21.1565 23.2711 21.6321 22.7956C22.1076 22.3201 22.3747 21.6752 22.3747 21.0027V15.2977"
            stroke="#0C1153" stroke-width="1.85746" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>

      <div class="rectangle-1275" (click)="CandidateForm()"></div>
      <div class="register-now" (click)="CandidateForm()">Register Now</div>
    </form>

    <img class="whats-app-image-2023-06-29-at-12-04-1" src="assets\images\whats-app-image-2023-06-29-at-12-04-1.png" />
  </div>

</body>

</html>