<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<div class="startposition" id="targetRed" style=" background-color: #f4f4f4; padding-bottom: 27px;">
    <div class="job-filter-area pt-100">
        <div class="container">
            <div class="row">
                <div class="col-sm-2 col-lg-1">
                    <div class="filter-by">Filter By</div>
                </div>

                <!-- <div class="col-sm-3 col-lg-3">
                    <input type="text" class="form-select form-control  mat-btn4" placeholder="JobRole"
                        [formControl]="jobroleControl" [matAutocomplete]="auto1">
                    <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayFnForJobRole"
                        (optionSelected)="onchangeJobRole($event.option.value.Id)">
                        <mat-option class="autooption" *ngFor="let customer of filterJobroles | async"
                            [value]="customer">
                            {{customer.JobRole}}
                        </mat-option>
                    </mat-autocomplete>
                </div> -->

                <div class="col-sm-3 col-lg-3">
                    <input type="text" class="form-control form-select mat-btn4" placeholder="Education"
                        [formControl]="QualificationsControl" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnForQualification"
                        (optionSelected)="onchangeQualification($event.option.value.Id)">
                        <mat-option class="autooption" *ngFor="let tanker of filterQualifications | async"
                            [value]="tanker">
                            {{tanker.Qualification}}
                        </mat-option>
                    </mat-autocomplete>
                </div>

                <!-- <div class="col-sm-3 col-lg-3">
                    <input type="text" class="form-control form-select mat-btn4" placeholder="Location"
                        [formControl]="citiesControl" [matAutocomplete]="autocity">
                    <mat-autocomplete #autocity="matAutocomplete" [displayWith]="displayFnForCity"
                        (optionSelected)="onchangeCity($event.option.value.Id)">
                        <mat-option class="autooption" *ngFor="let city of filtercities | async" [value]="city">
                            {{city.CityName}}
                        </mat-option>
                    </mat-autocomplete>
                </div> -->

                <div class="col-sm-2 col-lg-2">
                    <div class="frame-12"  (click)="applyselection()">
                        <div class="find-jobs">Find Jobs</div>
                    </div>
                </div>

                <div class="col-sm-2 col-lg-2"> <div class="frame-13" (click)="removeselection()">
                    <div class="showall">Show All</div>
                </div></div>
            </div>
        </div>

        <div class="employer-area two" style="padding-bottom:15px ;">
            <div *ngIf="obs">
                <div class="container">
                    <div class="row cardtop" >
                        <div class="col-lg-4 col-md-6" >
                        <div (click)="CardClick(jobpost)" *ngFor="let jobpost of obs ">
                            <div class="employer-item" id="posit" [class.bg]="jobpost.Id === afterClickData.Id">
                                <div class="col-lg-12">
                                    <div class="row" style="padding-bottom: 15px;" >
                                        <div class="col-lg-8">
                                            <span class="JobRole">{{jobpost.JobRole}}</span>
                                        </div>
                                        <div class="col-lg-4 col-md-3 col-sm-3">
                                            <img [src]="jobpost.Logo" style="max-width: 93px;
                                            float: right;
                                            height: 25px;">
                                        </div>
                                    </div>

                                    <div style="
                                     padding-bottom: 6px;">
                                    <div *ngIf="jobpost.IsDisClose == false || jobpost.JobForName == null">
                                        <span class="JobFor">Keshav Encon Private Limited</span>
                                    </div>
                                    <div *ngIf="jobpost.IsDisClose == true ">
                                        <span class="JobFor">{{jobpost.JobForName}}</span>
                                    </div>
                                </div>
                                    <div class="frame-1359" style="padding-top: 15px;">
                                        <div class="frame-1358">
                                            <svg class="group-1371" width="14" height="19" viewBox="0 0 14 19"
                                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M6.60859 0.0390625C2.95499 0.0390625 0 2.85973 0 6.34727C0 11.0784 6.60859 18.0625 6.60859 18.0625C6.60859 18.0625 13.2172 11.0784 13.2172 6.34727C13.2172 2.85973 10.2622 0.0390625 6.60859 0.0390625ZM1.88817 6.34727C1.88817 3.86003 4.00292 1.84141 6.60859 1.84141C9.21427 1.84141 11.329 3.86003 11.329 6.34727C11.329 8.94264 8.61005 12.8267 6.60859 15.2508C4.6449 12.8447 1.88817 8.91561 1.88817 6.34727Z"
                                                    fill="black" />
                                                <path
                                                    d="M6.61021 8.59961C7.91372 8.59961 8.97042 7.59094 8.97042 6.34668C8.97042 5.10242 7.91372 4.09375 6.61021 4.09375C5.3067 4.09375 4.25 5.10242 4.25 6.34668C4.25 7.59094 5.3067 8.59961 6.61021 8.59961Z"
                                                    fill="black" />
                                            </svg>
                                            <div class="cityName"> {{jobpost.JobLocationCity}}</div>
                                        </div>
                                        <div class="frame-1357">
                                            <svg class="vector6" width="22" height="19" viewBox="0 0 22 19"
                                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M7.70108 0.0390625V1.09926H2.40007C1.2412 1.09926 0.279663 2.0608 0.279663 3.21967V7.46048V11.7013V15.9421C0.279663 17.101 1.2412 18.0625 2.40007 18.0625H19.3633C20.5222 18.0625 21.4837 17.101 21.4837 15.9421V11.7013V7.46048V3.21967C21.4837 2.0608 20.5222 1.09926 19.3633 1.09926H14.0623V0.0390625H7.70108ZM2.40007 3.21967H19.3633V7.46048V11.7013H2.40007V7.46048V3.21967ZM10.8817 7.46048C10.6005 7.46048 10.3308 7.57218 10.132 7.771C9.93318 7.96983 9.82148 8.2395 9.82148 8.52068C9.82148 8.80186 9.93318 9.07153 10.132 9.27036C10.3308 9.46918 10.6005 9.58088 10.8817 9.58088C11.1629 9.58088 11.4325 9.46918 11.6314 9.27036C11.8302 9.07153 11.9419 8.80186 11.9419 8.52068C11.9419 8.2395 11.8302 7.96983 11.6314 7.771C11.4325 7.57218 11.1629 7.46048 10.8817 7.46048ZM2.40007 13.8217H19.3633V15.9421H2.40007V13.8217Z"
                                                    fill="black" />
                                            </svg>

                                            <div class="_2-3-years">{{jobpost.MinYearExp}} Years</div>
                                        </div>
                                    </div>

                                    <div class="frame-1360" style="padding-top:25px;">
                                        <svg class="vector7" width="19" height="19" viewBox="0 0 19 19" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M9.01172 0.101562C4.04536 0.101562 0 4.14692 0 9.11328C0 14.0796 4.04536 18.125 9.01172 18.125C13.9781 18.125 18.0234 14.0796 18.0234 9.11328C18.0234 4.14692 13.9781 0.101562 9.01172 0.101562ZM9.01172 1.90391C13.004 1.90391 16.2211 5.12098 16.2211 9.11328C16.2211 13.1056 13.004 16.3227 9.01172 16.3227C5.01942 16.3227 1.80234 13.1056 1.80234 9.11328C1.80234 5.12098 5.01942 1.90391 9.01172 1.90391ZM8.11055 3.70625V9.48642L11.9792 13.3551L13.2536 12.0808L9.91289 8.74014V3.70625H8.11055Z"
                                                fill="black" />
                                        </svg>

                                        <div class="joining-period-15-days">Joining Period :
                                            {{jobpost.NoticePerioddays}}</div>
                                    </div>

                                    <div class="row">
                                        <div class="col-lg-10">
                                    <div class="frame-1361">
                                        <svg class="vector8" width="31" height="19" viewBox="0 0 31 19" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M6.43694 13.0379V9.17578H10.2991V6.601H6.43694V2.73884H3.86216V6.601H0V9.17578H3.86216V13.0379H6.43694ZM15.4487 11.4287C12.4362 11.4287 6.43694 12.935 6.43694 15.9346V18.1875H24.4604V15.9346C24.4604 12.935 18.4611 11.4287 15.4487 11.4287ZM9.44943 15.6127C10.5308 14.866 13.1442 14.0035 15.4487 14.0035C17.7531 14.0035 20.3665 14.866 21.4479 15.6127H9.44943ZM15.4487 9.17578C17.9333 9.17578 19.9545 7.15458 19.9545 4.66992C19.9545 2.18526 17.9333 0.164062 15.4487 0.164062C12.964 0.164062 10.9428 2.18526 10.9428 4.66992C10.9428 7.15458 12.964 9.17578 15.4487 9.17578ZM15.4487 2.73884C16.5172 2.73884 17.3797 3.60139 17.3797 4.66992C17.3797 5.73845 16.5172 6.601 15.4487 6.601C14.3801 6.601 13.5176 5.73845 13.5176 4.66992C13.5176 3.60139 14.3801 2.73884 15.4487 2.73884ZM21.8856 9.17578C24.3703 9.17578 26.3915 7.15458 26.3915 4.66992C26.3915 2.18526 24.3703 0.164062 21.8856 0.164062C21.5766 0.164062 21.2677 0.18981 20.9716 0.25418C21.95 1.46433 22.5293 2.99632 22.5293 4.66992C22.5293 6.34353 21.9242 7.86265 20.9458 9.07279C21.2548 9.13716 21.5638 9.17578 21.8856 9.17578ZM24.8723 11.7763C26.1597 12.8191 27.0352 14.1837 27.0352 15.9346V18.1875H30.8973V15.9346C30.8973 13.7589 27.7561 12.3814 24.8723 11.7763Z"
                                                fill="black" />
                                        </svg>
                                        <div class="openings-10">Openings: {{jobpost.JobOpening}}</div>
                                    </div>
                                </div>
                                
                                <div class="col-lg-2  col-md-2 col-sm-2">
                                    <div style="padding-top: 25px;" class="bookmarklogo" matTooltip="BookMark Job" (click)="BookMarked(jobpost)">
                                        <svg class="frame-1678" width="13" height="21" viewBox="0 0 13 21" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.4543 0H1.74238C0.784072 0 0 1.0335 0 2.29667V20.67L6.09834 17.225L12.1967 20.67V2.29667C12.1967 1.0335 11.4126 0 10.4543 0ZM10.4543 17.225L6.09834 14.7216L1.74238 17.225V2.29667H10.4543V17.225Z"
                                                fill="#888888" />
                                        </svg>
                                    </div>
                                </div>
                                </div>
                                </div> 
                            </div>
                        </div>
                    </div>

                        <div class="col-lg-8">
                            <div *ngIf="this.visible " style="background: #FFFFFF;
                            border-radius: 5px;
                            border-style: solid;
                            border-color: #bdbdbd;
                            border-width: 1px;
                           ">
                                <div class="afterClickdate">
                                    <!-- <span class="v42_1225">{{afterClickData.JobRole}}</span>
                                    <div *ngIf="afterClickData.IsDisClose == false || afterClickData.JobForName == null">
                                        <div class="v42_1226">Keshav Encon Private Limited</div>
                                    </div>
                                    <div *ngIf="afterClickData.IsDisClose == true">
                                        <div class="v42_1226">{{afterClickData.JobForName}}</div>
                                    </div>
                                    <img class="images-3-1" *ngIf="afterClickData.Logo" [src]="afterClickData.Logo" /> -->

                                    <div class="row">
                                        <div class="col-lg-8">
                                    <span class="v42_1225">{{afterClickData.JobRole}}</span>
                                    <div *ngIf="afterClickData.IsDisClose == false || afterClickData.JobForName == null">
                                        <div class="v42_1226">Keshav Encon Private Limited</div>
                                    </div>
                                    <div *ngIf="afterClickData.IsDisClose == true">
                                        <div class="v42_1226">{{afterClickData.JobForName}}</div>
                                    </div>
                                </div>
                                
                                <div class="col-lg-4 col-md-3 col-sm-3">
                                    <img class="images-3-1" *ngIf="afterClickData.Logo" [src]="afterClickData.Logo" />
                                </div>
                            </div>
                                    <div class="rectangle-1414">
                                        <div class="frame-1368">
                                            <svg class="group-13712" width="14" height="20" viewBox="0 0 14 20" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M6.60859 0.988281C2.95499 0.988281 0 3.80895 0 7.29648C0 12.0276 6.60859 19.0117 6.60859 19.0117C6.60859 19.0117 13.2172 12.0276 13.2172 7.29648C13.2172 3.80895 10.2622 0.988281 6.60859 0.988281ZM1.88817 7.29648C1.88817 4.80925 4.00292 2.79062 6.60859 2.79062C9.21427 2.79062 11.329 4.80925 11.329 7.29648C11.329 9.89186 8.61005 13.7759 6.60859 16.2001C4.6449 13.7939 1.88817 9.86482 1.88817 7.29648Z"
                                                    fill="#6D6D6D" />
                                                <path
                                                    d="M6.61033 9.54883C7.91384 9.54883 8.97055 8.54016 8.97055 7.2959C8.97055 6.05164 7.91384 5.04297 6.61033 5.04297C5.30682 5.04297 4.25012 6.05164 4.25012 7.2959C4.25012 8.54016 5.30682 9.54883 6.61033 9.54883Z"
                                                    fill="#6D6D6D" />
                                            </svg>
            
                                            <div class="vadodara2">{{afterClickData.JobLocationCity}}</div>
                                            <div class="V1"></div>
            
                                            <div class="frame-1372">
                                                <div class="frame-1371">
                                                    <img class="money-2-1" src="../../../../assets/images/money-2-1.png" />
                                                    <div class="_7-2-8-2-lakh-py">
                                                        {{afterClickData.MinSalary}}-{{afterClickData.MaxSalary}} Lakh/PY</div>
                                                </div>
                                            </div>
            
                                        </div>
            
                                        <div class="frame-1369">
                                            <svg class="vector10" width="22" height="20" viewBox="0 0 22 20" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M7.42142 0.988281V2.04848H2.1204C0.961537 2.04848 0 3.01002 0 4.16889V8.4097V12.6505V16.8913C0 18.0502 0.961537 19.0117 2.1204 19.0117H19.0836C20.2425 19.0117 21.204 18.0502 21.204 16.8913V12.6505V8.4097V4.16889C21.204 3.01002 20.2425 2.04848 19.0836 2.04848H13.7826V0.988281H7.42142ZM2.1204 4.16889H19.0836V8.4097V12.6505H2.1204V8.4097V4.16889ZM10.602 8.4097C10.3208 8.4097 10.0512 8.5214 9.85235 8.72022C9.65352 8.91905 9.54182 9.18872 9.54182 9.4699C9.54182 9.75108 9.65352 10.0207 9.85235 10.2196C10.0512 10.4184 10.3208 10.5301 10.602 10.5301C10.8832 10.5301 11.1529 10.4184 11.3517 10.2196C11.5505 10.0207 11.6622 9.75108 11.6622 9.4699C11.6622 9.18872 11.5505 8.91905 11.3517 8.72022C11.1529 8.5214 10.8832 8.4097 10.602 8.4097ZM2.1204 14.7709H19.0836V16.8913H2.1204V14.7709Z"
                                                    fill="#6D6D6D" />
                                            </svg>
            
                                            <div class="_2-3-years2">{{afterClickData.MinYearExp}} Years</div>
                                        </div>
            
                                        <div class="frame-1375">
                                            <div class="frame-1374">
                                                <svg class="vector13" width="19" height="19" viewBox="0 0 19 19" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M9.01172 0C4.04536 0 0 4.04536 0 9.01172C0 13.9781 4.04536 18.0234 9.01172 18.0234C13.9781 18.0234 18.0234 13.9781 18.0234 9.01172C18.0234 4.04536 13.9781 0 9.01172 0ZM9.01172 1.80234C13.004 1.80234 16.2211 5.01942 16.2211 9.01172C16.2211 13.004 13.004 16.2211 9.01172 16.2211C5.01942 16.2211 1.80234 13.004 1.80234 9.01172C1.80234 5.01942 5.01942 1.80234 9.01172 1.80234ZM8.11055 3.60469V9.38486L11.9792 13.2536L13.2536 11.9792L9.91289 8.63858V3.60469H8.11055Z"
                                                        fill="#6D6D6D" />
                                                </svg>
            
                                                <div class="joining-period-15-days2">
                                                    Joining Period : {{afterClickData.NoticePerioddays}}
                                                </div>
                                            </div>
                                            <div class="frame-1373">
                                                <svg class="vector14" width="31" height="19" viewBox="0 0 31 19" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M6.43688 12.8739V9.01172H10.299V6.43694H6.43688V2.57478H3.8621V6.43694H-6.10352e-05V9.01172H3.8621V12.8739H6.43688ZM15.4486 11.2646C12.4361 11.2646 6.43688 12.7709 6.43688 15.7705V18.0234H24.4603V15.7705C24.4603 12.7709 18.4611 11.2646 15.4486 11.2646ZM9.44937 15.4487C10.5308 14.702 13.1442 13.8394 15.4486 13.8394C17.753 13.8394 20.3664 14.702 21.4478 15.4487H9.44937ZM15.4486 9.01172C17.9333 9.01172 19.9545 6.99052 19.9545 4.50586C19.9545 2.0212 17.9333 0 15.4486 0C12.9639 0 10.9427 2.0212 10.9427 4.50586C10.9427 6.99052 12.9639 9.01172 15.4486 9.01172ZM15.4486 2.57478C16.5171 2.57478 17.3797 3.43733 17.3797 4.50586C17.3797 5.57439 16.5171 6.43694 15.4486 6.43694C14.3801 6.43694 13.5175 5.57439 13.5175 4.50586C13.5175 3.43733 14.3801 2.57478 15.4486 2.57478ZM21.8855 9.01172C24.3702 9.01172 26.3914 6.99052 26.3914 4.50586C26.3914 2.0212 24.3702 0 21.8855 0C21.5766 0 21.2676 0.025748 20.9715 0.0901174C21.9499 1.30026 22.5292 2.83225 22.5292 4.50586C22.5292 6.17946 21.9242 7.69858 20.9457 8.90873C21.2547 8.9731 21.5637 9.01172 21.8855 9.01172ZM24.8723 11.6122C26.1597 12.655 27.0351 14.0197 27.0351 15.7705V18.0234H30.8973V15.7705C30.8973 13.5948 27.756 12.2173 24.8723 11.6122Z"
                                                        fill="#6D6D6D" />
                                                </svg>
            
                                                <div class="openings-102">Openings : {{afterClickData.JobOpening}}</div>
                                            </div>
                                        </div>
                                    </div>
            
                                    <div class="frame-1626">
                                        <div class="posted">Posted: <span class="_8-days-ago"> {{afterClickData.PostedDays}}</span>
                                        </div>
                                        <button class="rectangle-1415" matTooltip="Apply Job"
                                            (click)="ApplyNowClick(afterClickData.Id)">Apply</button>
                                    </div>
                                    <div class="job-details">Job Details</div>
                                    <div class="skill-sets">Skill sets</div>
                                    <div class="description">
                                        <ul>
                                            <li style="padding-bottom: 5px;">
                                                {{afterClickData.JobDescription}}
                                            </li>
            
                                            <li>
                                                <div class="employment-types-full-time">Employment Types :
                                                    {{afterClickData.EmploymentType}}</div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="educational-requirement">Educational requirement</div>
            
                                    <div class="graduation-bachelor-of-commerce">
                                        <ul>
                                            <li style="padding-bottom: 13px;"> {{afterClickData.Qualification}} <span>/
                                                    {{afterClickData.AcademicDescription}}</span>
                                            </li>
            
                                            <!-- <li style="padding-bottom: 13px;"> Minimum of {{afterClickData.MinYearExp}} years of
                                                experience in {{afterClickData.PostJobSpecializationDescription}}
                                                {{afterClickData.SpecializationDescription}}
                                            </li> -->
                                        </ul>
                                    </div>

                                    <div class="educational-requirement" style="padding-top: 0px;">Experience requirement</div>
            
                                    <div class="graduation-bachelor-of-commerce">
                                        <ul>
                                           
                                            <li style="padding-bottom: 13px;"> Minimum of {{afterClickData.MinYearExp}} years of
                                                experience in {{afterClickData.PostJobSpecializationDescription}}
                                                {{afterClickData.SpecializationDescription}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
            </div>

            

            </div>
                </div>
        </div>

        <div *ngIf="!visible">
           <div class="nodata"> No Recommended Jobs </div> 

        </div>
    </div>
<!--     
    <div style="margin-left: 529px; padding-bottom: 13px;">
        <ngb-pagination [collectionSize]="totalItems" [(page)]="page" [maxSize]="pagesize" [boundaryLinks]="true"
            (pageChange)="pageChanged(page)" [rotate]="false" [ellipses]="true">
        </ngb-pagination>
    </div> -->
    <div class="container">
        <div class="row">
            <div class="col-lg-1 col-md-12 wid">
              
            </div>
            <div class="col-lg-3 col-md-12 wid">
               
                <form [formGroup]="addEditForm">
                    <div class="row"> 
                        <div class="col-lg-3 col-md-12 wid">
                    <input class="form-control gototxt" (keyup)="GoToPage($event)" placeholder="Goto Page"
                      formControlName="PageSearch">
                      </div>
                      <div class="col-lg-6 col-md-12 wid">
                      <button matTooltip="Click here for go to Page" type="submit" (click)="GOTO()" class="gotobtn">Goto Page</button>
                    </div>
                    </div>
                    </form>
               
            </div>
            <div class="col-lg-2 col-md-12 wid">
              <button type="submit" (click)="Previous()" class="Previous">Previous<i class='bx bx-skip-previous-circle'
                  style="margin-left: 10px;"></i></button>
            </div>
            <div class="col-lg-2 col-md-12 wid">
              <button type="submit" (click)="Next()" class="Next">Next<i class='bx bx-skip-next-circle'
                  style="margin-left: 10px;"></i></button>
            </div>
            <div class="col-lg-2 col-md-12 wid">
              <label class="totalRecords">Total Records : {{totalItems}}</label>
            </div>
            <div class="col-lg-2 col-md-12 wid">
              <label class="totalpages">Total Pages : {{totalpages}}</label>
            </div>
          </div>
        </div>
    </div>

       
        <!-- Footer -->
        <app-footer-style-two></app-footer-style-two>
        <!-- End Footer -->