import { ViewportScroller } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { GlobalService } from 'src/app/global.service';
import { Bookmark, JobRole, Qualification } from 'src/app/interface';
import { SharedService } from 'src/app/shared.service';

@Component({
    selector: 'app-recommandedjobs',
    templateUrl: './Recommanded-jobs.component.html',
    styleUrls: ['./Recommanded-jobs.component.scss']
})
export class RecommandedjobsComponent implements OnInit, OnDestroy {

    obs: any;
    jobPostsInfo = [];
    datasource: any;
    bookmark: Bookmark;
    page: number = 1;
    pagesize: number = 5;
    totalItems: number = 0;
    datapageInfo: any;
    visible: boolean = false
    afterClickData: any = null;

    allJobRole !: JobRole[];
    filterJobroles?: Observable<JobRole[]>;
    jobroleControl = new FormControl();
    JobRole: number = 0;

    allQualification!: Qualification[];
    filterQualifications?: Observable<Qualification[]>;
    QualificationsControl = new FormControl();
    Education: number = 0;
    totalpages: number;
    addEditForm!: FormGroup;

    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private router: Router,
        private Service: SharedService<any>,
        private Global: GlobalService,
        private scroller: ViewportScroller,
    ) { }

    ngOnInit(): void {
        this.addEditForm = this.formBuilder.group({
            PageSearch: [null, Validators.nullValidator],
        })
        // this.Service.Get('JobRole/GetJobRole?Id=0', null).subscribe(data => {
        //     if (data != null) {
        //         this.allJobRole = data;
        //         this.filterJobroles = this.jobroleControl.valueChanges.pipe(
        //             startWith<string | JobRole>(''),
        //             map(value => typeof value === 'string' ? value : value.JobRole),
        //             map(JobRole => JobRole ? this._filterJobRole(JobRole) : this.allJobRole.slice()),
        //         );
        //     }
        // })
        this.addEditForm.controls["PageSearch"].setValue(this.page );

        this.Service.Get('Qualification/GetQualification?Id=0', null).subscribe(data => {
            if (data != null) {
                this.allQualification = data;
                this.filterQualifications = this.QualificationsControl.valueChanges.pipe(
                    startWith<string | Qualification>(''),
                    map(value => typeof value === 'string' ? value : value.
                        Qualification),
                    map(Qualification => Qualification ? this._filterQualificationa(Qualification) : this.allQualification.slice())
                );
            }
        })
       this.getAllRecommandedJobs();
    }

    private _filterJobRole(jobrole: string): JobRole[] {
        const filterValue = jobrole.toLowerCase();

        return this.allJobRole.filter(
            option => option.JobRole?.toLowerCase().indexOf(filterValue) === 0
        );
    }

    displayFnForJobRole(value: any) {
        return value ? value.JobRole : undefined;
    }

    private _filterQualificationa(Qualification: string): Qualification[] {
        const filterValue = Qualification.toLowerCase();

        return this.allQualification.filter(
            option => option.Qualification?.toLowerCase().indexOf(filterValue) === 0
        );
    }

    displayFnForQualification(value: any) {
        return value ? value.Qualification : undefined;
    }

    // onchangeJobRole(jobrole: any) {
    //     this.JobRole = jobrole;
    // }

    onchangeQualification(Qualification: any) {
        this.Education = Qualification;
    }

    getAllRecommandedJobs() {
     
        this.Service.Get('job/GetCandidateRecommandedJob?userId=' + this.Global.getuserId() + '&educationid=' + this.Education + '&page=' + this.page + '&pagesize=' + this.pagesize, null).subscribe(
            (res) => {
                this.jobPostsInfo = res[0];
                this.obs = this.jobPostsInfo;
                //this.datasource = this.jobPostsInfo;
                this.datapageInfo = res[1];
                this.totalItems = this.datapageInfo[0].length;
                this.totalpages = Math.ceil(this.totalItems / 5);
                this.CardClick(this.obs[0])
            },
            (error) => {
                //if (error.status == 401) this.router.navigate(['/login']);
                this.toastr.error(error.error);
            });
    }

    GOTO() {
        if (this.page >= 1) {
                this.getAllRecommandedJobs();}
            }
        
            Previous() {
        
        
                if (this.page > 1) {
                    this.page = this.page - 1;
                    this.addEditForm.controls["PageSearch"].setValue(this.page);
                    this.getAllRecommandedJobs();
        
                } else {
        
                }
        
            }
        
            Next() {
                if (this.totalpages > this.page) {
                    this.page = this.page + 1
                    this.addEditForm.controls["PageSearch"].setValue(this.page);
        
                    this.getAllRecommandedJobs();
                }
            }
        
            GoToPage(event: Event) {
                var value = (event.target as HTMLInputElement).value;
                if (+value > this.totalpages
                    || +value < 0) {
                    this.toastr.success('Page no Should be between : 1 And ' + this.totalpages);
        
                }
                else {
                    this.page = +value;
                }
            }
        

    pageChanged(value: any) {
        this.page = value;
        //this.getAllRecommandedJobs()
        this.applyselection()
    }

    CardClick(event) {
        this.scroller.scrollToAnchor("targetRed");
        if (event == undefined) {
            this.visible = false;
        }
        else {
            this.visible = true;
            this.afterClickData = event;
        }
    }

    BookMarked(Jobpost: any) {
        this.bookmark = new Bookmark;
        this.bookmark.PostedJobId = Jobpost.Id;
        this.bookmark.JobCompanyName = Jobpost.JobCompanyName;
        this.bookmark.UserId = this.Global.getuserId();
        this.Service.Post('Applyjob/Bookmark', this.bookmark).subscribe(data => {
            this.toastr.success(data);
            this.router.navigate(['/bookjobs']);
        })
    }

    ApplyNowClick(jobpost: any) {
        this.Service.Post('AppliedJob/InsertAppliedJob?jobpostId=' + jobpost, null).subscribe(
            (res) => {
                this.toastr.success(res);
                this.router.navigate(['/candidateappliedjob']);
            })
    }

    applyselection() {
        this.JobRole;
        this.Education;
        this.getAllRecommandedJobs();
    }

    removeselection() {
        this.JobRole = 0;
        this.Education = 0;
        this.getAllRecommandedJobs();

        this.filterQualifications = null;
        this.QualificationsControl = new FormControl();

        this.Service.Get('Qualification/GetQualification?Id=0', null).subscribe(data => {
            if (data != null) {
                this.allQualification = data;
                this.filterQualifications = this.QualificationsControl.valueChanges.pipe(
                    startWith<string | Qualification>(''),
                    map(value => typeof value === 'string' ? value : value.
                        Qualification),
                    map(Qualification => Qualification ? this._filterQualificationa(Qualification) : this.allQualification.slice())
                )
            }
        })

        this.filterJobroles = null
        this.jobroleControl = new FormControl();

        this.Service.Get('JobRole/GetJobRole?Id=0', null).subscribe(data => {
            if (data != null) {
                this.allJobRole = data;
                this.filterJobroles = this.jobroleControl.valueChanges.pipe(
                    startWith<string | JobRole>(''),
                    map(value => typeof value === 'string' ? value : value.JobRole),
                    map(JobRole => JobRole ? this._filterJobRole(JobRole) : this.allJobRole.slice()),
                );
            }
        })

    }

    ngOnDestroy(): void {

    }

}
