<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<div  class="mac-book-air-5">
  
  <div class="rectangle-13"></div>
  <div class="rectangle-1117"></div>
  <div style="position: absolute;width: 4000px;left: -81px;" *ngIf="getresumeData">

    <div style="position: absolute; 
    top: 239px;
    left: 762px;
    width: 240px;">
    <div class="frame-1166">
    <svg class="vector3" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.86964 1.08512L5.86833 1.07595C5.85121 0.955983 5.80107 0.866576 5.7057 0.788465C5.60271 0.704106 5.49608 0.666612 5.37005 0.666612H1.37005C1.18384 0.666612 1.05251 0.724 0.943748 0.832888C0.834978 0.941779 0.777481 1.07345 0.777452 1.26022L5.86964 1.08512ZM5.86964 1.08512L5.87133 1.09423M5.86964 1.08512L5.87133 1.09423M5.87133 1.09423L6.51153 4.54527C6.53701 4.72979 6.53098 4.88284 6.50325 5.00973C6.48126 5.11036 6.43163 5.20457 6.33955 5.29675L6.33954 5.29673M5.87133 1.09423L6.33954 5.29673M6.33954 5.29673L6.33633 5.30001M6.33954 5.29673L6.33633 5.30001M6.33633 5.30001L3.96596 7.72244L3.72914 7.96446M6.33633 5.30001L3.72914 7.96446M3.72914 7.96446L3.89962 8.25704M3.72914 7.96446L3.89962 8.25704M3.89962 8.25704C4.61146 9.47869 5.49961 10.6211 6.56177 11.6844C7.62401 12.7478 8.79627 13.6681 10.078 14.4448L10.3763 14.6255M3.89962 8.25704L10.3763 14.6255M10.3763 14.6255L10.6228 14.3787M10.3763 14.6255L10.6228 14.3787M10.6228 14.3787L12.9437 12.0552C13.0317 11.9671 13.1581 11.8891 13.3412 11.8325C13.5181 11.7779 13.6827 11.7639 13.8392 11.7841L17.2274 12.4723C17.2281 12.4724 17.2288 12.4726 17.2295 12.4727C17.367 12.5026 17.4685 12.5645 17.5507 12.6606C17.6294 12.7527 17.6663 12.8518 17.6663 12.977V16.9814C17.6663 17.1683 17.6088 17.3 17.5 17.4089C17.3913 17.5178 17.26 17.5752 17.0737 17.5752C15.0189 17.5752 13.0134 17.1171 11.0535 16.1956M10.6228 14.3787L11.0535 16.1956M11.0535 16.1956C9.0833 15.2693 7.3398 14.0465 5.82072 12.5264M11.0535 16.1956L5.82072 12.5264M5.82072 12.5264C4.30172 11.0064 3.0803 9.26102 2.1557 7.28802M5.82072 12.5264L2.1557 7.28802M2.1557 7.28802C1.2358 5.32505 0.77811 3.31711 0.777452 1.26035L2.1557 7.28802Z"
        stroke="white" stroke-width="0.888889" />
    </svg>

    <div class="_9899921352">{{getresumeData.MobileNo}}</div>
  </div>
    <div class="frame-1161">
      <svg class="vector11" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.874 0.78125H2.56977C1.97654 0.78125 1.40761 1.01717 0.98814 1.43711C0.568666 1.85705 0.333008 2.42661 0.333008 3.02049V12.7567C0.333008 13.3506 0.568666 13.9201 0.98814 14.3401C1.40761 14.76 1.97654 14.9959 2.56977 14.9959H15.874C16.4673 14.9959 17.0362 14.76 17.4557 14.3401C17.8751 13.9201 18.1108 13.3506 18.1108 12.7567V3.02049C18.1108 2.42661 17.8751 1.85705 17.4557 1.43711C17.0362 1.01717 16.4673 0.78125 15.874 0.78125ZM2.56977 1.67695H15.874C16.2127 1.67597 16.539 1.80414 16.7866 2.03541C17.0343 2.26668 17.1846 2.58368 17.2071 2.92196C14.9972 4.10428 12.7783 5.27764 10.5595 6.45996C10.2593 6.64829 9.9417 6.80726 9.6111 6.93468C9.35155 6.98596 9.08406 6.98133 8.82644 6.9211C8.56882 6.86087 8.32696 6.74642 8.11694 6.58536C6.84646 5.91358 5.57598 5.23286 4.31444 4.56108C3.29448 4.02367 2.25662 3.47729 1.23666 2.93092C1.25741 2.5912 1.40694 2.27222 1.65471 2.03916C1.90248 1.8061 2.22979 1.67654 2.56977 1.67695ZM17.2161 12.7567C17.2161 13.113 17.0747 13.4548 16.823 13.7067C16.5713 13.9587 16.23 14.1002 15.874 14.1002H2.56977C2.21383 14.1002 1.87248 13.9587 1.62079 13.7067C1.36911 13.4548 1.22771 13.113 1.22771 12.7567V3.94753C3.33922 5.0582 5.44177 6.18677 7.55327 7.30639C7.93052 7.53794 8.33665 7.71864 8.76113 7.84381C9.38239 7.94493 10.0191 7.81426 10.5505 7.47657C11.8479 6.79584 13.1362 6.10616 14.4336 5.42543C15.364 4.92384 16.2856 4.44017 17.2161 3.94753V12.7567Z"
          fill="#F8F8F8" />
      </svg>

      <div class="adityakeushna-123-gmail-com">{{getresumeData.Email}}</div>
    </div>
    
  </div>
    
    <div style="position: absolute;top: 239px;left: 479px; width: 240px" class="city">

      <div class="frame-1668">
      <svg class="vector5" viewBox="0 0 13 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.66558 17.3432C6.50372 17.1538 6.28943 16.8986 6.0386 16.5892C5.46099 15.8767 4.69157 14.8794 3.92303 13.739C3.15363 12.5974 2.3906 11.3205 1.82126 10.0482C1.24932 8.77016 0.887804 7.52941 0.887804 6.45128C0.887804 3.25247 3.47151 0.666612 6.66558 0.666612C9.85966 0.666612 12.4434 3.25247 12.4434 6.45128C12.4434 7.52941 12.0818 8.77016 11.5099 10.0482C10.9406 11.3205 10.1775 12.5974 9.40814 13.739C8.63959 14.8794 7.87017 15.8767 7.29256 16.5892C7.04173 16.8986 6.82744 17.1538 6.66558 17.3432ZM4.77979 8.33847C5.27985 8.83908 5.95818 9.12041 6.66558 9.12041C7.37298 9.12041 8.05131 8.83908 8.55137 8.33847C9.05142 7.83787 9.33225 7.15902 9.33225 6.45128C9.33225 5.74354 9.05142 5.06469 8.55137 4.5641C8.05131 4.06348 7.37298 3.78215 6.66558 3.78215C5.95818 3.78215 5.27985 4.06348 4.77979 4.5641C4.27975 5.06469 3.99892 5.74354 3.99892 6.45128C3.99892 7.15902 4.27975 7.83787 4.77979 8.33847Z"
          stroke="white" stroke-width="0.888889" />
      </svg>
  
    <div class="vadodara-gujarat-india">{{getresumeData.CityNameOfEmployeeInfo}}, {{getresumeData.StateName}},
      {{getresumeData.CountryName}}</div>

</div>
    <div class="frame-1603">
      <svg class="group2" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.22179 3.43964C5.22179 2.73161 5.50274 2.05258 6.00284 1.55193C6.50293 1.05128 7.18121 0.77002 7.88846 0.77002H11.8885C12.5957 0.77002 13.274 1.05128 13.7741 1.55193C14.2742 2.05258 14.5551 2.73161 14.5551 3.43964V4.32951H5.22179V3.43964ZM0.777344 8.33394L9.45068 10.5048C9.59201 10.5404 9.74046 10.5404 9.88179 10.5048L18.5551 8.33394V14.1181C18.5551 14.3541 18.4615 14.5805 18.2948 14.7474C18.1281 14.9142 17.902 15.008 17.6662 15.008H1.66623C1.43048 15.008 1.20439 14.9142 1.03769 14.7474C0.870994 14.5805 0.777344 14.3541 0.777344 14.1181V8.33394Z"
          stroke="white" stroke-width="0.888889" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M18.5551 9.22389V5.21946C18.5551 4.98345 18.4615 4.75711 18.2948 4.59023C18.1281 4.42334 17.902 4.32959 17.6662 4.32959H1.66623C1.43048 4.32959 1.20439 4.42334 1.03769 4.59023C0.870994 4.75711 0.777344 4.98345 0.777344 5.21946V9.22389"
          stroke="white" stroke-width="0.888889" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M10.7769 7.44388C10.7769 7.73889 10.6598 8.02182 10.4515 8.23043C10.2431 8.43903 9.96048 8.55622 9.6658 8.55622C9.37111 8.55622 9.0885 8.43903 8.88012 8.23043C8.67175 8.02182 8.55469 7.73889 8.55469 7.44388C8.55469 7.14887 8.67175 6.86594 8.88012 6.65734C9.0885 6.44874 9.37111 6.33154 9.6658 6.33154C9.96048 6.33154 10.2431 6.44874 10.4515 6.65734C10.6598 6.86594 10.7769 7.14887 10.7769 7.44388Z"
          fill="white" />
      </svg>


      <div class="frame-1162">
        <div class="fresher">{{getresumeData.JobRole}}</div>
      </div>
    </div>
 

    <div class="frame-1164">
      <svg class="group-1036" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.704 3.85498H15.0991V4.84373H16.5806V15.72H2.75341V4.84373H4.23489V3.85498H2.62995C2.51451 3.85691 2.40058 3.88159 2.29467 3.92761C2.18876 3.97363 2.09295 4.04008 2.0127 4.12317C1.93244 4.20627 1.86933 4.30438 1.82696 4.41189C1.78459 4.51941 1.76379 4.63423 1.76575 4.7498V15.8139C1.76379 15.9295 1.78459 16.0443 1.82696 16.1518C1.86933 16.2593 1.93244 16.3574 2.0127 16.4405C2.09295 16.5236 2.18876 16.5901 2.29467 16.6361C2.40058 16.6821 2.51451 16.7068 2.62995 16.7087H16.704C16.8195 16.7068 16.9334 16.6821 17.0393 16.6361C17.1452 16.5901 17.241 16.5236 17.3213 16.4405C17.4015 16.3574 17.4646 16.2593 17.507 16.1518C17.5494 16.0443 17.5702 15.9295 17.5682 15.8139V4.7498C17.5702 4.63423 17.5494 4.51941 17.507 4.41189C17.4646 4.30438 17.4015 4.20627 17.3213 4.12317C17.241 4.04008 17.1452 3.97363 17.0393 3.92761C16.9334 3.88159 16.8195 3.85691 16.704 3.85498Z"
          fill="white" />
        <path d="M4.72754 7.81006H5.71574V8.79935H4.72754V7.81006Z" fill="white" />
        <path d="M7.69141 7.81006H8.67961V8.79935H7.69141V7.81006Z" fill="white" />
        <path d="M10.6533 7.81006H11.6415V8.79935H10.6533V7.81006Z" fill="white" />
        <path d="M13.6172 7.81006H14.6054V8.79935H13.6172V7.81006Z" fill="white" />
        <path d="M4.72754 10.2817H5.71574V11.271H4.72754V10.2817Z" fill="white" />
        <path d="M7.69141 10.2817H8.67961V11.271H7.69141V10.2817Z" fill="white" />
        <path d="M10.6533 10.2817H11.6415V11.271H10.6533V10.2817Z" fill="white" />
        <path d="M13.6172 10.2817H14.6054V11.271H13.6172V10.2817Z" fill="white" />
        <path d="M4.72754 12.7534H5.71574V13.7427H4.72754V12.7534Z" fill="white" />
        <path d="M7.69141 12.7534H8.67961V13.7427H7.69141V12.7534Z" fill="white" />
        <path d="M10.6533 12.7534H11.6415V13.7427H10.6533V12.7534Z" fill="white" />
        <path d="M13.6172 12.7534H14.6054V13.7427H13.6172V12.7534Z" fill="white" />
        <path
          d="M5.71655 5.83298C5.84754 5.83298 5.97316 5.78089 6.06579 5.68816C6.15841 5.59544 6.21045 5.46967 6.21045 5.33854V2.37188C6.21045 2.24075 6.15841 2.11499 6.06579 2.02226C5.97316 1.92953 5.84754 1.87744 5.71655 1.87744C5.58556 1.87744 5.45994 1.92953 5.36731 2.02226C5.27469 2.11499 5.22266 2.24075 5.22266 2.37188V5.33854C5.22266 5.46967 5.27469 5.59544 5.36731 5.68816C5.45994 5.78089 5.58556 5.83298 5.71655 5.83298Z"
          fill="white" />
        <path
          d="M13.6179 5.83298C13.7489 5.83298 13.8745 5.78089 13.9672 5.68816C14.0598 5.59544 14.1118 5.46967 14.1118 5.33854V2.37188C14.1118 2.24075 14.0598 2.11499 13.9672 2.02226C13.8745 1.92953 13.7489 1.87744 13.6179 1.87744C13.4869 1.87744 13.3613 1.92953 13.2687 2.02226C13.1761 2.11499 13.124 2.24075 13.124 2.37188V5.33854C13.124 5.46967 13.1761 5.59544 13.2687 5.68816C13.3613 5.78089 13.4869 5.83298 13.6179 5.83298Z"
          fill="white" />
        <path d="M7.19727 3.85498H12.1362V4.84387H7.19727V3.85498Z" fill="white" />
      </svg>

      <div class="available-to-join-in-15-day">{{getresumeData.PassOutYear}}</div>
    </div>
  </div>
 


    <!-- <svg class="line-9-stroke" width="534" height="5" viewBox="0 0 534 5" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M533.888 4.88439H0.554688V0.439941H533.888V4.88439Z"
        fill="white" />
    </svg>

    <div class="frame-1157">
      <div class="profile-completed-excellent">Profile completed (Excellent)</div>

      <div class="_100">100%</div>
    </div> -->

    <div class="frame-1156">
      <div class="aditya-krushna">{{getresumeData.FirstName}} {{getresumeData.LastName}}</div>

      <svg class="vector30" width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg"
        (click)="EditBasicinfo(getresumeData)">
        <path
          d="M13.9371 5.07534L10.4478 1.62329L11.5973 0.472603C11.912 0.157534 12.2987 0 12.7573 0C13.216 0 13.6024 0.157534 13.9166 0.472603L15.066 1.62329C15.3807 1.93836 15.5449 2.31863 15.5586 2.76411C15.5723 3.20959 15.4218 3.58959 15.1071 3.90411L13.9371 5.07534ZM12.7467 6.28767L4.04397 15H0.554688V11.5068L9.25738 2.79452L12.7467 6.28767Z"
          fill="white" />
      </svg>

    </div>
    <div class="line-34"></div>
    <div class="line-35"></div>
  </div>

  <div class="please-complete-your-pending-actions-to-make-your-profile-attractive-to-recruiters">
    Please complete your pending actions to make your profile attractive to
    recruiters.
  </div>

  <div class="pending-action-s">Pending Action(s)</div>
  <div class="frame-1184" *ngIf="getresumeData">
    <div class="group-1183">
      <div *ngIf="getresumeData.Photo">
        <img class="ellipse-2" [src]="candidateImage" />
        <label mat-flat-button class="custom-file-upload Uploadbtn" style="background-color: #FFFFFF;
        /* left: 150px; */
        width: 46px;
        height: 38px;
        position: absolute;
        top: 0;
        left: 78px;"> <i  matTooltip="upload photo" class='bx bx-camera' style="color: black;
    font-size: 28px;
    margin-left: -3px;"></i>
            <input type="file" #myInput style="visibility:hidden" (change)="Uploadcompanylogo($event)" />
           
           
          </label>
      </div>
      <div *ngIf="getresumeData.Photo === ''">
        <img class="ellipse-2" src="assets/candidateimg/Male_Colors.jpg" alt="Dashboard">
        <label mat-flat-button class="custom-file-upload Uploadbtn" style="background-color: #FFFFFF;
        /* left: 150px; */
        width: 46px;
        height: 38px;
        position: absolute;
        top: 0;
        left: 78px;"
    > <i  matTooltip="upload photo" class='bx bx-camera' style="color: black;
    font-size: 28px;
    margin-left: -3px;"></i>
            <input type="file" #myInput style="visibility:hidden" (change)="Uploadcompanylogo($event)" />
           
           
          </label>
      </div>
    </div>
  </div>

  <div   class="background">
    <div>
      <div class="quick-links">Quick Links</div>
      <div
        class="resume-resume-headline-key-skills-education-it-skills-projects-profile-summary-accomplishments-career-profile-personal-details">
        <!-- [routerLink]="['/candidate/dashboard']"  -->
        <a class="nav-link" (click)="resume()">
          Resume <br />
        </a>
        <br />
        <a class="nav-link" (click)="Skill()">
          Key Skills<br />
        </a>
        <br />
        <a class="nav-link" (click)="Education()">
          Education<br />
        </a><br />

        <a class="nav-link" (click)="Experience()">
          Experience<br />
        </a><br />
        <a class="nav-link" (click)="Achivements()">
          Achivements <br />
        </a>
        <br />
        <a class="nav-link" (click)="Certificate()">
          Certification<br />
        </a><br />
        <a class="nav-link" (click)="Language()">
          Languages<br />
        </a><br />
        <a class="nav-link" (click)="PrefferedLocation()">
          Preffered Location<br />
        </a>
        <br />
        <a class="nav-link" (click)="Function()">
          Function
          <br />
        </a>
        <br />
        <a class="nav-link" (click)="IndustryType()">
          Industry Type<br />
        </a>
        <br />
        <a class="nav-link" (click)="ChangePass()">
          Change Password<br />
        </a>
        <br />
        <a class="nav-link" (click)="PersonalDetail()">
          Personal Details<br />
        </a>
        <br />
      </div>
    </div>
 
</div>


  <div  style="position: absolute;left: 512px;top: 476px;width: 905px;">
    <div id="resume">
      <div class="card ResumeCard">
        <div class="resume">Resume</div>
        <div
          class="resume-is-the-most-important-document-recruiters-look-for-recruiters-generally-do-not-look-at-profiles-without-resumes">
          Resume is the most important document recruiters look for. Recruiters
          generally do not look at profiles without resumes
        </div>
        <div class="emailin-aditya-keushna-pdf-uploaded-on-apr-15-2023" *ngIf="resumefilename">
          {{resumefilename.ResumeFIleName}}
        </div>
        <div class="mat-btn4 content">
          <label for="files" class="text3">UPLOAD RESUME</label>
          <input id="files" #myInput style="visibility:hidden;" type="file" (change)="uplodPfd($event.target.files)" />
        </div>
        <div class="supported-formats-doc-dock-if-pdf-upto-2-mb">
          Supported Formats: pdf
        </div>

        <div class="frame-1606" *ngIf="resumefilename">
          <div class="group-1688">
            <div class="group-15782">
              <div class="ellipse-21"></div>
            </div>
            <svg (click)="ViewResume()" class="vector34" width="18" height="10" viewBox="0 0 18 10" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.74976 0C3.85897 0 0 4.99968 0 4.99968C0 4.99968 3.85897 10 8.74976 10C12.4895 10 17.4995 4.99968 17.4995 4.99968C17.4995 4.99968 12.4895 0 8.74976 0ZM8.74976 8.11459C7.03221 8.11459 5.63419 6.71722 5.63419 4.99968C5.63419 3.28213 7.03221 1.88411 8.74976 1.88411C10.4673 1.88411 11.8653 3.28213 11.8653 4.99968C11.8653 6.71722 10.4673 8.11459 8.74976 8.11459ZM8.74976 3.18102C8.50808 3.17646 8.26792 3.22012 8.04332 3.30945C7.81871 3.39878 7.61417 3.53199 7.44164 3.70129C7.26911 3.87059 7.13207 4.07258 7.03851 4.29546C6.94496 4.51834 6.89677 4.75763 6.89677 4.99935C6.89677 5.24107 6.94496 5.48036 7.03851 5.70324C7.13207 5.92612 7.26911 6.12812 7.44164 6.29741C7.61417 6.46671 7.81871 6.59992 8.04332 6.68925C8.26792 6.77858 8.50808 6.82224 8.74976 6.81768C9.22606 6.80869 9.67982 6.61316 10.0135 6.27314C10.3472 5.93311 10.5341 5.47574 10.5341 4.99935C10.5341 4.52296 10.3472 4.06559 10.0135 3.72557C9.67982 3.38554 9.22606 3.19002 8.74976 3.18102Z"
                fill="#F8F8F8" />
            </svg>
          </div>
        </div>

      </div>
    </div>

    <div>
      <div class="card skillCard" id="skill">
        <div class="candidate-area" style="margin-top: 62px;
           margin-left: 19px;">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-12" *ngFor="let skill of skillInfo">
                <div class="image-white">
                  <div class="sol">{{skill.Skills}}</div>
                  <i *ngIf="editskill" matTooltip="Delete" class='bx bx-trash Clear' (click)="delSkill(skill)"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="frame-1196">
          <div class="key-skills" style="padding-right: 25px;">Key Skills</div>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" (click)="EditSkill()"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.1078 1.33624L17.1084 1.33685L18.6409 2.8711C18.8806 3.11104 18.9953 3.37626 19.0057 3.71618C19.0155 4.03483 18.9203 4.27425 18.6963 4.49815L18.6957 4.49876L17.8391 5.3563L14.6085 2.16019L15.4309 1.33685L14.7234 0.630137L15.4309 1.33685C15.6568 1.11074 15.9152 1 16.2702 1C16.6252 1 16.8829 1.11069 17.1078 1.33624ZM14.8425 8.38356L4.23785 19H1V15.7564L11.6036 5.14102L14.8425 8.38356Z"
              fill="white" stroke="#221668" stroke-width="2" />
          </svg>
          <i matTooltip="Cancel" class='bx bx-x Clear cancel' (click)="CancelSkill()"></i>

          <div class="add-Skill" (click)="AddSkill()">ADD SKILL</div>
        </div>

      </div>
    </div>

    <div>
      <div class="card EductionCard" id="education">
        <div class="frame-1190">
          <div class="key-skills">Education</div>


          <div class="add-education" (click)="AddEdu()">ADD EDUCATION</div>
        </div>
        <div class="candidate-area" style="margin-top: 50px; margin-left: 19px;">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 col-md-12 TableStyle" *ngFor="let edu of educationInfo; let i = index">

                <div class="seticon">
                  <i matTooltip="Delete" class='bx bx-trash delete' (click)="delEdu(edu)"></i>
                  <svg class="group-1042" matTooltip="Edit" width="20" height="20" viewBox="0 0 20 20" fill="none"
                    (click)="EditEdu(edu)" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.1078 1.33624L17.1084 1.33685L18.6409 2.8711C18.8806 3.11104 18.9953 3.37626 19.0057 3.71618C19.0155 4.03483 18.9203 4.27425 18.6963 4.49815L18.6957 4.49876L17.8391 5.3563L14.6085 2.16019L15.4309 1.33685L14.7234 0.630137L15.4309 1.33685C15.6568 1.11074 15.9152 1 16.2702 1C16.6252 1 16.8829 1.11069 17.1078 1.33624ZM14.8425 8.38356L4.23785 19H1V15.7564L11.6036 5.14102L14.8425 8.38356Z"
                      fill="white" stroke="#221668" stroke-width="2" />
                  </svg>


                </div>

                <div class="Educationdata">

                  <div class="FiledName">
                    School Or University:
                    <span class="Data">
                      {{edu.SchoolOrUniversity}}<br /></span>
                  </div>

                  <div class="FiledName">
                    Specialization: <span class="Data">{{edu.SpecializationDescription}}<br /></span>
                  </div>

                  <div class="FiledName">GradeOrPercentage: <span class="Data">{{edu.GradeOrPercentage}}<br /></span>
                  </div>

                  <div class="FiledName">Education Type: <span class="Data">{{edu.EducationTypeName}}<br /></span>
                  </div>
                  <div class="FiledName">Passout Year : <span class="Data">{{edu.PassOutYear}}</span></div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div>
      <div class="card ExperienceCard" id="experience">
        <div class="frame-1191">
          <div class="key-skills">Experience</div>

          <div class="add-education" (click)="AddExp()">ADD EXPERIENCE</div>
        </div>
        <div class="candidate-area" style="margin-top: 50px; margin-left: 19px;">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 col-md-12 TableStyle" *ngFor="let exp of experienceInfo; let i = index">

                <div class="seticon">
                  <i matTooltip="Delete" class='bx bx-trash delete' (click)="delExp(exp)"></i>
                  <svg class="group-1042" matTooltip="Edit" width="20" height="20" viewBox="0 0 20 20" fill="none"
                    (click)="EditExp(exp)" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.1078 1.33624L17.1084 1.33685L18.6409 2.8711C18.8806 3.11104 18.9953 3.37626 19.0057 3.71618C19.0155 4.03483 18.9203 4.27425 18.6963 4.49815L18.6957 4.49876L17.8391 5.3563L14.6085 2.16019L15.4309 1.33685L14.7234 0.630137L15.4309 1.33685C15.6568 1.11074 15.9152 1 16.2702 1C16.6252 1 16.8829 1.11069 17.1078 1.33624ZM14.8425 8.38356L4.23785 19H1V15.7564L11.6036 5.14102L14.8425 8.38356Z"
                      fill="white" stroke="#221668" stroke-width="2" />
                  </svg>


                </div>

                <div class="Educationdata">
                  <div class="FiledName">
                    Company Name :
                    <span class="Data">
                      {{exp.CompanyName}}<br /></span>
                  </div>

                  <div class="FiledName">
                    Job Role : <span class="Data">{{exp.JobRole}}<br /></span>
                  </div>

                  <div class="FiledName">Employment : <span class="Data">{{exp.EmployeementTypeName}}<br /></span>
                  </div>

                  <div class="FiledName">Work Location: <span class="Data">{{exp.WorkLocation}}<br /></span>
                  </div>

                  <div class="FiledName">Current Job : <span class="Data">{{exp.IsCurrentJob ? 'Yes' : 'No'}}</span>
                  </div>

                  <!-- <div class="FiledName">Start date : <span class="Data">{{exp.FromDate | date:'dd-MM-yyyy'}}</span>
                  </div>

                  <div class="FiledName">End date : <span class="Data">{{exp.ToDate | date:'dd-MM-yyyy'}}</span></div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div>
      <div class="card ExperienceCard" id="achivements">
        <div class="frame-1192">
          <div class="key-skills">Achievments</div>
          <div class="add-education" (click)="AddAchiv()">ADD ACHIEVMENT</div>
        </div>
        <div class="candidate-area" style="margin-top: 50px; margin-left: 19px;">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 col-md-12 " *ngFor="let achiv of projectsInfo; let i = index">

                <div class="seticon">
                  <i matTooltip="Delete" class='bx bx-trash delete' (click)="delPrj(achiv)"
                    style="padding-top: 0px;"></i>

                  <svg class="group-1042" matTooltip="Edit" width="20" height="20" viewBox="0 0 20 20" fill="none"
                    (click)="EditAchiv(achiv)" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.1078 1.33624L17.1084 1.33685L18.6409 2.8711C18.8806 3.11104 18.9953 3.37626 19.0057 3.71618C19.0155 4.03483 18.9203 4.27425 18.6963 4.49815L18.6957 4.49876L17.8391 5.3563L14.6085 2.16019L15.4309 1.33685L14.7234 0.630137L15.4309 1.33685C15.6568 1.11074 15.9152 1 16.2702 1C16.6252 1 16.8829 1.11069 17.1078 1.33624ZM14.8425 8.38356L4.23785 19H1V15.7564L11.6036 5.14102L14.8425 8.38356Z"
                      fill="white" stroke="#221668" stroke-width="2" />
                  </svg>


                </div>

                <div class="Educationdata">
                  <div class="FiledName">
                    Achievment :
                    <span class="Data">
                      {{achiv.DescriptionOfAchievments}}<br /></span>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="card CertificateCard" id="certificate">
        <div class="frame-1193">
          <div class="key-skills">Certificate</div>
          <!-- <svg class="group-1042" width="20" height="20" viewBox="0 0 20 20" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.1078 1.33624L17.1084 1.33685L18.6409 2.8711C18.8806 3.11104 18.9953 3.37626 19.0057 3.71618C19.0155 4.03483 18.9203 4.27425 18.6963 4.49815L18.6957 4.49876L17.8391 5.3563L14.6085 2.16019L15.4309 1.33685L14.7234 0.630137L15.4309 1.33685C15.6568 1.11074 15.9152 1 16.2702 1C16.6252 1 16.8829 1.11069 17.1078 1.33624ZM14.8425 8.38356L4.23785 19H1V15.7564L11.6036 5.14102L14.8425 8.38356Z"
              fill="white" stroke="#221668" stroke-width="2" />
          </svg> -->

          <div class="add-education" (click)="AddCertificate()">ADD CERTIFICATE</div>
        </div>
        <div class="candidate-area" style="margin-top: 50px; margin-left: 19px;">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 col-md-12" *ngFor="let certy of CertificateInfo; let i = index">

                <!-- <div style="height:10px ;">
                  <svg class="group-1042" matTooltip="Edit" width="20" height="20" viewBox="0 0 20 20" fill="none"
                    (click)="EditCertificate(certy)" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.1078 1.33624L17.1084 1.33685L18.6409 2.8711C18.8806 3.11104 18.9953 3.37626 19.0057 3.71618C19.0155 4.03483 18.9203 4.27425 18.6963 4.49815L18.6957 4.49876L17.8391 5.3563L14.6085 2.16019L15.4309 1.33685L14.7234 0.630137L15.4309 1.33685C15.6568 1.11074 15.9152 1 16.2702 1C16.6252 1 16.8829 1.11069 17.1078 1.33624ZM14.8425 8.38356L4.23785 19H1V15.7564L11.6036 5.14102L14.8425 8.38356Z"
                      fill="white" stroke="#221668" stroke-width="2" />
                  </svg>

                  <i matTooltip="Delete" class='bx bx-x delete' (click)="delCerty(certy)"></i>
                </div> -->
                <div class="seticon">
                  <i matTooltip="Delete" class='bx bx-trash delete' (click)="delCerty(certy)"
                    style="padding-top: 0px;"></i>

                  <svg class="group-1042" matTooltip="Edit" width="20" height="20" viewBox="0 0 20 20" fill="none"
                  (click)="EditCertificate(certy)" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.1078 1.33624L17.1084 1.33685L18.6409 2.8711C18.8806 3.11104 18.9953 3.37626 19.0057 3.71618C19.0155 4.03483 18.9203 4.27425 18.6963 4.49815L18.6957 4.49876L17.8391 5.3563L14.6085 2.16019L15.4309 1.33685L14.7234 0.630137L15.4309 1.33685C15.6568 1.11074 15.9152 1 16.2702 1C16.6252 1 16.8829 1.11069 17.1078 1.33624ZM14.8425 8.38356L4.23785 19H1V15.7564L11.6036 5.14102L14.8425 8.38356Z"
                      fill="white" stroke="#221668" stroke-width="2" />
                  </svg>


                </div>
                <div class="Educationdata">
                  <div class="FiledName">
                    Certification Name :
                    <span class="Data">
                      {{certy.CertificationName}}<br /></span>
                  </div>

                  <div class="FiledName">
                    Description : <span class="Data">{{certy.Description}}<br /></span>
                  </div>

                  <div class="FiledName">Date Of Issue :<span class="Data">{{certy.DateOfIssue | date
                      :'dd-MM-yyyy'}}<br /></span>
                  </div>

                  <div class="FiledName">Date Of Validity : <span class="Data">{{certy.DateOfValidity | date :'dd-MM-yyyy'}}<br /></span>
                  </div>
                  <!-- <div class="FiledName">Passout Year : <span class="Data">{{certy.DateOfValidity | date
                      :'dd-MM-yyyy'}}</span></div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="card skillCard" id="language">
        <div class="candidate-area" style="margin-top: 62px;
           margin-left: 19px;">
          <div class="container">
            <div class="row">
              <div class="col-lg-2 col-md-12" *ngFor="let lang of languageInfo;">
                <div class="image-white">
                  <div class="sol">{{lang.Language}}</div>
                  <i matTooltip="Delete" *ngIf="editLanguage" class='bx bx-trash Clear' (click)="delSkill(skill)"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="frame-1196">
          <div class="key-skills" style="padding-right: 25px;">Language</div>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" (click)="EditLang()"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.1078 1.33624L17.1084 1.33685L18.6409 2.8711C18.8806 3.11104 18.9953 3.37626 19.0057 3.71618C19.0155 4.03483 18.9203 4.27425 18.6963 4.49815L18.6957 4.49876L17.8391 5.3563L14.6085 2.16019L15.4309 1.33685L14.7234 0.630137L15.4309 1.33685C15.6568 1.11074 15.9152 1 16.2702 1C16.6252 1 16.8829 1.11069 17.1078 1.33624ZM14.8425 8.38356L4.23785 19H1V15.7564L11.6036 5.14102L14.8425 8.38356Z"
              fill="white" stroke="#221668" stroke-width="2" />
          </svg>
          <i matTooltip="Cancel" class='bx bx-x Clear cancel' (click)="CancelLang()"></i>
          <div class="add-language" (click)="AddLanguage()">ADD LANGUAGE</div>
        </div>
      </div>
    </div>

    <div>
      <div class="card skillCard" id="prefferedLocation">
        <div class="candidate-area" style="margin-top: 62px;
           margin-left: 19px;">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-12" *ngFor="let loc of locationInfo; let i = index">
                <div class="image-white">
                  <div class="sol">{{loc.CityName}}</div>
                  <i matTooltip="Delete" *ngIf="editLocation" class='bx bx-trash Clear' (click)="delLoc(loc)"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="frame-1195">
          <div class="key-skills">Preffered Location</div>
        
          <div class="add-education" (click)="AddLocation()">ADD LOCATION</div>
        </div> -->

        <div class="frame-1199">
          <div class="key-skills" style="padding-right: 25px;">Preffered Location</div>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" (click)="EditLocation()"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.1078 1.33624L17.1084 1.33685L18.6409 2.8711C18.8806 3.11104 18.9953 3.37626 19.0057 3.71618C19.0155 4.03483 18.9203 4.27425 18.6963 4.49815L18.6957 4.49876L17.8391 5.3563L14.6085 2.16019L15.4309 1.33685L14.7234 0.630137L15.4309 1.33685C15.6568 1.11074 15.9152 1 16.2702 1C16.6252 1 16.8829 1.11069 17.1078 1.33624ZM14.8425 8.38356L4.23785 19H1V15.7564L11.6036 5.14102L14.8425 8.38356Z"
              fill="white" stroke="#221668" stroke-width="2" />
          </svg>
          <i matTooltip="Cancel" class='bx bx-x Clear cancel' (click)="CancelLocation()"></i>
          <div class="add-location" (click)="AddLocation()">ADD LOCATION</div>
        </div>
      </div>
    </div>

    <div>
      <div class="card EductionCard" id="function">
        <div class="candidate-area" style="margin-top: 50px; margin-left: 19px;">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 col-md-12" *ngFor="let fun of function; let i = index" style="margin-bottom:10px ;">



                <div class="Educationdata">
                  <div class="FiledName">
                    Function1 :
                    <span class="Data">
                      {{fun.FunctionName}}<br /></span>
                  </div>

                  <div class="FiledName">
                    Function2: <span class="Data">{{fun.FunctionName2}}<br /></span>
                  </div>

                  <div class="frame-1181">
                    <div class="key-skills">Function</div>

                    <svg class="group-1043" width="20" height="20" viewBox="0 0 20 20" fill="none"
                      (click)="EditFunction(fun)" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.1078 1.33624L17.1084 1.33685L18.6409 2.8711C18.8806 3.11104 18.9953 3.37626 19.0057 3.71618C19.0155 4.03483 18.9203 4.27425 18.6963 4.49815L18.6957 4.49876L17.8391 5.3563L14.6085 2.16019L15.4309 1.33685L14.7234 0.630137L15.4309 1.33685C15.6568 1.11074 15.9152 1 16.2702 1C16.6252 1 16.8829 1.11069 17.1078 1.33624ZM14.8425 8.38356L4.23785 19H1V15.7564L11.6036 5.14102L14.8425 8.38356Z"
                        fill="white" stroke="#221668" stroke-width="2" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="card EductionCard" id="industryType">
        <div class="candidate-area" style="margin-top: 50px; margin-left: 19px;">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 col-md-12" *ngFor="let sec of section;" style="margin-bottom:10px ;">
                <div class="Educationdata">
                  <div class="FiledName">
                    Industry Type 1 :
                    <span class="Data">
                      {{sec.SectionName}}<br /></span>
                  </div>

                  <div class="FiledName">
                    Industry Type 2 : <span class="Data">{{sec.SectionName2}}<br /></span>
                  </div>

                  <div class="FiledName">
                    Industry Type 3 : <span class="Data">{{sec.SectionName3}}<br /></span>
                  </div>

                  <div class="frame-1182">
                    <div class="key-skills">Industry Type</div>
                    <svg class="group-1043" width="20" height="20" viewBox="0 0 20 20" fill="none"
                      (click)="EditSection(sec)" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.1078 1.33624L17.1084 1.33685L18.6409 2.8711C18.8806 3.11104 18.9953 3.37626 19.0057 3.71618C19.0155 4.03483 18.9203 4.27425 18.6963 4.49815L18.6957 4.49876L17.8391 5.3563L14.6085 2.16019L15.4309 1.33685L14.7234 0.630137L15.4309 1.33685C15.6568 1.11074 15.9152 1 16.2702 1C16.6252 1 16.8829 1.11069 17.1078 1.33624ZM14.8425 8.38356L4.23785 19H1V15.7564L11.6036 5.14102L14.8425 8.38356Z"
                        fill="white" stroke="#221668" stroke-width="2" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="card EductionCard" id="personalDetail">
        <div class="candidate-area" style="margin-top: 50px; margin-left: 19px;">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 col-md-12" *ngIf="info" style="margin-bottom:10px ;">
                <div class="Educationdata">
                  <div class="FiledName">
                    First Name :
                    <span class="Data">
                      {{info.FirstName}}<br /></span>
                  </div>

                  <div class="FiledName">
                    Last Name : <span class="Data">{{info.LastName}}<br /></span>
                  </div>

                  <div class="FiledName">Mobile No : <span class="Data">{{info.MobileNo}}<br /></span>
                  </div>

                  <div class="FiledName">Date of Birth : <span class="Data">{{info.DateOfBirth |
                      date:'dd-MM-yyyy'}}<br /></span>
                  </div>
                  <div class="FiledName">Gender : <span class="Data">{{info.GenderDesc}}</span></div>

                  <div class="FiledName">Marital Status : <span class="Data">{{info.MaritalStatusDesc}}</span></div>

                  <div class="FiledName">Nationality : <span class="Data">{{info.Nationality}}</span></div>

                  <div class="FiledName">Differently Abled : <span class="Data">{{info.DifferentlyAbled ? 'Yes' :
                      'No'}}</span></div>

                  <div class="FiledName">Address : <span class="Data">{{info.Address1}} {{info.Address2}}
                      ,{{info.StateName}},{{info.CityNameOfEmployeeInfo}}
                      {{info.PinCode}},{{info.CountryName}}</span></div>

                  <div class="frame-1183">
                    <div class="key-skills">Personal Detail</div>
                    <svg class="group-1043" width="20" height="20" viewBox="0 0 20 20" fill="none" (click)="EditPI()"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.1078 1.33624L17.1084 1.33685L18.6409 2.8711C18.8806 3.11104 18.9953 3.37626 19.0057 3.71618C19.0155 4.03483 18.9203 4.27425 18.6963 4.49815L18.6957 4.49876L17.8391 5.3563L14.6085 2.16019L15.4309 1.33685L14.7234 0.630137L15.4309 1.33685C15.6568 1.11074 15.9152 1 16.2702 1C16.6252 1 16.8829 1.11069 17.1078 1.33624ZM14.8425 8.38356L4.23785 19H1V15.7564L11.6036 5.14102L14.8425 8.38356Z"
                        fill="white" stroke="#221668" stroke-width="2" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>




</div>

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->