<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>My Resume</h2>
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image" />
                            <!-- <a routerLink="/">Home</a> -->
                        </li>
                        <!-- <li><span>/</span></li> -->
                        <li>My Resume</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Resume -->
<div class="person-details-area resume-area ptb-100">
    <div class="container">
        <div class="row">
            <div id="noScreen" class=" col-lg-4 col-md-12">
                <div class="widget-area" *ngIf="getresumeData">
                    <div class="resume-profile">
                        <img [src]="getresumeData.Photo" alt="Dashboard" />


                        <h2>{{getresumeData.FirstName}} {{getresumeData.LastName}}</h2>
                        <span>{{getresumeData.JobRole}}</span>
                    </div>
                    <div class="information widget-item">
                        <h3>Overview</h3>
                        <ul>

                            <li>
                                <img src="assets/png/pinklogo.png" alt="Details" />
                                <h4>Contact:</h4>
                                <span>{{getresumeData.MobileNo}}</span>
                            </li>
                            <li>
                                <img src="assets/png/pinklogo.png" alt="Details" />
                                <h4>Gender:</h4>
                                <span>{{ getresumeData.GenderDesc }}</span>
                            </li>
                            <li>
                                <img src="assets/png/pinklogo.png" alt="Details" />
                                <h4>Date Of Birth:</h4>
                                <span>{{getresumeData.DateOfBirth | date:'dd-MM-yyyy'}}</span>
                            </li>
                            <li>
                                <img src="assets/png/pinklogo.png" alt="Details" />
                                <h4>Marital Status:</h4>
                                <span>{{ getresumeData.MaritalStatusDesc }}</span>
                            </li>
                            <li>
                                <img src="assets/png/pinklogo.png" alt="Details" />
                                <h4>Nationality:</h4>
                                <span>{{ getresumeData.Nationality }}</span>
                            </li>
                            <li>
                                <img src="assets/png/pinklogo.png" alt="Details" />
                                <h4>Differently Abled:</h4>
                                <span>{{getresumeData.DifferentlyAbled ? 'Yes' : 'No'}}</span>
                            </li>
                            <li>
                                <img src="assets/png/pinklogo.png" alt="Details" />
                                <h4>Location:</h4>
                                <span>{{getresumeData.Address1}} ,{{getresumeData.Address2}}
                                    {{getresumeData.CityNameOfEmployeeInfo}},
                                    {{getresumeData.StateName}}, {{getresumeData.CountryName}} -
                                    {{getresumeData.PinCode}}</span>
                            </li>
                            <li>
                                <img src="assets/png/pinklogo.png" alt="Details" />
                                <h4>Country:</h4>
                                <span>{{ getresumeData.CountryName }}</span>
                            </li>
                            <li>
                                <img src="assets/png/pinklogo.png" alt="Details" />
                                <h4>State:</h4>
                                <span>{{ getresumeData.StateName }}</span>
                            </li>
                            <li>
                                <img src="assets/png/pinklogo.png" alt="Details" />
                                <h4>City:</h4>
                                <span>{{ getresumeData.CityNameOfEmployeeInfo }}</span>
                            </li>
                            <li>
                                <img src="assets/png/pinklogo.png" alt="Details" />
                                <h4>Expected Salary:</h4>
                                <span>{{ getresumeData.ExpectedSalary }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="details-item">
                    <!-- <div class="profile" *ngFor="let info of basicInfo">
                        <h3>About Me</h3>
                        <span>{{ info.summary }}</span>
                    </div> -->
                    <!-- Work Experience -->
                    <h3>Education</h3>
                    <div class="work bottom-item" *ngFor="let getEducationData of datasourceEdu ">
                        <div class="row">


                            <div class="col-11" *ngIf="getEducationData">

                                <ul>
                                    <li>
                                        <!-- <i class="bx bxs-shopping-bag"></i><strong>SchoolOrUniversity:</strong> -->
                                        {{ getEducationData.Qualification }}

                                    </li>
                                </ul>

                                <li>
                                    <strong>School Or University:</strong>
                                    {{ getEducationData.SchoolOrUniversity }}

                                </li>

                                <li>
                                    <strong>Specialization:</strong>
                                    {{ getEducationData.SpecializationDescription }}

                                </li>

                                <li>
                                    <strong>GradeOrPercentage:</strong>
                                    {{ getEducationData.GradeOrPercentage }}

                                </li>

                                <li>
                                    <strong>EducationTypeName:</strong>
                                    {{ getEducationData.EducationTypeName }}

                                </li>

                                <li>
                                    <strong>Passout Date ::</strong>
                                    {{getEducationData.FromDateOfEducation | date:'dd-MM-yyyy'}}
                                </li>
                                <ul>
                                    <li>
                                        <!-- <i class="bx bxs-shopping-bag"></i><strong>Job Title:</strong>
                                        {{ exp.jobTitle }},
                                        <i class="bx bxs-buildings"></i><strong>Company:</strong>
                                        {{ exp.company }} -->
                                    </li>
                                </ul>
                                <h4>
                                    <!-- <strong>Industry:</strong>
                                    {{ exp.industry }} -->
                                </h4>
                                <p>
                                    <!-- <strong>Salary Range:</strong>
                                    {{ exp.salary }} -->
                                </p>
                                <p>
                                    <!-- {{ exp.startDate | date }} -
                                    <span *ngIf="!exp.currentlyWorking">
                                        {{ exp.endDate | date }}
                                    </span>
                                    <span *ngIf="exp.currentlyWorking">Present</span>
                                    | {{ exp.location }},Pakistan -->
                                </p>
                                <!-- <p>{{ exp.description }}</p> -->
                            </div>
                        </div>
                    </div>
                </div>

                <div class="details-item">
                    <!-- <div class="profile" *ngFor="let info of basicInfo">
                        <h3>About Me</h3>
                        <span>{{ info.summary }}</span>
                    </div> -->
                    <!-- Work Experience -->
                    <h3>Experience </h3>
                    <div class="work bottom-item" *ngFor="let getExperienceData of datasourceExp ">
                        <div class="row">
                            <!-- <div class="col-1">
                                <h3 style="color: red">
                                SchoolOrUniversity 
                                </h3>
                            </div> -->

                            <div class="col-11" *ngIf="getExperienceData">

                                <ul>
                                    <li>
                                        <!-- <i class="bx bxs-shopping-bag"></i><strong>SchoolOrUniversity:</strong> -->
                                        {{ getExperienceData.JobRole }}

                                    </li>
                                </ul>

                                <li>
                                    <strong>Company Name:</strong>
                                    {{ getExperienceData.CompanyName }}

                                </li>

                                <li>
                                    <strong>Employeement Type:</strong>
                                    {{ getExperienceData.EmployeementTypeName }}

                                </li>

                                <li>
                                    <strong>WorkLocation:</strong>
                                    {{ getExperienceData.WorkLocation }}

                                </li>

                                <li>
                                    <strong>CurrentJob:</strong>
                                    {{getExperienceData.IsCurrentJob ? 'Yes' : 'No'}}

                                </li>

                                <li>
                                    <strong>Start Date ::</strong>
                                    {{getExperienceData.FromDate | date:'dd-MM-yyyy' }}
                                </li>

                                <li>
                                    <strong>End Date ::</strong>
                                    {{getExperienceData.ToDate | date:'dd-MM-yyyy' }}
                                </li>

                                <li>
                                    <strong>ShiftJob:</strong>
                                    {{getExperienceData.IsShiftJob ? 'Yes' : 'No'}}
                                </li>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="details-item">
                    <!-- <div class="profile" *ngFor="let info of basicInfo">
                        <h3>About Me</h3>
                        <span>{{ info.summary }}</span>
                    </div> -->
                    <!-- Work Experience -->
                    <h3>Achievements</h3>
                    <div class="work bottom-item" *ngFor="let getAchievementsData of datasourceAchiv ">
                        <div class="row">
                            <!-- <div class="col-1">
                                <h3 style="color: red">
                                SchoolOrUniversity 
                                </h3>
                            </div> -->

                            <div class="col-11" *ngIf="getAchievementsData">

                                <li>
                                    <strong>Description:</strong>
                                    {{ getAchievementsData.DescriptionOfAchievments}}

                                </li>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="details-item">
                    <!-- <div class="profile" *ngFor="let info of basicInfo">
                        <h3>About Me</h3>
                        <span>{{ info.summary }}</span>
                    </div> -->
                    <!-- Work Experience -->
                    <h3>Certification</h3>
                    <div class="work bottom-item" *ngFor="let getCertificateData of datasourceCerty">
                        <div class="row">


                            <div class="col-11" *ngIf="getCertificateData">

                                <ul>
                                    <li>
                                        <!-- <i class="bx bxs-shopping-bag"></i><strong>SchoolOrUniversity:</strong> -->
                                        {{ getCertificateData.CertificationName }}

                                    </li>
                                </ul>

                                <li>
                                    <strong>Description:</strong>
                                    {{ getCertificateData.Description }}

                                </li>

                                <li>
                                    <strong>Date Of Issue:</strong>
                                    {{getCertificateData.DateOfIssue | date:'dd-MM-yyyy'}}

                                </li>

                                <li>
                                    <strong> Date Of Validity:</strong>
                                    {{getCertificateData.DateOfValidity | date:'dd-MM-yyyy'}}
                                </li>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="details-item">
                    <!-- <div class="profile" *ngFor="let info of basicInfo">
                        <h3>About Me</h3>
                        <span>{{ info.summary }}</span>
                    </div> -->
                    <!-- Work Experience -->
                    <h3>Skills</h3>
                    <div class="work bottom-item" *ngFor="let getSkillData of datasourceSkill ">
                        <div class="row">
                            <div class="col-11" *nfIf="getSkillData">
                                <li>
                                    {{getSkillData.Skills}}
                                </li>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="details-item">

                    <h3>Languages</h3>
                    <div class="work bottom-item" *ngFor="let getLanguagesData of datasourceLanguages  ">
                        <div class="row">
                            <div class="col-11" *ngIf="getLanguagesData">
                                <li>
                                    {{getLanguagesData.Language}}
                                </li>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="details-item">

                    <h3>Preffered Location</h3>
                    <div class="work bottom-item" *ngFor="let getLocationsData of datasourceLocation ">
                        <div class="row">
                            <div class="col-11" *ngIf="getLocationsData">
                                <li>
                                    {{getLocationsData.CityName}}
                                </li>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>