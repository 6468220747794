<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Edit Company Profile</h2>
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                            <a routerLink="/employer/dashboard/:id/companies/:id">Companies list</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Edit Company Profile</li>
                    </ul>
                    <a class="cmn-btn" routerLink="/employer/:id/companies/:id/create">
                        Create
                        <i class='bx bx-plus'></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- company list -->

<!-- <div *ngIf="companyInfo.length>0" class="person-details-area resume-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="details-item">
                <div class="bottom-item">
                    <div class="create-button">
                        <div>
                            <h2>Companies</h2>
                        </div>
                        <div>
                            <a class="cmn-btn"  (click)="createButton()">Create <i class='bx bx-plus'></i></a>
                        </div>
                    </div>
                </div>

                <div class="work bottom-item" *ngFor="let comp of companyInfo; let i = index">
                    <ul>
                        <li>
                            <i class='bx bx-globe'></i>
                            {{comp.company_url}}
                        </li>
                        <i class="bx bx-trash bx-sm bx-tada-hover" style="float: right;" (click)="delComp(i)"></i>
                        <i class="bx bx-edit bx-sm bx-tada-hover" style="float: right;" (click)="editComp(i)"></i>
                    </ul>
                    <ul>
                        <li>
                            <i class='bx bx-envelope'></i>
                            {{comp.contact_email}}
                        </li>
                    </ul>

                    <p>{{comp.mobile_number}}, {{comp.office_number}}
                        | {{comp.company_address}},Pakistan
                    </p>

                    <h3>
                        {{comp.company_name}}
                    </h3><span *ngIf="comp.is_default">(Present)</span>

                    <p>Since: <strong>{{comp.operating_since | date}}</strong></p>
                    <p>CEO: <strong>{{comp.ceo_name}}</strong></p>
                    <p>HR / Dept Head: <strong>{{comp.hr_head_department}}</strong></p>
                    <p>Designation: <strong>{{comp.job_designation}}</strong></p>
                    <p>Industry: <strong>{{comp.industry}}</strong></p>
                    <p>Ownership Type: <strong>{{comp.ownership_type}}</strong></p>
                    <p>Description: <strong>{{comp.company_description}}</strong></p>
                    <p>Origin: <strong>{{comp.origin_of_company}}</strong></p>
                    <p># of Offices: <strong>{{comp.number_of_offices}}</strong></p>
                    <p>Website: <strong>{{comp.company_url}}</strong></p>
                    <p># of Employees: <strong>{{comp.number_of_employees}}</strong></p>

                </div>
            </div>
        </div>
    </div>
</div> -->

<!--  end company list -->

<!-- Create Company -->
<div class="dashboard-area ptb-100">
    <div class="container">
        <div class="profile-content">

            <form [formGroup]="companyForm">

                <div class="profile-content-inner">
                    <h2>Company</h2>

                    <div class="row">


                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>Company Name:</label>
                                <input type="text" class="form-control" formControlName="company_name"
                                    placeholder="e.g. Acme Corporation."
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.company_name.errors }">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.company_name.errors">
                                    <div *ngIf="fcomp.company_name.errors.required">
                                        Company Name is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>CEO/Company head Name:</label>
                                <input type="text" class="form-control" formControlName="ceo_name"
                                    placeholder="e.g. John Doe."
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.ceo_name.errors }">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.ceo_name.errors">
                                    <div *ngIf="fcomp.ceo_name.errors.required">
                                        CEO or Company head Name is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>HR/Head of Dept Name:</label>
                                <input type="text" class="form-control" formControlName="hr_head_department"
                                    placeholder="e.g. John Doe."
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.hr_head_department.errors }">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.hr_head_department.errors">
                                    <div *ngIf="fcomp.hr_head_department.errors.required">
                                        HR/Head of Dept Name is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>Job Designation:</label>
                                <input type="text" class="form-control" formControlName="job_designation"
                                    placeholder="e.g. executive, manager, director, supervisor etc."
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.job_designation.errors }">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.job_designation.errors">
                                    <div *ngIf="fcomp.job_designation.errors.required">
                                        Job Designation is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>Industry:</label>
                                <input type="text" class="form-control" formControlName="industry"
                                    placeholder="e.g. IT industry, Health industry etc."
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.industry.errors }">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.industry.errors">
                                    <div *ngIf="fcomp.industry.errors.required">
                                        Job Designation is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>Ownership Type:</label>
                                <select class="form-control form-select" formControlName="ownership_type"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.ownership_type.errors }">
                                    <option value="" disabled selected>Choose any one</option>
                                    <option value="Sole Proprietorship">Sole Proprietorship</option>
                                    <option value="Partnership">Partnership</option>
                                    <option value="Limited Liability Company">Limited Liability Company
                                    </option>
                                    <option value="Corporation">Corporation</option>
                                    <option value="Cooperative">Cooperative</option>
                                </select>
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.ownership_type.errors">
                                    <div *ngIf="fcomp.ownership_type.errors.required">
                                        Ownership Type is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>Company Address:</label>
                                <input type="text" class="form-control" formControlName="company_address"
                                    placeholder="Company Address."
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.company_address.errors }">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.company_address.errors">
                                    <div *ngIf="fcomp.company_address.errors.required">
                                        Company Address is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>Origin of Company:</label>
                                <input type="text" class="form-control" formControlName="origin_of_company"
                                    placeholder="How did the company start?"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.origin_of_company.errors }">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.origin_of_company.errors">
                                    <div *ngIf="fcomp.origin_of_company.errors.required">
                                        Origin of Company is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>Number of Offices:</label>
                                <input type="number" class="form-control" formControlName="number_of_offices"
                                    placeholder="Total number of offices"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.number_of_offices.errors }">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.number_of_offices.errors">
                                    <div *ngIf="fcomp.number_of_offices.errors.required">
                                        Number of Offices is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>Contact Email:</label>
                                <input type="email" class="form-control" placeholder="jane.doe@johndoehub.com"
                                    formControlName="contact_email"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.contact_email.errors }">
                                <div *ngIf="submittedComp && fcomp.contact_email.errors" class="invalid-feedback">
                                    <div *ngIf="fcomp.contact_email.errors.required">Email is required</div>
                                    <div *ngIf="fcomp.contact_email.errors.email">Email is invalid</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>Contact Person:</label>
                                <input type="text" class="form-control" formControlName="contact_person"
                                    placeholder="Name of Person to Contact"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.contact_person.errors }">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.contact_person.errors">
                                    <div *ngIf="fcomp.contact_person.errors.required">
                                        Contact Person is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>Company URL:</label>
                                <input type="text" class="form-control" formControlName="company_url"
                                    placeholder="URL to company website"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.company_url.errors }">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.company_url.errors">
                                    <div *ngIf="fcomp.company_url.errors.required">
                                        Company URL is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>Company Description:</label>
                                <input type="text" class="form-control" formControlName="company_description"
                                    placeholder="Company Address."
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.company_description.errors }">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.company_description.errors">
                                    <div *ngIf="fcomp.company_description.errors.required">
                                        Company Description is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>Number of Employees:</label>
                                <input type="number" class="form-control" formControlName="number_of_employees"
                                    placeholder="Total number of Employees"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.number_of_employees.errors }">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.number_of_employees.errors">
                                    <div *ngIf="fcomp.number_of_employees.errors.required">
                                        Number of Employees is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>Operating Since:</label>
                                <input type="date" class="form-control" placeholder="01/01/1995"
                                    formControlName="operating_since"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.operating_since.errors }">
                                <div *ngIf="submittedComp && fcomp.operating_since.errors" class="invalid-feedback">
                                    <div *ngIf="fcomp.operating_since.errors.required">
                                        Startup date is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>Mobile Number:</label>
                                <input type="number" class="form-control" formControlName="mobile_number"
                                    placeholder="Company's Phone Number"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.mobile_number.errors }">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.mobile_number.errors">
                                    <div *ngIf="fcomp.mobile_number.errors.required">
                                        Company's Mobile Number is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>Office Number:</label>
                                <input type="number" class="form-control" formControlName="office_number"
                                    placeholder="Company's Office Number"
                                    [ngClass]="{ 'is-invalid': submittedComp && fcomp.office_number.errors }">
                                <div class="invalid-feedback" *ngIf="submittedComp && fcomp.office_number.errors">
                                    <div *ngIf="fcomp.office_number.errors.required">
                                        Company's Office Number is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <input style="margin-right: 2%;" value="Present" type="checkbox"
                                    formControlName="is_default" class="form-check-input" />
                                <label>Currently Working here</label>
                            </div>
                        </div>

                    </div>
                </div>
                <button (click)="companyUpdateForm()" class="btn dashboard-btn">
                    Update Your Information
                </button>
            </form>
        </div>
    </div>
</div>

<!-- End create company -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
