
<div class="profile-content">
    <form [formGroup]="experienceForm" id="form">
        <!-- <i matTooltip="Close" style="font-size: 35px;margin-left: 529px;margin-top: -15px;color: red;"  
        class='bx bx-x Clear' (click)="close()"></i> -->
        <div style="width: 10px;
        height: 5px;
        position: relative;
        float: right;
        top: -16px;"><i matTooltip="Close" style="font-size: 25px;"  
            class='bx bx-x Clear' (click)="close()"></i></div>
        <div class="profile-content-inner">
            <div class="Name">Add Experience</div>

            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label>Company Name <label class="star">*</label></label>
                        <input type="text" class="form-control" formControlName="CompanyName"
                            placeholder="Enter Company Name"
                            [ngClass]="{ 'is-invalid': submittedExp && fexp.CompanyName.errors }">
                            <div class="invalid-feedback" *ngIf="submittedExp && fexp.CompanyName.errors">
                                <div *ngIf="fexp.CompanyName.errors.required">
                                    Company Name is required
                                </div>
                            </div>
                    </div>
                </div>


                <div class="col-lg-12 col-md-12">
                    <div class="form-group" *ngIf="saveExpBtn">
                        <label>Job Role <label class="star">*</label></label>
                        <input type="text" class="form-control form-select" [formControl]="formControlObj"
                                    [matAutocomplete]="auto" placeholder="Select Job Role" [ngClass]="{ 'is-invalid': submittedExp && fexp.JobRoleId.errors }">
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnForRole"
                                    (optionSelected)="onchangejobrole($event.option.value.Id)">
                                    <mat-option *ngFor="let jobrole of filterJobRole | async" [value]="jobrole">
                                        {{jobrole.JobRole}}
                                    </mat-option>
                                </mat-autocomplete>
                        <!-- <select class="form-control form-select" formControlName="JobRoleId"
                            [ngClass]="{ 'is-invalid': submittedExp && fexp.JobRoleId.errors }">
                            <option value="" disabled selected>Please select</option>
                            <option *ngFor="let JobRole of allJobRole" [value]="JobRole.Id ">
                                {{JobRole.JobRole}}
                            </option>
                        </select> -->
                        <div class="invalid-feedback" *ngIf="submittedExp && fexp.JobRoleId.errors">
                            <div *ngIf="fexp.JobRoleId.errors.required">
                                Jobrole is required
                            </div>
                        </div>
                    </div>

                    <div class="form-group" *ngIf="updateExpBtn">
                        <label>Job Role <label class="star">*</label></label>
                       
                        <select class="form-control form-select" formControlName="JobRoleId"
                            [ngClass]="{ 'is-invalid': submittedExp && fexp.JobRoleId.errors }">
                            <option value="" disabled selected>Please select</option>
                            <option *ngFor="let JobRole of allJobRole" [value]="JobRole.Id ">
                                {{JobRole.JobRole}}
                            </option>
                        </select>
                        <div class="invalid-feedback" *ngIf="submittedExp && fexp.JobRoleId.errors">
                            <div *ngIf="fexp.JobRoleId.errors.required">
                                Jobrole is required
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label>Employment </label>
                        <select class="form-control form-select" formControlName="EmployeementType"
                            [ngClass]="{ 'is-invalid': submittedExp && fexp.EmployeementType.errors }">
                            <option value="" disabled selected>Please select</option>
                            <option *ngFor="let TaxType of allEmployeementType" [value]="TaxType.ListIndex">
                                {{TaxType.MessageText}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label>Work Location <label class="star">*</label></label>
                        <input type="text" class="form-control" formControlName="WorkLocation"
                            placeholder="Enter Work Location"
                            [ngClass]="{ 'is-invalid': submittedExp && fexp.WorkLocation.errors }">
                            <div class="invalid-feedback" *ngIf="submittedExp && fexp.WorkLocation.errors">
                                <div *ngIf="fexp.WorkLocation.errors.required">
                                    Work Location is required
                                </div>
                            </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="form-group">
                        <input style="margin-right: 2%;" (change)=handleClick($event.target.checked) type="checkbox"
                            formControlName="IsCurrentJob" class="form-check-input" />
                        <label>Currently Working here</label>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="form-group">
                        <input style="margin-right: 2%;" value="Present" type="checkbox" formControlName="IsShiftJob"
                            class="form-check-input" />
                        <label>Shift Job</label>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="form-group">
                        <label>Start Date </label>
                        <input type="date" class="form-control" placeholder="01/01/1995" formControlName="FromDate"
                            [ngClass]="{ 'is-invalid': submittedExp && fexp.FromDate.errors }">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12" *ngIf="!isCurrentJobvalue">
                    <div class="form-group">
                        <label>End Date</label>
                        <input type="date" class="form-control" placeholder="01/01/1995" formControlName="ToDate"
                            [ngClass]="{ 'is-invalid': submittedExp && fexp.ToDate.errors }">
                    </div>
                </div>

            </div>
        </div>

        <!-- <button (click)="experienceInfoForm()" class="btn dashboard-btn" *ngIf="saveExpBtn">
            Save Your Information
        </button>
        <button (click)="experienceUpdateForm()" class="btn dashboard-btn" *ngIf="updateExpBtn">
            Update Your Information
        </button> -->

        <div class="button">
            <button (click)="close()" class=" btn cancle " >
                Cancel
            </button>

        <button (click)="experienceInfoForm()" class="btn savebtn" *ngIf="saveExpBtn">
            Save
        </button>

        <button (click)="experienceUpdateForm()" class="btn savebtn" *ngIf="updateExpBtn">
            Update
        </button>
        </div>
    </form>
</div>