import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import data from 'src/app/components/data/data';
import { SharedService } from 'src/app/shared.service';
import { Observable } from 'rxjs';
import { JobRole } from 'src/app/interface';
import { GlobalService } from 'src/app/global.service';
import * as _moment from 'moment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { idText } from 'typescript';
import { map, startWith } from 'rxjs/operators';
const moment = _moment;

@Component({
    selector: 'app-add-edit-exp',
    templateUrl: './add-edit-experience.html',
    styleUrls: ['./add-edit-experience.scss']
})
export class AddEditExperienceComponent implements OnInit, OnDestroy {

    // submitted: boolean = false;
    submittedExp: boolean = false;
    experienceForm: FormGroup;
    public experienceInfo = [];
    response: any;
    expId: number = null;
    saveExpBtn: boolean = true;
    updateExpBtn: boolean = false;
    city = data.cities;
    // openform = false;
    boolCreate = true;
    
    allJobRole: Observable<JobRole[]>;

    allJobRoleauto: JobRole[];
    filterJobRole?: Observable<JobRole[]>;
    formControlObj = new FormControl();
    JobRoleId: number;


    allEmployeementType: Observable<any>;
    isCurrentJobvalue: boolean = false;
    hideForm: boolean = false;
    hideTable: boolean = true;

    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private Service: SharedService<any>,
        private Global: GlobalService,
        private dialogRef: MatDialogRef<AddEditExperienceComponent>,
        @Inject(MAT_DIALOG_DATA) public editData: any
    ) { }

    ngOnInit(): void {
        this.experienceForm = this.formBuilder.group({
            Id: ['', null],
            EmpId :['',null],
            CompanyName: ['', [Validators.required]],
            JobRoleId: ['', [Validators.required]],
            JobRole: ['', [Validators.nullValidator]],
            EmployeementType: ['', [Validators.nullValidator]],
            WorkLocation: ['', [Validators.required]],
            FromDate: ['', [Validators.nullValidator]],
            ToDate: ['', [Validators.nullValidator]],
            IsShiftJob: ['', [Validators.nullValidator]],
            IsCurrentJob: ['', [Validators.nullValidator]],
        })

        if(this.editData){
            this.editExp(this.editData)
            this.updateExpBtn = true
            this.saveExpBtn = false
        }
        else{
            this.updateExpBtn = false
            this.saveExpBtn = true
        
        }
         
        this.Service.Get('JobRole/GetJobRole?Id=0', null).subscribe(data => {
            if (data != null) {
              this.allJobRoleauto = data;
             // this.formControlObj.setValue(this.allJobRole[0]);
              this.filterJobRole = this.formControlObj.valueChanges.pipe(
                startWith<string | JobRole>(''),
                map(value => typeof value === 'string' ? value : value.JobRole),
                map(JobRole => JobRole ? this._filterRole(JobRole) : this.allJobRoleauto.slice()),
              );
             // this.onchangejobrole(this.allJobRole[0].Id);
            }
          });


        this.Service.Get('JobRole/GetJobRole?Id=0', null).subscribe(jobrole => {
            this.allJobRole = jobrole;
        })

        this.Service.Get('GetListValue?Id=5', null).subscribe(employeetype => {
            this.allEmployeementType = employeetype;
     });

        this.experienceForm.get('currentlyWorking').valueChanges.subscribe(value => {
            value ? this.experienceForm.get('endDate').disable() : this.experienceForm.get('endDate').enable();
        })
    }

    get fexp(): { [key: string]: AbstractControl } {
        return this.experienceForm.controls;
    }
    handleClick(value: any){
       // alert(value.target.checked);
    this.isCurrentJobvalue = value;

    }

    private _filterRole(JobRole: string): JobRole[] {
        const filterValue = JobRole.toLowerCase();
        return this.allJobRoleauto.filter(
          option => option.JobRole?.toLowerCase().indexOf(filterValue) === 0
        );
      }
    
      displayFnForRole(value: any) {
        return value ? value.JobRole : undefined;
      }

      onchangejobrole(event) {
        this.JobRoleId = event
      }
    //Experience
    experienceInfoForm() {
        this.submittedExp = true;
        this.experienceForm.controls["JobRoleId"].setValue(this.JobRoleId);
        if (this.experienceForm.invalid) {
            this.toastr.error("Please fill out the required fields (* fields)");
        }
        else {
            const updateexperince = this.experienceForm.getRawValue();
            updateexperince.EmpId = this.Global.getCandidateId();
            // if(updateexperince.IsCurrentJob == true){
            //     this.isCurrentJob = false;
            // }
            this.Service.Post('CandidateExperienceInfo/InsertCandidateExperienceInfo/', updateexperince).subscribe(
                (res) => {
                    this.toastr.success(res);
                    this.dialogRef.close('Save');        
                    this.hideForm = false;
                    this.hideTable = true;                        
                    // this.openform = false;                        
                    this.boolCreate = true;   
                },
                (error) => {
                    this.toastr.error(error.error);
                });
            this.submittedExp = false;
        }
    }

    experienceUpdateForm() {
        this.submittedExp = true;

        if (this.experienceForm.invalid) {
            this.toastr.error("Please fill out the required fields (* fields)");
        }
        else {
            const updateexperince = this.experienceForm.getRawValue();
            updateexperince.EmpId = this.Global.getCandidateId();
            this.Service.Put('CandidateExperienceInfo/UpdateCandidateExperienceInfo/', updateexperince).subscribe(
                (res) => {
                    this.toastr.success(res);
                    // this.editExp(event)
                    this.dialogRef.close('Update');;
                   
                    this.hideForm = false;
                    this.hideTable = true;                        
                    // this.openform = false;                        
                    this.boolCreate = true;    
                },
                (error) => {
                    this.toastr.error(error.error);
                });
            this.submittedExp = false;
        }
    }
    close(){
        this.dialogRef.close(); 
    }
    editExp(experienceInfo: any) {
                this.experienceForm.controls["Id"].setValue(experienceInfo.Id);
                this.experienceForm.controls["CompanyName"].setValue(experienceInfo.CompanyName);
                this.experienceForm.controls["JobRoleId"].setValue(experienceInfo.JobRoleId);
                this.experienceForm.controls["EmployeementType"].setValue(experienceInfo.EmployeementType);
                this.experienceForm.controls["WorkLocation"].setValue(experienceInfo.WorkLocation);
                this.experienceForm.controls["IsCurrentJob"].setValue(experienceInfo.IsCurrentJob);
                if(experienceInfo.FromDate != null)
                {
                    const momentDate = new Date(experienceInfo.FromDate);
                    const formateddate = moment(momentDate).format("yyyy-MM-DD");
                    experienceInfo.FromDate = formateddate;
                }
                if(experienceInfo.ToDate != null)
                {
                    const momentDate1 = new Date(experienceInfo.ToDate);
                    const formateddate2 = moment(momentDate1).format("yyyy-MM-DD");
                    experienceInfo.ToDate = formateddate2;
                }
                this.experienceForm.controls["FromDate"].setValue(experienceInfo.FromDate);
                this.experienceForm.controls["ToDate"].setValue(experienceInfo.ToDate);
                this.experienceForm.controls["IsShiftJob"].setValue(experienceInfo.IsShiftJob);
         
                if(experienceInfo.IsCurrentJob == true)
                {
                    this.isCurrentJobvalue = true;
                }  
                this.Service.Get('JobRole/GetJobRole?Id=0', null).subscribe(jobrole => {
                    this.allJobRole = jobrole;
                    this.Service.Get('GetListValue?Id=5', null).subscribe(employeetype => {
                            this.allEmployeementType = employeetype;
                     });
        this.submittedExp = false;
    })
}

    ngOnDestroy(): void {

    }

}
