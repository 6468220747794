import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './components/guards/auth.guard';
import { AboutComponent } from './components/pages/about/about.component';
import { CandidatesComponent } from './components/pages/candidates/candidates.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { EmployersRegisterComponent } from './components/pages/employers-register/employers-register.component';
import { EmployersComponent } from './components/pages/employers/employers.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { JobDetailsComponent } from './components/pages/job-details/job-details.component';
import { JobsComponent } from './components/pages/jobs/jobs.component';
import { LoginComponent } from './components/pages/login/login.component';
import { PostAJobComponent } from './components/pages/post-a-job/post-a-job.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { ResumeDetailsComponent } from './components/pages/resume-details/resume-details.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { UsersComponent } from './components/pages/User/users.component';
import { RecommandedjobsComponent } from './components/pages/dashboard/Recommanded-jobs/Recommanded-jobs.component';
import { BookMarkjobsComponent } from './components/pages/dashboard/BookMark-jobs/BookMark-jobs.component';
import { WorkFromHomeJobComponent } from './components/pages/WorkFromHomeJob/WorkFromHomeJob.component';
import { CreateCandidateComponent } from './components/pages/employers-register/candidate/createcandidate.component';
import { CreateCollegeComponent } from './components/pages/College/createcollege.component';
import { CompanySignupComponent } from './components/pages/companys/companysignup/companysignup.component';
import { CompanyTableComponent } from './components/pages/companys/companytable/companytable.component';
import { EditJobPostComponent } from './components/pages/post-a-job/edit-job-post/edit-job-post.component';
import { ViewResumeComponent } from './components/pages/candidates/ViewResume/viewresume.component';
import { CollegeHomeComponent } from './components/pages/College/College-Home/college_home.component';
import { CollegeCandidateTableComponent } from './components/pages/College/collegecandidatetable/collegecandidatetable.component';
import { CollegeProfileComponent } from './components/pages/College/College-Home/CollegeProfile/collegeprofile.component';
import { CompanyHomeComponent } from './components/pages/companys/CompanyHome/companyhome.component';
import { CompanyManageJobComponent } from './components/pages/companys/CompanyManageJob/companymanagejob.component';
import { UserHomeComponent } from './components/pages/Users/UserHome/userhome.component';
import { ShortListedCandidateForOwnerComponent } from './components/pages/ShortListedCandidateForOwner/shortlistedcandidateforowner.component';
import { EditCandidateComponent } from './components/pages/employers-register/EditCandidate/editcandidate.component';
import { CandidateAppliedJobComponent } from './components/pages/candidates/candidateappliedjob/candidateappliedjob.component';
import { ForgotPasswordComponent } from './components/pages/ForgotPassword/forgotpassword.component';
import { CandidateTestComponent } from './components/pages/candidates/CandidateTest/candidatetest.component';
import { LandingPageComponent } from './components/pages/candidates/landingpage/landingpage.component';
import { PartTimeJobComponent } from './components/pages/part-time-job/parttimejob.component';
import { JobForWomenComponent } from './components/pages/jobsforwomen/jobforwomen.component';
import { JobForFreshersComponent } from './components/pages/jobforfreshers/jobforfresher.component';
import { JobForInternshipComponent } from './components/pages/JobForInternship/jobforinternship.component';
const routes: Routes = [
  
    { path: '', component: HomeOneComponent },
    // { path: 'home-two', component: HomeTwoComponent },
    { path: 'home-three', component: HomeThreeComponent },
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'jobs', component: JobsComponent },
    { path: 'searchjobs', component: EmployersComponent },
    { path: 'workfromhomejobs', component: WorkFromHomeJobComponent },
    { path: 'parttimejob', component: PartTimeJobComponent },
    { path: 'jobforfreshers', component: JobForFreshersComponent },
    { path: 'jobforwomen', component: JobForWomenComponent },
    { path: 'jobforinternship', component: JobForInternshipComponent },
    { path: 'recommandedjobs', component: RecommandedjobsComponent },
    { path: 'bookjobs', component: BookMarkjobsComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'about', component: AboutComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'terms-conditions', component: TermsConditionsComponent },
    { path: 'createcandidate', component: CreateCandidateComponent },
    { path: 'createcollege', component: CreateCollegeComponent },
    { path: 'company-register', component: CompanySignupComponent },
    { path: 'companymanagejob', component: CompanyManageJobComponent },
    { path: 'shortlistedcandidateforowner', component: ShortListedCandidateForOwnerComponent },
    { path: 'candidateappliedjob', component: CandidateAppliedJobComponent },
    { path: 'forgotpassword', component: ForgotPasswordComponent },
    { path: 'candidatetest', component: CandidateTestComponent },
    { path: 'landing', component: LandingPageComponent },
    {
        path: 'editcandidate/:id',
        component: EditCandidateComponent,
        // canActivate: [AuthGuard, RoleGuardGuard],
        data: {
            expectedRoles: ["ROLE_SUPER_USER"]
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./components/pages/employers-register/EditCandidate/editcandidate.module')
                    .then(m => m.EditCandidateModule)
            }
        ]
    },

    // { path: 'collegehome', component: CollegeHomeComponent },
    { path: 'collegetable', component: CollegeCandidateTableComponent },
    {
        path: 'collegehome/profile',
        component: CollegeHomeComponent,
        // canActivate: [AuthGuard, RoleGuardGuard],
        data: {
            expectedRoles: ['ROLE_CANDIDATE']
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./components/pages/College/College-Home/college_home.module')
                    .then(m => m.CollegeModule)
            }
        ]
    },

    {
        path: 'userhome/:id',
        component: UserHomeComponent,
        // canActivate: [AuthGuard, RoleGuardGuard],
        data: {
            expectedRoles: ['ROLE_CANDIDATE']
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./components/pages/Users/UserHome/userhome.module')
                    .then(m => m.UserModule)
            }
        ]
    },

    {
        path: 'companyhome/profile',
        component: CompanyHomeComponent,
        // canActivate: [AuthGuard, RoleGuardGuard],
        // data: {
        //     expectedRoles: ['ROLE_CANDIDATE']
        // },
        children: [
            {
                path: '',
                loadChildren: () => import('./components/pages/companys/CompanyHome/companyhome.module')
                    .then(m => m.CompanyModule)
            }
        ]
    },

    {
        path: 'company',
        // canActivate: [AuthGuard],
        component: CompanyTableComponent
    },

    {
        path: 'employers',
        canActivate: [AuthGuard],
        component: EmployersComponent
    },
    {
        path: 'testimonials',
        canActivate: [AuthGuard],
        component: TestimonialsComponent
    },
    {
        path: 'faq',
        component: FaqComponent
    },

    {
        path: 'coming-soon',
        canActivate: [AuthGuard],
        component: ComingSoonComponent
    },
    {
        path: 'error',
        canActivate: [AuthGuard],
        component: ErrorComponent
    },

    {
        path: 'single-resume',
        // canActivate: [AuthGuard, RoleGuardGuard],
        // data: {
        //     expectedRoles: ['']
        // },
        component: ResumeDetailsComponent
    },
    {
        path: 'edit-job-post',
        // canActivate: [AuthGuard, RoleGuardGuard],
        data: {
            expectedRoles: ['ROLE_EMPLOYER']
        },
        component: EditJobPostComponent
    },
    {
        path: 'post-a-job',
        // canActivate: [AuthGuard, RoleGuardGuard],
        data: {
            expectedRoles: ['ROLE_EMPLOYER']
        },
        component: PostAJobComponent
    },

    {
        path: 'candidate/dashboard',
        component: DashboardComponent,
        // canActivate: [AuthGuard, RoleGuardGuard],
        data: {
            expectedRoles: ['ROLE_CANDIDATE']
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./components/pages/dashboard/dashboard.module')
                    .then(m => m.DashboardModule)
            }
        ]
    },

    {
        path: 'candidates',
        component: CandidatesComponent,
        // canActivate: [AuthGuard, RoleGuardGuard],
        data: {
            expectedRoles: ["ROLE_SUPER_USER", "ROLE_CANDIDATE", "ROLE_EMPLOYER"]
        },
    },

    {
        path: 'viewresume/:id',
        component: ViewResumeComponent,
        // canActivate: [AuthGuard, RoleGuardGuard],
        data: {
            expectedRoles: ["ROLE_SUPER_USER", "ROLE_CANDIDATE", "ROLE_EMPLOYER"]
        },
    },

    // {
    //     path: 'job-details/:id',
    //     // canActivate: [AuthGuard, RoleGuardGuard],
    //     data: {
    //         expectedRoles: ["ROLE_SUPER_USER", "ROLE_CANDIDATE", "ROLE_EMPLOYER"]
    //     },
    //     component: JobDetailsComponent
    // },

    { path: 'job-details', component: JobDetailsComponent },

    { path: 'users', component: UsersComponent },

    { path: 'candidate/register', component: EmployersRegisterComponent },
    // ADMIN

    { path: '**', component: ErrorComponent } // This line will remain down from the whole component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
