import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import { Observable } from 'rxjs';
import { cities, City, JobRole, Qualification, Qualificationa } from 'src/app/interface';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/global.service';
import { map, startWith } from 'rxjs/operators';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-employers',
  templateUrl: './employers.component.html',
  styleUrls: ['./employers.component.scss']
})
export class EmployersComponent implements OnInit {

  jobPostsInfo = [];
  //allJobRole: any;
  //allQualification: Observable<Qualificationa[]>;
  allcitie: Observable<City[]>

  allCities!: cities[];
  filtercities?: Observable<cities[]>;
  citiesControl = new FormControl();

  obs: any;
  dataFilter1: number[] = [];
  dataFilter2: number[] = [];
  candidateform: FormGroup;
  data: any;
  citycontrol = new FormControl();
  EducationControl = new FormControl();
  jobRoleControl = new FormControl();
  datasource: any;
  allCompanyName: Observable<any>;
  gender: number = 0;
  parttime: number = 0;
  workFromHome: number = 0;
  frehsherjob: number = 0;
  shifttype: number = 0;
  Internshipjob: number = 0;
  company: number = 0;
  Education: number = 0;

  jobType: number = 0;
  city: number = 0;
  page: number = 1;
  pagesize: number = 10;
  totalItems: number = 0;
  datapageInfo: any;

  jobrole: any;
  homecity: number = 0;
  education: number = 0;
  visible: boolean = false
  afterClickData: any
  info: any;

  allJobRole !: JobRole[];
  filterJobroles?: Observable<JobRole[]>;
  jobroleControl = new FormControl();

  allQualification!: Qualification[];
  filterQualifications?: Observable<Qualification[]>;
  QualificationsControl = new FormControl();

  constructor(
    // private jobsService: JobsService,
    private Service: SharedService<any>,
    private router: Router,
    public Global: GlobalService,
    private formBuilder: FormBuilder,
    private scroller: ViewportScroller,

  ) {
    this.data = this.router.getCurrentNavigation().extras;
    if (this.data.queryParams != null) {
      if (this.data.queryParams.myArray[0] == "searchString") {
        this.dataFilter2 = [];
        this.jobRoleControl.setValue(this.data.queryParams.myArray[1]);
        this.citycontrol.setValue(this.data.queryParams.myArray[2]);
        this.EducationControl.setValue(this.data.queryParams.myArray[3]);
        this.dataFilter2.push(this.data.queryParams.myArray[1]);
        this.dataFilter2.push(this.data.queryParams.myArray[2]);
        this.dataFilter2.push(this.data.queryParams.myArray[3]);
        this.dataFilter2.push(this.data.queryParams.myArray[4]);
        this.dataFilter2.push(this.data.queryParams.myArray[5]);
        this.dataFilter2.push(this.data.queryParams.myArray[6]);
        this.dataFilter2.push(this.data.queryParams.myArray[7]);
        this.dataFilter2.push(this.data.queryParams.myArray[8]);
        this.dataFilter2.push(this.data.queryParams.myArray[9]);
        this.refreshListFilter();
        this.jobrole = this.dataFilter2[0];
        this.homecity = this.dataFilter2[1];
        this.education = this.dataFilter2[2];
      }
    }
    else if (this.data.queryParams == null) {
      this.dataFilter2 = [];
      this.refreshListFilter();
    }
  }

  ngOnInit(): void {

    this.candidateform = this.formBuilder.group({
      jobtype: ['', Validators.nullValidator],
      city: ['', Validators.nullValidator],
    });

    this.refreshListFilter();
    // this.getAllJobRole();
    // this.getAllQualification();
    // this.refreshListOfCompany();


    this.Service.Get('JobRole/GetJobRole?Id=0', null).subscribe(data => {
      if (data != null) {
        this.allJobRole = data;
        if (this.jobrole != 0) {
          for (let role of this.allJobRole) {
            if (role.Id == this.jobrole) {
              this.jobroleControl.setValue(role);
            }
          }
        }
        this.filterJobroles = this.jobroleControl.valueChanges.pipe(
          startWith<string | JobRole>(''),
          map(value => typeof value === 'string' ? value : value.JobRole),
          map(JobRole => JobRole ? this._filterJobRole(JobRole) : this.allJobRole.slice()),
        );
      }
    })



    this.Service.Get('Qualification/GetQualification?Id=0', null).subscribe(data => {
      if (data != null) {
        this.allQualification = data;
        if (this.education != 0) {
          for (let quali of this.allQualification) {
            if (quali.Id == this.education) {
              this.QualificationsControl.setValue(quali);
            }
          }
        }
        this.filterQualifications = this.QualificationsControl.valueChanges.pipe(
          startWith<string | Qualification>(''),
          map(value => typeof value === 'string' ? value : value.Qualification),
          map(Qualification => Qualification ? this._filterQualificationa(Qualification) : this.allQualification.slice())
        );
      }
    })

    this.Service.Get('City/GetCity?Id=0', null).subscribe(data => {
      if (data != null) {
        this.allCities = data;
        if (this.homecity != 0) {
          for (let city of this.allCities) {
            if (city.Id == this.homecity) {
              this.citiesControl.setValue(city);
            }
          }
        }
        this.filtercities = this.citiesControl.valueChanges.pipe(
          startWith<string | cities>(''),
          map(value => typeof value === 'string' ? value : value.
            CityName),
          map(CityName => CityName ? this._filterCity(CityName) : this.allCities.slice())
        );
      }
    })

    this.GetJobCount();

  }

  private _filterJobRole(jobrole: string): JobRole[] {
    const filterValue = jobrole.toLowerCase();

    return this.allJobRole.filter(
      option => option.JobRole?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnForJobRole(value: any) {
    return value ? value.JobRole : undefined;
  }

  private _filterQualificationa(Qualification: string): Qualification[] {
    const filterValue = Qualification.toLowerCase();

    return this.allQualification.filter(
      option => option.Qualification?.toLowerCase().indexOf(filterValue) === 0
    );
  }
  displayFnForQualification(value: any) {
    return value ? value.Qualification : undefined;
  }


  private _filterCity(City: string): cities[] {
    const filterValue = City.toLowerCase();

    return this.allCities.filter(
      option => option.CityName?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnForCity(value: any) {
    return value ? value.CityName : undefined;
  }

  pageChanged(value: any) {
    this.page = value;
    this.search();
  }

  // getAllJobRole() {
  //   this.Service.Get('JobRole/GetJobRoleById?Id=' + this.jobrole, null).subscribe(jobrole => {
  //     this.allJobRole = jobrole;
  //   })
  // }
  // getAllQualification() {
  //   this.Service.Get('Qualification/GetQualificationById?Id=' + this.education, null).subscribe(Qualificationa => {
  //     this.allQualification = Qualificationa;
  //   })
  // }
  Login() {
    this.router.navigate(['/login']);
  }
  onchangeJobRole(jobrole: any) {
    this.jobrole = jobrole;
  }


  onchangeQualification(Qualification: any) {
    this.education = Qualification;
  }

  // getAllCity() {
  //   this.Service.Get('City/GetCity?Id=0', null).subscribe(city => {
  //     this.allcitie = city;
  // //   })
  // }

  refreshListOfCompany() {
    this.Service.Get('Company/GetCompanyDataSelection', null).subscribe(company => {
      this.allCompanyName = company;
    });
  }

  shift() {
    this.shifttype = 1;
    this.gender = 0;
    this.parttime = 0;
    this.frehsherjob = 0;
    this.workFromHome = 0;
    this.Internshipjob = 0;
    this.search();
  }

  search() {
    this.dataFilter2 = [];
    this.dataFilter2.push(Number(this.jobrole));
    this.dataFilter2.push(Number(this.homecity));
    this.dataFilter2.push(Number(this.education));
    this.dataFilter2.push(this.city);
    this.dataFilter2.push(this.workFromHome);
    this.dataFilter2.push(this.gender);
    this.dataFilter2.push(this.parttime);
    this.dataFilter2.push(this.frehsherjob);
    this.dataFilter2.push(this.Internshipjob);
    this.refreshListFilter();
  }

  refreshListFilter() {
    this.dataFilter2.push(this.page - 1);
    this.dataFilter2.push(this.pagesize);
    this.datasource = null;
    this.Service.Post('Job/GetJobsCardData', this.dataFilter2).subscribe(res => {
      this.jobPostsInfo = res[0];
      this.obs = this.jobPostsInfo;
      this.datapageInfo = res[1];
      this.totalItems = this.datapageInfo[0].length;
      this.CardClick(this.obs[0])
    })
  }

  onchangeCity(event: any) {
    this.visible = false
    this.city = event;
    this.search();
  }

  // SelectJobType(event: any) {
  //   if (event == 'Work From Home') {
  //     this.workFromHome = 1;
  //     this.gender = 0;
  //     this.parttime = 0;
  //     this.frehsherjob = 0;
  //     this.Internshipjob = 0;
  //     this.search();
  //     this.visible = false
  //   }

  //   else if (event == 'Part Time') {
  //     this.parttime = 1;
  //     this.workFromHome = 0;
  //     this.gender = 0;
  //     this.frehsherjob = 0;
  //     this.Internshipjob = 0;
  //     this.search();
  //     this.visible = false
  //   }

  //   else if (event == 'Jobs For Women') {
  //     this.workFromHome = 0;
  //     this.gender = 1;
  //     this.parttime = 0;
  //     this.frehsherjob = 0;
  //     this.Internshipjob = 0;
  //     this.search();
  //     this.visible = false
  //   }

  //   else if (event == 'Jobs For Freshers') {
  //     this.workFromHome = 0;
  //     this.frehsherjob = 1;
  //     this.gender = 0;
  //     this.parttime = 0;
  //     this.Internshipjob = 0;
  //     this.search();
  //     this.visible = false
  //   }

  //   else if (event == 'Internship') {
  //     this.workFromHome = 0;
  //     this.frehsherjob = 0;
  //     this.gender = 0;
  //     this.parttime = 0;
  //     this.Internshipjob = 1;
  //     this.search();
  //     this.visible = false
  //   }
  // }


  // AllFilters() {
  //   if (this.workFromHome == 1) {
  //     this.workFromHome = 1;
  //     this.parttime = 0;
  //     this.gender = 0;
  //     this.frehsherjob = 0;
  //     this.Internshipjob = 0;
  //     this.search();

  //   }
  //   else if (this.parttime == 1) {
  //     this.workFromHome = 0;
  //     this.parttime = 1;
  //     this.gender = 0;
  //     this.frehsherjob = 0;
  //     this.Internshipjob = 0;
  //     this.search();
  //   }
  //   else if (this.gender == 1) {
  //     this.gender = 1;
  //     this.frehsherjob = 0;
  //     this.workFromHome = 0;
  //     this.parttime = 0;
  //     this.Internshipjob = 0;
  //     this.search();
  //   }

  // else if (this.shifttype == 1) {
  //   this.shifttype = 1;
  //   this.workFromHome = 0;
  //   this.parttime = 0;
  //   this.gender = 0;
  //   this.frehsherjob = 0;
  //   this.company;
  //   this.Education;
  //   this.Internshipjob = 0;
  //   this.search();
  // }

  // else if (this.frehsherjob == 1) {
  //   this.workFromHome = 0;
  //   this.parttime = 0;
  //   this.gender = 0;
  //   this.frehsherjob = 1;
  //   this.Internshipjob = 0;
  //   this.search();
  // }
  // else if (this.Internshipjob == 1) {
  //   this.workFromHome = 0;
  //   this.parttime = 0;
  //   this.gender = 0;
  //   this.frehsherjob = 0;
  //   this.Internshipjob = 1;
  //   this.search();
  // }
  // else {
  //   this.workFromHome = 0;
  //   this.parttime = 0;
  //   this.gender = 0;
  //   this.frehsherjob = 0;
  //   this.Internshipjob = 0;
  //   this.search();
  // }
  // }


  CardClick(event) {
    this.scroller.scrollToAnchor("targetRed");
    if (event == undefined) {
      this.visible = false;
    }
    else 
    {
      this.visible = true;
      this.afterClickData = event;
    }
  }

  removefilter() {
    this.workFromHome = 0;
    this.parttime = 0;
    this.gender = 0;
    this.frehsherjob = 0;
    this.Internshipjob = 0;
    this.jobrole = 0;
    this.homecity = 0;
    this.education = 0
    this.dataFilter2 = [0];
    this.dataFilter2.push(0);
    this.dataFilter2.push(0);
    this.dataFilter2.push(0);
    this.dataFilter2.push(0);
    this.dataFilter2.push(0);
    this.dataFilter2.push(0);
    this.dataFilter2.push(0);
    this.dataFilter2.push(0);
    this.allJobRole = null;
    this.allQualification = null;
    this.refreshListFilter();
    this.GetJobCount();
    this.candidateform.reset();
    this.visible = false

    this.Service.Get('JobRole/GetJobRole?Id=0', null).subscribe(data => {
      if (data != null) {
        this.allJobRole = data;
        this.filterJobroles = this.jobroleControl.valueChanges.pipe(
          startWith<string | JobRole>(''),
          map(value => typeof value === 'string' ? value : value.JobRole),
          map(JobRole => JobRole ? this._filterJobRole(JobRole) : this.allJobRole.slice()),
        );
      }
    })

    this.Service.Get('Qualification/GetQualification?Id=0', null).subscribe(data => {
      if (data != null) {
        this.allQualification = data;

        this.filterQualifications = this.QualificationsControl.valueChanges.pipe(
          startWith<string | Qualification>(''),
          map(value => typeof value === 'string' ? value : value.Qualification),
          map(Qualification => Qualification ? this._filterQualificationa(Qualification) : this.allQualification.slice())
        );
      }
    })

    this.Service.Get('City/GetCity?Id=0', null).subscribe(data => {
      if (data != null) {
        this.allCities = data;
        this.filtercities = this.citiesControl.valueChanges.pipe(
          startWith<string | cities>(''),
          map(value => typeof value === 'string' ? value : value.
            CityName),
          map(CityName => CityName ? this._filterCity(CityName) : this.allCities.slice())
        );
      }
    })
    this.jobroleControl.setValue('');
    this.QualificationsControl.setValue('');
    this.citiesControl.setValue('');
  }

  isReadMore = true

  showText() {
    this.isReadMore = !this.isReadMore
  }

  GetJobCount() {
    this.Service.Get('Dashboard/GetDashboardData', null).subscribe(data => {
      this.info = data;
    })
  }
}
