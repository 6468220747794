import { Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalToastrConfig, ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import data from 'src/app/components/data/data';
import { Observable } from 'rxjs';
import { Qualificationa, Skills } from 'src/app/interface';
import { SharedService } from 'src/app/shared.service';
import { GlobalService } from 'src/app/global.service';
import { map } from 'rxjs/internal/operators/map';
import { toUnicode } from 'punycode';
import { MatDialogRef } from '@angular/material/dialog';
import { startWith } from 'rxjs/operators';

@Component({
    selector: 'app-add-edit-skill',
    templateUrl: './add-edit-skill.component.html',
    styleUrls: ['./add-edit-skill.component.scss']
})
export class AddEditSkillsLanguagesComponent implements OnInit, OnDestroy {
   

    submittedSkill: boolean = false;
    skillsForm: FormGroup;
    public skillInfo = [];
    saveSkillBtn: boolean = true;
    updateSkillBtn: boolean = false;
    response: any;
    public placeholder: string = '';
    public keyword = 'skill';
    public historyHeading: string = 'Recently selected';
    
    allSkill: Skills[];
    filterSkill?: Observable<Skills[]>;
    formControlSkill= new FormControl();
    SkillId: number;
    
    skillControl = new FormControl();
    public langData = data.langData;
    public proficiency = data.proficiency;
    openForm = false;
    boolVar = true;
    filteredSkills: Observable<string[]>;
    // allQualification: Observable<Qualificationa[]>;
    hideForm: boolean = false;
    hideTable: boolean = true;
  

    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private router: Router,
        private Service: SharedService<any>,
        private Global: GlobalService,
        private dialogRef: MatDialogRef<AddEditSkillsLanguagesComponent>,
    ) { }

    ngOnInit(): void {

        this.skillsForm = this.formBuilder.group({
            Id: ['', null],
            EmpId: ['', null],
            Skill_Id: ['', [Validators.required]],
            Skills: ['', null],
        })
        
        // this.Service.Get('Skills/GetSkills', null).subscribe(skill => {
        //     this.skillsData = skill;
        // });

        this.Service.Get('Skills/GetSkills', null).subscribe(data => {
            if (data != null) {
              this.allSkill = data;
              this.filterSkill = this.formControlSkill.valueChanges.pipe(
                startWith<string | Skills>(''),
                map(value => typeof value === 'string' ? value : value.Skills),
                map(Skills => Skills ? this._filterSkill(Skills) : this.allSkill.slice()),
              );
            }
          })

     
    }

    get fskill(): { [key: string]: AbstractControl } {
        return this.skillsForm.controls;
    }

    close(){
        this.dialogRef.close(); 
    }
    private _filterSkill(Skills: string): Skills[] {
        const filterValue = Skills.toLowerCase();
        return this.allSkill.filter(
          option => option.Skills?.toLowerCase().indexOf(filterValue) === 0
        );
      }
    
      displayFnForSkill(value: any) {
        return value ? value.Skills : undefined;
      }

      onchangeSkill(event) {
        this.SkillId = event
      }
      
    //Skills
    skillsInfoForm() {
        this.submittedSkill = true;
        this.skillsForm.controls["Skill_Id"].setValue(this.SkillId);
        if (this.skillsForm.invalid) {
            this.toastr.error("Please fill out the required fields (* fields)");
        }
        else {
            const insertskill = this.skillsForm.getRawValue();
            insertskill.EmpId = this.Global.getCandidateId();
            
            this.Service.Post('CandidateSkills/InsertCandidtaeSkillsForIndiviual', insertskill).subscribe(
                (res) => {
                    this.toastr.success(res);
                    this.dialogRef.close('Save');                    
                    this.boolVar = true;    
                },
                (error) => {
                    //if (error.status == 401) this.router.navigate(['/login']);
                    this.toastr.error(error.error);
                });
            this.submittedSkill = false;
        }


    }

    ngOnDestroy(): void {

    }
}

