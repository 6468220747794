import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth.service';
import { GlobalService } from 'src/app/global.service';
import { cities, City, Jobpost, JobRole, Qualification, Qualificationa } from 'src/app/interface';
import { SharedService } from 'src/app/shared.service';


@Component({
  selector: 'app-jobforfreshers',
  templateUrl: './jobforfresher.component.html',
  styleUrls: ['./jobforfresher.component.scss']
})
export class JobForFreshersComponent implements OnInit, OnDestroy {

  // allJobRole: Observable<JobRole[]>;
  // allQualification: Observable<Qualificationa[]>;
  // allCities: Observable<cities[]>;
  cityControl = new FormControl();
  EducationControl = new FormControl();
  jobTypeControl = new FormControl();
  obs: any;
  data: any;
  dataFilter1: number[] = [];
  // datasource: Observable<Jobpost[]>;
  datasource = [];
  changeDetectorRef: any;
  paginator: any;
  page: number = 1;
  pagesize: number = 10;
  totalItems: number = 0;
  datapageInfo: any;
  visible : boolean = false
  afterClickData: any;

  
  allJobRole !: JobRole[];
  filterJobroles?: Observable<JobRole[]>;
  jobroleControl = new FormControl();

  allQualification!: Qualification[];
  filterQualifications?: Observable<Qualification[]>;
  QualificationsControl = new FormControl();

  allcitie: Observable<City[]>
  allCities!: cities[];
  filtercities?: Observable<cities[]>;
  citiesControl = new FormControl();

  city: number = 0;
  education: number = 0;
  jobrole:  number = 0;

  constructor(
    private Service: SharedService<any>,
    private toastr: ToastrService,
    private router: Router,
    public Global: GlobalService,
    private auth: AuthService
  ) {
    this.dataFilter1 = [];
    this.dataFilter1.push(0);
    this.dataFilter1.push(0);
    this.dataFilter1.push(0);

  }


  ngOnInit(): void {
    this.Service.Get('JobRole/GetJobRole?Id=0', null).subscribe(data => {
      if (data != null) {
        this.allJobRole = data;
        this.filterJobroles = this.jobroleControl.valueChanges.pipe(
          startWith<string | JobRole>(''),
          map(value => typeof value === 'string' ? value : value.JobRole),
          map(JobRole => JobRole ? this._filterJobRole(JobRole) : this.allJobRole.slice()),
        );
      }
    })



    this.Service.Get('Qualification/GetQualification?Id=0', null).subscribe(data => {
      if (data != null) {
        this.allQualification = data;
        this.filterQualifications = this.QualificationsControl.valueChanges.pipe(
          startWith<string | Qualification>(''),
          map(value => typeof value === 'string' ? value : value.Qualification),
          map(Qualification => Qualification ? this._filterQualificationa(Qualification) : this.allQualification.slice())
        );
      }
    })

    this.Service.Get('City/GetCity?Id=0', null).subscribe(data => {
      if (data != null) {
        this.allCities = data;
        this.filtercities = this.citiesControl.valueChanges.pipe(
          startWith<string | cities>(''),
          map(value => typeof value === 'string' ? value : value.
            CityName),
          map(CityName => CityName ? this._filterCity(CityName) : this.allCities.slice())
        );
      }
    })

    this.refreshList();

  }

  pageChanged(value: any) {
    this.page = value;
    this.refreshList();
  }

  private _filterJobRole(jobrole: string): JobRole[] {
    const filterValue = jobrole.toLowerCase();

    return this.allJobRole.filter(
      option => option.JobRole?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnForJobRole(value: any) {
    return value ? value.JobRole : undefined;
  }

  private _filterQualificationa(Qualification: string): Qualification[] {
    const filterValue = Qualification.toLowerCase();

    return this.allQualification.filter(
      option => option.Qualification?.toLowerCase().indexOf(filterValue) === 0
    );
  }
  displayFnForQualification(value: any) {
    return value ? value.Qualification : undefined;
  }


  private _filterCity(City: string): cities[] {
    const filterValue = City.toLowerCase();

    return this.allCities.filter(
      option => option.CityName?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnForCity(value: any) {
    return value ? value.CityName : undefined;
  }

  Login() {
    this.router.navigate(['/login']);
  }

  refreshList() {
//   if (this.Global.getjobTypeId() == 1) {
      this.dataFilter1 = [];
      this.dataFilter1.push(0);
      this.dataFilter1.push(0);
      this.dataFilter1.push(0);
      this.dataFilter1.push(1);
      this.dataFilter1.push(0);
      this.dataFilter1.push(this.jobrole);
      this.dataFilter1.push(this.education);
      this.dataFilter1.push(this.city);
      this.dataFilter1.push(this.page - 1);
      this.dataFilter1.push(this.pagesize);
      this.Service.Post('Job/GetJobsCardDataForHomePageJoft', this.dataFilter1).subscribe(data => {
        //   this.changeDetectorRef.detectChanges();
        this.datasource = data[0];
        this.obs = this.datasource;
        this.datapageInfo = data[1];
        if(this.datapageInfo.length > 0 ) {
        this.totalItems = this.datapageInfo[0].length;
          this.CardClick(this.obs[0]);
         }
      })
    }
    // }
    // else if (this.Global.getjobTypeId() == 2) {
    //   this.dataFilter1 = [];
    //   this.dataFilter1.push(0);
    //   this.dataFilter1.push(1);
    //   this.dataFilter1.push(0);
    //   this.dataFilter1.push(0);
    //   this.dataFilter1.push(0);
    //   this.dataFilter1.push(this.page - 1);
    //   this.dataFilter1.push(this.pagesize); 
    //   this.Service.Post('Job/GetJobsCardDataForHomePage', this.dataFilter1).subscribe(data => {
    //     this.datasource = data[0];
    //     this.obs = this.datasource;
    //     this.datapageInfo = data[1];
    //    if(this.datapageInfo.length > 0 ) {
    //     this.totalItems = this.datapageInfo[0].length;
       
    //       this.CardClick(this.obs[0]);
       
    //   }
    //   })
    // }

    // else if (this.Global.getjobTypeId() == 3) {
    //   this.dataFilter1 = [];
    //   this.dataFilter1.push(0);
    //   this.dataFilter1.push(0);
    //   this.dataFilter1.push(1);
    //   this.dataFilter1.push(0);
    //   this.dataFilter1.push(0);
    //   this.dataFilter1.push(this.page - 1);
    //   this.dataFilter1.push(this.pagesize);
    //   this.Service.Post('Job/GetJobsCardDataForHomePage', this.dataFilter1).subscribe(data => {
    //     this.datasource = data[0];
    //     this.obs = this.datasource;
    //     this.datapageInfo = data[1];
    //     if(this.datapageInfo.length > 0 ) {
    //     this.totalItems = this.datapageInfo[0].length;
    //       this.CardClick(this.obs[0]);
    //      }
    //   })
    // }
    // else if (this.Global.getjobTypeId() == 4) {
    //   this.dataFilter1 = [];
    //   this.dataFilter1.push(0);
    //   this.dataFilter1.push(0);
    //   this.dataFilter1.push(0);
    //   this.dataFilter1.push(1);
    //   this.dataFilter1.push(0);
    //   this.dataFilter1.push(this.page - 1);
    //   this.dataFilter1.push(this.pagesize);
    //   this.Service.Post('Job/GetJobsCardDataForHomePage', this.dataFilter1).subscribe(data => {
    //     this.datasource = data[0];
    //     this.obs = this.datasource;
    //     this.datapageInfo = data[1];
    //     if(this.datapageInfo.length > 0 ) {
    //     this.totalItems = this.datapageInfo[0].length;
    //       this.CardClick(this.obs[0]);
    //      }
    //   })
    // }
    // else if (this.Global.getjobTypeId() == 5) {
    //   this.dataFilter1 = [];
    //   this.dataFilter1.push(0);
    //   this.dataFilter1.push(0);
    //   this.dataFilter1.push(0);
    //   this.dataFilter1.push(0);
    //   this.dataFilter1.push(1);
    //   this.dataFilter1.push(this.page - 1);
    //   this.dataFilter1.push(this.pagesize);
    //   this.Service.Post('Job/GetJobsCardDataForHomePage', this.dataFilter1).subscribe(data => {
    //     this.datasource = data[0];
    //     this.obs = this.datasource;
    //     this.datapageInfo = data[1];
    //     if(this.datapageInfo.length > 0 ) {
    //     this.totalItems = this.datapageInfo[0].length;
    //     this.CardClick(this.obs[0]);
    //    }
    //   })
    // }}

    close(){
      this.router.navigate(['']);
    }
    
    search() {
      this.dataFilter1 = [];
      this.dataFilter1.push(Number(this.jobrole));
      this.dataFilter1.push(Number(this.education));
      this.dataFilter1.push(this.city);
     
      this.refreshList();
    }

    removefilter() {
      this.jobrole = 0;
      this.education = 0
      this.city = 0
      this.dataFilter1 = [];
      this.dataFilter1.push(Number(this.jobrole));
      this.dataFilter1.push(Number(this.education));
      this.dataFilter1.push(this.city);
      this.allJobRole = null;
      this.allQualification = null;
      this.refreshList();
      this.visible = false
  
      this.Service.Get('JobRole/GetJobRole?Id=0', null).subscribe(data => {
        if (data != null) {
          this.allJobRole = data;
          this.filterJobroles = this.jobroleControl.valueChanges.pipe(
            startWith<string | JobRole>(''),
            map(value => typeof value === 'string' ? value : value.JobRole),
            map(JobRole => JobRole ? this._filterJobRole(JobRole) : this.allJobRole.slice()),
          );
        }
      })
  
      this.Service.Get('Qualification/GetQualification?Id=0', null).subscribe(data => {
        if (data != null) {
          this.allQualification = data;
  
          this.filterQualifications = this.QualificationsControl.valueChanges.pipe(
            startWith<string | Qualification>(''),
            map(value => typeof value === 'string' ? value : value.Qualification),
            map(Qualification => Qualification ? this._filterQualificationa(Qualification) : this.allQualification.slice())
          );
        }
      })
  
      this.Service.Get('City/GetCity?Id=0', null).subscribe(data => {
        if (data != null) {
          this.allCities = data;
          this.filtercities = this.citiesControl.valueChanges.pipe(
            startWith<string | cities>(''),
            map(value => typeof value === 'string' ? value : value.
              CityName),
            map(CityName => CityName ? this._filterCity(CityName) : this.allCities.slice())
          );
        }
      })
      this.jobroleControl.setValue('');
      this.QualificationsControl.setValue('');
      this.citiesControl.setValue('');
    }

    onchangeJobRole(jobrole: any) {
      this.jobrole = jobrole;
    }
  
  
    onchangeQualification(Qualification: any) {
      this.education = Qualification;
    }

    onchangeCity(event: any) {
      this.city = event
    }

 


  CardClick(event) {
   
    if(event == undefined){
        this.visible = false;
    }
    else
    {
        this.visible = true;
        this.afterClickData = event;
    }
    
}
  ngOnDestroy(): void {


  }
}