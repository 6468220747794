<!-- Navbar -->
<div class="navbar-area fixed-top">
  <div class="mobile-nav mean-container" *ngIf="smallscreen">
    <div class="mean-bar">
        <a class="navbar-brand" href="#"><img src="assets/png/finallightthme.png" alt="Logo"></a>
        <a *ngIf="list" class="meanmenu-reveal" style="color:#fff;right:0;left:auto;top: 0;" (click)="click()">
            <span>
                <span>
                    <span></span>
                </span>
            </span>
        </a>
        <a *ngIf="close" (click)="closeclick1()" class="meanmenu-reveal meanmenu-reveal meanclose"
            style="right: 0px; left: auto; text-align: center; text-indent: 0px; font-size: 18px;">X</a>
    </div>

    <nav class="mean-nav" *ngIf="menu">
        <ul class="navbar-nav" style>
            <li class="nav-item">
                <a  *ngIf="myGlobals.isSubmitted  && myGlobals.isCandidate" routerLink="/candidate/dashboard"  class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">
                    My Profile
                </a>
            </li>

            <li  *ngIf="myGlobals.isCandidate && myGlobals.isSubmitted" class="nav-item">
              <a  routerLink="/jobs" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Find Job</a></li>

            <li  *ngIf="myGlobals.isCandidate  && myGlobals.isSubmitted" class="nav-item">
              <a routerLink="/recommandedjobs"  class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Recommanded Jobs </a></li>

                    <li  *ngIf="myGlobals.isCandidate  && myGlobals.isSubmitted" class="nav-item">
                      <a routerLink="/bookjobs"  class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">BookMarked Jobs  </a></li>

                            <li  *ngIf="myGlobals.isCandidate  && myGlobals.isSubmitted" class="nav-item">
                              <a routerLink="/candidateappliedjob"  class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Applied Jobs </a></li>
        </ul>
    </nav>
    <div class="side-nav">

        <a class="login-left" routerLink="/login" *ngIf="!myGlobals.isSubmitted" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}" style="margin-top: 56px;">
            <i class="flaticon-enter"></i>
            Login
        </a>
        <a class="login-left" *ngIf="myGlobals.isSubmitted" (click)="onLogout($event)"><i
                class="flaticon-enter"></i> Logout</a>
    </div>
</div>

<div class="main-nav three" *ngIf="largescreen">
    <div class="container-fluid">
      <nav class="navbar navbar-expand-md navbar-light">
        <!-- <a class="navbar-brand" href="#"><img src="assets/img/logo-4.png" alt="Logo"></a> -->
        <a class="navbar-brand" ><img src="assets/png/finallightthme.png" alt="Logo"></a>

        <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
          <ul class="navbar-nav">

            <li class="nav-item" *ngIf="!myGlobals.isSubmitted  && !myGlobals.isUser">
              <a routerLink="" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Home</a>
            </li>

            <!-- <li class="nav-item">
                            <a routerLink="/about" *ngIf="!myGlobals.isSubmitted" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">About Us</a>
                        </li> -->
            <li class="nav-item">
              <a href="javascript:void(0)" class="nav-link dropdown-toggle" *ngIf="!myGlobals.isSubmitted">Sign_Up<i
                  class='bx bx-chevron-down'></i></a>

              <ul class="dropdown-menu">

                <li class="nav-item"><a routerLink="/companysighup" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Company</a>
                </li>

                <li class="nav-item"><a routerLink="/candidate/register" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">candidates</a></li>

                <li class="nav-item"><a routerLink="/register" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Institute/University/College</a></li>
              </ul>
            </li>
            <!-- Candidate -->
            <li class="nav-item" *ngIf="myGlobals.isSubmitted  && myGlobals.isCandidate">
              <a routerLink="/landing" class="nav-link menu" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Home</a>
            </li>

            <li class="nav-item" *ngIf="myGlobals.isSubmitted  && myGlobals.isCandidate">
              <!-- <a routerLink="/candidate/dashboard/', this.loginData.UserId]);" class="nav-link"   routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a> -->
              <a routerLink="/candidate/dashboard" class="nav-link menu" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">My Profile</a>
            </li>

            <li class="nav-item" *ngIf="myGlobals.isCandidate && myGlobals.isSubmitted">
              <a routerLink="/jobs" class="nav-link menu" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Find Jobs</a>
            </li>

            <li class="nav-item" *ngIf="myGlobals.isCandidate  && myGlobals.isSubmitted">
              <a routerLink="/recommandedjobs" class="nav-link menu" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Recommanded Jobs </a> {{userid}}
            </li>

            <li class="nav-item" *ngIf="myGlobals.isCandidate  && myGlobals.isSubmitted">
              <a routerLink="/bookjobs" class="nav-link menu" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">BookMarked Jobs </a> {{userid}}
            </li>

            <li class="nav-item" *ngIf="myGlobals.isCandidate  && myGlobals.isSubmitted">
              <a routerLink="/candidateappliedjob" class="nav-link menu" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Applied Jobs </a> {{userid}}
            </li>

            <!-- <li class="nav-item" *ngIf="myGlobals.isCandidate  && myGlobals.isSubmitted">
                        <a routerLink="/candidatetest" class="nav-link"  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Test </a> {{userid}}
                    </li> -->

            <!-- College -->

            <li class="nav-item" *ngIf="myGlobals.isCollege && myGlobals.isSubmitted">
              <a routerLink="/collegehome/profile" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">My Profile</a>
            </li>

            <li class="nav-item" *ngIf="myGlobals.isCollege && myGlobals.isSubmitted">
              <a routerLink="/collegetable" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Candidates</a>
            </li>

            <li class="nav-item" *ngIf="myGlobals.isCollege && myGlobals.isSubmitted">
              <a routerLink="/jobs" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Find Jobs</a>
            </li>

            <li class="nav-item" *ngIf="myGlobals.isCollege && myGlobals.isSubmitted">
              <a routerLink="/candidates" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Find Candidate</a>
            </li>

            <li class="nav-item" *ngIf="myGlobals.isCollege && myGlobals.isSubmitted">
              <a routerLink="/createcandidate" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Create Candidate</a>
            </li>

            <!-- company -->

            <li class="nav-item" *ngIf="myGlobals.isCompany && myGlobals.isSubmitted">
              <a routerLink="/companyhome/profile" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">My Profile</a>
            </li>

            <li class="nav-item" *ngIf="myGlobals.isCompany && myGlobals.isSubmitted">
              <a routerLink="/post-a-job" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Job Post</a>
            </li>

            <li class="nav-item" *ngIf="myGlobals.isCompany && myGlobals.isSubmitted">
              <a routerLink="/candidates" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Find Candidate</a>
            </li>

            <li class="nav-item" *ngIf="myGlobals.isCompany && myGlobals.isSubmitted">
              <a routerLink="/companymanagejob" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Manage Jobs</a>
            </li>
          </ul>

          <div class="side-nav three">
            <!-- <a class="login-left">
                        <i class="flaticon-enter"></i>
                        Logged In User :  {{userName}}
                      </a> -->
            <a class="login-left" routerLink="/login" *ngIf="!myGlobals.isSubmitted" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}">
              <i class="flaticon-enter"></i>
              Login
            </a>

            <!-- <label class="login-left menu">
                <i class="flaticon-candidate"></i>
                {{userName}}
              </label> -->

            <!-- <a class="login-left" routerLink="/login" *ngIf="!isToken"><i class="flaticon-enter"></i> Login/Register</a> -->
            <a class="login-left" *ngIf="myGlobals.isSubmitted " (click)="onLogout($event)"><i
                class="flaticon-enter"></i> Logout</a>

            <!-- <a class="nav-link dropdown-toggle job-right">Employer 
                            <i class='bx bx-plus'></i>
                        </a> -->
          </div>
        </div>
      </nav>
    </div>
  </div>

</div>
<!-- End Navbar -->