<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Companies
                    </h2>
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                            <!-- <a routerLink="/">Home</a> -->
                        </li>
                        <!-- <li><span>/</span></li> -->
                        <li>Companies
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Jobs -->
<div class="job-area-list ptb-50">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <ul class="job-list-item align-items-center">
                    <!-- <li>
                        <a href="#">Results Found <span>(17)</span></a>
                        <a class="rss" routerLink="/"><i class='bx bx-rss'></i>RSS Feed</a>
                    </li> -->
                    <li>
                        <!-- <span class="sort">Sort By:</span> -->
                        <form>
                            <div class="form-group">
                                <!-- <select>
                                    <option>Title</option>
                                    <option>Another option</option>
                                    <option>A option</option>
                                    <option>Potato</option>
                                </select> -->
                            </div>
                        </form>
                    </li>
                </ul>
                <!-- [routerLink]="['/add-country', id=0]" -->
                <!-- <div>
                    <a media="print" class="cmn-btn" (click)="AddCompany()">
                        Add
                        <i class="bx bx-plus bx-sm bx-tada-hover"></i>
                    </a>
                </div> -->
                <input class="form-control" style="width: 300px; margin-left: 963px;margin-top: -49px;" (keyup)="applyFilter($event)" placeholder="Enter Text To Search" >

                <div class="card mat-elevation-z6 m-3 w-auto">
                    <table mat-table [dataSource]="datasource" matSort class="table-responsive">
              
                        <ng-container matColumnDef="JobCompanyName">
                            <th mat-header-cell *matHeaderCellDef class="M1" style="text-align:left ;"> Company Name </th>
                            <td mat-cell *matCellDef="let element" style="text-align:left ;"> {{element.CompanyName}} </td>
                        </ng-container>
              
                        <ng-container matColumnDef="IndustryType">
                            <th mat-header-cell *matHeaderCellDef class="M1" style="text-align:left ;"> Industry Type </th>
                            <td mat-cell *matCellDef="let element" style="text-align:left ;"> {{element.IndustryType}} </td>
                        </ng-container>
              
                        <ng-container matColumnDef="CityName">
                          <th mat-header-cell *matHeaderCellDef class="M1" style="text-align:left ;"> City Name </th>
                          <td mat-cell *matCellDef="let element" style="text-align:left ;"> {{element.CityName}} </td>
                      </ng-container>
              
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
              
                    <mat-paginator color="primary" [pageSize]="10" [pageSizeOptions]="[5, 10, 15, 25, 100]" aria-label="Select page"
                        class="paginator">
                    </mat-paginator>
              
                </div>

                
            </div>

        </div>
    </div>
</div>

<!-- Modal -->
<!-- <div class="modal fade" *ngIf="showModalBox" id="exampleModal" tabindex="-1" aria- 
  labelledby="exampleModalLabel" >
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-danger" id="exampleModalLabel">Hey 
      Bootstrap 5!!</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" 
       aria-label="Close"></button>
      </div>
      <div class="modal-body text-danger">
       Therichpost.com
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-warning" data-bs- 
       dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div> -->



<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->