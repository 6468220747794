import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import { Observable } from 'rxjs';
import { cities, JobRole, Qualificationa } from 'src/app/interface';
import { FormControl } from '@angular/forms';
import { GlobalService } from 'src/app/global.service';
import { AuthService } from 'src/app/auth.service';

@Component({
    selector: 'app-SearchJobs',
    templateUrl: './SearchJobs.component.html',
    styleUrls: ['./SearchJobs.component.scss']
})
export class SearchJobsComponent implements OnInit, OnDestroy {

    jobPostsInfo = [];
    allJobRole: Observable<JobRole[]>;
    allQualification: Observable<Qualificationa[]>;
    allCities: Observable<cities[]>
    obs: any;
    dataFilter1: string[] = [];
    data: any;
    citycontrol = new FormControl();
    EducationControl = new FormControl();
    jobRoleControl = new FormControl();
    datasource:any;
    visible : boolean = false
    afterClickData: any
    constructor(
        // private jobsService: JobsService,
        private Service:SharedService<any>,
        private toastr: ToastrService,
        private router: Router,
        public Global:GlobalService,
        private auth:AuthService
    ) { 
        this.data = this.router.getCurrentNavigation().extras;
        if (this.data.queryParams != null) {
          if (this.data.queryParams.myArray[0] == "searchString") {
            this.dataFilter1 = [];
            this.jobRoleControl.setValue(this.data.queryParams.myArray[1]);
            this.citycontrol.setValue(this.data.queryParams.myArray[2]);
            this.EducationControl.setValue(this.data.queryParams.myArray[3]);
            this.dataFilter1.push(this.data.queryParams.myArray[1]);
            this.dataFilter1.push(this.data.queryParams.myArray[2]);
            this.dataFilter1.push(this.data.queryParams.myArray[3]);
            this.getAllJobs();
          }
        }
        else if (this.data.queryParams == null) {
          this.dataFilter1 = [];
          this.dataFilter1.push(this.Global.getuserId().toString());
          this.dataFilter1.push(this.Global.GetUserType().toString());
          this.dataFilter1.push("0");
          this.dataFilter1.push("0");
          this.dataFilter1.push("0");
          this.getAllJobs();
    
        }
    }
    ngOnInit(): void {
        this.getAllJobs();
        this.getAllJobRole();
        this.getAllQualification();
        this.getAllCity();
    }

    getAllJobs() {
        this.Service.Post('Job/GetJobsCardDataOfSigleJob', this.dataFilter1).subscribe(
            (res) => {
                this.jobPostsInfo = res;
                this.obs = this.jobPostsInfo;
                this.CardClick(this.obs[0])

            },
            (error) => {
                //if (error.status == 401) this.router.navigate(['/login']);
                this.toastr.error(error.error);
            });
    }

    getAllJobRole(){
        this.Service.Get('JobRole/GetJobRole?Id=0', null).subscribe(jobrole => {
            this.allJobRole = jobrole;
        })
    }
    getAllQualification(){
        this.Service.Get('Qualification/GetQualification?Id=0', null).subscribe(Qualificationa => {
            this.allQualification = Qualificationa;
        })
    }
    getAllCity(){
        this.Service.Get('City/GetCity?Id=0', null).subscribe(city => {
            this.allCities = city;
        })
    }

    CardClick(event){
        this.visible = true
        this.afterClickData = event;
      }

    ngOnDestroy(): void {

    }

}
