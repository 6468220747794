import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';

import { Router } from "@angular/router";
import { Observable } from 'rxjs';
import { UserService } from './service/users.service';
import { SharedService } from 'src/app/shared.service';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {

    submittedComp: boolean = false;
    userForm: FormGroup;
    public companyInfo = [];
    allIndustry: Observable<any>;
    allCountries: Observable<any>;
    allStates: Observable<any>;
    allCities: Observable<any[]>;
    compId: number = null;

    constructor(private formBuilder: FormBuilder,
        private userService: UserService,
        private toastr: ToastrService,
        private router: Router,
        private Service:SharedService<any>
    ) { }

    ngOnInit(): void {
        this.userForm = this.formBuilder.group({
            FirstName: [null, Validators.required],
            LastName: ["", Validators.required],
            Email: [null, Validators.required],
            MobileNo: ["", Validators.required],
            UserType: [5],
        });
    }

    get fcomp(): { [key: string]: AbstractControl } {
        return this.userForm.controls;
    }

    userinfForm() {
        this.submittedComp = true;
        if (this.userForm.invalid) {
            this.toastr.error("This data is not valid");
        }
        else {
            const user =  this.userForm.getRawValue();
            var UserName = this.userForm.value.Email;
            this.Service.Post('Recruiter/InsertRecruiterData', user).subscribe(
                (res) => {
                  
                    this.toastr.success(res);
                    this.userForm.reset();
                //    this.Service.Post('ResetPassword?loginName=' + UserName, null).subscribe(
                //     (res) => {
                //         this.toastr.success(res);
                //     },
                //   )
                  this.router.navigate(['/login']);
                },
                (error) => {
                    this.toastr.error(error.error);
                });
            this.submittedComp = false;
        }
    }

    ngOnDestroy(): void {

    }

    
}
