import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import data from 'src/app/components/data/data';
import { SharedService } from 'src/app/shared.service';
import { Observable } from 'rxjs';
import { City } from 'src/app/interface';
import { GlobalService } from 'src/app/global.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'app-add-edit-location',
    templateUrl: './add-edit-location.component.html',
    styleUrls: ['./add-edit-location.component.scss']
})
export class AddEditPrefferedLocationComponent implements OnInit, OnDestroy {

    submitted: boolean = false;
    submittedLocation: boolean = false;
    locationForm: FormGroup;
    public locationInfo = [];
    langId: number = null;
    saveLocBtn: boolean = true;
    updateLocBtn: boolean = false;
    response: any;
    public placeholder: string = '';
    public keyword = 'skill';
    public historyHeading: string = 'Recently selected';
    public proficiency = data.proficiency;
    openform=false;
    // openForm=false;
    // boolVar=true;
    boolVar1=true;
    locData: Observable<City[]>;
    hideForm: boolean = false;
    hideTable: boolean = true;

    allCities: any[];
    filterCities?: Observable<any[]>;
    formCities = new FormControl();
    CityId: number;

    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private Service: SharedService<any>,
        private Global : GlobalService,
        private dialogRef: MatDialogRef<AddEditPrefferedLocationComponent>,
        @Inject(MAT_DIALOG_DATA) public editData: any
    ) { }

    ngOnInit(): void {

        this.locationForm = this.formBuilder.group({
            Id: ['', null],
            EmpId: ['', null],
            CityId: ['', [Validators.required]],
            CityName: ['', null],
        })

        this.Service.Get('City/GetCity', null).subscribe(city => {
            this.locData = city;
        });

        this.Service.Get('City/GetCity', null).subscribe(data => {
            if (data != null) {
              this.allCities = data;
              this.filterCities = this.formCities.valueChanges.pipe(
                startWith<string | any>(''),
                map(value => typeof value === 'string' ? value : value.CityName),
                map(CityName => CityName ? this._filterCity(CityName) : this.allCities.slice()),
              );
            }
          })
    }

    get floc(): { [key: string]: AbstractControl } {
        return this.locationForm.controls;
    }

    close()
    {
        this.dialogRef.close(); 
    }

    
  private _filterCity(CityName: string): any[] {
    const filterValue = CityName.toLowerCase();
    return this.allCities.filter(
      option => option.CityName?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFnForCity(value: any) {
    return value ? value.CityName : undefined;
  }

  onchangeCity(event) 
  {
    this.CityId = event;
  }

    locationInfoForm() {
        this.submittedLocation = true;
        this.locationForm.controls["CityId"].setValue(this.CityId);
        if (this.locationForm.invalid) {
            this.toastr.error("Please fill out the required fields (* fields)");
        }
        else {
            const insertcity = this.locationForm.getRawValue();
            insertcity.EmpId = this.Global.getCandidateId();
            
            this.Service.Post('CandidtaePreferredLocations/InsertCandidatePreferredLocationsForIndiviual', insertcity).subscribe(
                (res) => {
                    this.toastr.success(res);
                    this.dialogRef.close('Save');                     
                    // this.openform = false;                        
                    this.boolVar1 = true;    
                },
                (error) => {
                    this.toastr.error(error.error);
                });
            this.submittedLocation = false;
        }
    }

    ngOnDestroy(): void {

    }

}
