import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/shared.service';
import { GlobalService } from 'src/app/global.service';
import { BuildResume } from 'src/app/interface';

@Component({
    selector: 'app-resume-details',
    templateUrl: './resume-details.component.html',
    styleUrls: ['./resume-details.component.scss'],
})
export class ResumeDetailsComponent implements OnInit {
    @ViewChild('htmlData') htmlData!: ElementRef; //CV download
    public educationInfo = [];
    public experienceInfo = [];
    public basicInfo: [];
    summary: any;
    public projectsInfo: [];
    public skillInfo: [];
    designation: string;
    public languageInfo: [];
    hidden=false;
    closeResult = '';
    getresumeData: BuildResume;
    buildjob:any;
    datasourceEdu:any;
    datasourceExp:any;
    datasourceAchiv:any;
    datasourceCerty:any;
    datasourceSkill:any;
    datasourceLocation:any;
    datasourceLanguages:any;
    constructor(
        private toastr: ToastrService,
        private modalService: NgbModal,
        private Service: SharedService<any>,
        private Global: GlobalService
    ) { }

    ngOnInit(): void {
     
        this.getUser();
       
    }
    //Education
    getAllEducations(empId: number) {
        this.Service.Get('CandidateEducationInfo/GetCandidateEducationInfoByEmpId?EmpId=' + empId, null).subscribe(
            (res) => {
                this.datasourceEdu = res;
            },        
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }

    //Experience
    getAllExperience(empId : number) {
        this.Service.Get('CandidateExperienceInfo/GetCandidateExperienceInfoByEmpId?EmpId=0' + empId, null).subscribe(
            (res) => {
                this.datasourceExp = res;
            },        
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }
    //Basic Info
    getUser() {
        this.Service.Get('CandidateInfo/GetCandidateInfoByUserId?userId=' + this.Global.getuserId(), null).subscribe(
            (res) => {
                this.getresumeData = res;
                this.getAllEducations(this.getresumeData.Id);
                this.getAllExperience(this.getresumeData.Id);
                this.getAllAchievements(this.getresumeData.Id);
                this.getAllCertification(this.getresumeData.Id);
                this.getAllSkills(this.getresumeData.Id);
                this.getAllCity(this.getresumeData.Id);
                this.getAllLanguages(this.getresumeData.Id);




       
            },        
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }

    //Achievements
    getAllAchievements(empId : number) {
        this.Service.Get('CandidateAchievements/GetCandidateAchievementsByEmpId?EmpId=0' + empId, null).subscribe(
            (res) => {
                this.datasourceAchiv = res;
            },        
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }

    // Certification
    getAllCertification(empId : number) {
        this.Service.Get('CandidateCertificatios/GetCandidateCertificatiosByEmpId?EmpId=0' + empId, null).subscribe(
            (res) => {
                this.datasourceCerty = res;
            },        
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }



    //Skills

    getAllSkills(empId : number) {
        this.Service.Get('CandidateSkills/GetCandidateSkills?EmpId=' + empId, null).subscribe(
            (res) => {
                this.datasourceSkill = res;
            },        
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }

    //languages
    getAllLanguages(empId:number) {
        this.Service.Get('CandidateLanguages/GetCandidateLanguages?EmpId=' +empId, null).subscribe(
            (res) => {
                this.datasourceLanguages = res;
            },        
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }


    getAllCity(empId:number) {
        this.Service.Get('CandidtaePreferredLocations/GetCandidatePreferredLocations?EmpId=' + empId, null).subscribe(
            (res) => {
                this.datasourceLocation = res;
            },        
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }
    //Download CV
    public openPDF(): void {
        // window.print()
        let DATA: any = document.getElementById('htmlData');
        // document.getElementById('noScreen').style.opacity = '1';
        html2canvas(DATA).then((canvas) => {
            let fileWidth = 100;
            let fileHeight = (canvas.height * fileWidth) / canvas.width;
            const FILEURI = canvas.toDataURL('image/png');
            let PDF = new jsPDF('p', 'mm', 'a4');
            let position = 0;
            PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
            // document.getElementById('noScreen').style.opacity = '0';
            PDF.save('Resume.pdf');
        });
    }

    //Resume Dialog Box
    openXl(content) {
        this.modalService.open(content, { size: 'xl'});
      }
}