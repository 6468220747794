<div class="profile-content" >
    <form [formGroup]="proejctForm"  id="form">
        <div style="width: 10px;
        height: 5px;
        position: relative;
        float: right;
        top: -16px;"  ><i matTooltip="Close" style="font-size: 25px;"  
            class='bx bx-x Clear' (click)="close()"></i></div>
        <div class="profile-content-inner">
            <div class="Name">Certification</div>
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label>Certificate Name <label class="star">*</label></label>
                        <input type="text" class="form-control" placeholder="Enter Certificate Name" formControlName="CertificationName"
                            [ngClass]="{ 'is-invalid': submitted && fCerty.CertificationName.errors }">
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label>Certificate Description</label>
                        <textarea type="text" class="form-control" placeholder="Enter Certificate DEscription" formControlName="Description"
                            [ngClass]="{ 'is-invalid': submitted && fCerty.Description.errors }"></textarea>
                    </div>
                </div>


                <div class="col-lg-6 col-md-12">
                    <div class="form-group">
                        <label>Date Of Issue <label class="star">*</label></label>
                        <input type="date" class="form-control" placeholder="01/01/1995" formControlName="DateOfIssue"
                            [ngClass]="{ 'is-invalid': submitted && fCerty.DateOfIssue.errors }">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="form-group">
                        <label>Date Of Validity</label>
                        <input type="date" class="form-control" placeholder="01/01/1995" formControlName="DateOfValidity"
                            [ngClass]="{ 'is-invalid': submitted && fCerty.DateOfValidity.errors }">
                    </div>
                </div>
            </div>
        </div>

        <!-- <button (click)="CertificatetInfoForm()" class="btn dashboard-btn" *ngIf="savePrjBtn">
            Save Your Information
        </button>
        <button (click)="CertificatetUpdateForm()" class="btn dashboard-btn" *ngIf="updatePrjBtn">
            Update Your Information
        </button> -->

        <div class="button">
            <button (click)="close()" class=" btn cancle " >
                Cancel
            </button>

        <button (click)="CertificatetInfoForm()" class="btn savebtn" *ngIf="savePrjBtn">
            Save
        </button>

        <button (click)="CertificatetUpdateForm()" class="btn savebtn" *ngIf="updatePrjBtn">
            Update
        </button>
        </div>
    </form>
</div>
