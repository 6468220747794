<!-- Navbar -->
<app-navbar-style-one *ngIf="!Global.isSubmitted"></app-navbar-style-one>
<app-navbar-style-three *ngIf="Global.isSubmitted"></app-navbar-style-three>
<!-- End Navbar -->

<!-- Work -->
<div class="work-area  pt-100 ">
    <div class="container " style="padding-top:50px;">
        <div class="row">
            <div class="col-lg-3 col-md-12 column-3">
                <div class="form-group">
                    <div class="input-icons">
                        <i class='bx bx-search icon'></i>
                        <input type="text" class="form-control cmd input-field" [formControl]="formControlObj"
                            [matAutocomplete]="auto" placeholder="Job Role">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnForRole"
                            (optionSelected)="onchangejobrole($event.option.value.Id)">
                            <mat-option *ngFor="let jobrole of filterJobRole | async" [value]="jobrole">
                                {{jobrole.JobRole}}
                            </mat-option>
                        </mat-autocomplete>
                    </div>

                </div>
            </div>
            <div class="col-lg-3 col-md-12 column-3">
                <div class="form-group">
                    <div class="input-icons">
                        <i class='bx bx-location-plus icon'></i>
                        <input type="text" class="form-control cmd" [formControl]="formCities"
                            [matAutocomplete]="autocity" placeholder="Location">
                        <mat-autocomplete #autocity="matAutocomplete" [displayWith]="displayFnForCity"
                            (optionSelected)="onchangeCity($event.option.value.Id)">
                            <mat-option *ngFor="let cities of filterCities | async" [value]="cities">
                                {{cities.CityName}}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 column-3">
                <div class="form-group">
                    <div class="input-icons">
                        <i class='bx bx-briefcase icon'></i>
                        <input type="text" class="form-control cmd" [formControl]="formControlQualificationine"
                            [matAutocomplete]="autoQuali" placeholder="Qualification">
                        <mat-autocomplete #autoQuali="matAutocomplete" [displayWith]="displayFnForQualification"
                            (optionSelected)="onchangeEdu($event.option.value.Id)">
                            <mat-option *ngFor="let Quli of filterQualification | async" [value]="Quli">
                                {{Quli.Qualification}}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 col-md-12 frame-1476 column-1" (click)="search()">
                <button class="Search">Search</button>
            </div>

        </div>
    </div>


    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3 col-md-12">
                <img src="../../../../assets/images/Capture3.PNG" style="float: left;
              
              padding-top: 122px;">

            </div>

            <div class="col-lg-5 col-md-12 pt-100">
                <div class="find-the-job-of-your-choice-and-interest">
                    <span><span class="find-the-job-of-your-choice-and-interest-span">Find The Job Of Your </span><span
                            class="find-the-job-of-your-choice-and-interest-span2">Choice</span><span
                            class="find-the-job-of-your-choice-and-interest-span3">
                            And </span><span
                            class="find-the-job-of-your-choice-and-interest-span4">Interest</span></span>
                </div>
                <div style="text-align: center;">
                    <!-- <label for="files" class="resumeupload">UPLOAD RESUME</label> -->
                    <label for="files" class="resumeupload"> Upload Resume
                        <input id="files" #myInput style="visibility:hidden;visibility: hidden; width: 151px;position: relative;
                    top: -18px;" type="file" (change)="uplodPfd($event.target.files)" />
                    </label>
                    <!-- <button class="resumeupload"> Upload Resume</button> -->
                    <!-- <button class="register" (click)="regi()"> Register Now</button> -->
                    <label for="files" class="register" (click)="regi()"> Register Now
                        <input id="files" #myInput
                            style="visibility:hidden;visibility: hidden; width: 151px;position: relative;top: -18px;"
                            type="file" (change)="uplodPfd($event.target.files)" />
                    </label>
                </div>
            </div>
            <div class="col-lg-4 col-md-12" style="padding-top: 3%;">
                <img src="../../../../assets/images/creative-designers-team-working-project-discussing-details-1.png"
                    style="float: right; margin-right: 42px;">
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="row" style="background-color:#FFFFFF;margin-top: -4px;">
            <hr>
            <div class="col-lg-12 col-md-12 " style="padding-bottom: 61px;">
                <p class="imagearea">
                    Potential To Work With
                </p>
                <ng-image-slider #nav class="imagesize" 
                [images]="imageObject" [imagePopup]="false" [infinite]="true"
                    [autoSlide]="1" 
                    [imageSize]="{width: '180', height: '90px', space: 10}" slideImage="3">
                </ng-image-slider>
            </div>
        </div>
    </div>

    <div class="container pt-100 pb-100">
        <div class="row  rectangle-postarea">
            <!-- <div class="col-lg-1 col-md-12">
               
            </div> -->

            <div class="col-lg-5 col-md-12" style="padding-top: 27px;">
                <img src="../../../../assets/images/cartoon 1 1.svg">
            </div>

            <div class="col-lg-7 col-md-12  ">
                <div class="row" style="padding-top: 40px;">

                    <div class="col-lg-12 col-md-12 job-on-fingertip-for-employers">
                        job on fingertip for employers
                    </div>
                    <div class=" col-lg-12 col-md-12 looking-for-the-best">Looking for the best?</div>

                    <div class=" col-lg-12 col-md-12 uncover-your-ideal-hire-from-a-pool-of-23-k-active-job-seekers">
                        Uncover Your Ideal Hire from a Pool of 23k+ Active Job Seekers!
                    </div>
                    <div style="text-align: left;" (click)="step1()">
                        <button class="register"
                            style="font: 600 15px Roboto, sans-serif;width: 221px;height: 47px;border-radius: 9px;padding-top: 0;">
                            POST JOB</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid" style="background-color: #0c1153;">
        <div class="container">
            <div class="row" style="padding-top: 43px;padding-bottom: 43px;" *ngIf="info">
                <div class="col-lg-4 col-md-12">
                    <div class="discover-our-great-achievements">Discover Our Great Achievements</div>
                </div>

                <div class="col-lg-2 col-md-12" style="padding-top: 10px;">
                    <div class="line-177"></div>
                    <div class="_21-k">{{info[0].CandidateCount}}+</div>
                    <div class="candidates">CANDIDATES</div>
                </div>

                <div class="col-lg-2 col-md-12" style="padding-top: 10px;">
                    <div class="line-177"></div>
                    <div class="_21-k ">{{info[0].PostJobCount}}+</div>
                    <div class="candidates">TOTAL JOBS</div>
                </div>

                <div class="col-lg-2 col-md-12" style="padding-top: 10px;">
                    <div class="line-177"></div>
                    <div class="_21-k">0 +</div>
                    <div class="candidates">EMPLOYERS</div>
                </div>

                <div class="col-lg-2 col-md-12" style="padding-top: 10px;">
                    <div class="line-177"></div>
                    <div class="_21-k">0 +</div>
                    <div class="candidates">JOB APPLICATIONS</div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid" style="background-color:#FFFFFF;">
        <div class="container">
            <div class="row" style="padding-bottom: 50px;">
                <div class="col-lg-12 col-md-12">
                    <div class="latestfromblog">Latest from Blog</div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <img src="../../../../assets/images/screenshot5.png" style=" display: block;margin-left: auto;margin-right: auto;box-shadow: 0px 0px 5px 0px #a1969673;">
                </div>
                <div class="col-lg-4 col-md-12">
                    <img src="../../../../assets/images/screenshot6.png" style=" display: block;margin-left: auto;margin-right: auto;box-shadow: 0px 0px 5px 0px #a1969673;">
                </div>
                <div class="col-lg-4 col-md-12">
                    <img src="../../../../assets/images/screenshot7.png" style=" display: block;margin-left: auto;margin-right: auto;box-shadow: 0px 0px 5px 0px #a1969673;">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Work -->


<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->