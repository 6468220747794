<div class="profile-content">

    <form [formGroup]="proejctForm" id="form">
        <!-- <i matTooltip="Close" style="font-size: 35px;margin-left: 665px;margin-top: -15px;color: red;"  
        class='bx bx-x Clear' (click)="close()"></i> -->
        <div style="width: 10px;
        height: 5px;
        position: relative;
        float: right;
        top: -16px;"  ><i matTooltip="Close" style="font-size: 25px;"  
            class='bx bx-x Clear' (click)="close()"></i></div>
        
        <div class="profile-content-inner">
            <div class="Name"> Add Achivements</div>

            <div class="row">

                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label>Description Of Achivements</label>
                        <textarea type="text" class="form-control" formControlName="DescriptionOfAchievments"
                            placeholder="Enter Achivements"
                            [ngClass]="{ 'is-invalid': submittedPrj && fprj.DescriptionOfAchievments.errors }"></textarea>
                    </div>
                </div>
            </div>
        </div>

        <!-- <button (click)="projectInfoForm()" class="btn dashboard-btn" *ngIf="savePrjBtn">
            Save Your Information
        </button>
        <button (click)="projectUpdateForm()" class="btn dashboard-btn" *ngIf="updatePrjBtn">
            Update Your Information
        </button> -->

        <div class="button">
            <button (click)="close()" class=" btn cancle " >
                Cancel
            </button>

        <button (click)="projectInfoForm()" class="btn savebtn" *ngIf="savePrjBtn">
            Save
        </button>

        <button (click)="projectUpdateForm()" class="btn savebtn" *ngIf="updatePrjBtn">
            Update
        </button>
        </div>

    </form>
</div>