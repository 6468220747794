<div class="profile-content">
<form [formGroup]="SectionInfoForm" id="noScreen">
    
    <div style="width: 10px;
    height: 5px;
    position: relative;
    float: right;
    top: -16px;"  ><i matTooltip="Close" style="font-size: 25px;"  
        class='bx bx-x Clear' (click)="close()"></i></div>
        <div class="profile-content-inner">
            <div class="Name">Industry Type</div>

            <div class="row">
                <div class="col-lg-6 col-md-12">
                   
                    <div class="form-group">
                        <label>Industry Type 1:</label>
                        <select class="form-control form-select  select" formControlName="Section1">
                            <option value="" disabled selected>Please select Industry Type 1</option>
                            <option *ngFor="let section of allsection"
                                [value]="issection == true ? section.Id :  section.Id ">
                                {{section.SectionName}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                  
                    <div class="form-group">
                        <label>Industry Type 2:</label>
                        <select class="form-control form-select  select" formControlName="Section2">
                            <option value="" disabled selected>Please select Industry Type 2</option>
                            <option *ngFor="let section2 of allsection"
                                [value]="issection2 == true ? section2.Id :  section2.Id ">
                                {{section2.SectionName}}
                            </option>
                        </select>
                    </div>
                </div>


                <div class="col-lg-6 col-md-12">
                  
                    <div class="form-group">
                        <label> Industry Type 3:</label>
                        <select class="form-control form-select  select" formControlName="Section3">
                            <option value="" disabled selected>Please select Industry Type 3</option>
                            <option *ngFor="let section3 of allsection"
                                [value]="issection3 == true ? section3.Id :  section3.Id ">
                                {{section3.SectionName}}
                            </option>
                        </select>
                    </div>
                </div>


            </div>
        </div>

        <!-- <button (click)="selectionInfoForm()" class="btn dashboard-btn">Update Your
            Information</button> -->
            <div class="button">
                <button (click)="close()" class=" btn cancle " >
                    Cancel
                </button>
    
            <button (click)="selectionInfoForm()" class="btn savebtn">
                Save
            </button>
    
            </div>
    </form>
</div>