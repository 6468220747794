 <div class="profile-content">
        <form [formGroup]="basicInfoForm" id="noScreen" >
            <div style="width: 10px;height: 5px;position: relative;float: right;top: -16px;">
                <i matTooltip="Close" style="font-size: 25px;" class='bx bx-x Clear' (click)="close()"></i></div>      
            <div class="profile-content-inner">
                <div class="Name">Add Job Role</div>
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <label>Job Role <label class="star">*</label></label>
                                <input type="text" class="form-control form-select" [formControl]="formControlObj"
                                    [matAutocomplete]="auto" placeholder="Select Job Role">
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnForRole"
                                    (optionSelected)="onchangejobrole($event.option.value.Id)">
                                    <mat-option *ngFor="let jobrole of filterJobRole | async" [value]="jobrole">
                                        {{jobrole.JobRole}}
                                    </mat-option>
                                </mat-autocomplete>
                        </div>
                    </div>
                </div>
            </div>
                <div class="button">
                    <button (click)="close()" class="btn cancle " >
                        Cancel
                    </button>
                <button (click)="baiscInfoForm()" class="btn savebtn">
                    Save
                </button>
                </div>

        </form>
    </div>
