

<div class="container1">
 
  <div class="sidenav">
    <app-menu></app-menu>
  </div>
  <div class="jobonfingertip-candidates-manage-jobs content">

    <div class="job-area-list ptb-100">
      <div class="container">
        <div class="row rectangle-1151">
          <div class="col-lg-3 col-md-3">
            <div class="frame-1154">
              <div class="group-1172">
                <!-- <svg class="vector" width="30" height="30" viewBox="0 0 30 30" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M21.206 19.2112C20.9056 18.9102 20.4978 18.7411 20.0725 18.7411H19.6192C19.4954 18.7411 19.3764 18.693 19.2873 18.6071V18.6071C19.1046 18.4309 19.0923 18.1435 19.2498 17.9445C20.7489 16.0499 21.568 13.7006 21.5663 11.2758C21.5663 9.14311 20.9338 7.0583 19.749 5.28503C18.5641 3.51175 16.88 2.12965 14.9097 1.3135C12.9393 0.497348 10.7712 0.283806 8.67945 0.699875C6.58773 1.11594 4.66636 2.14294 3.15831 3.65099C1.65026 5.15903 0.623269 7.0804 0.2072 9.17213C-0.20887 11.2638 0.00467216 13.432 0.820822 15.4023C1.63697 17.3727 3.01907 19.0568 4.79235 20.2417C6.56563 21.4265 8.65043 22.0589 10.7831 22.0589C13.2927 22.0589 15.6118 21.1949 17.4516 19.7419C17.6507 19.5846 17.9381 19.5972 18.1143 19.7798V19.7798C18.2003 19.8691 18.2484 19.9882 18.2484 20.1121V20.5652C18.2484 20.9905 18.4175 21.3983 18.7185 21.6987L25.3059 28.2729C25.9894 28.955 27.0962 28.9545 27.779 28.2717V28.2717C28.4618 27.5889 28.4623 26.4821 27.7802 25.7986L21.206 19.2112ZM10.7831 18.7411C6.65237 18.7411 3.31789 15.4066 3.31789 11.2758C3.31789 7.14504 6.65237 3.81057 10.7831 3.81057C14.9139 3.81057 18.2484 7.14504 18.2484 11.2758C18.2484 15.4066 14.9139 18.7411 10.7831 18.7411Z"
                  fill="#6A6A6A" />
              </svg> -->

                <!-- <div class="search-job-titles">Search job titles...</div> -->
                <input matInput (keyup)="Searchjobtitles($event)" class="form-control1 search-job-titles"
                  placeholder="Search job titles...">
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-3">
            <div class="frame-1155">
              <input matInput (keyup)="Searchjoblocation($event)" class="form-control1 search-locations"
                placeholder="Search locations....">
            </div>
          </div>
          <div class="short">
            <div class="col-lg-3 col-md-3">
              <div class="group-1125">
                <div class="short-by">short by:</div>
                <div class="frame-1123">
                  <div class="frame-1299">
                    <div class="posting-date">Posting Date</div>
                    <!--   
              <svg
                class="vector-7"
                width="17"
                height="18"
                viewBox="0 0 17 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.000976562 8.95996L8.4887 17.4428L16.9764 8.95996"
                  stroke="black"
                  stroke-width="1.5"
                />
              </svg> -->
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-3">
              <div class="group-1126">
                <div class="order">order:</div>
                <div class="frame-1124">
                  <select class="form-control form-select frame-1205 posting-date2 "
                    (change)="onchangeOrder($event.target.value)">
                    <option value="ASC" selected>ASC</option>
                    <option value="DESC">DESC</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12" class="cardtop">
            <div class="Job-item" *ngFor="let jobpost of obs">
              <!-- <img [src]="jobpost.Logo" alt="Dashboard" style="height: 25%;margin-top: 57px;
                margin-left: -12px;" *ngIf="jobpost.Logo"> -->
                <h3 class="software-developer">{{jobpost.JobNumber}}</h3>
              <h3 class="software-developer">{{jobpost.JobRole}}</h3>
              <div class="vadodara-390007-gujara">
                {{jobpost.JobLocationCity}}-{{jobpost.StateName}},
                {{jobpost.CountryName}}
              </div>
              <div class="v100_77"></div>
              <svg class="group-1103" width="14" height="18" viewBox="0 0 14 18" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <mask id="path-1-inside-1_205_127" fill="white">
                  <path d="M1.64148 8.99609H0.407227V15.2146C0.407227 16.4965 1.44646 17.5358 2.72842 
                      17.5358H8.29002V16.2553H1.72102L1.64148 8.99609Z" />
                </mask>
                <path d="M0.407227 8.99609V6.6749H-1.91397V8.99609H0.407227ZM1.64148 8.99609L3.96253 
                    8.97066L3.93738 6.6749H1.64148V8.99609ZM1.72102 16.2553L-0.60003 16.2808L-0.574873 
                    18.5765H1.72102V16.2553ZM8.29002 16.2553H10.6112V13.9341H8.29002V16.2553ZM8.29002 
                    17.5358V19.857H10.6112V17.5358H8.29002ZM0.407227 11.3173H1.64148V6.6749H0.407227V11.3173ZM-0.679576 
                    9.02153L-0.60003 16.2808L4.04208 16.2299L3.96253 8.97066L-0.679576 9.02153ZM1.72102 
                    18.5765H8.29002V13.9341H1.72102V18.5765ZM5.96882 16.2553V17.5358H10.6112V16.2553H5.96882ZM8.29002 
                    15.2146H2.72842V19.857H8.29002V15.2146ZM2.72842 15.2146V8.99609H-1.91397V15.2146H2.72842ZM2.72842 
                    15.2146H2.72842H-1.91397C-1.91397 17.7785 0.164496 19.857 2.72842 19.857V15.2146Z" fill="black"
                  mask="url(#path-1-inside-1_205_127)" />
                <path d="M1.63891 1.97276C1.63891 1.80375 1.7038 1.64167 1.81929 1.52216C1.93478 1.40265 2.09142 
                    1.33552 2.25476 1.33552H6.97622V5.15896C6.97622 5.60965 7.14924 6.04187 7.45722 6.36055C7.7652 
                    6.67924 8.18291 6.85827 8.61847 6.85827H12.3135V15.1424C12.3135 15.3114 12.2486 15.4735 12.1331 
                    15.593C12.0177 15.7125 11.861 15.7796 11.6977 15.7796H7.79734V17.0541H11.6977C11.9403 17.0541 
                    12.1805 17.0047 12.4047 16.9086C12.6289 16.8125 12.8325 16.6717 13.0041 16.4942C13.1756 16.3167 
                    13.3117 16.1059 13.4046 15.874C13.4974 15.642 13.5452 15.3935 13.5452 15.1424V6.57279C13.5452 
                    6.17855 13.3941 5.80045 13.124 5.52176L8.26785 0.496908C7.99851 0.218016 7.63315 0.061232 7.25212 
                    0.0610352H2.25476C1.76476 0.0610352 1.29483 0.262448 0.948355 0.620966C0.601877 0.979483 0.407227 
                    1.46574 0.407227 1.97276V8.55758H1.63891V1.97276ZM8.2079 5.15896V2.23615L11.4431 5.58379H8.61847C8.50958
                     5.58379 8.40515 5.53903 8.32815 5.45936C8.25116 5.37969 8.2079 5.27163 8.2079 5.15896Z"
                  fill="black" />
              </svg>
              <div class="posted-december-2-2022">Posted: {{jobpost.JobPostedDate | date:'MMMM dd,YYYY'}}</div>
              <div class="ends-january-23-2023">Ends: {{jobpost.TargetDate | date:'MMMM dd,YYYY'}}</div>
              <svg class="vector5" width="18" height="20" viewBox="0 0 18 20" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M4.32212 2.08044C4.32212 1.8995 4.39399 1.72596 4.52191 1.59802C4.64983 1.47007 4.82332 1.39819 
                    5.00423 1.39819H10.2337V5.49169C10.2337 5.97421 10.4253 6.43696 10.7665 6.77815C11.1076 7.11935 11.5702
                    7.31103 12.0527 7.31103H16.1453V16.1803C16.1453 16.3612 16.0734 16.5348 15.9455 16.6627C15.8176 
                    16.7906 15.6441 16.8625 15.4632 16.8625H11.1432V18.227H15.4632C15.7319 18.227 15.998 18.1741 
                    16.2463 18.0712C16.4945 17.9684 16.7201 17.8176 16.9101 17.6275C17.1002 17.4375 17.2509 17.2119 
                    17.3537 16.9635C17.4566 16.7152 17.5095 16.4491 17.5095 16.1803V7.00538C17.5095 6.58329 17.3421 
                    6.17849 17.0429 5.88012L11.6643 0.50035C11.366 0.20176 10.9613 0.0339022 10.5393 0.0336914H5.00423C4.46151 
                    0.0336914 3.94102 0.249331 3.55727 0.633171C3.17351 1.01701 2.95791 1.53761 2.95791 2.08044V9.13036H4.32212V2.08044ZM11.5979 
                    5.49169V2.36244L15.1812 5.94653H12.0527C11.932 5.94653 11.8164 5.89861 11.7311 5.81331C11.6458 5.72801 
                    11.5979 5.61232 11.5979 5.49169ZM3.41265 10.7223C3.41265 10.9032 3.34079 11.0768 3.21287 11.2047C3.08495
                     11.3326 2.91145 11.4045 2.73054 11.4045H1.5937V17.7722H2.73054C2.91145 17.7722 3.08495 17.8441 3.21287 
                     17.972C3.34079 18.1 3.41265 18.2735 3.41265 18.4544C3.41265 18.6354 3.34079 18.8089 3.21287 
                     18.9369C3.08495 19.0648 2.91145 19.1367 2.73054 19.1367H1.5937C1.23189 19.1367 0.884899 18.9929 0.62906 
                     18.737C0.373221 18.4811 0.229492 18.1341 0.229492 17.7722V11.4045C0.229492 11.0426 0.373221 10.6956 0.62906 
                     10.4397C0.884899 10.1838 1.23189 10.04 1.5937 10.04H2.73054C2.91145 10.04 3.08495 10.1119 3.21287 
                     10.2399C3.34079 10.3678 3.41265 10.5413 3.41265 10.7223ZM7.73265 11.4045C7.55174 11.4045 7.37825 11.3326 7.25033 
                     11.2047C7.12241 11.0768 7.05055 10.9032 7.05055 10.7223C7.05055 10.5413 7.12241 10.3678 7.25033 10.2399C7.37825 
                     10.1119 7.55174 10.04 7.73265 10.04H8.86949C9.2313 10.04 9.5783 10.1838 9.83414 10.4397C10.09 10.6956 10.2337 
                     11.0426 10.2337 11.4045V17.7722C10.2337 18.1341 10.09 18.4811 9.83414 18.737C9.5783 18.9929 9.2313 19.1367 
                     8.86949 19.1367H7.73265C7.55174 19.1367 7.37825 19.0648 7.25033 18.9369C7.12241 18.8089 7.05055 18.6354 
                     7.05055 18.4544C7.05055 18.2735 7.12241 18.1 7.25033 17.972C7.37825 17.8441 7.55174 17.7722 7.73265 
                     17.7722H8.86949V11.4045H7.73265ZM5.2316 13.4513C5.4125 13.4513 5.586 13.5232 5.71392 13.6511C5.84184 
                     13.779 5.9137 13.9526 5.9137 14.1335V17.3174C5.9137 17.4983 5.84184 17.6718 5.71392 17.7998C5.586 17.9277 
                     5.4125 17.9996 5.2316 17.9996C5.05069 17.9996 4.8772 17.9277 4.74928 17.7998C4.62136 17.6718 4.54949 
                     17.4983 4.54949 17.3174V14.1335C4.54949 13.9526 4.62136 13.779 4.74928 13.6511C4.8772 13.5232 5.05069 13.4513 
                     5.2316 13.4513ZM5.2316 12.769C5.4125 12.769 5.586 12.6971 5.71392 12.5692C5.84184 12.4413 5.9137 12.2677 5.9137 
                     12.0868C5.9137 11.9058 5.84184 11.7323 5.71392 11.6044C5.586 11.4764 5.4125 11.4045 5.2316 11.4045C5.05069 11.4045 
                     4.8772 11.4764 4.74928 11.6044C4.62136 11.7323 4.54949 11.9058 4.54949 12.0868C4.54949 12.2677 4.62136 12.4413 
                     4.74928 12.5692C4.8772 12.6971 5.05069 12.769 5.2316 12.769Z" fill="black" />
              </svg>

              <div class="submitted-resume">submitted resume</div>

              <div class="_10">{{jobpost.CandidateResumeCount}}</div>

              <div class="selected">Selected</div>

              <div class="_12-15">{{jobpost.JoinCandidateCount}}</div>

              <div class="job-status">Job status</div>

              <div class="frame-1209">
                <div class="frame-1208">
                  <div class="closed">{{jobpost.Jobstatus}}</div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="frame-1212">
          <div class="manage-jobs">Manage Jobs</div>

          <div class="frame-1211" (click)="PostJob()">
            <svg class="frame-1206" width="56" height="45" viewBox="0 0 56 45" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M34.387 26.6776H33.0354L32.5563 26.1973C34.2909 24.1055 35.2443 21.4349 35.2424 18.673C35.2424 16.3862 34.5902 14.1508 33.3682 12.2494C32.1463 10.348 30.4094 8.86607 28.3774 7.99096C26.3454 7.11585 24.1094 6.88688 21.9521 7.33301C19.7949 7.77914 17.8134 8.88033 16.2582 10.4973C14.7029 12.1143 13.6438 14.1745 13.2147 16.4173C12.7856 18.6602 13.0058 20.9849 13.8475 23.0977C14.6892 25.2104 16.1146 27.0161 17.9434 28.2866C19.7721 29.5571 21.9222 30.2352 24.1217 30.2352C26.8762 30.2352 29.4083 29.1857 31.3587 27.4425L31.8206 27.9405V29.3458L40.375 38.2219L42.9242 35.5715L34.387 26.6776ZM24.1217 26.6776C19.8616 26.6776 16.4227 23.1022 16.4227 18.673C16.4227 14.2438 19.8616 10.6684 24.1217 10.6684C28.3818 10.6684 31.8206 14.2438 31.8206 18.673C31.8206 23.1022 28.3818 26.6776 24.1217 26.6776Z"
                fill="#434242" />
              <rect x="1.00109" y="1.33312" width="53.9233" height="42.6667" rx="9.33333" stroke="black"
                stroke-width="0.888889" />
            </svg>
            <div class="frame-1096" (click)="PostJob()">
              <div class="post-a-job" (click)="PostJob()">Post a job</div>
            </div>
          </div>
        </div>

        <div class="frame-1207" *ngIf="jobpostcount">
          <div class="frame-1097" (click)="getAllActiveJobs()" [ngClass]="{'bg': Active}">
            <div class="group-1098">
              <div class="active-jobs-50" [ngClass]="{'bg': Active}">Active Jobs ({{jobpostcount[0].ActiveJob}})</div>
            </div>
          </div>

          <div class="frame-1097" (click)="getAllCloseJobs()" [ngClass]="{'bg': Close}">
            <div class="group-1098">
              <div class="active-jobs-50" [ngClass]="{'bg': Close}">Closed Jobs ({{jobpostcount[0].CloseJob}})</div>
            </div>
          </div>

          <div class="frame-1097" (click)="getAllJobs()" [ngClass]="{'bg': Expired}">
            <div class="group-1098">
              <div class="active-jobs-50" [ngClass]="{'bg': Expired}">Expired Jobs ({{jobpostcount[0].ExpiredJobs}})
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-2 col-md-12 wid">
        <form [formGroup]="manageForm">
          <input class="form-control gototxt" (keyup)="GoToPage($event)" placeholder="Goto Page"
            formControlName="PageSearch">
        </form>
      </div>
      <div class="col-lg-2 col-md-12 wid">
        <button type="submit" (click)="GOTO()" class="gotobtn">Goto Page<i class='bx bx-search'></i></button>
      </div>
      <div class="col-lg-2 col-md-12 wid">
        <button type="submit" (click)="Previous()" class="Previous">Previous<i class='bx bx-skip-previous-circle'
            style="margin-left: 10px;"></i></button>
      </div>
      <div class="col-lg-2 col-md-12 wid">
        <button type="submit" (click)="Next()" class="Next">Next<i class='bx bx-skip-next-circle'
            style="margin-left: 10px;"></i></button>
      </div>
      <div class="col-lg-2 col-md-12 wid">
        <label class="totalRecords">Total Records : {{totalItems}}</label>
      </div>
      <div class="col-lg-2 col-md-12 wid">
        <label class="totalpages">Total Pages : {{totalpages}}</label>
      </div>
    </div>

  </div>
</div>

<div class="footer">
  <!-- Footer -->
  <app-footer-style-two></app-footer-style-two>
</div>

<!-- End Footer -->