import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Languages } from 'src/app/interface';
import { SharedService } from 'src/app/shared.service';
import { GlobalService } from 'src/app/global.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'app-add-edit-languages',
    templateUrl: './add-edit-languages.component.html',
    styleUrls: ['./add-edit-languages.component.scss']
})
export class AddEditLanguagesComponent implements OnInit, OnDestroy {

    submitted: boolean = false;
    submittedLanguage: boolean = false;
    languageForm: FormGroup;
    public languageInfo = [];
    langId: number = null;
    saveLangBtn: boolean = true;
    updateLangBtn: boolean = false;
    response: any;
    public placeholder: string = '';
    public keyword = 'skill';
    public historyHeading: string = 'Recently selected';
    // public proficiency = data.proficiency;
    // filteredLanguages: Observable<string[]>;
    //allLanguages:Observable<Languages[]>;
    hideForm: boolean = false;
    hideTable: boolean = true;

    allLanguages: Languages[];
    filterLanguages?: Observable<Languages[]>;
    formControlLanguages = new FormControl();
    LanguagesId: number;

    openform=false;
    // openForm=false;
    // boolVar=true;
    boolVar = true;


    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private Service: SharedService<any>,
        private Global:GlobalService,
        private dialogRef: MatDialogRef<AddEditLanguagesComponent>,
        @Inject(MAT_DIALOG_DATA) public editData: any
    ) { }

    ngOnInit(): void {

        this.languageForm = this.formBuilder.group({
            Id: ['', null],
            EmpId: ['', null],
            LanguageId: ['', [Validators.required]],
            Language: ['', null],
        })

        // this.Service.Get('Languages/GetLanguages', null).subscribe(languages => {
        //     this.allLanguages = languages;
        // })

        this.Service.Get('Languages/GetLanguages', null).subscribe(data => {
            if (data != null) {
              this.allLanguages = data;
              //this.formControlLanguages.setValue(this.allJobRole[0]);
              this.filterLanguages = this.formControlLanguages.valueChanges.pipe(
                startWith<string | Languages>(''),
                map(value => typeof value === 'string' ? value : value.Language),
                map(Language => Language ? this._filterLanguage(Language) : this.allLanguages.slice()),
              );
              //this.onchangejobrole(this.allJobRole[0].Id);
            }
          });
    }
 
    private _filterLanguage(Language: string): Languages[] {
        const filterValue = Language.toLowerCase();
        return this.allLanguages.filter(
          option => option.Language?.toLowerCase().indexOf(filterValue) === 0
        );
      }
    
      displayFnForLanguage(value: any) {
        return value ? value.Language : undefined;
      }

      onchangeLanguage(event) {
        this.LanguagesId = event
      }

      close(){
        this.dialogRef.close(); 
    }

    get flang(): { [key: string]: AbstractControl } {
        return this.languageForm.controls;
    }

    languageInfoForm() {
        this.submittedLanguage = true;

        this.languageForm.controls["LanguageId"].setValue(this.LanguagesId);

        if (this.languageForm.invalid) {
            this.toastr.error("Please fill out the required fields (* fields)");
        }
        else {
            const insertlanguage = this.languageForm.getRawValue();
            insertlanguage.EmpId = this.Global.getCandidateId();
            this.Service.Post('CandidateLanguages/InsertCandidtaeLanguagesForIndiviual',insertlanguage).subscribe(
                (res) => {
                    this.toastr.success(res);
                    this.hideForm = false;
                    this.dialogRef.close('Save');
                    
                },
                (error) => {
                    //if (error.status == 401) this.router.navigate(['/login']);
                this.toastr.error(error.error);
                });
            this.submittedLanguage = false;
        }
    }

    getAllLanguages() {
        this.Service.Get('CandidateLanguages/GetCandidateLanguages?EmpId=' + this.Global.getCandidateId(), null).subscribe(res => {
            this.languageInfo = res;
        },
            (error) => {
                //if (error.status == 401) this.router.navigate(['/login']);
                this.toastr.error(error.error);
            });
    }



    ngOnDestroy(): void {

    }

}
