import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ExcelServicesService } from 'src/app/excel-services.service';
import { GlobalService } from 'src/app/global.service';
import { EmployeeMaster } from 'src/app/interface';
import { SharedService } from 'src/app/shared.service';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-collegecandidatetable',
  templateUrl: './collegecandidatetable.component.html',
  styleUrls: ['./collegecandidatetable.component.scss']
})

export class CollegeCandidateTableComponent implements OnInit, OnDestroy {

  Info = [];
  dataSource: MatTableDataSource<EmployeeMaster>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  data: any;
  displayedColumns: string[] = ['FirstName', 'LastName', 'MobileNo','Qualification','AcademicDescription','SpecializationDescription','JobRole','EmployeementType'];
  constructor(
    private toastr: ToastrService,
    private router: Router,
    private service: SharedService<any>,
    private myGlobal: GlobalService,
    private excelService: ExcelServicesService,
  ) { }

  ngOnInit(): void {
    this.getCandidates()
  }

  getCandidates() {

    this.service.Get('CandidateInfo/GetCandidateDataForCollege?UserId=' + this.myGlobal.getuserId(), null,).subscribe(
      (res) => {
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.paginator = this.paginator;
      },
      (error) => {
        //if (error.status == 401) this.router.navigate(['/login']);
        this.toastr.error(error.error);
      });
  }

  exportAsXLSX(): void {
    const InternShipStartDate: any = 'mm-dd-yyyy';
    const InternShipEndDate: any = 'mm-dd-yyyy';
    const MobileNo: any = 1234567890;
    const GET: any = 1;

    let objEMColln: EmployeeMaster[] = [];
    // for (let item of this.importData) {
    let objEM: EmployeeMaster = new EmployeeMaster();

    objEM.FirstName = 'Bhumi';
    objEM.LastName = 'Pathak';
    objEM.ProfileName = 'Bhumi Pathak';
    objEM.Email = 'test@gmail.com';
    objEM.MobileNo = MobileNo;
    objEM.Qualification = '';
    objEM.AcademicDescription = '';
    objEM.SpecializationDescription = '';
    objEM.IsGET = GET;
    objEM.JobRole = '';
    objEM.NoOfExperience = 1  ;
    objEM.PassOutYear = 2021  ;
    objEM.InternShipStartDate = InternShipStartDate;
    objEM.InternShipEndDate = InternShipEndDate;
    objEMColln.push(objEM);
    // }
    this.excelService.exportAsExcelFile(objEMColln, 'Sample_Candidate_Data_');
  }


  // onFileChange(event: any): void {
  //   /* wire up file reader */
  //   const target: DataTransfer = <DataTransfer>(event.target);
  //   if (target.files.length !== 1) throw new Error('Cannot use multiple files');
  //   const reader: FileReader = new FileReader();
  //   reader.readAsBinaryString(target.files[0]);
  //   reader.onload = (e: any) => {
  //     /* read workbook */
  //     const bstr: string = e.target.result;
  //     const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

  //     /* grab first sheet */
  //     const wsname: string = wb.SheetNames[0];
  //     const ws: XLSX.WorkSheet = wb.Sheets[wsname];

  //     /* save data */
  //     this.data = XLSX.utils.sheet_to_json(ws);

  //     let objEMColln: EmployeeMaster[] = [];
  //     for (let item of this.data) {
  //       let objER: EmployeeMaster = new EmployeeMaster();
  //       objER.FirstName = item.FirstName;
  //       objER.LastName = item.LastName;
  //       objER.ProfileName = item.ProfileName;
  //       objER.MobileNo = item.MobileNo;
  //       objER.Email = item.Email;
  //       objER.IsGET = true;

  //       const momentSatrtDate = new Date(item.InternShipStartDate);
  //       const formatedStartdate = moment(momentSatrtDate).format("YYYY-MM-DD");
  //       item.InternShipStartDate = formatedStartdate;
  //       objER.InternShipStartDate = item.InternShipStartDate;

  //       const momentEndDate = new Date(item.InternShipEndDate);
  //       const formatedEnddate = moment(momentEndDate).format("YYYY-MM-DD");
  //       item.InternShipEndDate = formatedEnddate;
  //       objER.InternShipEndDate = item.InternShipEndDate;
  //       objER.UserTypeId = 4;
  //       objEMColln.push(objER);
  //     }
  //     this.service.Post('CandidateInfo/ImportCandidateInfoDetails', objEMColln).subscribe(
  //       (res) => {
         
  //         this.toastr.success(res);
  //         this.getCandidates();

  //         for (let user of res) {
  //           this.service.Post('ResetPassword?loginName=' + user.Email, null).subscribe(
  //             (res) => {
  //               this.toastr.success(res);
  //             },
  //           )
  //         }
  //       },

  //     )
  //   }
  // }
  onFileChange(event: any): void {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = XLSX.utils.sheet_to_json(ws);

      let objEMColln: EmployeeMaster[] = [];
      
      for (let item of this.data) {
        
        let objER: EmployeeMaster = new EmployeeMaster();
        objER.FirstName = item.FirstName;
        objER.LastName = item.LastName;
        objER.ProfileName = item.ProfileName;
        objER.MobileNo = item.MobileNo;
        objER.Email = item.Email;
        objER.IsGET = item.IsGET;
        objER.Qualification = item.Qualification;
        objER.AcademicDescription = item.AcademicDiscipline;
        objER.SpecializationDescription = item.Specialization;
        objER.JobRole = item.JobRole;
        objER.UserTypeId = 4;
        objER.IsGET = true;
        objER.NoOfExperience = item.TotalExperience;
        objER.PassOutYear = item.PassOutYear;
        const momentSatrtDate = new Date(item.InternShipStartDate);
        const formatedStartdate = moment(momentSatrtDate).format("YYYY-MM-DD");
        item.InternShipStartDate = formatedStartdate;
        objER.InternShipStartDate = item.InternShipStartDate;

        const momentEndDate = new Date(item.InternShipEndDate);
        const formatedEnddate = moment(momentEndDate).format("YYYY-MM-DD");
        item.InternShipEndDate = formatedEnddate;
        objER.InternShipEndDate = item.InternShipEndDate;
        objEMColln.push(objER);
      }
      
      this.service.Post('CandidateInfo/ImportCandidateInfoDetails', objEMColln).subscribe(
        (res) => {
          this.toastr.success(res);
          this.getCandidates();

          for (let user of res) {
            this.service.Post('ResetPassword?loginName=' + user.Email, null).subscribe(
              (res) => {
                this.toastr.success(res);
              },
            )
          }
        },

      )
    }
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  ngOnDestroy(): void {

  }

}

