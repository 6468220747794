import { ViewportScroller } from '@angular/common';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { GlobalService } from 'src/app/global.service';
import { AcademicDiscipline, cities, JobRole, Qualification, Qualificationa, Specialization } from 'src/app/interface';
import { SharedService } from 'src/app/shared.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';
import { CandidatesService } from "./services/candidates.service";

@Component({
    selector: 'app-candidates',
    templateUrl: './candidates.component.html',
    styleUrls: ['./candidates.component.scss']
})
export class CandidatesComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator) paginator!: MatPaginator;

    candidatsInfo = []
    datapageInfo: any;
    dataFilter: number[] = [];
    data: any;
    jobRoleControl = new FormControl();
    control = new FormControl();
    skillControl = new FormControl();
    JobRole: number = 0;
    // allJobRole: Observable<JobRole[]>;
    // alldiscipline: Observable<AcademicDiscipline[]>;
    // allspecialization: Observable<Specialization[]>;
    // allCities: Observable<cities[]>;
    // allQualification?: Observable<Qualificationa[]>;
    Education: number = 0;
    City: number = 0;
    Specialization: number = 0;
    AcademicDiscipline: number = 0;
    //page: number = 1;
    pagesize: number = 10;
    totalItems: number = 0;
    // allfunction1: Observable<any>;
    // allsection1: Observable<any>;
    Function1: number = 0;
    Section1: number = 0;


    allJobRole: JobRole[];
    filterJobRole?: Observable<JobRole[]>;
    formControlObj = new FormControl();

    allCities: cities[];
    filtercity?: Observable<cities[]>;
    formControlcity = new FormControl();

    allQualification?: Qualification[];
    filterQuli?: Observable<Qualification[]>;
    formControlQuli = new FormControl();

    alldiscipline: AcademicDiscipline[];
    filterdiscipline?: Observable<AcademicDiscipline[]>;
    formControldiscipline = new FormControl();

    allspecialization: Specialization[];
    filterspecialization?: Observable<Specialization[]>;
    formControlspecialization = new FormControl();

    JobRoleId: any;

    allfunction: any[];
    filterfunction?: Observable<any[]>;
    formControlfunction = new FormControl();

    allsection: any[];
    filtersection?: Observable<any[]>;
    formControlsection = new FormControl();

    candidatecount: Number;
    visible: boolean = false
    afterClickData: any ; 
    appliedjob: any;
    shortlistdataSource: any;
    shortlistcandidate: boolean = false;
    allcandidate: boolean = true;

    displayedColumns: string[] = ['CandidateName', 'JobRole', 'FirstName', 'LastName'];
    candidateForm: FormGroup;

    totalpages: number;
    displaytotalpage: number;
    FindCandidatePage : number = 1;

    PostJobIdForShortlist: number = 0;
    allJobRoleForShortlist:  any[];
    filterJobRoleForShortlist?: Observable<any[]>;
    formControlObjForShortlist = new FormControl();

    CandidateId : number ; 
    companyId: number;
    dataFilterForShortlist: string[] = [];
    CandidateStatus : any = null;
    message: string = '';

    constructor(private toastr: ToastrService,
        private router: Router,
        private myGlobal: GlobalService,
        private service: SharedService<any>,
        private scroller: ViewportScroller,
        public Global: GlobalService,
        private formBuilder: FormBuilder,
        private dialog: MatDialog
    ) {
        this.dataFilter = [];
        this.dataFilter.push(this.myGlobal.getuserId());
        this.dataFilter.push(this.myGlobal.GetUserType());
        this.dataFilter.push(0);
        this.dataFilter.push(0);
        this.dataFilter.push(0);
        this.dataFilter.push(0);
        this.dataFilter.push(0);
        this.dataFilter.push(0);
        this.dataFilter.push(0);

    }

    ngOnInit(): void {
     this.companyId = this.myGlobal.GetCompanyId();
        this.dataFilter.push(this.FindCandidatePage);
        this.dataFilter.push(this.pagesize);
        this.afterClickData = null
        this.refreshList();
        
        this.candidateForm = this.formBuilder.group({
            PageSearch: [null, Validators.nullValidator],
        });
        this.candidateForm.controls["PageSearch"].setValue(this.FindCandidatePage);

        this.service.Get('JobRole/GetJobRole?Id=0', null).subscribe(data => {
            if (data != null) {
                this.allJobRole = data;
                this.filterJobRole = this.formControlObj.valueChanges.pipe(
                    startWith<string | JobRole>(''),
                    map(value => typeof value === 'string' ? value : value.JobRole),
                    map(JobRole => JobRole ? this._filterRole(JobRole) : this.allJobRole.slice()),
                );}
        })

        this.service.Get('ShortListCandidate/GetJobRoleWithPostedJobIdForShortlistedCandidate?companyId=' + this.companyId, null).subscribe(data => {
            if (data != null) {
                this.allJobRoleForShortlist = data;
                this.filterJobRoleForShortlist = this.formControlObjForShortlist.valueChanges.pipe(
                    startWith<string | any>(''),
                    map(value => typeof value === 'string' ? value : value.JobNumber),
                    map(JobNumber => JobNumber ? this._filterRoleForShortlist(JobNumber) : this.allJobRoleForShortlist.slice()),
                );}
        })

        this.service.Get('City/GetCity?Id=0', null).subscribe(data => {
            if (data != null) {
                this.allCities = data;
                this.filtercity = this.formControlcity.valueChanges.pipe(
                    startWith<string | cities>(''),
                    map(value => typeof value === 'string' ? value : value.CityName),
                    map(CityName => CityName ? this._filtercity(CityName) : this.allCities.slice()),
                );}
        })


        this.service.Get('Qualification/GetQualification?Id=0', null).subscribe(data => {
            if (data != null) {
                this.allQualification = data;
                this.filterQuli = this.formControlQuli.valueChanges.pipe(
                    startWith<string | Qualification>(''),
                    map(value => typeof value === 'string' ? value : value.Qualification),
                    map(Qualification => Qualification ? this._filterQuali(Qualification) : this.allQualification.slice()),
                );}
        })

        this.service.Get('CandidateInfo/GetFunctionData?Id=0', null).subscribe(data => {
            if (data != null) {
                this.allfunction = data;
                this.filterfunction = this.formControlfunction.valueChanges.pipe(
                    startWith<string | any>(''),
                    map(value => typeof value === 'string' ? value : value.FunctionName),
                    map(FunctionName => FunctionName ? this._filterFunction(FunctionName) : this.allfunction.slice()),
                );}
        })


        this.service.Get('IndustryTypes/GetIndustryTypes?Id=0', null).subscribe(data => {
            if (data != null) {
                this.allsection = data;
                this.filtersection = this.formControlsection.valueChanges.pipe(
                    startWith<string | any>(''),
                    map(value => typeof value === 'string' ? value : value.IndustryType),
                    map(IndustryType => IndustryType ? this._filterSection(IndustryType) : this.allsection.slice()),
                );}
        })

        this.service.Get('CandidateInfo/GetActiveInActiveCanditeCount', null).subscribe(data => {
            if (data != null)
             {
                this.candidatecount = data;
            }
        })
        // this.refreshListForFilter();
    }


    private _filterRole(JobRole: string): JobRole[] {
        const filterValue = JobRole.toLowerCase();
        return this.allJobRole.filter(
            option => option.JobRole?.toLowerCase().indexOf(filterValue) === 0
        );}

    displayFnForRole(value: any) {
        return value ? value.JobRole : undefined;
    }

    private _filterRoleForShortlist(JobNumber: string): any[] {
        const filterValue = JobNumber.toLowerCase();
        return this.allJobRoleForShortlist.filter(
            option => option.JobNumber?.toLowerCase().indexOf(filterValue) === 0
        );
    }

    displayFnForRoleForShortlist(value: any) {
        return value ? value.JobNumber : undefined;
    }

    private _filtercity(CityName: string): cities[] {
        const filterValue = CityName.toLowerCase();
        return this.allCities.filter(
            option => option.CityName?.toLowerCase().indexOf(filterValue) === 0
        );}

    displayFnForCity(value: any) {
        return value ? value.CityName : undefined;
    }

    private _filterQuali(Qualification: string): Qualification[] {
        const filterValue = Qualification.toLowerCase();
        return this.allQualification.filter(
            option => option.Qualification?.toLowerCase().indexOf(filterValue) === 0
        );}

    displayFnForQuli(value: any) {
        return value ? value.Qualification : undefined;
    }

    private _filterdiscipline(AcademicDescription: string): AcademicDiscipline[] {
        const filterValue = AcademicDescription.toLowerCase();
        return this.alldiscipline.filter(
            option => option.AcademicDescription?.toLowerCase().indexOf(filterValue) === 0
        );}

    displayFnFordiscipline(value: any)
    {
        return value ? value.AcademicDescription : undefined;
    }

    private _filterSpecialization(SpecializationDescription: string): Specialization[] {
        const filterValue = SpecializationDescription.toLowerCase();
        return this.allspecialization.filter(
            option => option.SpecializationDescription?.toLowerCase().indexOf(filterValue) === 0
        );
    }

    displayFnForSpecialization(value: any) {
        return value ? value.SpecializationDescription : undefined;
    }

    private _filterFunction(FunctionName: string): any[] {
        const filterValue = FunctionName.toLowerCase();
        return this.allfunction.filter(
            option => option.FunctionName?.toLowerCase().indexOf(filterValue) === 0
        );
    }

    displayFnForFunction(value: any) {
        return value ? value. FunctionName : undefined;
    }

    private _filterSection(IndustryType: string): any[] {
        const filterValue = IndustryType.toLowerCase();
        return this.allsection.filter(
            option => option.IndustryType?.toLowerCase().indexOf(filterValue) === 0
        );
    }

    displayFnForSection(value: any) {
        return value ? value.IndustryType : undefined;
    }

    refreshList() {
        this.service.Post('FindCandidate/GetFilteredDataOfCandidate', this.dataFilter).subscribe(data => {
            this.candidatsInfo = data[0];
            this.datapageInfo = data[1];
            this.totalItems = this.datapageInfo[0].length;
            this.totalpages = Math.ceil(this.totalItems / 10);
            this.CardClick(this.candidatsInfo[0])
        },(error) => {
                this.toastr.error(error.error);
        });
    }

    GOTO() {
        var _pageNumber = this.dataFilter.length - 2;
        var _pageSize = this.dataFilter.length - 1;
        delete this.dataFilter[_pageNumber];
        delete this.dataFilter[_pageSize];
    
        this.dataFilter.push(this.FindCandidatePage);
        this.dataFilter.push(this.pagesize);
       this.refreshList();
      }
    
      Previous() {
        var _pageNumber = this.dataFilter.length - 2;
        var _pageSize = this.dataFilter.length - 1;
        delete this.dataFilter[_pageNumber];
        delete this.dataFilter[_pageSize];
    
        if (this.FindCandidatePage > 0) {
          this.FindCandidatePage = this.FindCandidatePage - 1
          this.candidateForm.controls["PageSearch"].setValue(this.FindCandidatePage);
          this.dataFilter.push(this.FindCandidatePage);
          this.dataFilter.push(this.pagesize);
          this.refreshList();
        } else {
    
        }
    
      }
    
      Next() {
        var _pageNumber = this.dataFilter.length - 2;
        var _pageSize = this.dataFilter.length - 1;
        delete this.dataFilter[_pageNumber];
        delete this.dataFilter[_pageSize];
        if (this.totalpages > this.FindCandidatePage) {
          this.FindCandidatePage = this.FindCandidatePage + 1
          this.candidateForm.controls["PageSearch"].setValue(this.FindCandidatePage);
          this.dataFilter.push(this.FindCandidatePage);
          this.dataFilter.push(this.pagesize);
          this.refreshList();
        }
      }
    
      GoToPage(event: Event) {
    
        var value = (event.target as HTMLInputElement).value;
        if (+value > this.totalpages
          || +value < 0) {
          this.toastr.success('Page no Should be between : 0 And ' + this.totalpages);
        }
        else {
          this.FindCandidatePage = +value;
    
          var _pageNumber = this.dataFilter.length - 2;
          var _pageSize = this.dataFilter.length - 1;
          delete this.dataFilter[_pageNumber];
          delete this.dataFilter[_pageSize];
    
          this.dataFilter.push(this.FindCandidatePage);
          this.dataFilter.push(this.pagesize);
        }
    
      }



    search() {
        this.dataFilter = [];
        this.dataFilter.push(this.myGlobal.getuserId());
        this.dataFilter.push(this.myGlobal.GetUserType());
        this.dataFilter.push(this.JobRole);
        this.dataFilter.push(this.City);
        this.dataFilter.push(this.Education);
        this.dataFilter.push(this.Specialization);
        this.dataFilter.push(this.AcademicDiscipline);
        this.dataFilter.push(this.Function1);
        this.dataFilter.push(this.Section1);
        this.dataFilter.push(this.FindCandidatePage);
        this.dataFilter.push(this.pagesize);
        this.refreshList();
        this.candidateForm.controls["PageSearch"].setValue(this.FindCandidatePage);

    }

    onchangeCity(city: any) {
        this.City = city;
    }

    onchangeJobRole(jobrole: any) {
        this.JobRole = jobrole;
    }

    onchangeSpecialization(specialization: any) {
        this.Specialization = specialization;
    }

    onchangeFunction(function1: any) {
        this.Function1 = function1;
    }

    onchangeSection(section1: any) {
        this.Section1 = section1;
    }

    onchangeAcademicDiscipline(AcademicDescription: any) {
        this.AcademicDiscipline = AcademicDescription;
        //this.allspecialization = this.service.Get('Specialization/GetSpecializationDataByDiscipline?AcademicDisplineId=' + AcademicDescription, null);
        this.service.Get('Specialization/GetSpecializationDataByDiscipline?AcademicDisplineId=' + this.AcademicDiscipline, null).subscribe(data => {
            if (data != null) {
                this.allspecialization = data;
                this.filterspecialization = this.formControlspecialization.valueChanges.pipe(
                    startWith<string | Specialization>(''),
                    map(value => typeof value === 'string' ? value : value.SpecializationDescription),
                    map(SpecializationDescription => SpecializationDescription ? this._filterSpecialization(SpecializationDescription) : this.allspecialization.slice()),
                );
            }
        })}

    onchangeQualification(Qualification: any) {
        this.Education = Qualification;
        // this.alldiscipline = this.service.Get('AcademicDiscipline/GetAcademicDisciplineByQualification?QualificationId=' + Qualification, null);
        this.service.Get('AcademicDiscipline/GetAcademicDisciplineByQualification?QualificationId=' + this.Education, null).subscribe(data => {
            if (data != null) {
                this.alldiscipline = data;
                this.filterdiscipline = this.formControldiscipline.valueChanges.pipe(
                    startWith<string | AcademicDiscipline>(''),
                    map(value => typeof value === 'string' ? value : value.AcademicDescription),
                    map(AcademicDescription => AcademicDescription ? this._filterdiscipline(AcademicDescription) : this.alldiscipline.slice()),
                );
            }
        })}

    CardClick(event) {
        this.visible = true
        this.scroller.scrollToAnchor("targetRed");
        this.service.Get('AppliedJob/GetCompanyWiseCandidateApliedjobData?candidateId=' + event.UserId + '&companyid=' + this.Global.GetCompanyId(), null).subscribe(data => {
            this.appliedjob = data[0];
        })
        this.afterClickData = event;
        this.CandidateId = this.afterClickData.Id;
        this.getStatus();
    }

    removefilter() {
        this.JobRole = 0;
        this.City = 0;
        this.Education = 0;
        this.Specialization = 0;
        this.AcademicDiscipline = 0;
        this.Education = 0;
        this.Function1 = 0;
        this.Section1 = 0;
        this.FindCandidatePage = 1;
        this.formControlsection = new FormControl();
        this.formControlfunction = new FormControl();
        this.formControlspecialization = new FormControl();
        this.formControldiscipline = new FormControl();
        this.formControlQuli = new FormControl();
        this.formControlcity = new FormControl();
        this.formControlObj = new FormControl();

        this.dataFilter = [];
        this.dataFilter.push(this.myGlobal.getuserId());
        this.dataFilter.push(this.myGlobal.GetUserType());
        this.dataFilter.push(this.JobRole);
        this.dataFilter.push(this.City);
        this.dataFilter.push(this.Education);
        this.dataFilter.push(this.Specialization);
        this.dataFilter.push(this.AcademicDiscipline);
        this.dataFilter.push(this.Function1);
        this.dataFilter.push(this.Section1);
        this.dataFilter.push(this.FindCandidatePage);
        this.dataFilter.push(this.pagesize);
        this.refreshList();

        this.service.Get('JobRole/GetJobRole?Id=0', null).subscribe(data => {
            if (data != null) {
                this.allJobRole = data;
                this.filterJobRole = this.formControlObj.valueChanges.pipe(
                    startWith<string | JobRole>(''),
                    map(value => typeof value === 'string' ? value : value.JobRole),
                    map(JobRole => JobRole ? this._filterRole(JobRole) : this.allJobRole.slice()),
                );}
        })

        this.service.Get('City/GetCity?Id=0', null).subscribe(data => {
            if (data != null) {
                this.allCities = data;
                this.filtercity = this.formControlcity.valueChanges.pipe(
                    startWith<string | cities>(''),
                    map(value => typeof value === 'string' ? value : value.CityName),
                    map(CityName => CityName ? this._filtercity(CityName) : this.allCities.slice()),
                );
            }})


        this.service.Get('Qualification/GetQualification?Id=0', null).subscribe(data => {
            if (data != null) {
                this.allQualification = data;
                this.filterQuli = this.formControlQuli.valueChanges.pipe(
                    startWith<string | Qualification>(''),
                    map(value => typeof value === 'string' ? value : value.Qualification),
                    map(Qualification => Qualification ? this._filterQuali(Qualification) : this.allQualification.slice()),
                );
            }
        })

        this.service.Get('AcademicDiscipline/GetAcademicDisciplineByQualification?QualificationId=' + this.Education, null).subscribe(data => {
            if (data != null) {
                this.alldiscipline = data;
                this.filterdiscipline = this.formControldiscipline.valueChanges.pipe(
                    startWith<string | AcademicDiscipline>(''),
                    map(value => typeof value === 'string' ? value : value.AcademicDescription),
                    map(AcademicDescription => AcademicDescription ? this._filterdiscipline(AcademicDescription) : this.alldiscipline.slice()),
                );
            }
        })

        this.service.Get('Specialization/GetSpecializationDataByDiscipline?AcademicDisplineId=' + this.AcademicDiscipline, null).subscribe(data => {
            if (data != null) {
                this.allspecialization = data;
                this.filterspecialization = this.formControlspecialization.valueChanges.pipe(
                    startWith<string | Specialization>(''),
                    map(value => typeof value === 'string' ? value : value.SpecializationDescription),
                    map(SpecializationDescription => SpecializationDescription ? this._filterSpecialization(SpecializationDescription) : this.allspecialization.slice()),
                );
            }
        })

        this.service.Get('CandidateInfo/GetFunctionData?Id=0', null).subscribe(data => {
            if (data != null) {
                this.allfunction = data;
                this.filterfunction = this.formControlfunction.valueChanges.pipe(
                    startWith<string | any>(''),
                    map(value => typeof value === 'string' ? value : value.FunctionName),
                    map(FunctionName => FunctionName ? this._filterFunction(FunctionName) : this.allfunction.slice()),
                );
            }
        })

        this.service.Get('IndustryTypes/GetIndustryTypes?Id=0', null).subscribe(data => {
            if (data != null) {
                this.allsection = data;
                this.filtersection = this.formControlsection.valueChanges.pipe(
                    startWith<string | any>(''),
                    map(value => typeof value === 'string' ? value : value.IndustryType),
                    map(IndustryType => IndustryType ? this._filterSection(IndustryType) : this.allsection.slice()),
                );
            }
        })
        this.candidateForm.controls["PageSearch"].setValue(this.FindCandidatePage);
    }

    getshortlistedcandidates() 
    {
        this.allcandidate = false;
        this.shortlistcandidate = true;
        this.service.Get('CandidateInfo/GetCompanyWiseShortlistCandidate?EmpId=0&UserId=' + this.Global.getuserId(), null).subscribe(data => {
            this.shortlistdataSource = new MatTableDataSource(data);
            this.shortlistdataSource.paginator = this.paginator;
        })
    }


    AllCandidate() {
        this.allcandidate = true;
        this.shortlistcandidate = false;
    }

    PostJob() 
    {
        this.router.navigate(['/post-a-job']);
    }

    getStatus()
    {
        this.service.Get('ShortListCandidate/GetShortlistCandidateDataById?postjobId=' +this.PostJobIdForShortlist+ '&candidateId=' + this.CandidateId, null)
        .subscribe(data => {
            if ( 
                data.length != 0 )
             {
            this.CandidateStatus = data;
        }
        else{
            this.CandidateStatus = null;
        }
        })
    }

    onchangeJobRoleCompany(event) { 
        this.PostJobIdForShortlist = event;
        this.getStatus();
        //this.allJobRoleForShortlist = this.service.Get('ShortListCandidate/GetJobRoleWithPostedJobIdForShortlistedCandidate?companyId=' + this.companyId, null);
      }

  confirmDialog(row: any) {
    if(this.PostJobIdForShortlist != 0){
      this.message = `Are you sure you want to ShortList Candidate ` + row.
      FirstName + ` ?`;
    const dialogData = new ConfirmDialogModel("Confirm Action", this.message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult == true) {
        this.shortListCandidate()
      }
    });
}
else
{
    this.toastr.error('First Select Job Number');
}
}

    shortListCandidate() {
        this.dataFilterForShortlist = [];
        this.dataFilterForShortlist.push(this.PostJobIdForShortlist.toString());
        this.dataFilterForShortlist.push(this.CandidateId.toString());
        this.dataFilterForShortlist.push(this.Global.getuserId().toString());
        this.dataFilterForShortlist.push(this.companyId.toString());
       
        this.service.Post('ShortListCandidate/ShortListCandidateForComany', this.dataFilterForShortlist)
        .subscribe(
            (res) => {
                this.toastr.success(res);  
                this.getStatus();
            },
            (error) => {
                this.toastr.error(error.error);
            }
        );}

    ngOnDestroy(): void {

    }

}
