import { ViewportScroller } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/global.service';
import { Bookmark } from 'src/app/interface';
import { SharedService } from 'src/app/shared.service';

@Component({
    selector: 'app-bookjobs',
    templateUrl: './BookMark-jobs.component.html',
    styleUrls: ['./BookMark-jobs.component.scss']
})
export class BookMarkjobsComponent implements OnInit, OnDestroy {
    jobPostsInfo = [];
    bookmarkdatasource: any;
    page: number = 1;
    pagesize: number = 5;
    totalItems: number = 0;
    datapageInfo: any;
    bookmark: Bookmark;
    visible: boolean = false
    afterClickData: any = null;
    obs: any;
    addEditForm!: FormGroup;
    totalpages: number;

    constructor(
        private toastr: ToastrService,
        private Service: SharedService<any>,
        private Global: GlobalService,
        private scroller: ViewportScroller,
        private router: Router,
        private formBuilder: FormBuilder,
    ) { }

    ngOnInit(): void {
        this.addEditForm = this.formBuilder.group({
            PageSearch: [null, Validators.nullValidator],
        })
        this.addEditForm.controls["PageSearch"].setValue(this.page );
        this.getAllBookMarkJobs();
    }

    getAllBookMarkJobs() {
        this.Service.Get('BookMark/GetBookmarkDetails?userid=' + this.Global.getuserId() + '&page=' + this.page + '&pagesize=' + this.pagesize, null).subscribe(
            (res) => {
                this.jobPostsInfo = res[0];
                this.obs = this.jobPostsInfo;
                this.bookmarkdatasource = this.jobPostsInfo;
                this.datapageInfo = res[1];
                this.totalItems = this.datapageInfo[0].length;
                this.totalpages = Math.ceil(this.totalItems / 5);
                this.CardClick(this.obs[0]);
            },
            (error) => {
                this.toastr.error(error.error);
            });
    }

    GOTO() {
        if (this.page >= 1) {
                this.getAllBookMarkJobs();}
            }
        
            Previous() {
        
        
                if (this.page > 1) {
                    this.page = this.page - 1;
                    this.addEditForm.controls["PageSearch"].setValue(this.page);
                    this.getAllBookMarkJobs();
        
                } else {
        
                }
        
            }
        
            Next() {
                if (this.totalpages > this.page) {
                    this.page = this.page + 1
                    this.addEditForm.controls["PageSearch"].setValue(this.page);
                    this.getAllBookMarkJobs();
                }
            }
        
            GoToPage(event: Event) {
                var value = (event.target as HTMLInputElement).value;
                if (+value > this.totalpages
                    || +value < 0) {
                    this.toastr.success('Page no Should be between : 1 And ' + this.totalpages);
        
                }
                else {
                    this.page = +value;
                }
            }

            
    pageChanged(value: any) {
        this.page = value;
        this.getAllBookMarkJobs();
    }

    BookMarked(Jobpost: any) {
        this.bookmark = new Bookmark;
        this.bookmark.PostedJobId = Jobpost.Id;
        this.bookmark.UserId = this.Global.getuserId();
        this.Service.Post('Applyjob/Bookmark', this.bookmark).subscribe(data => {
            this.toastr.success(data);
            this.getAllBookMarkJobs();

        })
    }

    CardClick(event) {
        this.scroller.scrollToAnchor("targetRed");
        if (event == undefined) {
            this.visible = false;
        }
        else {
            this.visible = true;
            this.afterClickData = event;
        }
    }

    ApplyNowClick(jobpost: any) {
        this.Service.Post('AppliedJob/InsertAppliedJob?jobpostId=' + jobpost, null).subscribe(
            (res) => {
                this.toastr.success(res);
                this.router.navigate(['/candidateappliedjob']);
            })
    }

    ngOnDestroy(): void {
    }
}
