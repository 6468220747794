import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';

import { FooterStyleOneComponent } from './components/common/footer-style-one/footer-style-one.component';
import { NavbarStyleTwoComponent } from './components/common/navbar-style-two/navbar-style-two.component';
import { NavbarStyleThreeComponent } from './components/common/navbar-style-three/navbar-style-three.component';

import { LoginComponent } from './components/pages/login/login.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { EmployersComponent } from './components/pages/employers/employers.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { ResumeDetailsComponent } from './components/pages/resume-details/resume-details.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { AboutComponent } from './components/pages/about/about.component';
import { JobsComponent } from './components/pages/jobs/jobs.component';
import { JobDetailsComponent } from './components/pages/job-details/job-details.component';
import { PostAJobComponent } from './components/pages/post-a-job/post-a-job.component';
import { CandidatesComponent } from './components/pages/candidates/candidates.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { EmployersRegisterComponent } from './components/pages/employers-register/employers-register.component';
import { CompanyListComponent } from './components/pages/companys/companyList/companyList.component';
import { CompanyEditComponent } from './components/pages/companys/company-edit/company-edit.component';
import { NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ConfigService } from './config.service';
import { AuthService } from './auth.service';
import { UsersComponent } from './components/pages/User/users.component';
import { RecommandedjobsComponent } from './components/pages/dashboard/Recommanded-jobs/Recommanded-jobs.component';
import { BookMarkjobsComponent } from './components/pages/dashboard/BookMark-jobs/BookMark-jobs.component';
import { PersonalInfoComponent } from './components/pages/dashboard/Personal-info/Personal-info.component';
import { SearchJobsComponent } from './components/SearchJobs/SearchJobs.component';
import { WorkFromHomeJobComponent } from './components/pages/WorkFromHomeJob/WorkFromHomeJob.component';
import { CreateCandidateComponent } from './components/pages/employers-register/candidate/createcandidate.component';
import { CreateCollegeComponent } from './components/pages/College/createcollege.component';
import { CompanySignupComponent } from './components/pages/companys/companysignup/companysignup.component';
import { CollegeHomeComponent } from './components/pages/College/College-Home/college_home.component';
import { ShortListedCandidateForOwnerComponent } from './components/pages/ShortListedCandidateForOwner/shortlistedcandidateforowner.component';

// angular Material Imports
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CompanyTableComponent } from './components/pages/companys/companytable/companytable.component';
import { EditJobPostComponent } from './components/pages/post-a-job/edit-job-post/edit-job-post.component';
import { ViewResumeComponent } from './components/pages/candidates/ViewResume/viewresume.component';
import { CollegeCandidateTableComponent } from './components/pages/College/collegecandidatetable/collegecandidatetable.component';
import { CompanyHomeComponent } from './components/pages/companys/CompanyHome/companyhome.component';
import { CompanyManageJobComponent } from './components/pages/companys/CompanyManageJob/companymanagejob.component';
import { UserHomeComponent } from './components/pages/Users/UserHome/userhome.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { EditCandidateComponent } from './components/pages/employers-register/EditCandidate/editcandidate.component';
import { CandidateAppliedJobComponent } from './components/pages/candidates/candidateappliedjob/candidateappliedjob.component';
import { ForgotPasswordComponent } from './components/pages/ForgotPassword/forgotpassword.component';
import { CandidateTestComponent } from './components/pages/candidates/CandidateTest/candidatetest.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

import { AddEditProjectComponent } from './components/pages/dashboard/project/add-edit-project/add-edit-project.component';
import { AddEditLanguagesComponent } from './components/pages/dashboard/Languages/add-edit-languages/add-edit-languages.component';
import { AddEditCertificationComponent } from './components/pages/dashboard/Certification/add-edit-certification/add-edit-certificate.component';
import { AddEditPrefferedLocationComponent } from './components/pages/dashboard/Preffered Location/add-edit-location/add-edit-location.component';
import { AddEditCandidateFunctionComponent } from './components/pages/dashboard/CandidateFunction/add-edit-function/add-edit-function.component';
import { AddEditCandidateSectionComponent } from './components/pages/dashboard/CandidateSection/add-edit-section/add-edit-section.component';
import { AddEditPersonalInfoComponent } from './components/pages/dashboard/Personal-info/add-edit-personalinfo/add-edit-personalinfo.component';


import { MatInputModule } from '@angular/material/input';
import { AddEditCompanyProfileComponent } from './components/pages/companys/CompanyHome/CompanyProfile/add-edit-companyinfo/add-edit-companyinfo.component';
import { MenuComponent } from './components/common/Menu/menu.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { ConfirmDialogComponent } from './components/pages/confirm-dialog/confirm-dialog.component';
import { AddEditSkillsLanguagesComponent } from './components/pages/dashboard/skills-languages/add-edit-Skill/add-edit-skill.component';
import { AddEditEducationComponent } from './components/pages/dashboard/education/add-edit-edu/add-edit-edu.component';
import { AddEditExperienceComponent } from './components/pages/dashboard/experience/add-edit-experience/add-edit-experience';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AddEditBasicInfoComponent } from './components/pages/dashboard/basic-info/add-edit-basicinfo/add-edit-basicinfo.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { FooterStyleTwoComponent } from './components/common/footer-style-two/footer-style-two.component';
import { NavbarStyleOneComponent } from './components/common/navbar-style-one/navbar-style-one.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { LandingPageComponent } from './components/pages/candidates/landingpage/landingpage.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { PartTimeJobComponent } from './components/pages/part-time-job/parttimejob.component';
import { JobForWomenComponent } from './components/pages/jobsforwomen/jobforwomen.component';
import { JobForFreshersComponent } from './components/pages/jobforfreshers/jobforfresher.component';
import { JobForInternshipComponent } from './components/pages/JobForInternship/jobforinternship.component';
import { SpinnerOverlayComponent } from './components/pages/Spinner/spinner.component';
import { SpinnerInterceptor } from './components/pages/Spinner/spinner-interceptor.service';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { AddJobRoleComponent } from './components/pages/candidates/landingpage/jobrol/addjobrole.component';

export function init_app(configService: ConfigService) {
  return () => configService.init();
}
@NgModule({
  declarations: [
    AppComponent,
    HomeOneComponent,
    HomeTwoComponent,
    HomeThreeComponent,
    PreloaderComponent,
    NavbarStyleOneComponent,
    FooterStyleOneComponent,
    NavbarStyleTwoComponent,
    NavbarStyleThreeComponent,
    FooterStyleTwoComponent,
    LoginComponent,
    RegisterComponent,
    EmployersComponent,
    DashboardComponent,
    ResumeDetailsComponent,
    TestimonialsComponent,
    FaqComponent,
    ComingSoonComponent,
    ErrorComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    AboutComponent,
    JobsComponent,
    JobDetailsComponent,
    PostAJobComponent,
    CandidatesComponent,
    ContactComponent,
    CompanyListComponent,
    EmployersRegisterComponent,
    CompanyEditComponent,
    UsersComponent,
    RecommandedjobsComponent,
    BookMarkjobsComponent,
    PersonalInfoComponent,
    SearchJobsComponent,
    WorkFromHomeJobComponent,
    CreateCandidateComponent,
    CreateCollegeComponent,
    CompanySignupComponent,
    CompanyTableComponent,
    EditJobPostComponent,
    ViewResumeComponent,
    CollegeHomeComponent,
    CollegeCandidateTableComponent,
    CompanyHomeComponent,
    CompanyManageJobComponent,
    UserHomeComponent,
    ShortListedCandidateForOwnerComponent,
    EditCandidateComponent,
    CandidateAppliedJobComponent,
    ForgotPasswordComponent,
    CandidateTestComponent,
    AddEditEducationComponent,
    AddEditExperienceComponent,
    AddEditProjectComponent,
    AddEditLanguagesComponent,
    AddEditCertificationComponent,
    AddEditPrefferedLocationComponent,
    AddEditCandidateFunctionComponent,
    AddEditCandidateSectionComponent,
    AddEditPersonalInfoComponent,
    AddEditSkillsLanguagesComponent,
    AddEditBasicInfoComponent,
    AddEditCompanyProfileComponent,
    MenuComponent,
    ConfirmDialogComponent,
    LandingPageComponent,
    PartTimeJobComponent,
    JobForWomenComponent,
    JobForFreshersComponent,
    JobForInternshipComponent,
    SpinnerOverlayComponent,
    AddJobRoleComponent
  ],
  
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(
      {
        positionClass: 'toast-center',
        timeOut: 6000
        // autoDismiss:false,
        // disableTimeOut:true,
        // closeButton:true
      }
    ),
    NgbModule,
    NgbDatepickerModule,

    // Material
    MatPaginatorModule,
    MatTableModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatGridListModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule   ,
    MatSelectModule,
    MatCheckboxModule,
    MatFormFieldModule, 
    MatInputModule,
    MatSidenavModule,
    MatListModule,
    MatTooltipModule,
    NgImageSliderModule,
    MatDatepickerModule,
    MatProgressSpinnerModule
  ],
  providers: [ConfigService,
    {
      provide: APP_INITIALIZER, 
      useFactory: init_app,
      deps: [ConfigService],
      multi: true
    },
    SpinnerInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true
    },
    {
      provide: LocationStrategy, useClass: HashLocationStrategy,
    },
    AuthService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
