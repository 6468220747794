<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Candidates Test</h2>
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                            <!-- <a href="index.html">Home</a> -->
                        </li>
                        <!-- <li>
                            <span>/</span>
                        </li> -->
                        <li>
                            Candidates Test
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Filter -->
<div class="job-filter-area pt-100">
    <div class="container">
        <form>
            <div class="row">
                <div class="col-sm-4 col-lg-3">
                    <div class="form-group">
                        <label>Select Test Type : </label>
                        <select class="form-control" formControlName="TesttypeId"
                            (change)="onchangeTestType($event.target.value)">
                           
                            <option *ngFor="let testtype of (allTestType | async)" [value]="testtype.Id">
                                {{testtype.Testtype}}
                            </option>
                        </select>

                    </div>
                </div>



            </div>
        </form>
    </div>
</div>
<!-- End Filter -->


<!-- Candidate -->

<div class="candidate-area pb-100" style="margin-top: 31px;">

    <div class="container">

        <div class="row">

            <div class="col-lg-12 col-md-12" *ngFor="let BuildResume of candidatsInfo">

                <div class="candidate-item two ">

                    <div class="left">
                        <form [formGroup]="candidatetestform">
                            <a>{{BuildResume.Description}} </a>
                            <div>
                                <form>
                                <span>
                                  
                                    <label class="form-check-label" for="inlineRadio4">{{BuildResume.AnswerA}} </label>
                                    <input class="form-check-input" type="radio" formControlName="Answer"
                                        name="inlineRadioOptions4" id="inlineRadio4" value="A" (click)="AnswerA()" style="margin-left:2%">
                                </span>
                                <span> <label class="form-check-label" for="inlineRadio5">{{BuildResume.AnswerB}}
                                    </label>
                                    <input class="form-check-input" type="radio" formControlName="Answer"
                                        name="inlineRadioOptions4" id="inlineRadio5" value="B"
                                        style="margin-left:2%" (click)="AnswerB()">
                                    </span>
                                <span>
                                    <label class="form-check-label" for="inlineRadio6">{{BuildResume.Answerc}}
                                </label>
                                    <input class="form-check-input" type="radio" formControlName="Answer"
                                        name="inlineRadioOptions4" id="inlineRadio6" value="C"
                                        style="margin-left:2%" (click)="AnswerC()">
                                    </span>
                                    
                                <span><label class="form-check-label" for="inlineRadio7">{{BuildResume.AnswerD}}
                                </label>
                                    <input class="form-check-input" type="radio" formControlName="Answer"
                                        name="inlineRadioOptions4" id="inlineRadio7" value="D"
                                        style="margin-left:2%" (click)="AnswerD()"></span>
                                    </form>
                            </div>

                            <span class="submit" (click)="submitForm(BuildResume)" style="cursor: pointer;">Submit
                            </span>
                        </form>
                    </div>

                </div>

            </div>

        </div>

    </div>

</div>

<!-- End Candidate -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->