import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ScheduleInterviewService } from './services/schedule-interview.service';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/shared.service';
import { AuthService } from 'src/app/auth.service';
import { GlobalService } from 'src/app/global.service';
import { Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { UserType } from 'src/app/interface';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent implements OnInit, OnDestroy {
  allCandidateStatus: Observable<any>;
  datasource1: MatTableDataSource<any>;
  obs: Observable<any>;
  public openMenu: boolean = false;
  isOver = false;
  cms: string[] = [];
  jobPostId: number = 0;
  cardCandidateStatus: number = 0;
  EmpId: number = 0;
  usertype: number;
  userForm: FormGroup;
  submittedComp: boolean = false;
  allCandidate: Observable<any>;
  jobrole: any
  jobpostId: number
  datasource: any;
  dataFilter: number[] = [];
  candidateId: number;

  constructor(

    private Service: SharedService<any>,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private scheduleInterviewService: ScheduleInterviewService,
    private auth: AuthService,
    public Global: GlobalService, public formatter: NgbDateParserFormatter) {
    // this.fromDate = calendar.getToday();
    // this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }


  ngOnInit(): void {
    this.userForm = this.formBuilder.group({
      CandidateId: [null, Validators.required],
      RecruiterId: [null, Validators.nullValidator],
    });
    this.Service.Get('GetListValue?Id=12', null).subscribe(data => {
      this.allCandidateStatus = data;
    });

    this.getjobdetails();
    this.usertype = this.Global.GetUserType();
    
  }

  get fcomp(): { [key: string]: AbstractControl } {
    return this.userForm.controls;
  }

  getjobdetails() {
    this.Service.Get('Job/GetJobPostDataForFindJob?Id=' + this.auth.getJobPostId(), null).subscribe(data => {
      this.datasource = data;
      this.jobrole = this.datasource[0].JobRoleId;
      this.jobpostId = this.datasource[0].Id;
      this.GetCandidate();
    })
  }
  GetCandidate() {
    this.allCandidate = this.Service.Get('Job/GetCandidateDataByJobPostRole?jobroleId=' + this.jobrole, null);
  }


  ApplyNowClick(jobpost: any) {
    this.Service.Post('AppliedJob/InsertAppliedJob?jobpostId=' + jobpost, null).subscribe(
      (res) => {
        this.toastr.success(res);
      })
  }


  ngOnDestroy(): void {

  }

}