<!-- Navbar -->
<div class="container1">
  <div class="sidenav">
    <app-menu ></app-menu>
  </div>

  <div class="my-profile content">

    <div class="frame-1283">
      <div [ngClass]="{'bg': profile}" (click)="profileclick()" [routerLink]="['/companyhome/profile']"
        class="frame-1280">
        <div [ngClass]="{'bg': profile}" class="short-listed-candidate">Profile Information</div>
      </div>
      <!-- routerLink="shortlistedcandidate" -->
      <div routerLink="shortlistedcandidate" (click)="shortlistclick()" [ngClass]="{'bg': shortlist}" class="frame-1281">
        <div [ngClass]="{'bg': shortlist}" class="short-listed-candidate">ShortListed Candidate</div>
      </div>
      <!-- routerLink="companychange password" -->
      <div routerLink="companychangepassword" (click)="changepassword()" [ngClass]="{'bg': changepass}"
        class="frame-1281" style="height:147px ;">
        <div [ngClass]="{'bg': changepass}" class="change-password">Change Password</div>
      </div>
    </div>
  
    <div class="col-lg-6 col-md-12">
      <div class="tab-content" id="v-pills-tabContent">
        <router-outlet></router-outlet>
      </div>
    </div>
  
  </div>
</div>


<div class="footer"><!-- Footer -->
  <app-footer-style-two></app-footer-style-two>
</div>






