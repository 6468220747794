<div class="bottom-item">
  
    <div class="create-button">
        <div class="frame-1170">
            <div class="education">View Resume <i matTooltip="Close" style="font-size: 35px;margin-left: 660px;margin-top: -15px;color: red;"  
                class='bx bx-x Clear' (click)="close()"></i></div>
            <div class="add-education"></div>
        </div>
    </div>
    <h4>{{filepath}}</h4>

    <div id="outerContainer" style="width: 827px;">
      <!-- <object [data]="safeUrl"  width="100%" height="700px"></object> -->
      <iframe  [src]="safeUrl" width="100%" height="600px"></iframe>
      <!-- <iframe src="http://docs.google.com/gview?url=http://remote.url.tld/path/to/document.doc&embedded=true"></iframe> -->
    </div>
</div> 
