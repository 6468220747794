import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Router } from "@angular/router";
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared.service';
import { GlobalService } from 'src/app/global.service';
import { CollegeInformation } from 'src/app/interface';
import userToken from 'src/app/components/config/userToken';

@Component({
    selector: 'app-userhome',
    templateUrl: './userhome.component.html',
    styleUrls: ['./userhome.component.scss']
})

export class UserHomeComponent implements OnInit, OnDestroy{
    ngOnInit(): void {
        
    }
    ngOnDestroy(): void {
        
    }
}
