import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import { Observable } from 'rxjs';
import { AcademicDiscipline,Country,Qualificationa, Specialization, UserType} from 'src/app/interface';
import { JobRole } from 'src/app/interface';
import { GlobalService } from 'src/app/global.service';

@Component({
    selector: 'app-createcandidate',
    templateUrl: './createcandidate.component.html',
    styleUrls: ['./createcandidate.component.scss']
})
export class CreateCandidateComponent implements OnInit, OnDestroy {

    submitted: boolean = false;
    candidateform: FormGroup;
    displayMe: boolean = false;
    allQualification?: Observable<Qualificationa[]>;
    alldiscipline: Observable<AcademicDiscipline[]>;
    allspecialization: Observable<Specialization[]>;
    allJobRole: Observable<JobRole[]>;
    selectCollegeID:number ;
    fileType: string = '';
    base64textString: string[] = [];
    allCountries: Observable<Country[]>;

    constructor(private formBuilder: FormBuilder,

        private toastr: ToastrService,
        private router: Router,
        private service: SharedService<any>,
        public global: GlobalService
    ) {

    }

    ngOnInit(): void {
        this.candidateform = this.formBuilder.group({
            FirstName: ['', Validators.required],
            LastName: ['', Validators.required],
            ProfileName: ['', null],
            Email: ['', [Validators.required, Validators.maxLength(150), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
            Password: ['', null],
            MobileNo: ['', [Validators.required, Validators.maxLength(10), Validators.pattern("^[0-9]*$")]],
            Photo: ['', null],
            IsGET: ['', Validators.nullValidator],
            InternShipStartDate: ['', Validators.nullValidator],
            InternShipEndDate: ['', Validators.nullValidator],
            UserTypeId: [4],
            QualificationId: ['', Validators.required],
            AcademicDisciplineId: ['', Validators.required],
            SpecializationId: ['', Validators.required],
            JobRoleId: ['', Validators.nullValidator],
            PassOutYear: ['', Validators.nullValidator],
            CollegeId:['',Validators.nullValidator],
            CountryISDCode: ['', Validators.nullValidator],
        });

        this.allQualification = this.service.Get('Qualification/GetQualification?Id=0', null);
        this.allJobRole = this.service.Get('JobRole/GetJobRole?Id=0', null);
        this.alldiscipline = this.service.Get('AcademicDiscipline/GetAcademicDisciplineData?Id=0', null);
        this.allspecialization = this.service.Get('Specialization/GetSpecialization?Id=0', null);
        this.allCountries = this.service.Get('Country/GetCountryISDCode?Id=0',null);

    }

    get f(): { [key: string]: AbstractControl } {
        return this.candidateform.controls;
    }

    CandidateForm() {

        this.submitted = true;
        if (this.candidateform.invalid) {
            this.toastr.error("This data is not Valid");
        }
        else {
            const emp = this.candidateform.getRawValue();
            emp.Photo = this.base64textString[0];
            var UserName = this.candidateform.value.Email;
            emp.ProfileName = this.candidateform.value.FirstName + ' ' + this.candidateform.value.LastName;
            
            this.service.Post('CandidateInfo/InsertCandidtaeInfo', emp).subscribe(
                (res) => {

                    this.toastr.success(res);
                    this.candidateform.reset();
                    // this.service.Post('ResetPassword?loginName=' + UserName, null).subscribe(
                    //     (res) => {
                    //         this.toastr.success(res);
                    //     },
                    // )
                    this.router.navigate(['/candidatetable']);
                },
                (error) => {

                    this.toastr.error(error.error);
                });
            this.submitted = false;
            // }
        }
    }

    onchangeQualification(Qualification: any) {
        this.alldiscipline = this.service.Get('AcademicDiscipline/GetAcademicDisciplineByQualification?QualificationId='+ Qualification, null);
      }

    onchangeDiscipline(event) {
        this.allspecialization = this.service.Get('Specialization/GetSpecializationDataByDiscipline?AcademicDisplineId=' + event, null);
    }



    toggleTag() {
        this.displayMe = true;
    }
    toggleTag1() {
        this.displayMe = false;
    }

    Uploadcompanylogo(event: any) {
        this.fileType = event.target.files[0].type
        var files = event.target.files;
        var file = files[0];
        if (files && file) {
            var reader = new FileReader();
            reader.onload = this._handleReaderLoadedCompany.bind(this);
            reader.readAsBinaryString(file);
        }
    }

    _handleReaderLoadedCompany(readerEvt) {
        var binaryString = readerEvt.target.result;
        this.base64textString.push('data:' + this.fileType + ';base64,' + btoa(readerEvt.target.result));
    }


    ngOnDestroy(): void {

    }
}
