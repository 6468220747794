import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";

import { BuildResume } from './basic-info/basic-info.types';
import { SharedService } from 'src/app/shared.service';
import { GlobalService } from 'src/app/global.service';

@Component({
    selector: 'app-editcandidate',
    templateUrl: './editcandidate.component.html',
    styleUrls: ['./editcandidate.component.scss']
})
export class EditCandidateComponent implements OnInit, OnDestroy {

    candidateInfo = [];
    userId: number ;
    name: string;
    designation: string;
    getresumeData: BuildResume;
    datasourceExp:any;
    Id:number;


    public placeholder: string = '';

    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private router: Router,
        private Service: SharedService<any>,
        private Global: GlobalService,
        private route: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.route.paramMap.subscribe(
            (params) => {
                console.log("params.get('id')", params.get('id'));
                
                this.Id = Number(params.get('id'));
            }
        );
        this.userId = this.Id
        this.getUser();
    }

    getUser() {
        this.Service.Get('CandidateInfo/GetCandidateInfoByUserId?userId=' + this.Id, null).subscribe(
            (res) => {
                this.getresumeData = res;
                this.getAllExperience(this.getresumeData.Id);
            },        
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }

    getAllExperience(empId : number) {
        this.Service.Get('CandidateExperienceInfo/GetCandidateExperienceInfoByEmpId?EmpId=0' + empId, null).subscribe(
            (res) => {
                this.datasourceExp = res;
            },        
            (error) => {
                this.toastr.error(error.error);
            }
        );
    }

    // onLogout(event: Event) {
    //     event.preventDefault();
    //     this.Service.logout().subscribe(
    //         (res) => {
    //             this.toastr.success(res);
    //         },
    //         (error) => {
    //             this.toastr.error(error.error);
    //         }
    //     )
    // }

    ngOnDestroy(): void {

    }
}
