import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {  ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';

import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared.service';
import { GlobalService } from 'src/app/global.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-add-edit-section',
    templateUrl: './add-edit-section.component.html',
    styleUrls: ['./add-edit-section.component.scss']
})
export class AddEditCandidateSectionComponent implements OnInit, OnDestroy {
    submitted: boolean = false;
    SectionInfoForm: FormGroup;
    public info: any;
    summary: string;
    response: any;
    openform = false;
    boolVar = true;
    fileType: string = '';
    allsection: Observable<any[]>;
    hideForm: boolean = false;
    hideTable: boolean = true;
    saveEduBtn: boolean = true;
    updateEduBtn: boolean = false;
    public section = [];
    submittedEdu: boolean = false;

    constructor(private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private Service: SharedService<any>,
        private Global: GlobalService,
        private dialogRef: MatDialogRef<AddEditCandidateSectionComponent>,
        @Inject(MAT_DIALOG_DATA) public editData: any
    ) { }
    
    ngOnInit(): void {
        this.SectionInfoForm = this.formBuilder.group({
            Id: ['', null],
            Section1: ['', null],
            Section2: ['', null],
            Section3: ['', null],
        });

        this.allsection =  this.Service.Get('CandidateInfo/GetSectionData?Id=0', null)
        
        if (this.editData) {
            this.editEdu(this.editData)
            this.updateEduBtn = true
            this.saveEduBtn = false
        }
        else {
            this.updateEduBtn = false
            this.saveEduBtn = true
        }
    }

    close(){
        this.dialogRef.close(); 
    }
    selectionInfoForm() {
        this.submitted = true;
        if (this.SectionInfoForm.invalid) {
            this.toastr.error("Please fill out the required fields (* fields)");
        }
        else {
            const updatepersonalinfo = this.SectionInfoForm.getRawValue();
            this.Service.Put('CandidateInfo/UpdateSection', updatepersonalinfo).subscribe(
                (res) => {
                    this.toastr.success(res);
                    this.dialogRef.close('Update');  

                },
                (error) => {
                   
                    this.toastr.error(error.error);
                });
            this.submitted = false;
        }
    }

    editEdu(event: any) {
        this.Service.Get('CandidateInfo/GetCandidateInfo?id=' + event.Id, null).subscribe(
            (res) => {
                this.section = res;
                this.SectionInfoForm.controls["Id"].setValue(this.section[0].Id);
                this.SectionInfoForm.controls["Section1"].setValue(this.section[0].Section1);
                this.SectionInfoForm.controls["Section2"].setValue(this.section[0].Section2);
                this.SectionInfoForm.controls["Section3"].setValue(this.section[0].Section3);  
                
        this.Service.Get('CandidateInfo/GetSectionData?Id=0', null).subscribe(data => {
            this.allsection = data;
        })
            },

            (error) => {
                //if (error.status == 401) this.router.navigate(['/login']);
                this.toastr.error(error.error);
            });
        this.submittedEdu = false;
    }

    ngOnDestroy(): void {

    }
}

