<!-- Navbar -->
<div class="navbar-area fixed-top">
    <div class="mobile-nav mean-container" *ngIf="smallscreen">
        <div class="mean-bar">
            <a class="navbar-brand" href="#"><img src="assets/png/finallightthme.png" alt="Logo"></a>
            <a *ngIf="list" class="meanmenu-reveal" style="color:#fff;right:0;left:auto;top: 23px;" (click)="click()">
                <span>
                    <span>
                        <span></span>
                    </span>
                </span>
            </a>
            <a *ngIf="close" (click)="closeclick1()" class="meanmenu-reveal meanmenu-reveal meanclose"
                style="right: 0px; left: auto; text-align: center; text-indent: 0px; font-size: 18px;">X</a>
        </div>

        <nav class="mean-nav" *ngIf="menu">
            <ul class="navbar-nav" style>
                <li class="nav-item">
                    <a routerLink="/company-register" class="nav-link" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}">
                        Company
                    </a>
                </li>

                <li class="nav-item"><a routerLink="/candidate/register" class="nav-link" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}">Candidate</a></li>

                <li class="nav-item"><a routerLink="/register" class="nav-link" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}">Institute/University/College</a></li>
            </ul>
        </nav>
        <div class="side-nav">

            <a class="login-left" routerLink="/login" *ngIf="!myGlobals.isSubmitted" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}" style="margin-top: 56px;">
                <i class="flaticon-enter"></i>
                Login
            </a>
            <a class="login-left" *ngIf="myGlobals.isSubmitted" (click)="onLogout($event)"><i
                    class="flaticon-enter"></i> Logout</a>
        </div>
    </div>


    <div class="main-nav" *ngIf="largescreen">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <!-- <a class="navbar-brand" href="#"><img src="assets/img/logo-4.png" alt="Logo"></a> -->

                <a class="navbar-brand"><img src="assets/png/finallightthme.png" alt="Logo"></a>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <!-- style="padding-left: 968px;" -->
                    <ul class="navbar-nav">
                        <li class="nav-item" *ngIf="!myGlobals.isSubmitted">
                            <a routerLink="" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Home</a>
                        </li>


                        <li class="nav-item">

                            <a style="font-size: 16px;" class="nav-link dropdown-toggle"
                                *ngIf="!myGlobals.isSubmitted">Jobs<i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">


                                <li class="nav-item" (click)="WorkFromHome(1)"><a class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Work From
                                        Home </a></li>

                                <li class="nav-item" (click)="PartTimeJob(2)"><a class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Part Time
                                        Jobs</a></li>

                                <li class="nav-item" (click)="women(3)"><a class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Jobs For Women</a></li>

                                <li class="nav-item" (click)="freshers(4)"><a class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Jobs For Freshers</a></li>

                                <li class="nav-item" (click)="Internship(5)"><a class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                        Internship</a></li>

                            </ul>


                        </li>

                        <!-- Candidate -->


                        <!-- <li class="nav-item" *ngIf="myGlobals.isSubmitted  && myGlobals.isCandidate">
                            <a routerLink="/candidate/dashboard/', this.loginData.UserId]);" class="nav-link"   routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a>
                        </li>

                        <li class="nav-item" *ngIf="myGlobals.isCandidate && myGlobals.isSubmitted">
                            <a routerLink="/jobs" class="nav-link"  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Find Jobs</a>
                        </li>


                        <li class="nav-item" *ngIf="myGlobals.isCandidate  && myGlobals.isSubmitted">
                            <a routerLink="/recommandedjobs" class="nav-link"  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Recommanded Jobs </a> {{userid}}
                        </li>

                        <li class="nav-item" *ngIf="myGlobals.isCandidate  && myGlobals.isSubmitted">
                            <a routerLink="/bookjobs" class="nav-link"  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">BookMarked Jobs </a> {{userid}}
                        </li> -->


                        <!-- Owner -->

                        <li class="nav-item" *ngIf="myGlobals.isOwner && myGlobals.isSubmitted">
                            <a routerLink="ownerdashboard" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Home</a>
                        </li>


                        <li class="nav-item" *ngIf="myGlobals.isOwner && myGlobals.isSubmitted">
                            <a routerLink="jobpost" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Job Post</a>
                        </li>

                        <li class="nav-item" *ngIf="myGlobals.isOwner && myGlobals.isSubmitted">
                            <a routerLink="ManageJobs" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Manage Jobs </a>
                        </li>


                        <li class="nav-item" *ngIf="myGlobals.isOwner && myGlobals.isSubmitted">
                            <a routerLink="candidatetableforowner" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Candidate</a>
                        </li>

                        <li class="nav-item" *ngIf="myGlobals.isOwner && myGlobals.isSubmitted">
                            <a routerLink="applyjobs" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Applied Job</a>
                        </li>

                        <li class="nav-item" *ngIf="myGlobals.isOwner && myGlobals.isSubmitted">
                            <a routerLink="companyInfo" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Company</a>
                        </li>

                        <li class="nav-item" *ngIf="myGlobals.isOwner && myGlobals.isSubmitted">
                            <a routerLink="receuitertable" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Users</a>
                        </li>


                        <li class="nav-item" *ngIf="myGlobals.isOwner && myGlobals.isSubmitted">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Masters<i
                                    class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/companies/create" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Create
                                        Company</a></li>
                                <li class="nav-item"><a routerLink="/employers-register" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Create
                                        Candidate</a></li>
                                <li class="nav-item"><a routerLink="/users" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Create Users</a></li>
                                <li class="nav-item"><a routerLink="/post-a-job" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Create
                                        Jobs</a></li>
                                <li class="nav-item"><a routerLink="/register" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Create
                                        College</a></li>

                            </ul>
                        </li>

                        <li class="nav-item" *ngIf="myGlobals.isOwner && myGlobals.isSubmitted">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Configuration<i
                                    class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/country" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Country</a></li>
                                <li class="nav-item"><a routerLink="/state" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">State</a></li>
                                <li class="nav-item"><a routerLink="/city" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">City</a></li>
                                <li class="nav-item"><a routerLink="/currency" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Currency</a>
                                </li>
                                <li class="nav-item"><a routerLink="/industrytype" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Industry
                                        Types</a></li>
                                <li class="nav-item"><a routerLink="/jobrole" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Job Role</a></li>
                                <li class="nav-item"><a routerLink="/language" class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Languages</a></li>
                                <li class="nav-item"><a routerLink="/qualification" class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Qualification</a></li>
                                <li class="nav-item"><a routerLink="/skill" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Skills</a></li>
                            </ul>
                        </li>
                    </ul>
                    <!-- company -->
                    <!-- 
 <li class="nav-item" *ngIf="myGlobals.isCompany && myGlobals.isSubmitted">
    <a routerLink="/companyhome/profile" class="nav-link"  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">My Profile</a>
  </li>

  <li class="nav-item" *ngIf="myGlobals.isCompany && myGlobals.isSubmitted">
    <a routerLink="/post-a-job" class="nav-link"  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Job Post</a>
  </li>

  <li class="nav-item" *ngIf="myGlobals.isCompany && myGlobals.isSubmitted">
    <a routerLink="/candidates" class="nav-link"  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Find Candidate</a>
  </li>

  <li class="nav-item" *ngIf="myGlobals.isCompany && myGlobals.isSubmitted">
    <a routerLink="/companymanagejob" class="nav-link"  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Manage Jobs</a>
  </li> -->




                    <div class="side-nav">
                        <ul class="navbar-nav">

                            <a class="login-left loginbtn" routerLink="/login" *ngIf="!myGlobals.isSubmitted"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">

                                Log in
                            </a>

                            <a class="login-left regibtn" routerLink="/candidate/register"
                                *ngIf="!myGlobals.isSubmitted" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">

                                Register
                            </a>

                            <!-- <li class="nav-item" *ngIf="!myGlobals.isSubmitted  && !myGlobals.isUser">
                            <a routerLink="" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Home</a>
                        </li> -->

                            <!-- <li class="nav-item">
                            <a routerLink="/about" *ngIf="!myGlobals.isSubmitted" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">About Us</a>
                        </li> -->
                            <svg class="line-136-stroke" width="2" height="27" viewBox="0 0 2 27" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M0.984374 26.3184L0.984375 0.318359L1.98438 0.318359L1.98437 26.3184L0.984374 26.3184Z"
                                    fill="#969696" />
                            </svg>

                            <li class="nav-item" style="margin-top: -3px;">
                                <a style="font-size: 16px;" class="nav-link dropdown-toggle"
                                    *ngIf="!myGlobals.isSubmitted">For Employers<i class='bx bx-chevron-down'></i></a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a routerLink="/company-register" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">
                                            Company
                                        </a>
                                    </li>

                                    <!-- <li class="nav-item"><a routerLink="/candidate/register" class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Candidate </a></li> -->

                                    <li class="nav-item"><a routerLink="/register" class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Institute/University/College</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <a class="login-left" *ngIf="myGlobals.isSubmitted" (click)="onLogout($event)"><i
                                class="flaticon-enter"></i> Logout</a>
                    </div>
                </div>
            </nav>
        </div>
    </div>

</div>
<!-- End Navbar -->