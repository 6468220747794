import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/global.service';
import userToken from "../../config/userToken";
import { EmployersRegisterComponent } from '../../pages/employers-register/employers-register.component';

@Component({
  selector: 'app-navbar-style-one',
  templateUrl: './navbar-style-one.component.html',
  styleUrls: ['./navbar-style-one.component.scss']
})
export class NavbarStyleOneComponent implements OnInit {
  token: any = userToken.token;
  isToken: boolean = false;
  screenWidth: any;
  screenHeight: any;
  menu: boolean = false
  close: boolean = false;
  list: boolean = true;
  smallscreen: boolean = false;
  largescreen: boolean = false;
  constructor(public myGlobals: GlobalService,
    private toastr: ToastrService,
    public router: Router,
    private dialog: MatDialog,
  private Global: GlobalService,
  ) { }

  ngOnInit(): void {

    this.getScreenSize();
  }
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 768) {
      this.smallscreen = true;
      this.largescreen = false
    }
    else {
      this.smallscreen = false;
      this.largescreen = true;
    }

  }
  Login() {
    this.router.navigate(['/login']);
  }

  click() {
    this.menu = true;
    this.close = true;
    this.list = false;
  }

  closeclick1() {
    this.menu = false;
    this.close = false;
    this.list = true;
  }
  WorkFromHome(jobtype:number){
    this.Global.setjobTypeId(jobtype);

    this.router.navigate(['workfromhomejobs']);
  }
  PartTimeJob(jobtype:number){
    this.Global.setjobTypeId(jobtype);
    this.router.navigate(['parttimejob']);
  }
  women(jobtype:number){
    this.Global.setjobTypeId(jobtype);
    this.router.navigate(['jobforwomen']);
  }
  freshers(jobtype:number){
    this.Global.setjobTypeId(jobtype);
    this.router.navigate(['jobforfreshers']);
  }
  Internship(jobtype:number){
    this.Global.setjobTypeId(jobtype);
    this.router.navigate(['jobforinternship']);
  }
  getToken() {
    // if(this.token) this.isToken = true;
  }

  // onLogout(event: Event) {
  //     event.preventDefault();
  //     this.navbarService.logout().subscribe(
  //         (res) => {
  //             this.toastr.success(res.message);
  //         },
  //         (error) => {
  //             this.toastr.error(error.error.message);
  //         }
  //     )
  // }

  AddCandidate() {
    const dialogRef = this.dialog.open(EmployersRegisterComponent, {
      width: '92%',
      position: { right: '1px', top: '96px' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == "Save") {

      }
    });
  }
}
