
    <div class="profile-content">
        <form [formGroup]="basicInfoForm" id="noScreen" >
            <div style="width: 10px;
            height: 5px;
            position: relative;
            padding-left: 757px;
            top: -16px;"  ><i matTooltip="Close" style="font-size: 25px;"  
                class='bx bx-x Clear' (click)="close()"></i></div>
                
            <div class="profile-content-inner">
                
                <div class="Name">Basic Information</div>
                <div class="row">
                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>First Name <label class="star">*</label></label>
                            <input type="text" class="form-control" placeholder="Tom Henry" formControlName="FirstName"
                                [ngClass]="{ 'is-invalid': submitted && f.FirstName.errors }" (keypress)="keyPressAlpha($event)">
                            <div *ngIf="submitted && f.FirstName.errors" class="invalid-feedback">
                                <div *ngIf="f.FirstName.errors.required">First Name is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>Last Name <label class="star">*</label></label>
                            <input type="text" class="form-control" placeholder="Tom Henry" formControlName="LastName"
                                [ngClass]="{ 'is-invalid': submitted && f.LastName.errors }" (keypress)="keyPressAlpha($event)">
                            <div *ngIf="submitted && f.LastName.errors" class="invalid-feedback">
                                <div *ngIf="f.LastName.errors.required">Last Name is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>Job Role <label class="star">*</label></label>
                                <input type="text" class="form-control form-select" [formControl]="formControlObj"
                                    [matAutocomplete]="auto" placeholder="Select Job Role">
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnForRole" panelWidth="auto"
                                    (optionSelected)="onchangejobrole($event.option.value.Id)" >
                                    <mat-option *ngFor="let jobrole of filterJobRole | async" [value]="jobrole" style="min-width: 235px;">
                                        {{jobrole.JobRole}}
                                    </mat-option>
                                </mat-autocomplete>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>Passout Year <label class="star">*</label></label>
                            <input type="text" id="username" class="form-control" placeholder="Passout Year"
                                formControlName="PassoutYear"
                                [ngClass]="{ 'is-invalid': submitted && f.NoOfExperience.errors }" (keypress)="keyPressAlphaNumeric($event)">
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>Expected Salary</label>
                            <input type="text" class="form-control" placeholder="Expected Salary"
                                formControlName="ExpectedSalary"
                                [ngClass]="{ 'is-invalid': submitted && f.ExpectedSalary.errors }">
                            <div class="invalid-feedback" *ngIf="submitted && f.ExpectedSalary.errors">
                                <div *ngIf="f.ExpectedSalary.errors.required">
                                    Expected Salary is required
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>Mobile No. <label class="star">*</label></label>
                            <input type="text" class="form-control" placeholder="+91xxxxx-xxxxx"
                                formControlName="MobileNo" [ngClass]="{ 'is-invalid': submitted && f.MobileNo.errors }" (keypress)="keyPressAlphaNumeric($event)" maxlength="15">
                            <div *ngIf="submitted && f.MobileNo.errors" class="invalid-feedback">
                                <div *ngIf="f.MobileNo.errors.required">Phone number is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>Country <label class="star">*</label></label>
                                <input type="text" class="form-control form-select" [formControl]="formCountries"
                                [ngClass]="{ 'is-invalid': submitted && f.CountryId.errors }"
                                [matAutocomplete]="autocou" placeholder="Select Country">
                                <mat-autocomplete #autocou="matAutocomplete" [displayWith]="displayFnForCountry"
                                    (optionSelected)="onchangeCountry($event.option.value.Id)" panelWidth="auto">
                                    <mat-option *ngFor="let Countries of filterCountries | async" [value]="Countries" style="min-width: 235px;">
                                        {{Countries.CountryName}}
                                    </mat-option>
                                </mat-autocomplete>
                            <div class="invalid-feedback" *ngIf="submitted && f.CountryId.errors">
                                <div *ngIf="f.CountryId.errors.required">
                                    Country is required
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                            <label>State <label class="star">*</label></label>
                                <input type="text" class="form-control form-select" [formControl]="formStates"
                                    [matAutocomplete]="autostate" placeholder="Select State"
                                    [ngClass]="{ 'is-invalid': submitted && f.State.errors }">
                                <mat-autocomplete #autostate="matAutocomplete" [displayWith]="displayFnForState"
                                    (optionSelected)="onchangeState($event.option.value.Id)" panelWidth="auto">
                                    <mat-option *ngFor="let states of filterStates | async" [value]="states" style="min-width: 235px;">
                                        {{states.StateName}}
                                    </mat-option>
                                </mat-autocomplete>
                            <div class="invalid-feedback" *ngIf="submitted && f.StateId.errors">
                                <div *ngIf="f.StateId.errors.required">
                                    State is required
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="col-lg-4 col-md-12">
                        <div class="form-group">
                           <label>City <label class="star">*</label></label>
                            <input type="text" class="form-control form-select" [formControl]="formCities"
                                [matAutocomplete]="autocity" placeholder="Select State"
                                [ngClass]="{ 'is-invalid': submitted && f.CityIdOfEmployeeInfo.errors }">
                            <mat-autocomplete #autocity="matAutocomplete" [displayWith]="displayFnForCity"
                                (optionSelected)="onchangeCity($event.option.value.Id)" panelWidth="auto">
                                <mat-option *ngFor="let cities of filterCities | async" [value]="cities" style="min-width: 235px;">
                                    {{cities.CityName}}
                                </mat-option>
                            </mat-autocomplete>
                            <div class="invalid-feedback" *ngIf="
                        submitted && f.CityIdOfEmployeeInfo.errors">
                                <div *ngIf="f.CityIdOfEmployeeInfo.errors.required">
                                    City is required
                                </div>
                            </div>
                        </div>
                    </div>

                      <!-- <div class="col-lg-4 col-md-12">
                    <div class="form-group">
                        <label>Profile Picture</label>
                        <input (change)="Uploadcompanylogo($event)" formControlName="Photo" type="file" class="input">
                    </div>
                </div> -->

                </div>
            </div>

            <!-- <button (click)="baiscInfoForm()" class="btn dashboard-btn">Update Your
                Information</button> -->

                <div class="button">
                    <button (click)="close()" class=" btn cancle " >
                        Cancel
                    </button>
        
                <button (click)="baiscInfoForm()" class="btn savebtn">
                    Save
                </button>
                </div>
    
                
        </form>
    </div>
