import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

import { SharedService } from 'src/app/shared.service';
import { MatTableDataSource } from '@angular/material/table';
import { CompanyInformation } from 'src/app/interface';
import { MatPaginator } from '@angular/material/paginator';


@Component({
    selector: 'app-companytable',
    templateUrl: './companytable.component.html',
    styleUrls: ['./companytable.component.scss']
})
export class CompanyTableComponent implements OnInit, OnDestroy {

    displayedColumns: string[] = [ 'JobCompanyName' , 'IndustryType','CityName'];
    datasource: MatTableDataSource<CompanyInformation[]>;
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    
    constructor(
        private toastr: ToastrService,
        private router: Router,
        private service: SharedService<any>
    ) { }

    ngOnInit(): void {
        this.getCompany()
    }

    getCompany() {
       
        this.service.Get('Company/GetCompanyDataforOwner?Id=0', null).subscribe(data => {
            this.datasource = new MatTableDataSource(data);
            this.datasource.paginator = this.paginator;
            },
            (error) => {
                //if (error.status == 401) this.router.navigate(['/login']);
                this.toastr.error(error.error);
            });
    }
    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.datasource.filter = filterValue.trim().toLowerCase();
      }
    ngOnDestroy(): void {

    }
   
}
